import { all } from 'redux-saga/effects';
import user from './user';
import organization from './organization';
import activity from './activity';
import room from './room';
import error from './error';
import device from './device';
import liveprofile from './liveprofile';
import vod from './vod';
import app from './app';

export default function* sagas() {
  yield all([
    ...user,
    ...organization,
    ...activity,
    ...room,
    ...device,
    ...liveprofile,
    ...error,
    ...vod,
    ...app,
  ]);
}
