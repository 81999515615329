import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  // Request API
  requestVod: ['_id'],

  // Set reducers
  setVodInfo: ['vod'],

  // Request Socket
  recover: ['vodId'],

  // Vod events
  stateUpdate: ['vodId', 'state'],
  videoAvailable: ['locationUrl'],
});

export { Types };
export default Creators;
