import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { View, StyleSheet, Text, useWindowDimensions } from 'react-native';
import { useForm } from 'react-hook-form';
import { ScrollView, TouchableOpacity } from 'react-native-web';
import { isEmpty } from 'lodash';
import { createStructuredSelector } from 'reselect';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import activityActions from '../../App/actions/activity';
import activitySelectors from '../../App/selectors/activity';
import userSelectors from '../../App/selectors/user';

import ScheduleForm from '../../components/ScheduleForm';
import colors from '../../constants/colors';
import fonts from '../../constants/fonts';
import { userCanEditActivity } from '../../helpers/activity';
import { navigate } from '../../helpers/navigation';
import { screenName } from '../../constants/navigation';
import { useActivityStartTime } from '../../hooks/activity';
import { Strings } from '../../helpers/strings';

const styles = StyleSheet.create({
  formContainer: {
    backgroundColor: colors.getGrayAthens(),
    padding: 20,
    borderRadius: 20,
  },
  saveButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 20,
    width: 384,
    borderRadius: 10,
    backgroundColor: colors.getMainPurple(),
  },
  saveButtonText: {
    fontFamily: fonts.getSignika(),
    fontSize: 22,
    fontWeight: 600,
    color: colors.getMainWhite(),
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
});

const MobileWidth = 800;

const ActivityEdit = ({ requestUpdateActivity, activityInfo, requestActivity, route, user }) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (route?.params?.section_name !== 'edit') {
      // If an item is selected in the left navigator, somehow the route here is updated
      // and doesn't have an ID anymore. Testing if the route props still have 'edit'
      // as section_name allows us to avoid that case.
      return;
    }

    if (route?.params?.id) {
      if (activityInfo?._id === route.params.id) {
        if (!isEmpty(user)) {
          if (!userCanEditActivity(activityInfo, user)) {
            navigate(screenName.CALENDAR, { section_name: 'create' });
          }
        }
      } else {
        requestActivity(route.params.id);
      }
    } else {
      navigate(screenName.CALENDAR, { section_name: 'create' });
    }
  }, [route, activityInfo, user]);

  const now = useActivityStartTime();

  const formProps = useForm({
    defaultValues: {
      name: undefined,
      _liveProfile: undefined,
      _uploadProfile: undefined,
      _room: undefined,
      date: now,
      startDatetime: now,
      endDatetime: now,
      isPrivate: true,
      shouldLive: true,
      shouldRecord: false,
      interactionsAreEnabled: false,
    },
  });

  const { width } = useWindowDimensions();

  const submit = (data) => {
    requestUpdateActivity(activityInfo._id, {
      name: data.name,
      _liveProfile: data._liveProfile,
      _uploadProfile: data._uploadProfile,
      _room: data._room,
      startDatetime: moment(data.startDatetime).toISOString(),
      endDatetime: moment(data.endDatetime).toISOString(),
      isPrivate: data.isPrivate,
      shouldLive: data.shouldLive,
      shouldRecord: data.shouldRecord,
      interactionsAreEnabled: data.interactionsAreEnabled,
    });
  };

  const buttonWidth = { width: width <= MobileWidth ? '50%' : 384 };

  return (
    <ScrollView style={{ height: 0 }}>
      <View style={styles.formContainer}>
        {activityInfo?._id ? (
          <ScheduleForm
            formProps={{ ...formProps }}
            defaultData={activityInfo}
            now={now}
            isMobile={width <= MobileWidth}
          />
        ) : (
          <Text>{t(Strings.WAITING)}</Text>
        )}
      </View>
      <View style={[styles.row, { marginTop: 31, zIndex: -1 }]}>
        <TouchableOpacity
          style={[styles.saveButtonContainer, buttonWidth]}
          onPress={formProps.handleSubmit(submit)}
        >
          <Text style={styles.saveButtonText}>{t(Strings.SAVE)}</Text>
        </TouchableOpacity>
      </View>
    </ScrollView>
  );
};

ActivityEdit.propTypes = {
  route: PropTypes.object.isRequired,
  activityInfo: PropTypes.object,
  user: PropTypes.object.isRequired,

  // Action
  requestActivity: PropTypes.func.isRequired,
  requestUpdateActivity: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  activityInfo: activitySelectors.makeSelectActivityInfo(),
  user: userSelectors.makeSelectUserInfo(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    requestActivity: (_id) => dispatch(activityActions.requestActivity(_id)),
    requestUpdateActivity: (_id, formData) =>
      dispatch(activityActions.requestUpdateActivity(_id, formData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivityEdit);
