import { requestAPI, getHeadersAPI } from '../../helpers/request';

const basePath = '/organization';

const paths = {
  getAuthInfo: () => {
    return `${basePath}/auth/info`;
  }, // GET
  getOrganizationById: (id) => {
    return `${basePath}/${id}`;
  }, // GET
  getOrganizations: () => {
    return `${basePath}`;
  }, // GET
  getRoomById: (id) => {
    return `/room/${id}`;
  }, // GET
  getRooms: () => {
    return `/room`;
  }, // GET
};

// ------------------------------------------------------- //
// --------------------- REQUEST ------------------------- //
// ------------------------------------------------------- //

// ---------- GET
const requestAuthInfo = async () => {
  return requestAPI(paths.getAuthInfo(), getHeadersAPI('GET', false));
};

const requestOrganization = async (id) => {
  return requestAPI(paths.getOrganizationById(id), getHeadersAPI('GET', true));
};

const requestOrganizations = async () => {
  return requestAPI(paths.getOrganizations(), getHeadersAPI('GET', true));
};

const requestRoom = async (id) => {
  return requestAPI(paths.getRoomById(id), getHeadersAPI('GET', true));
};

const requestRooms = async () => {
  return requestAPI(paths.getRooms(), getHeadersAPI('GET', true));
};

// ---------- POST

// ---------- PATCH

// ---------- DELETE

export default {
  requestAuthInfo,
  requestOrganization,
  requestOrganizations,
  requestRooms,
  requestRoom,
};
