import { StyleSheet, View, Text, ActivityIndicator, Platform } from 'react-native';
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import colors from '../../constants/colors';
import { AUTH_TYPE, WEB_PLATFORM } from '../../constants/global';

import userActions, { Types as userTypes } from '../../App/actions/user';
import userSelectors from '../../App/selectors/user';
import organizationSelectors from '../../App/selectors/organization';
import organizationActions from '../../App/actions/organization';
import appSelectors from '../../App/selectors/app';
import LoginCAS from './LoginCAS';
import LoginLocal from './LoginLocal';

import { getOrigin } from '../../helpers/navigation';
import { screenName } from '../../constants/navigation';
import AppVersion from '../../components/Generic/appVersion';
import LogoAnimated from '../../components/Svg/logoAnimated';
import fonts from '../../constants/fonts';
import LoginGoogle from './LoginGoogle';
import LoginMicrosoft from './LoginMicrosoft';
import { isValidHttpUrl } from '../../helpers/utils';

const { REQUEST_LOGIN_LOCAL, REQUEST_LOGIN_CAS } = userTypes;
const { LOCAL, CAS, GOOGLE, AZURE_AD } = AUTH_TYPE;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'stretch',
    flex: 1,
    backgroundColor: 'white',
  },
  formContainer: {
    maxHeight: 'calc(var(--app-height) * 0.9)',
    overflow: 'auto',
    width: 'min(80vw, 450px)',
    borderRadius: 20,
    backdropFilter: 'blur(45px)',
    border: `solid 0.5px ${colors.getGraySantas(0.2)}`,
    backgroundColor: colors.getGrayAthens(),
    paddingHorizontal: 30,
    paddingVertical: 33,
  },
  cover: {
    flex: 1,
    backgroundColor: colors.getMirage(),
  },
  form: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontFamily: fonts.getHalisR(),
    fontSize: 36,
    fontWeight: 900,
    textAlign: 'center',
    color: colors.getMainGreen(),
    alignSelf: 'center',
  },
  logoContainer: {
    width: '100%',
    marginBottom: 50,
    height: 20,
  },
  logoContainerNorOrganizationName: {
    width: '100%',
    marginBottom: 50,
    height: 60,
  },
  loader: {
    position: 'absolute',
    zIndex: 1,
  },
  divider: {
    marginTop: 30,
    marginBottom: 30,
    borderTopColor: 'black',
    borderTopWidth: 1,
    borderTopStyle: 'solid',
    height: 1,
  },
  button: {
    marginTop: 30,
  },
});

const Login = ({
  route,
  navigation,
  currentlySending,
  organization,
  isAuthenticated,
  userRole,
  requestLoginLti,
  requestAuthInfo,
  setIsAuthenticated,
  authUser,
}) => {
  const { name, authMethods } = organization;
  const initialParamsRef = useRef(route?.params);

  const defaultRedirect = () => {
    navigation.navigate(screenName.CALENDAR);
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (isAuthenticated) {
      if (route?.params?.lti && route?.params?.redirect) {
        const { redirect, redirectParams } = route.params;
        const screen = redirect.replace(getOrigin(), '').split('/').pop();
        const params = JSON.parse(redirectParams);
        const unsub = navigation.addListener('focus', () => {
          navigation.navigate(screenName.ACTIVITY_SCREENS, { screen, params });
        });
        return unsub;
      }
      // route can't be used. AuthAwareStack is updated after login (isAuthenticated) and LoginPage component are reload (lose route)
      const redirect =
        Platform.OS === WEB_PLATFORM
          ? new URL(window.location.href).searchParams?.get('redirect')
          : undefined;
      const unsub = navigation.addListener('focus', () => {
        if (redirect && isValidHttpUrl(redirect)) {
          // React web only
          window.location.replace(redirect);
        } else {
          defaultRedirect();
        }
      });
      return unsub;
    }
    requestAuthInfo();
  }, [isAuthenticated, userRole]);

  useEffect(() => {
    const token = route?.params?.token;
    const refreshToken = route?.params?.refresh_token;
    if (token) {
      authUser(token, refreshToken);
    }
  }, []);

  const getAuthMethods = () => {
    const methods = [];
    if (!authMethods) return methods;

    // Succession of if the ensure the same order no matter what!
    if (authMethods[LOCAL]) methods.push(<LoginLocal key={LOCAL} />);

    if (authMethods[CAS] || authMethods[GOOGLE] || authMethods[AZURE_AD])
      if (authMethods[LOCAL]) methods.push(<View key="divider" style={styles.divider} />);

    if (authMethods[CAS]) methods.push(<LoginCAS key={CAS} method={authMethods[CAS]} />);

    if (authMethods[GOOGLE])
      methods.push(
        <LoginGoogle
          key={GOOGLE}
          method={authMethods[GOOGLE]}
          error={route?.params?.google_error}
        />,
      );

    if (authMethods[AZURE_AD])
      methods.push(
        <LoginMicrosoft
          key={AZURE_AD}
          method={authMethods[AZURE_AD]}
          error={route?.params?.azure_error}
        />,
      );

    return methods;
  };

  const nameIsKalyzee = name?.trim()?.toLowerCase() === 'kalyzée';
  return (
    <View style={styles.container}>
      <View style={styles.form}>
        {organization?.authMethods ? (
          <View style={styles.formContainer}>
            {!nameIsKalyzee ? <Text style={styles.title}>{name}</Text> : null}
            <View
              style={
                name?.trim()?.length && !nameIsKalyzee
                  ? styles.logoContainer
                  : styles.logoContainerNorOrganizationName
              }
            >
              <LogoAnimated colorLeft={colors.getBluePrussian()} />
            </View>
            {getAuthMethods()}
          </View>
        ) : (
          <ActivityIndicator animating={true} size="large" style={styles.loader} />
        )}
      </View>
      <AppVersion></AppVersion>
    </View>
  );
};

Login.propTypes = {
  route: PropTypes.object,
  navigation: PropTypes.object,
  currentlySending: PropTypes.bool.isRequired,
  organization: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  userRole: PropTypes.string,
  // Actions
  requestLoginLti: PropTypes.func.isRequired,
  requestAuthInfo: PropTypes.func.isRequired,
  setIsAuthenticated: PropTypes.func.isRequired,
  authUser: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return {
    requestLoginLti: (token) => dispatch(userActions.requestLoginLti(token)),
    requestAuthInfo: () => dispatch(organizationActions.requestAuthInfo()),
    setIsAuthenticated: (bool) => dispatch(userActions.setIsAuthenticated(bool)),
    authUser: (token, refreshToken) => dispatch(userActions.authUser(token, refreshToken)),
  };
};

const mapStateToProps = createStructuredSelector({
  currentlySending: appSelectors.makeSelectCurrentlySending([
    REQUEST_LOGIN_LOCAL,
    REQUEST_LOGIN_CAS,
  ]),
  organization: organizationSelectors.makeSelectOrganization(),
  isAuthenticated: userSelectors.makeSelectIsAuthenticated(),
  userRole: userSelectors.makeSelectRole(),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
