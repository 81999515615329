import { requestAPI, getHeadersAPI } from '../../helpers/request';

const basePath = '/vod';

const paths = {
  getVodById: (_id) => {
    return `${basePath}/${_id}`;
  }, // GET
};

// ------------------------------------------------------- //
// --------------------- REQUEST ------------------------- //
// ------------------------------------------------------- //

// ---------- GET
const requestVodById = async (id) => {
  return requestAPI(paths.getVodById(id), getHeadersAPI('GET', true));
};

export default {
  requestVodById,
};
