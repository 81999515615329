import React, { useState, useEffect, useMemo } from 'react';
import { Text, StyleSheet, TouchableOpacity, View, useWindowDimensions } from 'react-native';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import ArrowIcon from '../../assets/settings/icon-title-arrow.svg';

import IconCalendar from '../../assets/activities/icon-calendar.svg';
import IconCalendarOn from '../../assets/activities/icon-calendar-on.svg';
import IconAddEvent from '../../assets/activities/icon-add-event.svg';
import IconAddEventOn from '../../assets/activities/icon-add-event-on.svg';
import IconDisconnect from '../../assets/navigation/disconnect.svg';

import colors from '../../constants/colors';
import fonts from '../../constants/fonts';

import userSelectors from '../../App/selectors/user';

import ActivityList from './activityList';
import ActivityCreate from './activityCreate';
import ActivityEdit from './activityEdit';
import TestSection from './testSection';

import { navigate } from '../../helpers/navigation';
import { screenName } from '../../constants/navigation';
import { Strings } from '../../helpers/strings';
import { USER_ROLE } from '../../constants/global';
import { userRoleIsGTE } from '../../helpers/role';

const styles = StyleSheet.create({
  // Flex :
  row: {
    flexDirection: 'row',
  },
  col: {
    flexDirection: 'column',
  },

  // Full page :
  mainContainer: {
    height: '100%',
    flex: 1,
    display: 'flex',
    borderRadius: 20,
    overflow: 'hidden',
    backgroundColor: colors.getMainWhite(),
  },

  // Navigation :
  titleContainer: {
    alignItems: 'end',
    marginTop: 51,
    marginBottom: 38,
  },
  titleText: {
    fontSize: 29,
    fontFamily: fonts.getHalisR(),
    fontWeight: 900,
    color: colors.getBluePrussian(),
  },
  navigationContainer: {
    flex: '0 0 0px',
    backgroundColor: colors.getGrayAthens(),
  },
  disconnectContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: 40,
    gap: 10,
    cursor: 'pointer',
  },
  disconnectText: {
    color: colors.getMirage(),
    fontWeight: 500,
    fontFamily: fonts.getHalisR(),
    fontSize: 12,
  },
  disconnectButtonMobile: {
    paddingRight: 5,
  },

  // Content :
  sectionContainer: {
    flex: 1,
    display: 'flex',
    backgroundColor: colors.getMainWhite(),
    flexDirection: 'column',
  },
  sectionContentContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 40,
    marginRight: 40,
    marginBottom: 20,
  },

  // Web Specific :
  mainContainerWeb: {
    flexDirection: 'row',
  },
  sectionContainerWeb: {
    height: '100%',
  },
  navigationContainerWeb: {
    paddingHorizontal: 20,
    flexDirection: 'column',
  },
  sectionTitleContainer: {
    flex: '0 0 0px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8,
    marginTop: 57,
    marginLeft: 8,
    marginBottom: 38,
  },
  sectionTitleContainerMobile: {
    marginTop: 20,
    marginBottom: 10,
    marginLeft: 20,
    marginRight: 20,
    justifyContent: 'space-between',
  },
  sectionTitleText: {
    fontFamily: fonts.getHalisR(),
    color: colors.getMountainMeadow(),
    fontSize: 24,
    fontWeight: 900,
  },

  // Mobile Specific :
  mainContainerMobile: {
    flexDirection: 'column-reverse',
  },
  navigationContainerMobile: {
    paddingVertical: 10,
    flexDirection: 'row',
  },
  sectionContentContainerMobile: {
    marginTop: 20,
  },
});

const Sections = {
  ACTIVITIES: 'activities',
  CREATE: 'create',
  EDIT: 'edit',
  TEST: 'test',
};

const sectionsData = [
  {
    name: Sections.ACTIVITIES,
    display: true,
    label: Strings.ACTIVITIES,
    title: Strings.ACTIVITIES,
    iconOn: IconCalendarOn,
    iconOff: IconCalendar,
    component: ActivityList,
    requiredUserRole: USER_ROLE.ATTENDEE,
  },
  {
    name: Sections.CREATE,
    display: true,
    label: Strings.CREATE,
    title: Strings.CREATE_AN_EVENT,
    iconOn: IconAddEventOn,
    iconOff: IconAddEvent,
    component: ActivityCreate,
    requiredUserRole: USER_ROLE.ORGANIZER,
  },
  {
    name: Sections.EDIT,
    display: false,
    title: Strings.MODIFY_AN_EVENT,
    component: ActivityEdit,
    requiredUserRole: USER_ROLE.ORGANIZER,
  },
  {
    name: Sections.TEST,
    display: false,
    title: 'Test',
    component: TestSection,
    requiredUserRole: USER_ROLE.ORGANIZER,
  },
];

const getSectionByName = (name) => {
  return sectionsData.find((section) => section.name === name);
};

const MobileWidth = 800;

const CalendarPage = ({ route, userRole }) => {
  const [selected, setSelected] = useState(sectionsData[0]);

  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const [isMobile, setIsMobile] = useState(width <= MobileWidth);

  useEffect(() => {
    setIsMobile(width <= MobileWidth);
  }, [width]);

  useEffect(() => {
    if (route?.params?.section_name) {
      const section = getSectionByName(route.params.section_name);
      if (section && userRoleIsGTE(userRole, section.requiredUserRole)) setSelected(section);
    }
  }, [route]);

  const renderNavigationContainer = () => {
    return isMobile ? (
      <View style={[styles.navigationContainer, styles.navigationContainerMobile]}>
        <CalendarNavigation selected={selected} setSelected={setSelected} isMobile={true} />
      </View>
    ) : (
      <View style={[styles.navigationContainer, styles.navigationContainerWeb]}>
        <View style={styles.titleContainer}>
          <Text style={styles.titleText}>AGENDA</Text>
        </View>
        <CalendarNavigation
          selected={selected}
          setSelected={setSelected}
          isMobile={false}
          userRole={userRole}
        />
        <TouchableOpacity
          style={styles.disconnectContainer}
          onPress={() => navigate(screenName.LOGOUT)}
        >
          <IconDisconnect width="15px" height="15px" />
          <Text style={styles.disconnectText}>{t(Strings.DISCONNECT)}</Text>
        </TouchableOpacity>
      </View>
    );
  };

  const renderContent = useMemo(() => {
    return (
      <View
        style={[
          styles.mainContainer,
          isMobile ? styles.mainContainerMobile : styles.mainContainerWeb,
        ]}
      >
        {renderNavigationContainer()}
        <View style={[styles.sectionContainer, isMobile ? null : styles.sectionContainerWeb]}>
          <View
            style={[
              styles.sectionTitleContainer,
              isMobile ? styles.sectionTitleContainerMobile : null,
            ]}
          >
            {!isMobile ? <ArrowIcon /> : null}
            <Text style={styles.sectionTitleText}>{t(selected.title)}</Text>
            {isMobile ? (
              <TouchableOpacity
                style={styles.disconnectButtonMobile}
                onPress={() => navigate('logout')}
              >
                <IconDisconnect width="20px" height="20px" />
              </TouchableOpacity>
            ) : null}
          </View>
          <View
            style={[
              styles.sectionContentContainer,
              isMobile ? styles.sectionContentContainerMobile : null,
            ]}
          >
            {React.createElement(selected.component, { route })}
          </View>
        </View>
      </View>
    );
  }, [isMobile, selected]);

  return renderContent;
};

CalendarPage.propTypes = {
  route: PropTypes.object.isRequired,
  userRole: PropTypes.string,
};

const navStyles = StyleSheet.create({
  itemContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 100,
    borderRadius: 26,
    padding: 35,
    gap: 20,
  },
  itemContainerMobile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: 10,
    padding: 10,
    gap: 5,
    minWidth: 80,
    maxWidth: 150,
  },
  navigationContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    gap: 20,
    flex: 1,
  },
  navigationContainerMobile: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    flexDirection: 'row',
    gap: 20,
    flex: 1,
  },
  itemSelected: {
    backgroundColor: colors.getMainPurple(),
  },
  iconText: {
    color: colors.getMirage(),
    fontWeight: 500,
    fontFamily: fonts.getHalisR(),
    textAlign: 'center',
  },
  iconTextSelected: {
    color: colors.getMainWhite(),
    fontWeight: 500,
    fontFamily: fonts.getHalisR(),
  },
});

const CalendarNavigation = ({ selected, isMobile, userRole }) => {
  const { t } = useTranslation();

  const onSelectItem = (item) => {
    // TODO : 'id: undefined' dirty hack to remove the id from the URL
    navigate(screenName.CALENDAR, { section_name: item.name, id: undefined });
  };

  const renderItem = (item) => {
    const isSelected = selected.name === item.name;
    const itemStyle = isSelected ? navStyles.itemSelected : '';
    const itemTextStyle = isSelected ? navStyles.iconTextSelected : navStyles.iconText;

    if (isMobile) {
      return (
        <TouchableOpacity onPress={() => onSelectItem(item)} key={item.name}>
          <View style={[navStyles.itemContainerMobile, itemStyle]}>
            {React.createElement(isSelected ? item.iconOn : item.iconOff)}
            <View>
              <Text style={itemTextStyle}>{t(item.label)}</Text>
            </View>
          </View>
        </TouchableOpacity>
      );
    }

    return (
      <TouchableOpacity onPress={() => onSelectItem(item)} key={item.name}>
        <View style={[navStyles.itemContainer, itemStyle]}>
          {React.createElement(isSelected ? item.iconOn : item.iconOff)}
          <View>
            <Text style={itemTextStyle}>{t(item.label)}</Text>
          </View>
        </View>
      </TouchableOpacity>
    );
  };

  return (
    <View style={isMobile ? navStyles.navigationContainerMobile : navStyles.navigationContainer}>
      {sectionsData
        .filter((item) => item.display && userRoleIsGTE(userRole, item.requiredUserRole))
        .map((item) => renderItem(item))}
    </View>
  );
};

CalendarNavigation.defaultProps = {
  isMobile: false,
};

CalendarNavigation.propTypes = {
  selected: PropTypes.object.isRequired,
  isMobile: PropTypes.bool,
  userRole: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
  userRole: userSelectors.makeSelectRole(),
});

export default connect(mapStateToProps, null)(CalendarPage);
