/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { StyleSheet, View, Text, TextInput } from 'react-native';
import { isEmpty } from 'lodash';

import PropTypes from 'prop-types';
import { createURL } from 'expo-linking';
import moment from 'moment';
import Clipboard from '@react-native-clipboard/clipboard';
import { TouchableOpacity } from 'react-native-web';

import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import colors from '../../constants/colors';
import fonts from '../../constants/fonts';
import { useActivityState } from '../../hooks/activity';
import { Strings } from '../../helpers/strings';
import { RESPONSIVE_THRESHOLD, USER_ROLE } from '../../constants/global';

import userSelectors from '../../App/selectors/user';

import { userRoleIsGTE } from '../../helpers/role';

const { SMALL, MEDIUM, LARGE } = RESPONSIVE_THRESHOLD;

const styles = StyleSheet.create({
  mainContainer: {
    padding: 20,
    borderRadius: 20,
    backgroundColor: colors.getGrayAthens(),
  },
  dateContainer: {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    borderRadius: 20,
    backgroundColor: colors.getMainWhite(),
    padding: 24,
  },
  linkContainer: {
    // width: '100%',
    borderRadius: 10,
    overflow: 'hidden',
    // padding: 5,
    border: `solid 1px ${colors.getGraySantas(0.6)}`,
    alignItems: 'center',
    justifyContent: 'space-between',
    display: 'flex',
    flexFlow: 'row wrap',
    gap: 15,
  },
  textContainer: {
    marginTop: 4,
    marginBottom: 4,
  },
  dateDayText: {
    fontFamily: fonts.getSignika(),
    fontSize: 20,
    color: colors.getBluePrussian(),
  },
  dateMonthText: {
    fontFamily: fonts.getSignika(),
    fontSize: 13,
    color: colors.getBluePrussian(),
  },
  nameText: {
    fontFamily: fonts.getHalisR(),
    fontSize: 20,
    fontWeight: 500,
    color: colors.getBluePrussian(),
  },
  timeRangeText: {
    fontFamily: fonts.getHalisR(),
    fontSize: 14,
    color: colors.getBluePrussian(),
    whiteSpace: 'nowrap',
  },
  durationText: {
    fontFamily: fonts.getHalisR(),
    fontSize: 14,
    color: colors.getGraySantas(),
  },
  linkText: {
    width: '100%',
    margin: 5,
    marginRight: 0,
    fontFamily: fonts.getSignika(),
    fontSize: 18,
    color: colors.getPurpleHeart(),
  },
  linkButtonText: {
    fontFamily: fonts.getSignika(),
    fontSize: 18,
    color: colors.getMainWhite(),
  },
  timeSeparatorText: {
    fontSize: 64,
    fontFamily: fonts.getHalisR(),
    color: colors.getBluePrussian(),
  },
  leftTimeText: {
    fontSize: 14,
    fontFamily: fonts.getHalisR(),
    color: colors.getBluePrussian(),
  },
  timeLabelText: {
    fontFamily: fonts.getHalisR(),
    fontSize: 14,
    color: colors.getSilverChalice(),
  },
  roomText: {
    fontFamily: fonts.getSignika(),
    fontSize: 18,
    color: colors.getSilverChalice(),
  },
  timeText: {
    fontFamily: fonts.getHalisR(),
    fontSize: 64,
    fontWeight: 900,
  },
  eventStartText: {
    fontFamily: fonts.getSignika(),
    fontSize: 18,
    color: colors.getBluePrussian(),
  },
  eventStateText: {
    fontFamily: fonts.getSignika(),
    fontSize: 22,
    fontWeight: 600,
    color: colors.getBluePrussian(),
    // whiteSpace: 'nowrap',
  },
  buttonText: {
    fontFamily: fonts.getSignika(),
    fontSize: 22,
    fontWeight: 600,
    color: colors.getMainWhite(),
  },
  buttonSubText: {
    fontFamily: fonts.getSignika(),
    fontSize: 14,
    fontWeight: 600,
    color: colors.getMainWhite(),
  },
  copyButton: {
    // borderRadius: 10,
    padding: 9,
    textAlign: 'center',
    border: `solid 1px ${colors.getGraySantas(0.29)}`,
  },
  buttonBig: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 10,
    paddingHorizontal: 20,
    paddingVertical: 15,
  },
  buttonSmall: {
    borderRadius: 10,
    paddingVertical: 5,
    paddingHorizontal: 10,
    backgroundColor: colors.getMainPurple(),
  },
  flex: {
    display: 'flex',
  },
  row: {
    display: 'flex',
    flexFlow: 'row',
  },
  col: {
    display: 'flex',
    flexFlow: 'column',
  },
  alignStart: {
    alignItems: 'flex-start',
  },
  alignBase: {
    alignItems: 'baseline',
  },
  alignCenter: {
    alignItems: 'center',
  },
});

const ActivityState = {
  Coming: 0,
  InProgress: 1,
  Over: 2,
};

const Activity = ({
  activity,
  viewSize,
  onJoinEvent,
  isClickable,
  onClick,
  onClickVod,
  containerStyle,
  showLink,
  userRole,
}) => {
  const { t } = useTranslation();
  const { state } = useActivityState(activity);
  const [showCopy, setShowCopy] = useState(false);
  const [hasCopyPerm, setHasCopyPerm] = useState(true);

  useEffect(() => {
    let mounted = true;
    const requestPerm = async () => {
      let canCopy;
      try {
        // First, we try to test if we have the permission to edit ClipBoard in a clean way
        const permState = await navigator.permissions.query({ name: 'clipboard-write' });
        canCopy = permState === 'granted' || permState === 'prompt';
      } catch {
        return; // Leaving hasCopyPerm to true by default
      }
      if (mounted && canCopy !== hasCopyPerm) setHasCopyPerm(canCopy);
    };
    requestPerm();
    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    if (showCopy) {
      const timer = setTimeout(() => {
        setShowCopy(false);
      }, 2000);
      return () => clearTimeout(timer);
    }
    return () => {};
  }, [showCopy]);

  const generateLink = () => {
    let link = '';
    if (activity) {
      const currURL = createURL('/');
      const { protocol, hostname, port } = new URL(currURL);
      link = `${protocol}//${hostname}${port ? `:${port}` : ''}/kapt/${activity.shortId}`;
    }
    return link;
  };

  const renderCalendar = () => {
    if (isEmpty(activity)) return null;
    const eventDate = moment(activity.startDatetime);
    return (
      <View style={styles.dateContainer}>
        <Text style={styles.dateDayText}>{eventDate.format('DD')}</Text>
        <Text style={styles.dateMonthText}>
          {eventDate.format('MMMM').substring(0, 4).toUpperCase()}
        </Text>
      </View>
    );
  };

  const renderTime = (displayDate = false) => {
    if (!isEmpty(activity)) {
      let date = null;

      if (displayDate) {
        date = moment(activity.startDatetime).format('dddd DD MMMM ');
        date = date.charAt(0).toUpperCase() + date.slice(1);
      }

      const start = moment(activity.startDatetime).format('HH:mm');
      const end = moment(activity.endDatetime).format('HH:mm');

      const getLengthStr = () => {
        const diffMinutes = -moment(activity.startDatetime).diff(activity.endDatetime, 'minutes');
        const hours = parseInt(diffMinutes / 60, 10);
        const minutes = diffMinutes % 60;
        let lengthStr = '';
        if (hours > 0) lengthStr += `${hours}h`;
        if (minutes > 0) {
          if (lengthStr !== '') lengthStr += ' ';
          lengthStr += `${minutes}m`;
        }
        return lengthStr;
      };

      return (
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: 15,
            flexWrap: 'wrap',
            flexShrink: 1,
          }}
        >
          {date ? <Text style={[styles.timeRangeText]}>{`${date}`}</Text> : null}
          <View style={[styles.row, { gap: 15 }]}>
            <Text style={[styles.timeRangeText]}>{`${start} - ${end}`}</Text>
            <Text style={styles.durationText}>{getLengthStr()}</Text>
          </View>
        </View>
      );
    }
    return null;
  };

  const getClockUnits = () => {
    const now = moment();
    const leftMs = now.diff(moment(activity.startDatetime));

    if (leftMs > 0) return null; // TODO check
    const leftM = parseInt(leftMs / -60000, 10); // minutes
    const leftH = parseInt(leftM / 60, 10); // hours
    const leftD = parseInt(leftH / 24, 10); // days

    return [
      {
        value: leftD,
        name: Strings.DAY,
        twoDigits: false,
        style: { color: colors.getMountainMeadow() },
      },
      {
        value: leftH % 24,
        name: Strings.HOUR,
        twoDigits: true,
        style: { color: colors.getBluePrussian() },
      },
      {
        value: leftM > 0 ? leftM % 60 : 1,
        name: Strings.MINUTE,
        twoDigits: true,
        style: { color: colors.getBluePrussian() },
      },
    ];
  };

  const renderTextClock = () => {
    const units = getClockUnits();
    if (!units) return '';

    let str = '';

    units.forEach((unit, idx) => {
      if (!str) {
        if (unit.value === 0) return;
        if (idx === 2) {
          str += `${unit.value} ${t(unit.name, { count: unit.value })}`;
          return;
        }
      }
      str += `${unit.value}${t(unit.name, { count: unit.value })[0]}`;
      if (idx === units.length - 1) return;
      str += ', ';
    });

    return str;
  };

  const renderComponentClock = () => {
    const units = getClockUnits();
    if (!units) return [];

    let currentKey = -1;
    const generateKey = () => {
      currentKey += 1;
      return `clock-col-${currentKey}`;
    };

    const renderTimeUnit = (unit) => {
      return (
        <View style={[styles.col, styles.alignCenter]} key={generateKey()}>
          <Text style={[styles.flex, styles.alignCenter, styles.timeText, unit.style]}>
            {unit.value < 10 && unit.twoDigits ? '0' : ''}
            {unit.value}
          </Text>
          <Text style={[styles.timeLabelText]}>
            {t(unit.name, { count: unit.value })?.toUpperCase()}
          </Text>
        </View>
      );
    };

    const renderSeparator = () => {
      return (
        <View style={[styles.col, styles.alignCenter]} key={generateKey()}>
          <Text style={[styles.flex, styles.alignCenter, styles.timeSeparatorText]}>:</Text>
          <View style={[styles.timeLabelText]}></View>
        </View>
      );
    };

    const components = [];

    units.forEach((unit, idx) => {
      if (unit.value === 0 && components.length === 0) return;
      components.push(renderTimeUnit(unit));
      if (idx === units.length - 1) return;
      components.push(renderSeparator());
    });

    return components;
  };

  const renderLeftTime = () => {
    return (
      <View style={[styles.row, styles.alignBase]}>
        <View style={[styles.flex, styles.alignCenter]}>
          <Text style={[styles.leftTimeText]}>{t(Strings.TIME_LEFT)}</Text>
        </View>
        <View style={[styles.row, { marginLeft: 26, gap: 14 }]}>{renderComponentClock()}</View>
      </View>
    );
  };

  const copyLink = (link) => {
    try {
      Clipboard.setString(link);
      setShowCopy(true);
    } catch {
      // eslint-disable-next-line no-alert
      alert("Impossible d'accéder au Presse-Papier");
    }
  };

  const renderLink = () => {
    if (!showLink) return null;
    const link = generateLink();
    const color = showCopy ? colors.getMountainMeadow(0.6) : colors.getGraySantas(0.6);
    const getButton = () => {
      return (
        <TouchableOpacity
          style={{ width: '80px' }}
          onPress={() => {
            copyLink(link);
          }}
        >
          <View style={[styles.copyButton, { backgroundColor: color }]}>
            <Text style={styles.linkButtonText}>
              {showCopy ? t(Strings.COPIED) : t(Strings.COPY)}
            </Text>
          </View>
        </TouchableOpacity>
      );
    };
    return (
      <View style={[styles.linkContainer, { borderColor: color }]}>
        <TouchableOpacity style={{ flex: 1 }}>
          <TextInput
            value={link}
            editable={false}
            style={styles.linkText}
            selectTextOnFocus={true}
          />
        </TouchableOpacity>
        {hasCopyPerm ? getButton() : null}
      </View>
    );
  };

  const renderVodButton = (isSmall) => {
    if (activity?.vodSessions?.length > 0) {
      return (
        <TouchableOpacity
          onPress={() => {
            if (onClickVod && typeof onClickVod === 'function') onClickVod();
          }}
        >
          <View
            style={[
              isSmall ? styles.buttonSmall : styles.buttonBig,
              { backgroundColor: colors.getMountainMeadow() },
            ]}
          >
            <Text style={styles.buttonText}>{t(Strings.SEE_VOD)}</Text>
          </View>
        </TouchableOpacity>
      );
    }
    return null;
  };

  const renderJoinButton = (isSmall) => {
    const isOrganizer = userRole && userRoleIsGTE(userRole, USER_ROLE.ORGANIZER);
    const isOngoing = state === ActivityState.InProgress;
    if (!isOngoing && !isOrganizer) return null;
    return (
      <View
        style={[
          styles.row,
          styles.alignCenter,
          isSmall ? { justifyContent: 'space-between' } : { gap: 20 },
        ]}
      >
        <TouchableOpacity
          onPress={() => {
            if (onJoinEvent) onJoinEvent(true);
          }}
        >
          <View
            style={[
              isSmall ? styles.buttonSmall : styles.buttonBig,
              { backgroundColor: isOngoing ? colors.getMainPurple() : colors.getSunglow() },
            ]}
          >
            <Text style={styles.buttonText}>{t(Strings.JOIN)}</Text>
          </View>
        </TouchableOpacity>
      </View>
    );
  };

  const renderEventState = () => {
    if (state === ActivityState.Over)
      return <Text style={styles.eventStateText}>{t(Strings.EVENT_TERMINATED)}</Text>;
    if (state === ActivityState.Coming)
      return (
        <Text style={styles.eventStateText}>{`${t(
          Strings.EVENT_UPCOMING,
        )} (${renderTextClock()})`}</Text>
      );
    return null;
  };

  const renderInteraction = (isSmall = false) => {
    if (isSmall) {
      return (
        <View
          style={{
            gap: 10,
            flexDirection: 'row',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
        >
          {renderEventState()}
          {renderVodButton(isSmall)}
          {renderJoinButton(isSmall)}
        </View>
      );
    }
    return (
      <View
        style={{
          gap: 5,
          flexDirection: 'column',
          alignItems: 'end',
          flexWrap: 'wrap',
        }}
      >
        {renderEventState()}
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: 10,
            flexWrap: 'wrap',
            justifyContent: 'flex-end',
          }}
        >
          {renderVodButton(isSmall)}
          {renderJoinButton(isSmall)}
        </View>
      </View>
    );
  };

  const renderSmallView = () => {
    return (
      <View style={[styles.col]}>
        <View style={[styles.col]}>
          <Text style={styles.roomText}>
            {t(Strings.ROOM)?.toUpperCase()}{' '}
            {activity?.room?.name ? activity?.room?.name?.toUpperCase() : null}
          </Text>
          <View style={[styles.row, styles.textContainer, styles.alignCenter]}>
            <Text style={styles.nameText}>{activity?.name}</Text>
          </View>
          <View style={[styles.textContainer]}>{renderTime(true)}</View>
        </View>
        {renderLink()}
        <View style={{ marginTop: 15 }}>{renderInteraction(true)}</View>
      </View>
    );
  };

  const renderMediumView = () => {
    return (
      <View style={[styles.row, styles.alignCenter, { justifyContent: 'space-between' }]}>
        <View style={[styles.row, { flexShrink: 0 }]}>
          {renderCalendar()}
          <View style={[styles.col, styles.alignStart, { marginLeft: 17 }]}>
            <View style={[styles.row, styles.textContainer]}>
              <Text style={styles.roomText}>
                {t(Strings.ROOM)?.toUpperCase()}{' '}
                {activity?.room?.name ? activity?.room?.name.toUpperCase() : null}
              </Text>
            </View>
            <View style={[styles.row, styles.textContainer, styles.alignCenter]}>
              <Text style={styles.nameText}>{activity?.name}</Text>
            </View>
            <View style={[styles.row, styles.textContainer]}>{renderTime()}</View>
          </View>
        </View>
        {showLink && <View style={{ flexShrink: 2, minWidth: 50 }}>{renderLink()}</View>}
        <View style={{ marginTop: 5, marginLeft: 10, flexShrink: 1 }}>{renderInteraction()}</View>
      </View>
    );
  };

  const renderLargeView = () => {
    const isOrganizer = userRole && userRoleIsGTE(userRole, USER_ROLE.ORGANIZER);
    return (
      <View style={[styles.row, styles.alignCenter, { justifyContent: 'space-between' }]}>
        <View style={[styles.row]}>
          {renderCalendar()}
          <View style={[styles.col, styles.alignStart, { marginLeft: 17 }]}>
            <View style={[styles.row, styles.textContainer]}>
              <Text style={styles.roomText}>
                {t(Strings.ROOM)?.toUpperCase()}{' '}
                {activity?.room?.name ? activity?.room?.name.toUpperCase() : null}
              </Text>
            </View>
            <View style={[styles.row, styles.textContainer, styles.alignCenter]}>
              <Text style={styles.nameText}>{activity?.name}</Text>
            </View>
            <View style={[styles.row, styles.textContainer]}>{renderTime()}</View>
          </View>
        </View>
        <View style={{ marginTop: 5, marginLeft: 10 }}>{renderLink()}</View>
        <View style={{ marginLeft: 10 }}>
          {state === ActivityState.Coming && !isOrganizer ? renderLeftTime() : renderInteraction()}
        </View>
      </View>
    );
  };

  return (
    <TouchableOpacity
      disabled={!isClickable}
      onPress={() => {
        if (onClick && typeof onClick === 'function') onClick();
      }}
    >
      <View style={[styles.mainContainer, containerStyle]}>
        {(() => {
          switch (viewSize) {
            case SMALL:
              return renderSmallView();
            case MEDIUM:
              return renderMediumView();
            case LARGE:
              return renderLargeView();
            default:
              return null;
          }
        })()}
      </View>
    </TouchableOpacity>
  );
};

Activity.defaultProps = {
  viewSize: true,
  isClickable: true,
  showLink: true,
};

Activity.propTypes = {
  activity: PropTypes.object.isRequired,
  viewSize: PropTypes.string,
  onJoinEvent: PropTypes.func,
  isClickable: PropTypes.bool,
  onClick: PropTypes.func,
  onClickVod: PropTypes.func,
  containerStyle: PropTypes.object,
  showLink: PropTypes.bool,

  // Actions
};

Activity.propTypes = {
  userRole: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
  userRole: userSelectors.makeSelectRole(),
});

export default connect(mapStateToProps, null)(Activity);
