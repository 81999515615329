import { requestAPI, getHeadersAPI } from '../../helpers/request';

const basePath = '/activity';

const paths = {
  getAvailableRooms: () => {
    return `${basePath}/available-rooms`;
  }, // GET
  getActivityById: (id) => {
    return `${basePath}/${id}`;
  }, // GET
  getActivityByShortId: (shortId) => {
    return `${basePath}/shortid/${shortId}`;
  }, // GET
  getActivities: () => {
    return `${basePath}`;
  }, // GET
  createActivity: () => {
    return `${basePath}`;
  }, // POST
  updateActivity: (id) => {
    return `${basePath}/${id}`;
  }, // PATCH
  deleteActivityById: (id) => {
    return `${basePath}/${id}`;
  }, // DELETE
};

// ------------------------------------------------------- //
// --------------------- REQUEST ------------------------- //
// ------------------------------------------------------- //

// ---------- GET
const requestAvailableRooms = async (params) => {
  return requestAPI(paths.getAvailableRooms(), getHeadersAPI('GET', true), params);
};

const requestActivity = async (id) => {
  return requestAPI(paths.getActivityById(id), getHeadersAPI('GET', true));
};

const requestActivityByShortId = (shortId) => {
  return requestAPI(paths.getActivityByShortId(shortId), getHeadersAPI('GET', true));
};

const requestActivities = async (params) => {
  return requestAPI(paths.getActivities(), getHeadersAPI('GET', true), params);
};

// ---------- POST
const requestScheduleActivity = async (body) => {
  return requestAPI(paths.createActivity(), getHeadersAPI('POST', true, JSON.stringify(body)));
};

// ---------- PATCH
const requestUpdateActivity = async (id, body) => {
  return requestAPI(paths.updateActivity(id), getHeadersAPI('PATCH', true, JSON.stringify(body)));
};

// ---------- DELETE
const requestDeleteActivity = async (id) => {
  return requestAPI(paths.deleteActivityById(id), getHeadersAPI('DELETE', true));
};

export default {
  requestScheduleActivity,
  requestUpdateActivity,
  requestAvailableRooms,
  requestActivity,
  requestActivityByShortId,
  requestActivities,
  requestDeleteActivity,
};
