import React from 'react';
import { View, ScrollView } from 'react-native';

const TestSection = () => {
  const generateComponents = () => {
    const comps = [];
    for (let i = 0; i < 15; i += 1) {
      comps.push(<View style={{ height: 100, border: '1px solid black' }}></View>);
    }
    return comps;
  };

  return (
    <View style={{ flex: 1, height: '100%' }}>
      <ScrollView contentContainerStyle={{ height: 0 }}>{generateComponents()}</ScrollView>
    </View>
  );
};

export default TestSection;
