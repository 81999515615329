import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { View, StyleSheet, TouchableOpacity, Text, FlatList } from 'react-native';
import { createStructuredSelector } from 'reselect';

import { connect } from 'react-redux';
import Modal from 'modal-enhanced-react-native-web';

import IconRtmp from '../../assets/modals/icon-rtmp.svg';
import colors from '../../constants/colors';

import userSelector from '../../App/selectors/user';
import appSelectors from '../../App/selectors/app';
import liveProfileActions from '../../App/actions/liveprofile';

const styles = StyleSheet.create({
  modal: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 20,
  },
  containers: {
    width: 555, // TODO : reactivity !
    borderRadius: 20,
    backdropFilter: 'blur(15px)',
    boxShadow: '0 0 64px 0 rgba(0, 0, 0, 0.15)',
    backgroundColor: 'rgba(245, 245, 245, 0.4)',
  },
  header: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 23,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    marginBottom: 20,
    alignItems: 'center',
    border: 'solid 0.5px rgba(155, 155, 155, 0.2)',
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
  },
  footer: {
    flex: 1,
    flexDirection: 'column',
    padding: 23,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    marginTop: 20,
    alignItems: 'center',
    border: 'solid 0.5px rgba(155, 155, 155, 0.2)',
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
  },
  form: {
    paddingHorizontal: 25,
    paddingVertical: 35,
    position: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  TextHeader: {
    fontSize: 20,
    fontWeight: 600,
    letterSpacing: 0.28,
    color: '#04275a',
  },
  LiveProfile: {
    width: 108,
    height: 108,
    margin: 15,
    borderRadius: 20,
    backgroundColor: 'white',
  },
  IconStream: {
    marginHorizontal: 'auto',
    marginTop: 24,
    marginBottom: 13,
    width: 40,
    height: 40,
  },
  TextIcon: {
    fontSize: 13,
    textAlign: 'center',
    color: colors.getBluePrussian(),
  },
  TextHeaderCancel: {
    // marginLeft: 150,
    fontSize: 18,
    letterSpacing: 0.25,
    textAlign: 'center',
    color: '#523bd1',
  },
  TextHeaderValidate: {
    letterSpacing: 0.25,
    fontSize: 18,
    textAlign: 'center',
    color: '#523bd1',
  },
  profileSelector: {
    backgrounCcolor: '#fefefe',
    margin: '15% auto',
    padding: '20px',
    border: '1px solid #888',
    width: '50%',
    height: '20%',
  },
  background: {
    position: 'fixed',
    zIndex: '2',
    left: '0',
    top: '0',
    width: '100%',
    height: '100%',
    overflow: 'auto',
    backgroundColor: 'rgba(0,0,0,0.4)',
  },
  profile: {
    width: '200',
    height: '200',
    flexDirection: 'column',
    alignItems: 'center',
    border: '1px solid #888',
  },
  text: {
    fontSize: '50',
    color: 'white',
  },
});

const LiveProfileSelector = ({
  user,
  liveProfiles,
  requestUserLiveProfiles,
  selectLiveProfile,
  isVisible,
  setVisible,
  currentlySending,
}) => {
  // useEffect(() => {
  //   if (user._id !== undefined && liveProfiles.length === 0) {
  //     // TODO : BAD, liveProfiles.length === 0, if we don't have any profile, we can end up stuck in
  //     // TODO : a loop because it's behaving like if the profiles weren't fetched, and it's fetching
  //     // TODO : again and again, updating the currentlySending selector constantly, which will
  //     // TODO : lead to components rendering again and again because they are cleaning themself
  //     // TODO : to render the "loading" animation, and in consequence fetch profiles again ...
  //     requestUserLiveProfiles();
  //   }
  // }, [user._id]);

  const [pickedProfile, setPickedProfile] = useState(null);

  const closeModal = () => {
    setVisible(false);
    setPickedProfile(null);
  };

  const profilePick = (profile) => {
    setPickedProfile(profile);
  };

  const profileConfirm = (profile) => {
    selectLiveProfile(profile);
    closeModal();
  };

  const renderLiveProfileButton = ({ item }) => {
    return (
      <TouchableOpacity onPress={() => profilePick(item)} key={item._id}>
        <LiveProfile liveProfile={item} />
      </TouchableOpacity>
    );
  };

  const profilePickForm = () => {
    return (
      <View style={styles.containers}>
        <View style={styles.header}>
          <Text style={styles.TextHeader}>Sélectionner une plateforme</Text>
          <TouchableOpacity onPress={closeModal}>
            <Text style={styles.TextHeaderCancel}>Annuler</Text>
          </TouchableOpacity>
        </View>
        <View style={styles.form}>
          <FlatList
            horizontal={true}
            data={liveProfiles ?? []}
            renderItem={renderLiveProfileButton}
            keyExtractor={(item) => `${item._id}`}
          />
        </View>
      </View>
    );
  };

  const profileConfirmForm = (liveProfile) => {
    return (
      <View style={styles.containers}>
        <View style={styles.header}>
          <Text style={styles.TextHeader}>Diffuser sur</Text>
          <TouchableOpacity onPress={closeModal}>
            <Text style={styles.TextHeaderCancel}>Annuler</Text>
          </TouchableOpacity>
        </View>
        <View style={styles.form}>
          <LiveProfile liveProfile={liveProfile} />
        </View>
        <View style={styles.footer}>
          <TouchableOpacity onPress={() => profileConfirm(liveProfile)}>
            <Text style={styles.TextHeaderValidate}>Valider</Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  };

  const modalContent = () => {
    if (pickedProfile) {
      return profileConfirmForm(pickedProfile);
    }
    if (currentlySending) return <Text>Chargement ...</Text>;
    return profilePickForm();
  };

  return (
    <Modal
      isVisible={isVisible}
      // onBackdropPress={() => this.resetState()}
      style={styles.modal}
    >
      {modalContent()}
    </Modal>
  );
};

const LiveProfile = ({ liveProfile }) => {
  return (
    <View style={styles.LiveProfile}>
      <IconRtmp style={styles.IconStream} />
      <Text style={styles.TextIcon}>{liveProfile.name}</Text>
    </View>
  );
};

LiveProfileSelector.defaultProps = {
  isVisible: false,
};

LiveProfileSelector.propTypes = {
  user: PropTypes.object.isRequired,
  liveProfiles: PropTypes.array.isRequired,
  requestUserLiveProfiles: PropTypes.func.isRequired,
  selectLiveProfile: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  currentlySending: PropTypes.bool.isRequired,
};

LiveProfile.propTypes = {
  liveProfile: PropTypes.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  user: userSelector.makeSelectUserInfo(),
  liveProfiles: userSelector.makeSelectLiveProfiles(),
  currentlySending: appSelectors.makeSelectCurrentlySending(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    requestUserLiveProfiles: () => dispatch(liveProfileActions.requestUserLiveProfiles()),
    /* selectLiveProfile: (_liveProfile) =>
      dispatch(liveProfileActions.selectLiveProfile(_liveProfile)), */
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LiveProfileSelector);
