import React, { useEffect, useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useReduxAction } from '../../helpers/store';

import { Types as activityTypes } from '../../App/actions/activity';
import colors from '../../constants/colors';
import fonts from '../../constants/fonts';
import { getPlayerMessageData, MESSAGE_CRITICITY } from '../../helpers/player-messages';

const styles = StyleSheet.create({
  playerMessageContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 5,
    borderRadius: 5,
    padding: 5,
  },
  playerMessageTypeBox: {
    borderRadius: 5,
    padding: 5,
  },
  playerMessageText: {
    color: 'white',
    fontFamily: fonts.getSignika(),
    fontSize: 16,
    fontWeight: 800,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

const PlayerMessage = ({ style }) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [playerMessage, setPlayerMessage] = useState({});

  const [timer, setTimer] = useState(0);

  useEffect(() => {
    if (timer === 0) {
      setVisible(false);
      return () => {};
    }
    const id = setTimeout(() => {
      setTimer((time) => time - 1);
    }, 1000);
    return () => {
      clearTimeout(id);
    };
  }, [timer]);

  useReduxAction((action) => {
    setPlayerMessage({ messageType: action.messageType });
    setVisible(true);
    setTimer(5);
  }, activityTypes.PLAYER_MESSAGE_EVENT);

  const renderPlayerMessage = () => {
    const { messageType } = playerMessage;
    if (!messageType) return null;

    const messageData = getPlayerMessageData(messageType);
    if (!messageData) return null;

    let lightColor = '#4b8bf2';
    let darkColor = '#3765ad';
    if (messageData.criticity === MESSAGE_CRITICITY.ERROR) {
      lightColor = colors.getMainRed();
      darkColor = '#c00000';
    } else if (messageData.criticity === MESSAGE_CRITICITY.SUCCESS) {
      lightColor = '#00ba00';
      darkColor = '#009c02';
    }

    const viewStyle = [styles.playerMessageContainer, { backgroundColor: lightColor }];
    if (style) viewStyle.push(...(Array.isArray(style) ? style : [style]));
    return (
      <View style={viewStyle}>
        <View style={[styles.playerMessageTypeBox, { backgroundColor: darkColor }]}>
          <Text style={styles.playerMessageText}>{t(messageData.category).toUpperCase()}</Text>
        </View>
        <Text style={styles.playerMessageText}>{t(messageData.message)}</Text>
      </View>
    );
  };

  return visible ? renderPlayerMessage() : null;
};

PlayerMessage.propTypes = {
  style: PropTypes.any,
};

export default PlayerMessage;
