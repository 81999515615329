import React, { useEffect } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import appSelectors from '../../App/selectors/app';
import appActions from '../../App/actions/app';
import colors from '../../constants/colors';
import fonts from '../../constants/fonts';

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    display: 'flex',
    flexDirection: 'column',
    padding: 5,
    textAlign: 'right',
  },
  text: {
    fontFamily: fonts.getSignika(),
    fontSize: 14,
    color: colors.getBlueCornflower(),
  },
});

const AppVersion = ({ style, backendApiInfo, requestBackendApiVersion }) => {
  useEffect(() => {
    if (!backendApiInfo) {
      requestBackendApiVersion();
    }
  }, []);

  const getStyle = () => {
    const customStyle = [styles.text];
    if (style) customStyle.push(...(Array.isArray(style) ? style : [style]));
    return customStyle;
  };

  const renderVersion = () => {
    const version = process.env.APP_VERSION ?? '1.0.0';
    if (version === undefined || version === 'undefined') return null;
    const text = `v${version}`;
    const customStyle = getStyle();
    return <Text style={customStyle}>{text}</Text>;
  };

  const renderBackendVersion = () => {
    if (!backendApiInfo) return null;
    const text = `v${backendApiInfo.version}`;
    const customStyle = getStyle();
    if (!backendApiInfo.isCompatible) customStyle.push({ color: colors.getRed() });
    return <Text style={customStyle}>{text}</Text>;
  };

  const render = () => {
    return (
      <View style={styles.container}>
        {renderVersion()}
        {renderBackendVersion()}
      </View>
    );
  };

  return render();
};

AppVersion.propTypes = {
  style: PropTypes.any,
  backendApiInfo: PropTypes.object,
  requestBackendApiVersion: PropTypes.func,
};

AppVersion.defaultProps = {};

const mapStateToProps = createStructuredSelector({
  backendApiInfo: appSelectors.makeSelectBackendApiInfo(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    requestBackendApiVersion: () => dispatch(appActions.requestBackendApiVersion()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppVersion);
