import PropTypes from 'prop-types';
import React, { createRef, useMemo } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { connect, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import roomActions from '../../App/actions/room';
import roomSelectors from '../../App/selectors/room';
import userSelectors from '../../App/selectors/user';
import colors from '../../constants/colors';
import fonts from '../../constants/fonts';
import ElementOnHover from '../Generic/elementOnHover';
import ElementInOverlay from '../Generic/elementInOverlay';

const emoteReactions = {
  thumbs_up: '👍',
  thumbs_down: '👎',
  raised_hand: '🤚',
  clap: '👏',
};

const styles = StyleSheet.create({
  global: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 30,
    backgroundColor: colors.getClayEbony(),
    border: `solid 3px ${colors.getMirage()}`,
    borderRadius: 15,
    padding: 5,
    gap: 5,
  },
  support: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 2,
  },
  emote: {
    fontSize: 16,
  },
  emoteClickable: {
    cursor: 'pointer', // web
  },
  count: {
    fontSize: 14,
    color: colors.getMainWhite(),
    fontFamily: fonts.getSignika(),
    margin: 1,
  },
  me: {
    color: colors.getTreePoppy(),
  },
  hide: {
    display: 'none',
  },
  supportReactionUsers: {
    maxWidth: 250,
    minWidth: 100,
    backgroundColor: colors.getBlueOxford(0.8),
    borderRadius: 10,
    shadowRadius: 5,
    shadowColor: colors.getMainBlack(0.6),
    padding: 10,
  },
  rowReactionUser: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: 2,
  },
  typeReactionUser: {
    fontSize: 12,
    marginRight: 10,
  },
  nameReactionUser: {
    color: colors.getMainWhite(),
    fontFamily: 'Signika',
    fontSize: 12,
    textAlign: 'right',
    overflow: 'hidden',
  },
});

const MessageReaction = ({
  message,
  backgroundColor,
  style,
  userId,
  showAll,
  addMessageReaction,
  clearMessageReaction,
  onReactionClicked,
}) => {
  const selectMessage = useMemo(roomSelectors.makeSelectMessage, []);
  const messageUpdated = useSelector((state) => selectMessage(state, message));
  const reactions = messageUpdated?.reactions;

  const isClickable = !!userId;

  const clickOnReaction = (event, type, me) => {
    if (!isClickable) return;
    event.stopPropagation(); // to not trigger the onClick of parent
    if (!messageUpdated?.id) return;
    if (onReactionClicked) onReactionClicked(messageUpdated?.id, type);
    if (me) clearMessageReaction(messageUpdated.id, userId);
    else addMessageReaction(messageUpdated.id, userId, type);
  };

  const renderHoverReaction = (type, users) => {
    if (!users?.length) return null;
    const emoteStyle = [styles.emote, styles.typeReactionUser];
    const renderRow = (user, me) => (
      <View key={user.id} style={styles.rowReactionUser}>
        <Text style={emoteStyle}>{emoteReactions[type]}</Text>
        <Text
          ellipsizeMode="tail"
          numberOfLines={1}
          style={[styles.nameReactionUser, me ? styles.me : {}]}
        >
          {user.username ?? '-'}
        </Text>
      </View>
    );
    const rows = [];
    const limit = 30;
    for (let i = 0; i < users.length; i += 1) {
      if (i >= limit) break;
      const user = users[i];
      rows.push(renderRow(user, user.id === userId));
    }
    return (
      <View style={styles.supportReactionUsers}>
        {rows}
        {rows.length >= limit ? (
          <Text
            key={'unique_...'}
            style={{ width: '100%', color: 'white', textAlign: 'center', fontSize: 12 }}
          >
            ...
          </Text>
        ) : null}
      </View>
    );
  };

  const renderReaction = (type, number, me, users) => {
    const emoteStyle = [styles.emote];
    const ref = createRef();
    if (isClickable) emoteStyle.push(styles.emoteClickable);
    return (
      <View ref={ref} key={type} style={styles.support}>
        <Text style={emoteStyle} onClick={(ev) => clickOnReaction(ev, type, me)}>
          {emoteReactions[type]}
        </Text>
        <View style={number < 1 ? styles.hide : null}>
          <Text style={[styles.count, me ? styles.me : {}]}>{number}</Text>
        </View>
        <ElementInOverlay>
          <ElementOnHover
            targetRef={ref}
            delay={200}
            style={{ position: 'absolute' }}
            enableClick={false}
          >
            {renderHoverReaction(type, users)}
          </ElementOnHover>
        </ElementInOverlay>
      </View>
    );
  };

  const renderReactions = () => {
    let result = [];
    if (!reactions && !showAll) return result;
    const reactionSorted = {};

    // Init
    const types = Object.keys(emoteReactions);
    types.forEach((type) => {
      reactionSorted[type] = {
        count: 0,
        withMe: false,
        users: [],
      };
    });

    // Set values
    const typeIsValide = (type) => {
      return types.includes(type);
    };
    if (reactions) {
      reactions.forEach((r) => {
        const { type } = r;
        if (typeIsValide(type)) {
          reactionSorted[type].count += 1;
          if (r.author && !reactionSorted[type].users.find((user) => user.id === r.author?.id)) {
            reactionSorted[type].users.push(r.author);
          }
          if (r.author?.id === userId) reactionSorted[type].withMe = true;
        }
      });
    }

    // Filter and push for result
    types.forEach((type) => {
      if (reactionSorted[type].count || showAll) {
        result.push({
          ...reactionSorted[type],
          type,
        });
      }
    });

    // Sort & Map result
    result.sort((a, b) => b.count - a.count);
    result = result.map((r) => renderReaction(r.type, r.count, r.withMe, r.users));
    return result;
  };

  const viewStyle = [styles.global];
  if (style) viewStyle.push(style);
  if (backgroundColor) viewStyle.push({ backgroundColor });
  const reactionRendered = renderReactions(reactions);
  return reactionRendered.length ? ( // Show only if at least one reaction is present
    <View style={viewStyle}>{reactionRendered}</View>
  ) : null;
};

MessageReaction.propTypes = {
  message: PropTypes.object.isRequired,
  userId: PropTypes.string,
  backgroundColor: PropTypes.string,
  style: PropTypes.number,
  showAll: PropTypes.bool,

  // Actions :
  clearMessageReaction: PropTypes.func.isRequired,
  addMessageReaction: PropTypes.func.isRequired,
  // Callback
  onReactionClicked: PropTypes.func,
};

MessageReaction.defaultProps = {
  showAll: false,
  backgroundColor: colors.getClayEbony(),
};

const mapStateToProps = createStructuredSelector({
  userId: userSelectors.makeSelectUserId(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    clearMessageReaction: (messageId, userId) =>
      dispatch(roomActions.clearMessageReaction(messageId, userId)),
    addMessageReaction: (messageId, userId, type) =>
      dispatch(roomActions.addMessageReaction(messageId, userId, type)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MessageReaction);
