/* eslint-disable prettier/prettier */
export const Strings = {
  JOIN: 'Join',
  EVENT_ONGOING: 'Event ongoing',
  EVENT_UPCOMING: 'Event upcoming',
  EVENT_STARTS_IN: 'The event starts in',
  EVENT_TERMINATED: 'Event terminated',
  PUBLICATION_INTERRUPTED: 'Publication interrupted',
  YOUR_VOD_SESSIONS: 'Your VOD sessions',
  ACTIVITY: 'Activity',
  ACTIVITIES: 'Activities',
  CREATE: 'Create',
  CLOSE: 'Close',
  DELETE: 'Delete',
  CONFIRM: 'Confirm',
  CANCEL: 'Cancel',
  MODIFY: 'Modify',
  COPY: 'Copy',
  COPIED: 'Copied!',
  CREATE_AN_EVENT: 'Create an event',
  MODIFY_AN_EVENT: 'Modify an event',
  NAME: 'Name',
  DATE: 'Date',
  TIME_SLOT: 'Time slot',
  ROOM: 'Room',
  USER: 'User',
  WHERE_TO_RECORD_THE_VIDEO: 'Where to record the video',
  WHERE_TO_BROADCAST_THE_VIDEO: 'Where to broadcast the video',
  RECORD_THE_VIDEO: 'Record the video',
  BROADCAST_THE_VIDEO: 'Broadcast the video',
  VISIBILITY: 'Visibility',
  PRIVATE: 'Private',
  PUBLIC: 'Public',
  SAVE: 'Save',
  ASCENDING: 'Ascending',
  DESCENDING: 'Descending',
  OLD: 'Old',
  NEW: 'New',
  USER_PROFILES: 'User profiles',
  ROOM_PROFILES: 'Room profiles',
  YOUR_EVENT: 'Your event',
  ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_ACTIVITY: 'Are you sure you want to delete this activity ?',
  VOD_IDLE: 'Waiting',
  VOD_STARTING: 'Starting',
  VOD_RECORD_ONGOING: 'Record ongoing',
  VOD_RECORD_STOP_WAITING_DEVICE: 'Waiting for the device to stop the record ...',
  VOD_RECORD_STOP_ERROR: 'Record could not stop because of an error',
  VOD_RECORD_ERROR: 'Record interrupted because of an error',
  VOD_UPLOAD_START_WAITING_DEVICE: 'Waiting for the device to start the upload ...',
  VOD_UPLOAD_START_ERROR: 'Upload could not start because of an error',
  VOD_UPLOAD_ONGOING: 'Upload ongoing',
  VOD_UPLOAD_ERROR: 'Upload interrupted because of an error',
  VOD_PUBLISH_START_WAITING_SERVICE: 'Waiting for the publisher to start the publication ...',
  VOD_PUBLISH_START_ERROR: 'Publish could not start because of an error',
  VOD_PUBLISH_ONGOING: 'Publish ongoing',
  VOD_PUBLISH_ERROR: 'Publish interrupted because of an error',
  VOD: 'VOD',
  SEE_VOD: 'See VOD',
  VIDEO_AVAILABLE_ON_KALYZEE: 'Video available on Kalyzée !',
  VIDEO_NOT_AVAILABLE_YET: 'Video not available yet !',
  ACTIVITY_COUNTDOWN_FIVE_MINUTES: 'The activity ends in 5 minutes !',
  ACTIVITY_COUNTDOWN_ONE_MINUTE: 'The activity ends in 1 minute !',
  ACTIVITY_COUNTDOWN_TEN_SECONDS: 'The activity ends in 10 seconds !',
  ACTIVITY_NOT_ONGOING: 'The activity is not ongoing',
  SEND_A_MESSAGE: 'Send a message',
  THIS_FIELD_IS_REQUIRED: 'This field is required',
  ATTEMPTING_RESOLUTION: 'Attempting resolution ...',
  THE_CAMERA_DOES_NOT_SEEM_TO_BE_ONLINE: 'The camera does not seem to be online ...',
  WAITING_FOR_THE_LIVE_TO_START: 'Waiting for the live to start',
  YOU_CAN_START_THE_PUBLICATION_AT_ANY_TIME:
    'You can start the publication at any time by clicking on the red button bellow',
  CHECK_IF_THE_CAMERA_IS_CONNECTED_TO_THE_INTERNET:
    'Check if the camera is connected to the internet or contact an administrator',
  THE_LIVE_SOURCE_WAS_NOT_DEFINED: 'The source to visualize the live was not defined ...',
  THE_LIVE_SOURCE_IS_NOT_VALID: 'The source to visualize the live is not valid ...',
  PREVIEW_OF_THE_LIVE: 'Preview of the live',
  LIVE_STATUS: 'Live status',
  LOADING_PREVIEW_OF_THE_LIVE: 'Loading preview of the live ...',
  LOADING_LIVE: 'Loading live ...',
  LOADING_VIDEO_EEDBACK: 'Loading video feedback ...',
  NO_VIDEO_SIGNAL: 'No video signal',
  NO_VIDEO_SIGNAL_RETRY: 'Retry',
  DEVICE_IS_NOT_CONNECTED: 'Device is not connected',
  DAY: 'day',
  HOUR: 'hour',
  MINUTE: 'minute',
  TIME_LEFT: 'Time left :',
  WAITING: 'Waiting ...',
  THE_REQUESTED_ACTIVITY_DOES_NOT_EXIST: 'The requested activity does not exist.',
  A_CONNECTION_IS_REQUIRED_OR_THE_REQUESTED_ACTIVITY_DOES_NOT_EXIST:
    'A connection is required or the requested activity does not exist.',
  TRY_TO_LOGIN_OR_VERIFY_THE_ACTIVITY_URL: 'Try to login or verify the activity url.',
  THE_EVENT_MAY_HAVE_BEEN_DELETED: 'The event may have been deleted.',
  NETWORK_ISSUE: 'Network issue',
  CLICK_TO_RETRY: 'Click to retry',
  NO_DATA_FOUND: 'No data found',
  NO_PROFILE_FOUND: 'No profile found',
  DISCONNECT: 'Disconnect',
  LOGIN: 'Login',
  EMAIL: 'Email',
  PASSWORD: 'Password',
  EXEMPLE_EMAIL: 'exemple@email.fr',
  EXEMPLE_PASSWORD: '****',
  ERROR_EMAIL: 'Please enter a valid email',
  ERROR_PASSWORD: 'Please enter your password',
  PASSWORD_RESET_EMAIL_SENT: 'An email containing a confirmation link was sent to the address',
  PASSWORD_RESET_SUCCESS:
    'Your password was successfully reset! You can go back to the login page.',
  PASSWORD_RESET_REFUSED: 'The password reset was refused.',
  PASSWORD_RESET_INVALID_KEY:
    'This password reset link is invalid, perhaps it is expired or it was already used.',
  I_FORGOT_MY_PASSWORD: 'I forgot my password',
  SIGN_IN_WITH: 'Sign in with',
  SOCKET_CONNEXION_LOST_RETRYING: 'Connection lost... Attempting to reconnect...',
  SOCKET_CONNEXION_LOST: 'Unable to connect... Try again later...',
  // APP
  APP_STATUS_BAR_TRIAL_PERIOD_IN_PROGRESS: 'APP_STATUS_BAR_TRIAL_PERIOD_IN_PROGRESS',
  // ACTIVITY
  ACTIVITY_SCHEDULE_FORM_INTERACTIONS_TITLE: 'ACTIVITY_SCHEDULE_FORM_INTERACTIONS_TITLE',
  ACTIVITY_SCHEDULE_FORM_INTERACTIONS_LABEL: 'ACTIVITY_SCHEDULE_FORM_INTERACTIONS_LABEL',
  ACTIVITY_SCHEDULE_FORM_INTERACTIONS_INFO: 'ACTIVITY_SCHEDULE_FORM_INTERACTIONS_INFO',
  ACTIVITY_SCHEDULE_FORM_INTERACTIONS_HOVER_MESSAGE_SOON:
    'ACTIVITY_SCHEDULE_FORM_INTERACTIONS_HOVER_MESSAGE_SOON',
  ACTIVITY_SCHEDULE_FORM_INTERACTIONS_HOVER_MESSAGE_DISABLED:
    'ACTIVITY_SCHEDULE_FORM_INTERACTIONS_HOVER_MESSAGE_DISABLED',
  // ACTIVITY - INTERACTIONS - CONTROLLER FOR HOST
  ACTIVITY_INTERACTION_HOST_CONTROLLER_WAITING_RESPONSE_MESSAGE:
    'ACTIVITY_INTERACTION_HOST_CONTROLLER_WAITING_RESPONSE_MESSAGE',
  ACTIVITY_INTERACTION_HOST_CONTROLLER_WAITING_RESPONSE_ACCEPT_BUTTON:
    'ACTIVITY_INTERACTION_HOST_CONTROLLER_WAITING_RESPONSE_ACCEPT_BUTTON',
  ACTIVITY_INTERACTION_HOST_CONTROLLER_WAITING_RESPONSE_REFUSE_BUTTON:
    'ACTIVITY_INTERACTION_HOST_CONTROLLER_WAITING_RESPONSE_REFUSE_BUTTON',
  ACTIVITY_INTERACTION_HOST_CONTROLLER_WAITING_MEDIA_MESSAGE:
    'ACTIVITY_INTERACTION_HOST_CONTROLLER_WAITING_MEDIA_MESSAGE',
  ACTIVITY_INTERACTION_HOST_CONTROLLER_WAITING_MEDIA_CANCEL_BUTTON:
    'ACTIVITY_INTERACTION_HOST_CONTROLLER_WAITING_MEDIA_CANCEL_BUTTON',
  ACTIVITY_INTERACTION_HOST_CONTROLLER_WAITING_TO_JOIN_WAITING_VIDEO_MESSAGE:
    'ACTIVITY_INTERACTION_HOST_CONTROLLER_WAITING_TO_JOIN_WAITING_VIDEO_MESSAGE',
  ACTIVITY_INTERACTION_HOST_CONTROLLER_WAITING_TO_JOIN_VIDEO_IS_READY_MESSAGE:
    'ACTIVITY_INTERACTION_HOST_CONTROLLER_WAITING_TO_JOIN_VIDEO_IS_READY_MESSAGE',
  ACTIVITY_INTERACTION_HOST_CONTROLLER_WAITING_TO_JOIN_START_BUTTON:
    'ACTIVITY_INTERACTION_HOST_CONTROLLER_WAITING_TO_JOIN_START_BUTTON',
  ACTIVITY_INTERACTION_HOST_CONTROLLER_WAITING_TO_JOIN_CANCEL_BUTTON:
    'ACTIVITY_INTERACTION_HOST_CONTROLLER_WAITING_TO_JOIN_CANCEL_BUTTON',
  ACTIVITY_INTERACTION_HOST_CONTROLLER_RUNNING_MESSAGE:
    'ACTIVITY_INTERACTION_HOST_CONTROLLER_RUNNING_MESSAGE',
  ACTIVITY_INTERACTION_HOST_CONTROLLER_RUNNING_MESSAGE_WITH_DATE:
    'ACTIVITY_INTERACTION_HOST_CONTROLLER_RUNNING_MESSAGE_WITH_DATE',
  ACTIVITY_INTERACTION_HOST_CONTROLLER_RUNNING_STOP_BUTTON:
    'ACTIVITY_INTERACTION_HOST_CONTROLLER_RUNNING_STOP_BUTTON',
  ACTIVITY_INTERACTION_HOST_CONTROLLER_REFUSED_MESSAGE:
    'ACTIVITY_INTERACTION_HOST_CONTROLLER_REFUSED_MESSAGE',
  ACTIVITY_INTERACTION_HOST_CONTROLLER_REFUSED_MESSAGE_WITH_DATE:
    'ACTIVITY_INTERACTION_HOST_CONTROLLER_REFUSED_MESSAGE_WITH_DATE',
  ACTIVITY_INTERACTION_HOST_CONTROLLER_REFUSED_MESSAGE_WITH_DATE_BY_ME:
    'ACTIVITY_INTERACTION_HOST_CONTROLLER_REFUSED_MESSAGE_WITH_DATE_BY_ME',
  ACTIVITY_INTERACTION_HOST_CONTROLLER_REFUSED_MESSAGE_WITH_DATE_BY_USER:
    'ACTIVITY_INTERACTION_HOST_CONTROLLER_REFUSED_MESSAGE_WITH_DATE_BY_USER',
  ACTIVITY_INTERACTION_HOST_CONTROLLER_CANCELED_MESSAGE:
    'ACTIVITY_INTERACTION_HOST_CONTROLLER_CANCELED_MESSAGE',
  ACTIVITY_INTERACTION_HOST_CONTROLLER_CANCELED_MESSAGE_WITH_DATE:
    'ACTIVITY_INTERACTION_HOST_CONTROLLER_CANCELED_MESSAGE_WITH_DATE',
  ACTIVITY_INTERACTION_HOST_CONTROLLER_CANCELED_MESSAGE_WITH_DATE_BY_ME:
    'ACTIVITY_INTERACTION_HOST_CONTROLLER_CANCELED_MESSAGE_WITH_DATE_BY_ME',
  ACTIVITY_INTERACTION_HOST_CONTROLLER_CANCELED_MESSAGE_WITH_DATE_BY_USER:
    'ACTIVITY_INTERACTION_HOST_CONTROLLER_CANCELED_MESSAGE_WITH_DATE_BY_USER',
  ACTIVITY_INTERACTION_HOST_CONTROLLER_TERMINATED_MESSAGE:
    'ACTIVITY_INTERACTION_HOST_CONTROLLER_TERMINATED_MESSAGE',
  ACTIVITY_INTERACTION_HOST_CONTROLLER_TERMINATED_MESSAGE_WITH_DATE:
    'ACTIVITY_INTERACTION_HOST_CONTROLLER_TERMINATED_MESSAGE_WITH_DATE',
  ACTIVITY_INTERACTION_HOST_CONTROLLER_TERMINATED_MESSAGE_WITH_DATE_BY_ME:
    'ACTIVITY_INTERACTION_HOST_CONTROLLER_TERMINATED_MESSAGE_WITH_DATE_BY_ME',
  ACTIVITY_INTERACTION_HOST_CONTROLLER_TERMINATED_MESSAGE_WITH_DATE_BY_USER:
    'ACTIVITY_INTERACTION_HOST_CONTROLLER_TERMINATED_MESSAGE_WITH_DATE_BY_USER',
  ACTIVITY_INTERACTION_HOST_CONTROLLER_DURATION_MESSAGE:
    'ACTIVITY_INTERACTION_HOST_CONTROLLER_DURATION_MESSAGE',
  ACTIVITY_INTERACTION_HOST_CONTROLLER_TITLE_RAISE_HAND:
    'ACTIVITY_INTERACTION_HOST_CONTROLLER_TITLE_RAISE_HAND',
  ACTIVITY_INTERACTION_HOST_CONTROLLER_TITLE_MANY_RAISE_HANDS:
    'ACTIVITY_INTERACTION_HOST_CONTROLLER_TITLE_MANY_RAISE_HANDS',
  ACTIVITY_INTERACTION_HOST_CONTROLLER_TITLE_DISABLED:
    'ACTIVITY_INTERACTION_HOST_CONTROLLER_TITLE_DISABLED',
  ACTIVITY_INTERACTION_HOST_CONTROLLER_CLOSE_BUTTON:
    'ACTIVITY_INTERACTION_HOST_CONTROLLER_CLOSE_BUTTON',
  ACTIVITY_INTERACTION_HOST_CONTROLLER_INTERACTION_ONGOING:
    'ACTIVITY_INTERACTION_HOST_CONTROLLER_INTERACTION_ONGOING',
  ACTIVITY_INTERACTION_HOST_CONTROLLER_INTERACTIONS_ONGOING:
    'ACTIVITY_INTERACTION_HOST_CONTROLLER_INTERACTIONS_ONGOING',
  ACTIVITY_INTERACTION_HOST_CONTROLLER_INTERACTION_PENDING:
    'ACTIVITY_INTERACTION_HOST_CONTROLLER_INTERACTION_PENDING',
  ACTIVITY_INTERACTION_HOST_CONTROLLER_INTERACTIONS_PENDING:
    'ACTIVITY_INTERACTION_HOST_CONTROLLER_INTERACTIONS_PENDING',
  ACTIVITY_INTERACTION_HOST_CONTROLLER_INTERACTION_COMPLETED:
    'ACTIVITY_INTERACTION_HOST_CONTROLLER_INTERACTION_COMPLETED',
  ACTIVITY_INTERACTION_HOST_CONTROLLER_INTERACTIONS_COMPLETED:
    'ACTIVITY_INTERACTION_HOST_CONTROLLER_INTERACTIONS_COMPLETED',
  ACTIVITY_INTERACTION_HOST_CONTROLLER_NO_INTERACTION_ONGOING:
    'ACTIVITY_INTERACTION_HOST_CONTROLLER_NO_INTERACTION_ONGOING',
  ACTIVITY_INTERACTION_HOST_CONTROLLER_NO_INTERACTION_PENDING:
    'ACTIVITY_INTERACTION_HOST_CONTROLLER_NO_INTERACTION_PENDING',
  ACTIVITY_INTERACTION_HOST_CONTROLLER_ENABLE_DESCRIPTION_TITLE:
    'ACTIVITY_INTERACTION_HOST_CONTROLLER_ENABLE_DESCRIPTION_TITLE',
  ACTIVITY_INTERACTION_HOST_CONTROLLER_ENABLE_DESCRIPTION_MESSAGE:
    'ACTIVITY_INTERACTION_HOST_CONTROLLER_ENABLE_DESCRIPTION_MESSAGE',
  // ACTIVITY - INTERACTIONS - CONTROLLER FOR ATTENDEE
  ACTIVITY_INTERACTION_ATTENDEE_CONTROLLER_WAITING_CAPTURE_MEDIA_MESSAGE:
    'ACTIVITY_INTERACTION_ATTENDEE_CONTROLLER_WAITING_CAPTURE_MEDIA_MESSAGE',
  ACTIVITY_INTERACTION_ATTENDEE_CONTROLLER_WAITING_CAPTURE_MEDIA_CANCEL_BUTTON:
    'ACTIVITY_INTERACTION_ATTENDEE_CONTROLLER_WAITING_CAPTURE_MEDIA_CANCEL_BUTTON',
  ACTIVITY_INTERACTION_ATTENDEE_CONTROLLER_ALREADY_STARTED_MESSAGE:
    'ACTIVITY_INTERACTION_ATTENDEE_CONTROLLER_ALREADY_STARTED_MESSAGE',
  ACTIVITY_INTERACTION_ATTENDEE_CONTROLLER_ALREADY_STARTED_STOP_BUTTON:
    'ACTIVITY_INTERACTION_ATTENDEE_CONTROLLER_ALREADY_STARTED_STOP_BUTTON',
  ACTIVITY_INTERACTION_ATTENDEE_CONTROLLER_ALREADY_STARTED_OK_BUTTON:
    'ACTIVITY_INTERACTION_ATTENDEE_CONTROLLER_ALREADY_STARTED_OK_BUTTON',
  ACTIVITY_INTERACTION_ATTENDEE_CONTROLLER_MEDIA_ERROR_MESSAGE:
    'ACTIVITY_INTERACTION_ATTENDEE_CONTROLLER_MEDIA_ERROR_MESSAGE',
  ACTIVITY_INTERACTION_ATTENDEE_CONTROLLER_MEDIA_RETRY_BUTTON:
    'ACTIVITY_INTERACTION_ATTENDEE_CONTROLLER_MEDIA_RETRY_BUTTON',
  ACTIVITY_INTERACTION_ATTENDEE_CONTROLLER_START_NEW_MESSAGE:
    'ACTIVITY_INTERACTION_ATTENDEE_CONTROLLER_START_NEW_MESSAGE',
  ACTIVITY_INTERACTION_ATTENDEE_CONTROLLER_START_NEW_MESSAGE_IAM_CREATOR:
    'ACTIVITY_INTERACTION_ATTENDEE_CONTROLLER_START_NEW_MESSAGE_IAM_CREATOR',
  ACTIVITY_INTERACTION_ATTENDEE_CONTROLLER_START_NEW_PLACEHOLDER_MESSAGE:
    'ACTIVITY_INTERACTION_ATTENDEE_CONTROLLER_START_NEW_PLACEHOLDER_MESSAGE',
  ACTIVITY_INTERACTION_ATTENDEE_CONTROLLER_START_NEW_VALIDATE_BUTTON:
    'ACTIVITY_INTERACTION_ATTENDEE_CONTROLLER_START_NEW_VALIDATE_BUTTON',
  ACTIVITY_INTERACTION_ATTENDEE_CONTROLLER_START_NEW_VALIDATE_BUTTON_AGAIN:
    'ACTIVITY_INTERACTION_ATTENDEE_CONTROLLER_START_NEW_VALIDATE_BUTTON_AGAIN',
  ACTIVITY_INTERACTION_ATTENDEE_CONTROLLER_START_NEW_VALIDATE_BUTTON_IAM_CREATOR:
    'ACTIVITY_INTERACTION_ATTENDEE_CONTROLLER_START_NEW_VALIDATE_BUTTON_IAM_CREATOR',
  ACTIVITY_INTERACTION_ATTENDEE_CONTROLLER_WAITING_RESPONSE_MESSAGE:
    'ACTIVITY_INTERACTION_ATTENDEE_CONTROLLER_WAITING_RESPONSE_MESSAGE',
  ACTIVITY_INTERACTION_ATTENDEE_CONTROLLER_WAITING_RESPONSE_CANCEL_BUTTON:
    'ACTIVITY_INTERACTION_ATTENDEE_CONTROLLER_WAITING_RESPONSE_CANCEL_BUTTON',
  ACTIVITY_INTERACTION_ATTENDEE_CONTROLLER_WAITING_MEDIA_MESSAGE:
    'ACTIVITY_INTERACTION_ATTENDEE_CONTROLLER_WAITING_MEDIA_MESSAGE',
  ACTIVITY_INTERACTION_ATTENDEE_CONTROLLER_WAITING_MEDIA_CANCEL_BUTTON:
    'ACTIVITY_INTERACTION_ATTENDEE_CONTROLLER_WAITING_MEDIA_CANCEL_BUTTON',
  ACTIVITY_INTERACTION_ATTENDEE_CONTROLLER_WAITING_TO_JOIN_MESSAGE:
    'ACTIVITY_INTERACTION_ATTENDEE_CONTROLLER_WAITING_TO_JOIN_MESSAGE',
  ACTIVITY_INTERACTION_ATTENDEE_CONTROLLER_WAITING_TO_JOIN_CANCEL_BUTTON:
    'ACTIVITY_INTERACTION_ATTENDEE_CONTROLLER_WAITING_TO_JOIN_CANCEL_BUTTON',
  ACTIVITY_INTERACTION_ATTENDEE_CONTROLLER_RUNNING_MESSAGE:
    'ACTIVITY_INTERACTION_ATTENDEE_CONTROLLER_RUNNING_MESSAGE',
  ACTIVITY_INTERACTION_ATTENDEE_CONTROLLER_RUNNING_STOP_BUTTON:
    'ACTIVITY_INTERACTION_ATTENDEE_CONTROLLER_RUNNING_STOP_BUTTON',
  ACTIVITY_INTERACTION_ATTENDEE_CONTROLLER_REFUSED_MESSAGE:
    'ACTIVITY_INTERACTION_ATTENDEE_CONTROLLER_REFUSED_MESSAGE',
  ACTIVITY_INTERACTION_ATTENDEE_CONTROLLER_TERMINATED_MESSAGE:
    'ACTIVITY_INTERACTION_ATTENDEE_CONTROLLER_TERMINATED_MESSAGE',
  ACTIVITY_INTERACTION_ATTENDEE_CONTROLLER_CANCELED_MESSAGE:
    'ACTIVITY_INTERACTION_ATTENDEE_CONTROLLER_CANCELED_MESSAGE',
  ACTIVITY_INTERACTION_ATTENDEE_CONTROLLER_TITLE: 'ACTIVITY_INTERACTION_ATTENDEE_CONTROLLER_TITLE',
  // ACTIVITY - INTERACTIONS - TOAST ERRORS
  ACTIVITY_INTERACTION_TOAST_DEFAULT_MESSAGE_ERROR:
    'ACTIVITY_INTERACTION_TOAST_DEFAULT_MESSAGE_ERROR',
  ACTIVITY_INTERACTION_TOAST_PUBLISH_MEDIA_ERROR: 'ACTIVITY_INTERACTION_TOAST_PUBLISH_MEDIA_ERROR',
  ACTIVITY_INTERACTION_TOAST_DEVICE_UNKNOWN_ERROR:
    'ACTIVITY_INTERACTION_TOAST_DEVICE_UNKNOWN_ERROR',
  ACTIVITY_INTERACTION_TOAST_DEVICE_DISCONNECTED_ERROR:
    'ACTIVITY_INTERACTION_TOAST_DEVICE_DISCONNECTED_ERROR',
  ACTIVITY_INTERACTION_TOAST_LIMIT_SIMULTANEOUS_INTERACTION_EXCEEDED_ERROR:
    'ACTIVITY_INTERACTION_TOAST_LIMIT_SIMULTANEOUS_INTERACTION_EXCEEDED_ERROR',
  ACTIVITY_INTERACTION_TOAST_MEDIA_ERROR: 'ACTIVITY_INTERACTION_TOAST_MEDIA_ERROR',
  ACTIVITY_INTERACTION_TOAST_CAPTURE_MEDIA_ERROR: 'ACTIVITY_INTERACTION_TOAST_CAPTURE_MEDIA_ERROR',
  ACTIVITY_INTERACTION_TOAST_VIDEO_ERROR: 'ACTIVITY_INTERACTION_TOAST_VIDEO_ERROR',
  // VIDEO - CAPTURE (Component)
  VIDEO_CAPTURE_DEVICE_SHARE_SCREEN_LABEL: 'VIDEO_CAPTURE_DEVICE_SHARE_SCREEN_LABEL',
  VIDEO_CAPTURE_ELEMENT_ENABLE_DISABLE_VIDEO_HOVER_MESSAGE:
    'VIDEO_CAPTURE_ELEMENT_ENABLE_DISABLE_VIDEO_HOVER_MESSAGE',
  VIDEO_CAPTURE_ELEMENT_ENABLE_DISABLE_AUDIO_HOVER_MESSAGE:
    'VIDEO_CAPTURE_ELEMENT_ENABLE_DISABLE_AUDIO_HOVER_MESSAGE',
  VIDEO_CAPTURE_ELEMENT_ENABLE_DISABLE_SCREEN_SHARING_HOVER_MESSAGE:
    'VIDEO_CAPTURE_ELEMENT_ENABLE_DISABLE_SCREEN_SHARING_HOVER_MESSAGE',
  VIDEO_CAPTURE_ELEMENT_NO_VIDEO_MESSAGE: 'VIDEO_CAPTURE_ELEMENT_NO_VIDEO_MESSAGE',
  VIDEO_CAPTURE_ELEMENT_NO_AUDIO_MESSAGE: 'VIDEO_CAPTURE_ELEMENT_NO_AUDIO_MESSAGE',
  VIDEO_CAPTURE_ELEMENT_NO_VIDEO_AND_AUDIO_MESSAGE:
    'VIDEO_CAPTURE_ELEMENT_NO_VIDEO_AND_AUDIO_MESSAGE',
  // FORBIDDEN - Page
  FORBIDDEN_PAGE_CONTACT_ADMINISTRATOR: 'FORBIDDEN_PAGE_CONTACT_ADMINISTRATOR',
  FORBIDDEN_PAGE_USER_BANNED_TITLE: 'FORBIDDEN_PAGE_USER_BANNED_TITLE',
  FORBIDDEN_PAGE_USER_BANNED_MESSAGE: 'FORBIDDEN_PAGE_USER_BANNED_MESSAGE',
  FORBIDDEN_PAGE_USER_DISABLED_TITLE: 'FORBIDDEN_PAGE_USER_DISABLED_TITLE',
  FORBIDDEN_PAGE_USER_DISABLED_MESSAGE: 'FORBIDDEN_PAGE_USER_DISABLED_MESSAGE',
  FORBIDDEN_PAGE_USER_TRIAL_PERIOD_IS_OVER_TITLE: 'FORBIDDEN_PAGE_USER_TRIAL_PERIOD_IS_OVER_TITLE',
  FORBIDDEN_PAGE_USER_TRIAL_PERIOD_IS_OVER_MESSAGE:
    'FORBIDDEN_PAGE_USER_TRIAL_PERIOD_IS_OVER_MESSAGE',
  FORBIDDEN_PAGE_ORGANIZATION_DISABLED_TITLE: 'FORBIDDEN_PAGE_ORGANIZATION_DISABLED_TITLE',
  FORBIDDEN_PAGE_ORGANIZATION_DISABLED_MESSAGE: 'FORBIDDEN_PAGE_ORGANIZATION_DISABLED_MESSAGE',
  FORBIDDEN_PAGE_ORGANIZATION_TRIAL_PERIOD_IS_OVER_TITLE:
    'FORBIDDEN_PAGE_ORGANIZATION_TRIAL_PERIOD_IS_OVER_TITLE',
  FORBIDDEN_PAGE_ORGANIZATION_TRIAL_PERIOD_IS_OVER_MESSAGE:
    'FORBIDDEN_PAGE_ORGANIZATION_TRIAL_PERIOD_IS_OVER_MESSAGE',
  FORBIDDEN_PAGE_ERROR_TITLE: 'FORBIDDEN_PAGE_ERROR_TITLE',
  FORBIDDEN_PAGE_ERROR_MESSAGE: 'FORBIDDEN_PAGE_ERROR_MESSAGE',
};
export default Strings;
