import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import * as RNLocalize from 'react-native-localize';
import moment from 'moment';

import { localeEn, localeFr } from '@mobiscroll/react';
import translationEN from './en/translation.json';
import translationFR from './fr/translation.json';

export const resources = {
  en: {
    translation: translationEN,
  },
  fr: {
    translation: translationFR,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false, // Not needed for React as it escapes by default
  },
});

export const getLocale = () => {
  const locales = RNLocalize.getLocales();
  if (locales && locales.length > 0) {
    const favorite = locales[0];
    return favorite.languageCode;
  }
  return undefined;
};

export const getMobiscrollLocale = () => {
  const locale = getLocale();
  if (locale === 'fr') return localeFr;
  if (locale === 'en') return localeEn;
  return undefined;
};

const locale = getLocale();
if (locale) {
  i18n.changeLanguage(locale);
  moment.locale(locale);
}

export default i18n;
