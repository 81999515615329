import { createSelector } from 'reselect';

const appSelector = (globalState) => globalState.get('app');
const currentlySendingSelector = (state) => appSelector(state).get('currentlySending');
const socketStatusSelector = (state) => appSelector(state).get('socketStatus');
const backendApiInfoSelector = (state) => appSelector(state).get('backendApiInfo');

/*
 * Will send true if there is an action "currentlySending", else false.
 * Also accept an array of name as param, will return true ONLY if one of
 * the name is "currentlySending", else false.
 * Empty array is ignored and look for any action "currentlySending"
 */
const makeSelectCurrentlySending = (names) =>
  createSelector(currentlySendingSelector, (state) => {
    if (names !== undefined && names.length > 0) return state.some((el) => names.includes(el));
    return !state.isEmpty();
  });

const makeSelectSocketStatus = () => createSelector(socketStatusSelector, (state) => state);

const makeSelectBackendApiInfo = () => createSelector(backendApiInfoSelector, (state) => state);

export default {
  makeSelectCurrentlySending,
  makeSelectSocketStatus,
  makeSelectBackendApiInfo,
};
