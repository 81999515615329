import React, { useEffect, useState } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { Button, StyleSheet, Text } from 'react-native';
import PropTypes from 'prop-types';

import Lottie from 'react-lottie-player';
import { ActivityIndicator, ScrollView, TouchableOpacity, View } from 'react-native-web';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

import activitySelectors from '../../App/selectors/activity';
import userSelectors from '../../App/selectors/user';
import appSelectors from '../../App/selectors/app';
import activityActions from '../../App/actions/activity';
import ActivityPlayer from './player';

import Activity from '../../components/Activity';

import LiveButton from '../../assets/animations/readyToRecord.json';
import colors from '../../constants/colors';
import fonts from '../../constants/fonts';
import { screenName } from '../../constants/navigation';
import { navigate } from '../../helpers/navigation';

import IconNotFound from '../../assets/activities/icon-violet-not-found.svg';
import { userCanEditActivity } from '../../helpers/activity';
import ActivityDeleteModal from '../../components/Modals/activityDelete';
import VodCard from '../../components/Vod/card';
import { Strings } from '../../helpers/strings';
import ActivityVod from './vod';
import { useResponsiveThreshold } from '../../hooks/element';
import { RESPONSIVE_THRESHOLD } from '../../constants/global';
import AppVersion from '../../components/Generic/appVersion';
import { SocketStatus } from '../../helpers/socket';

const styles = StyleSheet.create({
  activityDropdownWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    borderRadius: 20,
    flex: 1,
  },
  notFoundContainer: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    left: '50%',
    transform: 'translate(-50%)',
    maxWidth: '100%',
  },
  formRow: {
    display: 'flex',
    flexFlow: 'row',
    marginTop: 10,
  },
  background: {
    height: '100vh',
    backgroundColor: colors.getMainWhite(),
    padding: 20,
    borderRadius: 20,
  },
  logoText: {
    fontFamily: fonts.getHalisR(),
    fontSize: 29,
    fontWeight: 900,
    letterSpacing: 'normal',
    marginRight: 20,
    color: colors.getBluePrussian(),
  },
  notFoundText: {
    fontFamily: fonts.getSignika(),
    fontSize: 20,
    fontWeight: 600,
    color: colors.getBluePrussian(),
  },
  notFoundSubText: {
    fontFamily: fonts.getSignika(),
    fontSize: 18,
    fontWeight: 300,
    color: colors.getBluePrussian(),
  },
  purpleText: {
    fontFamily: fonts.getSignika(),
    fontSize: 22,
    fontWeight: 600,
    color: colors.getMainPurple(),
  },
  redText: {
    fontFamily: fonts.getSignika(),
    fontSize: 22,
    fontWeight: 600,
    color: colors.getDeleteRed(),
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  row: {
    display: 'flex',
    flexFlow: 'row',
  },
  col: {
    display: 'flex',
    flexFlow: 'column',
  },
  loginButton: {
    marginTop: 10,
  },
});

const DropDownMode = {
  None: 0,
  Edit: 1,
  Vod: 2,
};

const ActivityPage = ({
  isAuthenticated,
  route,
  user,
  activityInfo,
  activityState,
  activityDevices,
  activityInteractions,
  currentlySending,
  joinActivity,
  leaveActivity,
  requestActivity,
  requestDeleteActivity,
  setActivityInfo,
  socketStatus,
}) => {
  const [showPlayer, setShowPlayer] = useState(false);
  const [vodId, setVodId] = useState(undefined);
  const [dropdownMode, setDropdownMode] = useState(DropDownMode.None);
  const [canEdit, setCanEdit] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [activityId, setActivityId] = useState(activityInfo?.id);
  const { t } = useTranslation();

  const viewSize = useResponsiveThreshold();

  const refreshActivity = () => {
    const routeId = route?.params?.id;
    if (routeId) {
      requestActivity(routeId);
    }
  };

  useEffect(() => {
    if (route?.params?.vodId) {
      setVodId(route.params.vodId);
    }
    if (route?.params?.showPlayer) {
      setShowPlayer(String(route?.params?.showPlayer).toLowerCase() === 'true');
    } else {
      // default value
      setShowPlayer(false);
    }
  }, [route]);

  useEffect(() => {
    refreshActivity();
    return () => {
      setActivityInfo({}); // clear
    };
  }, []);

  useEffect(() => {
    // We must wait for the activity to be fetched before joining it,
    // otherwise the fetch result data will override the devices
    // contexts that were provided when joining by websocket
    //
    // the currentlySending will become true on mount when starting to fetch
    // the activity, then false once we have it
    let joinActivityId;
    if (activityId && !currentlySending && socketStatus === SocketStatus.CONNECTED) {
      joinActivityId = activityId;
      joinActivity(joinActivityId);
    }
    return () => {
      if (joinActivityId) leaveActivity(activityId);
    };
  }, [activityId, socketStatus, currentlySending]);

  useEffect(() => {
    if (activityInfo?.id) setActivityId(activityInfo?.id);
  }, [activityInfo]);

  useEffect(() => {
    setCanEdit(isAuthenticated && userCanEditActivity(activityInfo, user));
  }, [activityInfo, user, isAuthenticated]);

  const getActivityPlayer = () => (
    <ActivityPlayer
      activity={activityInfo}
      activityState={activityState}
      interactions={activityInteractions}
    />
  );

  const onJoinEvent = () => {
    navigate(screenName.ACTIVITY_SCREENS, {
      screen: 'activity',
      params: { id: activityInfo._id, showPlayer: true, vodId: undefined },
    });
  };

  const onClickVod = () => {
    if (dropdownMode === DropDownMode.Vod) setDropdownMode(DropDownMode.None);
    else setDropdownMode(DropDownMode.Vod);
  };

  const onModify = () => {
    if (activityInfo) {
      navigate(screenName.SCHEDULE, { id: activityInfo._id });
    }
  };

  const onDelete = () => {
    setDeleteModalVisible(true);
  };

  const onClick = () => {
    if (dropdownMode === DropDownMode.Edit) setDropdownMode(DropDownMode.None);
    else setDropdownMode(DropDownMode.Edit);
  };

  const onConfirmDelete = () => {
    setDeleteModalVisible(false);
    requestDeleteActivity(activityInfo._id);
    navigate(screenName.ACTIVITY, { id: undefined, isUnknown: true });
  };

  const onVodSelectFromActivityPage = (selectedVodId) => {
    navigate(screenName.ACTIVITY, { id: activityInfo?.id, vodId: selectedVodId });
  };

  const onVodSelectFromVodPage = (selectedVodId) => {
    navigate(screenName.ACTIVITY, { id: activityInfo?.id, vodId: selectedVodId });
  };

  const onClickVodItem = (vodItem) => {
    onVodSelectFromActivityPage(vodItem._id);
  };

  const renderNotFound = () => {
    if (isAuthenticated) {
      return (
        <View style={[styles.notFoundContainer, { marginVertical: 135 }]}>
          <IconNotFound />
          <View style={{ marginTop: 40, marginBottom: 15 }}>
            <Text style={styles.notFoundText}>
              {t(Strings.THE_REQUESTED_ACTIVITY_DOES_NOT_EXIST)}
            </Text>
          </View>
          <View>
            <Text style={styles.notFoundSubText}>{t(Strings.VERIFY_THE_ACTIVITY_URL)}</Text>
            <Text style={styles.notFoundSubText}>{t(Strings.THE_EVENT_MAY_HAVE_BEEN_DELETED)}</Text>
          </View>
        </View>
      );
    }
    return (
      <View style={[styles.notFoundContainer, { marginVertical: 135 }]}>
        <IconNotFound />
        <View style={{ marginTop: 40, marginBottom: 15 }}>
          <Text style={styles.notFoundText}>
            {t(Strings.A_CONNECTION_IS_REQUIRED_OR_THE_REQUESTED_ACTIVITY_DOES_NOT_EXIST)}
          </Text>
        </View>
        <View>
          <Text style={[styles.notFoundSubText, { marginBottom: 10 }]}>
            {t(Strings.TRY_TO_LOGIN_OR_VERIFY_THE_ACTIVITY_URL)}
          </Text>
          <Button
            title={t(Strings.LOGIN)}
            onPress={() => {
              const currentUrl = window.location?.href;
              navigate(screenName.LOGIN, { redirect: currentUrl });
            }}
            color="#5b41f0"
          />
        </View>
      </View>
    );
  };

  const renderEditionDropdown = () => {
    return (
      <View style={[styles.row, { gap: 35, marginLeft: 5, marginTop: 25 }]}>
        <TouchableOpacity onPress={onModify}>
          <Text style={styles.purpleText}>{t(Strings.MODIFY)}</Text>
        </TouchableOpacity>
        <TouchableOpacity onPress={onDelete}>
          <Text style={styles.redText}>{t(Strings.DELETE)}</Text>
        </TouchableOpacity>
      </View>
    );
  };

  const renderVodDropdown = () => (
    <ScrollView
      style={[
        styles.col,
        {
          marginLeft: viewSize === RESPONSIVE_THRESHOLD.SMALL ? 0 : 25,
          marginTop: 10,
          flex: 1,
        },
      ]}
      contentContainerStyle={{ flex: 1, gap: 10 }}
    >
      {activityInfo.vodSessions.map((session) => (
        <VodCard key={session._id} vodSession={session} onClick={() => onClickVodItem(session)} />
      ))}
    </ScrollView>
  );

  const renderDropdown = () => {
    if (dropdownMode === DropDownMode.None) return null;
    if (dropdownMode === DropDownMode.Edit) return renderEditionDropdown();
    if (dropdownMode === DropDownMode.Vod) return renderVodDropdown(viewSize);
    return undefined;
  };

  const renderPageContent = (isUnknown = false) => {
    return (
      <View style={styles.background}>
        <View style={[styles.formRow, { alignItems: 'center' }]}>
          <Lottie
            style={{
              margin: -10,
              height: 100,
              width: 100,
            }}
            animationData={LiveButton}
            loop
            play
            rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
          />
          <Text style={styles.logoText}>KAPTURE</Text>
        </View>
        <View style={[styles.formRow, { flex: 1, height: '100%' }]}>
          {isUnknown ? (
            renderNotFound()
          ) : (
            <View style={styles.activityDropdownWrapper}>
              <Activity
                activity={activityInfo}
                onJoinEvent={onJoinEvent}
                onClickVod={onClickVod}
                onClick={onClick}
                isClickable={canEdit}
                viewSize={viewSize}
              />
              {renderDropdown(viewSize)}
            </View>
          )}
        </View>
        <ActivityDeleteModal
          isVisible={deleteModalVisible}
          setIsVisible={setDeleteModalVisible}
          onConfirm={onConfirmDelete}
          onCancel={() => {
            setDeleteModalVisible(false);
          }}
        />
        <AppVersion />
      </View>
    );
  };

  const renderLoading = () => {
    return <ActivityIndicator size="large" style={styles.loading} />;
  };

  const renderVodPlayer = () => {
    return (
      <ActivityVod
        vodSessions={activityInfo?.vodSessions}
        selectedVodId={vodId}
        onSelect={onVodSelectFromVodPage}
        requestActivityRefresh={refreshActivity}
      />
    );
  };

  const getDisplay = () => {
    if (currentlySending) return renderLoading();
    if (!route?.params?.id || route?.params?.isUnknown || isEmpty(activityInfo))
      return renderPageContent(true);
    if (vodId) return renderVodPlayer();
    if (showPlayer) return getActivityPlayer();
    return renderPageContent();
  };

  return getDisplay();
};

ActivityPage.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  route: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  activityInfo: PropTypes.object.isRequired,
  activityDevices: PropTypes.array.isRequired,
  activityInteractions: PropTypes.array,
  currentlySending: PropTypes.bool.isRequired,
  socketStatus: PropTypes.string.isRequired,

  // Actions
  joinActivity: PropTypes.func.isRequired,
  leaveActivity: PropTypes.func.isRequired,
  requestActivity: PropTypes.func.isRequired,
  requestDeleteActivity: PropTypes.func.isRequired,
  setActivityInfo: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  isAuthenticated: userSelectors.makeSelectIsAuthenticated(),
  user: userSelectors.makeSelectUserInfo(),
  activityInfo: activitySelectors.makeSelectActivityInfo(),
  activityState: activitySelectors.makeSelectActivityState(),
  activityDevices: activitySelectors.makeSelectActivityDevices(),
  activityInteractions: activitySelectors.makeSelectActivityInteractions(),
  currentlySending: appSelectors.makeSelectCurrentlySending(),
  socketStatus: appSelectors.makeSelectSocketStatus(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    joinActivity: (activityId) => dispatch(activityActions.socketJoinActivity(activityId)),
    leaveActivity: (activityId) => dispatch(activityActions.socketLeaveActivity(activityId)),
    requestActivity: (activityId) => dispatch(activityActions.requestActivity(activityId)),
    requestDeleteActivity: (activityId) =>
      dispatch(activityActions.requestDeleteActivity(activityId)),
    setActivityInfo: (activityInfo) => dispatch(activityActions.setActivityInfo(activityInfo)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivityPage);
