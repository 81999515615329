import { useEffect, useRef, useState } from 'react';
import {
  addMediaDevicesChangeListener,
  addTrackEndedMediaStreamListener,
  getAudioTrack,
  getMediaDevices,
  removeMediaDevicesChangeListener,
} from '../helpers/media';

export const useMediaDevicesChange = (
  /** @type { 'video' | 'audio' | 'both' } */ type,
  /** @type { 'input' | 'output' | 'both' } */ io,
) => {
  const [mediaDevices, setMediaDevices] = useState([]);
  /** @type {MediaDeviceInfo[]} */
  const result = mediaDevices;

  useEffect(() => {
    const listener = async () => {
      const devices = await getMediaDevices(type, io);
      setMediaDevices(devices);
    };
    addMediaDevicesChangeListener(listener);
    listener();
    return () => {
      removeMediaDevicesChangeListener(listener);
    };
  }, []); // no dependencies -> stable listener

  return result;
};

export const useAudioInputMediaDevicesChange = () => {
  return useMediaDevicesChange('audio', 'input');
};

export const useAudioOutputMediaDevicesChange = () => {
  return useMediaDevicesChange('audio', 'output');
};

export const useVideoInputMediaDevicesChange = () => {
  return useMediaDevicesChange('video', 'input');
};

export const useVideoOutputMediaDevicesChange = () => {
  return useMediaDevicesChange('video', 'output');
};

export const useAudioVolumeAnalyzer = (
  /** @type { MediaStream } */ stream,
  /** @type { (volume: number) => void } */ callback,
  samplingInterval = 40,
) => {
  // initialize mutable ref, which stores callback
  const callbackRef = useRef();
  const [audioTrack, setAudioTrack] = useState(null);

  const audioContext = new AudioContext();
  const analyser = audioContext.createAnalyser();

  analyser.smoothingTimeConstant = 0.5;
  analyser.fftSize = 1024;

  useEffect(() => {
    callbackRef.current = callback;
  });

  useEffect(() => {
    let audioSource;
    if (audioTrack) {
      audioSource = audioContext.createMediaStreamSource(new MediaStream([audioTrack]));
      audioSource.connect(analyser);
    }
    const interval = setInterval(() => {
      if (!callbackRef.current) return;

      if (stream) {
        const currAudioTrack = getAudioTrack(stream);
        if (audioTrack !== currAudioTrack) {
          setAudioTrack(currAudioTrack);
          return;
        }
      }

      const array = new Uint8Array(analyser.frequencyBinCount);
      analyser.getByteFrequencyData(array);
      const arraySum = array.reduce((a, value) => a + value, 0);
      const average = arraySum / array.length;
      callbackRef.current(average);
    }, samplingInterval);
    return () => {
      if (audioSource) audioSource.disconnect();
      clearInterval(interval);
    };
  }, [audioTrack]);
};

export const useTrackEndedMediaStream = (
  /** @type { MediaStream } */ stream,
  /** @type { (volume: number) => void } */ callback,
) => {
  // initialize mutable ref, which stores callback
  const callbackRef = useRef();

  useEffect(() => {
    callbackRef.current = callback;
  });

  useEffect(() => {
    const result = addTrackEndedMediaStreamListener(stream, callbackRef.current);
    return () => result.clear();
  }, [stream]);
};
