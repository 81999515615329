import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text, View, StyleSheet } from 'react-native';
import { TouchableOpacity } from 'react-native-web';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import userSelectors from '../../App/selectors/user';
import fonts from '../../constants/fonts';
import colors from '../../constants/colors';
import { navigate } from '../../helpers/navigation';
import { Strings } from '../../helpers/strings';
import BackButton from '../../components/Generic/backButton';

import RocketOutline from '../../assets/navigation/rocket-outline.svg';
import IconDisconnect from '../../assets/navigation/disconnect.svg';

const styles = StyleSheet.create({
  main: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    height: 50,
    width: 50,
    padding: 30,
  },
  title: {
    fontFamily: fonts.getSignika(),
    color: colors.getBluePrussian(),
    fontSize: 20,
    fontWeight: 600,
    paddingHorizontal: '15px',
    marginBottom: '15px',
    textAlign: 'center',
  },
  subTitle: {
    fontFamily: fonts.getSignika(),
    color: colors.getBluePrussian(),
    fontSize: 18,
    fontWeight: 300,
    paddingHorizontal: '15px',
    textAlign: 'center',
  },
  disconnectContainer: {
    position: 'absolute',
    bottom: '5px',
    left: '20px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: 40,
    gap: 10,
    cursor: 'pointer',
  },
  disconnectText: {
    color: colors.getMirage(),
    fontWeight: 500,
    fontFamily: fonts.getHalisR(),
    fontSize: 12,
  },
});

const ComingSoonPage = ({ isAuthenticated }) => {
  const { t } = useTranslation();
  return (
    <View style={styles.main}>
      <BackButton />
      <RocketOutline style={styles.icon} />
      <Text style={styles.title}>Coming Soon</Text>
      <Text style={styles.subTitle}>
        Un peu de patience... De nouvelles fonctionnalités vont bientôt arriver!
      </Text>
      {isAuthenticated ? (
        <TouchableOpacity style={styles.disconnectContainer} onPress={() => navigate('logout')}>
          <IconDisconnect width="15px" height="15px" fill={colors.getMirage()} />
          <Text style={styles.disconnectText}>{t(Strings.DISCONNECT)}</Text>
        </TouchableOpacity>
      ) : null}
    </View>
  );
};

ComingSoonPage.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
};

const mapStateToProps = createStructuredSelector({
  isAuthenticated: userSelectors.makeSelectIsAuthenticated(),
  user: userSelectors.makeSelectUserInfo(),
});

export default connect(mapStateToProps, null)(ComingSoonPage);
