import { useEffect, useRef, useState } from 'react';

import moment from 'moment';
import { roundDownTime } from '../helpers/utils';

/**
 * Utility hook that awakes every *stepSize* amounts of minutes.
 */
export const useMinuteStepClock = (stepSize) => {
  const [roundedNow, setRoundedNow] = useState(roundDownTime(moment(), stepSize));

  useEffect(() => {
    const id = setInterval(() => {
      const now = moment();
      const nowMinutes = now.minutes();
      if (nowMinutes % stepSize === 0 && nowMinutes !== roundedNow.minutes()) {
        setRoundedNow(roundDownTime(now, stepSize));
      }
    }, 1000);
    return () => clearInterval(id);
  }, [roundedNow, stepSize]);

  return roundedNow;
};

export const useInterval = (callback, delay) => {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const tick = () => savedCallback.current();
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};

export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value; // assign the value of ref to the argument
  }, [value]); // this code will run when the value of 'value' changes
  return ref.current; // in the end, return the current ref value.
};

class EventListeners {
  constructor() {
    this.listeners = new Map();
  }

  addEventListerner(key, callback) {
    if (!this.listeners.get(key)) {
      this.listeners.set(key, [callback]);
      return;
    }
    this.listeners.get(key).push(callback);
  }

  removeEventListerner(key, callback) {
    let listeners = this.listeners.get(key);
    if (!listeners) return;
    listeners = listeners.filter((listener) => listener !== callback);
    this.listeners.set(key);
  }

  triggersEventListeners(key, ...args) {
    const listeners = this.listeners.get(key);
    if (!listeners) return;
    listeners.forEach((listener) => listener(...args));
  }
}

export const useEventListeners = (/** @type {string[]} */ keys) => {
  const listenersRef = useRef(new EventListeners());
  return listenersRef.current; // in the end, return the current ref value.
};

export default {
  usePrevious,
  useMinuteStepClock,
};
