import { call, put, select, takeLatest } from 'redux-saga/effects';

import api from '../api/_index';
import errorActions from '../actions/error';
import appActions from '../actions/app';
import organizationActions, { Types as organizationTypes } from '../actions/organization';
import userSelectors from '../selectors/user';
import { submitRequest } from './user';

const { REQUEST_AUTH_INFO, REQUEST_ORGANIZATION, REQUEST_ROOMS, REQUEST_DEVICES } =
  organizationTypes;

function* requestAuthInfo() {
  try {
    yield put(appActions.addCurrentlySending(`${REQUEST_AUTH_INFO}`));

    const authInfo = yield call(submitRequest, api.organization.requestAuthInfo);

    yield put(organizationActions.setAuthInfo(authInfo));
  } catch (error) {
    yield put(errorActions.handleError(error));
  } finally {
    yield put(appActions.removeCurrentlySending(`${REQUEST_AUTH_INFO}`));
  }
}

function* requestOrganization() {
  const user = yield select(userSelectors.makeSelectUserInfo());

  try {
    yield put(appActions.addCurrentlySending(`${REQUEST_ORGANIZATION}`));

    const organization = yield call(
      submitRequest,
      api.organization.requestOrganization,
      user._organization,
    );

    yield put(organizationActions.setOrganization(organization));
  } catch (error) {
    yield put(errorActions.handleError(error));
  } finally {
    yield put(appActions.removeCurrentlySending(`${REQUEST_ORGANIZATION}`));
  }
}

function* requestRooms() {
  try {
    yield put(appActions.addCurrentlySending(`${REQUEST_ROOMS}`));

    const rooms = yield call(submitRequest, api.organization.requestRooms);

    yield put(organizationActions.setRooms(rooms));
  } catch (error) {
    yield put(errorActions.handleError(error));
  } finally {
    yield put(appActions.removeCurrentlySending(`${REQUEST_ROOMS}`));
  }
}

function* watcherRequestAuthInfo() {
  yield takeLatest(REQUEST_AUTH_INFO, requestAuthInfo);
}

function* watcherRequestOrganization() {
  yield takeLatest(REQUEST_ORGANIZATION, requestOrganization);
}

function* watcherRequestRooms() {
  yield takeLatest(REQUEST_ROOMS, requestRooms);
}

export default [watcherRequestAuthInfo(), watcherRequestOrganization(), watcherRequestRooms()];
