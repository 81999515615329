import { fromJS } from 'immutable';
import { createReducer } from 'reduxsauce';
import { satisfies, validate } from 'compare-versions';
import { Types } from '../actions/app';
import { COMPATIBLE_BACKEND_API_VERSIONS } from '../../constants/api';

const initialState = fromJS({
  currentlySending: [], // Store the action needing a "loading" state
  socketStatus: 'DISCONNECTED',
  isRefreshingToken: false,
  backendApiInfo: undefined, // { version: string, isCompatible: boolean }
});

export const addCurrentlySending = (state = initialState, { name }) =>
  state.update('currentlySending', (currentlySending) => currentlySending.push(name));

export const removeCurrentlySending = (state = initialState, { name }) =>
  state.update('currentlySending', (currentlySending) => {
    const index = currentlySending.findIndex((el) => el === name);
    return currentlySending.delete(index);
  });

export const setSocketStatus = (state = initialState, { status }) =>
  state.set('socketStatus', status);

export const setBackendApiVersion = (state = initialState, { apiVersion }) => {
  let backendApiInfo;

  if (apiVersion && validate(apiVersion)) {
    const isCompatible = COMPATIBLE_BACKEND_API_VERSIONS.some((version) => {
      try {
        return satisfies(apiVersion, version);
      } catch {
        // wrongly formatted version
        return false;
      }
    });
    backendApiInfo = { version: apiVersion, isCompatible };
  }

  return state.set('backendApiInfo', backendApiInfo);
};

export default createReducer(initialState, {
  [Types.ADD_CURRENTLY_SENDING]: addCurrentlySending,
  [Types.REMOVE_CURRENTLY_SENDING]: removeCurrentlySending,
  [Types.SET_SOCKET_STATUS]: setSocketStatus,
  [Types.SET_BACKEND_API_VERSION]: setBackendApiVersion,
});
