import React, { useState, useEffect, createRef } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import moment from 'moment';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Text, View, StyleSheet, TextInput, CheckBox } from 'react-native';
import { Controller, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import activitySelectors from '../../App/selectors/activity';
import userSelectors from '../../App/selectors/user';
import organizationSelectors from '../../App/selectors/organization';
import activityActions from '../../App/actions/activity';
import userActions from '../../App/actions/user';
import ActivityDatePicker from './activityDatePicker';
import ActivityTimePicker from './activityTimePicker';

import fonts from '../../constants/fonts';
import colors from '../../constants/colors';

import './customStyles.css';
import ActivityRoomPicker from './activityRoomPicker';
import ActivityProfilePicker from './activityProfilePicker';
import { Strings } from '../../helpers/strings';
import Info from '../Generic/info';
import MessageOnHover from '../Generic/messageOnHover';

const styles = StyleSheet.create({
  mainContainer: {
    width: 500,
    display: 'flex',
  },
  fieldContainer: {
    flex: 1,
    display: 'flex',
    flexFlow: 'column',
  },
  timeRangeContainer: {
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center',
  },
  pickerItemContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    marginLeft: 5,
    marginTop: 3,
    marginBottom: 3,
  },
  requiredText: {
    color: colors.getRed(),
  },
  labelContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: 5,
  },
  infoIcon: {
    width: 25,
    height: 25,
    padding: 5,
  },
  labelText: {
    fontFamily: fonts.getSignika(),
    fontSize: 20,
    fontWeight: 600,
    color: colors.getBluePrussian(),
  },
  timeSeparatorText: {
    fontFamily: fonts.getSignika(),
    fontSize: 16,
    fontWeight: 300,
    color: colors.getGraySantas(),
  },
  formRow: {
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center',
  },
  formCol: {
    display: 'flex',
    flexFlow: 'column',
  },
  input: {
    fontFamily: fonts.getSignika(),
    fontSize: 16,
    fontWeight: 300,
    paddingLeft: 15,
    backgroundColor: colors.getMainWhite(),
    borderRadius: 8,
    height: 40,
    border: `solid 1px ${colors.getBlueOxford(0.14)}`,
  },
  // Mobile :
  mainContainerMobile: {
    width: '100%',
    display: 'flex',
  },
});

const ScheduleForm = ({
  formProps,
  now,
  organization,
  userId,
  availableRooms,
  requestAvailableRooms,
  userLiveProfiles,
  requestUserLiveProfiles,
  roomLiveProfiles,
  requestRoomLiveProfiles,
  defaultData,
  isMobile,
}) => {
  const interactionsStatus = organization?.settings?.interactions?.status;
  const { t } = useTranslation();

  const [openRoomPicker, setOpenRoomPicker] = useState(false);

  const [openLiveProfilePicker, setOpenLiveProfilePicker] = useState(false);
  const [openUploadProfilePicker, setOpenUploadProfilePicker] = useState(false);

  const [userCanUpload, setUserCanUpload] = useState(false);
  const [userCanLive, setUserCanLive] = useState(false);
  /**
   * Reason to use the formIsReady state :
   *
   * There is 2 default values logics that are conflicting:
   * - The parent can provide a 'defaultData' dict to use to fill the fields
   * - Some useEffect hooks are responsible for assigning values to undefined fields
   *
   * The problem was that, the initializeForm() logic, responsible for filling the fields
   * with the parents values, was being run, and, just few millisecs later, when the values
   * were still undefined, the useEffect hooks were assigning default values...
   * The result was ending up with fields that are being assigned default values that
   * are not the one of the activity we are editing.
   */
  const [formIsReady, setFormIsReady] = useState(false);

  // TODO : parent could do the useForm, since the parent is the root of the form...
  // it contains the submit button... and then pass everything to the child.
  const {
    control,
    setValue,
    formState: { errors },
  } = formProps;

  const roomWatcher = useWatch({
    control,
    name: '_room',
  });
  const startDatetimeWatcher = useWatch({
    control,
    name: 'startDatetime',
  });
  const endDatetimeWatcher = useWatch({
    control,
    name: 'endDatetime',
  });
  const dateWatcher = useWatch({
    control,
    name: 'date',
  });
  const shouldRecordWatcher = useWatch({
    control,
    name: 'shouldRecord',
  });
  const shouldLiveWatcher = useWatch({
    control,
    name: 'shouldLive',
  });

  // Default Initializer
  const initializeForm = () => {
    setValue('name', defaultData.name);
    setValue('_liveProfile', defaultData._liveProfile);
    setValue('_uploadProfile', defaultData._uploadProfile);
    setValue('_room', defaultData._room);
    setValue('date', moment(defaultData.startDatetime));
    setValue('startDatetime', moment(defaultData.startDatetime));
    setValue('endDatetime', moment(defaultData.endDatetime));
    setValue('isPrivate', defaultData.isPrivate);
    setValue('shouldRecord', defaultData.shouldRecord);
    setValue('shouldLive', defaultData.shouldLive);
    setValue(
      'interactionsAreEnabled',
      interactionsStatus === 'enabled' ? defaultData.interactionsAreEnabled : false,
    );
  };

  const createDateTime = (oldDate, time) => {
    const newDate = moment(oldDate);
    newDate.set('hour', time.hour());
    newDate.set('minute', time.minute());
    return newDate;
  };

  useEffect(() => {
    if (formIsReady) return;
    if (!isEmpty(defaultData)) {
      initializeForm();
    }
    setFormIsReady(true);
  }, [defaultData]);

  useEffect(() => {
    if (userId && !userLiveProfiles) {
      requestUserLiveProfiles();
    }
  }, [userId]);

  useEffect(() => {
    if (roomWatcher) requestRoomLiveProfiles(roomWatcher);
  }, [roomWatcher]);

  useEffect(() => {
    if (availableRooms.length === 0) return;
    if (!formIsReady) return;
    // If the selected room is still available in the new rooms :
    if (!isEmpty(roomWatcher) && availableRooms.some((element) => element._id === roomWatcher))
      return;
    // If no room was selected or one was but isn't available anymore :
    setValue('_room', availableRooms[0]._id);
    // TODO / Improvement : when the user selected a room, and then is scrolling thro schedules, if the room
    // TODO : isn't available in one of them, he loses the room since an other get selected (the first available one).
    // TODO : This could be good if the user preferred/selected room was memorized, so the selecter falls back on it
    // TODO : when it's available again.
  }, [availableRooms]);

  useEffect(() => {
    if (dateWatcher && startDatetimeWatcher && endDatetimeWatcher) {
      const startDatetime = createDateTime(dateWatcher, moment(startDatetimeWatcher));
      const endDatetime = createDateTime(dateWatcher, moment(endDatetimeWatcher));
      const isoStringsArray = [startDatetime.toISOString(), endDatetime.toISOString()];
      const ignoredActivities = [];
      if (!isEmpty(defaultData)) ignoredActivities.push(defaultData._id);
      requestAvailableRooms(isoStringsArray, ignoredActivities);
    }
  }, [dateWatcher, startDatetimeWatcher, endDatetimeWatcher]);

  /**
   * Done so startDatetime and endDatetime match the date day.
   */
  useEffect(() => {
    if (!formIsReady) return;
    const newStartDateTime = moment(dateWatcher)
      .set('hour', moment(startDatetimeWatcher).hour())
      .set('minute', moment(startDatetimeWatcher).minutes());
    const newEndDateTime = moment(dateWatcher)
      .set('hour', moment(endDatetimeWatcher).hour())
      .set('minute', moment(endDatetimeWatcher).minutes());
    setValue('startDatetime', newStartDateTime);
    setValue('endDatetime', newEndDateTime);
  }, [dateWatcher]);

  const isUploadProfile = (profile) => profile.type === 'upload';
  const isLiveProfile = (profile) => profile.type === 'live';

  useEffect(() => {
    if (!userLiveProfiles || !roomLiveProfiles) return;

    const canUpload =
      userLiveProfiles.some(isUploadProfile) || roomLiveProfiles.some(isUploadProfile);
    if (!canUpload) setValue('shouldRecord', false);
    setUserCanUpload(canUpload);

    const canLive = userLiveProfiles.some(isLiveProfile) || roomLiveProfiles.some(isLiveProfile);
    if (!canLive) setValue('shouldLive', false);
    setUserCanLive(canLive);
  }, [userLiveProfiles, roomLiveProfiles]);

  const renderInteractionsSection = () => {
    if (interactionsStatus === 'disabled_and_hidden') return null;
    let hoverMessage;
    if (interactionsStatus === 'soon') {
      hoverMessage = t(Strings.ACTIVITY_SCHEDULE_FORM_INTERACTIONS_HOVER_MESSAGE_SOON);
    } else if (interactionsStatus !== 'enabled') {
      hoverMessage = t(Strings.ACTIVITY_SCHEDULE_FORM_INTERACTIONS_HOVER_MESSAGE_DISABLED);
    }
    const enabled = interactionsStatus === 'enabled';
    const targetHoverMessageRef = createRef();
    return (
      <View style={[styles.formRow, { marginTop: 10 }]}>
        <View style={[styles.fieldContainer]}>
          <View style={styles.labelContainer}>
            <Text style={styles.labelText}>
              {t(Strings.ACTIVITY_SCHEDULE_FORM_INTERACTIONS_TITLE)}
            </Text>
            <Info
              style={styles.infoIcon}
              iconProps={{ fill: colors.getBluePrussian() }}
              message={t(Strings.ACTIVITY_SCHEDULE_FORM_INTERACTIONS_INFO)}
            />
          </View>
          <View ref={targetHoverMessageRef}>
            <Controller
              control={control}
              defaultValue={false}
              render={({ field: { onChange, value } }) => (
                <View style={[styles.formRow, { marginTop: 5 }]}>
                  <CheckBox
                    disabled={!enabled}
                    style={{ marginRight: 10 }}
                    value={value}
                    onValueChange={onChange}
                  />
                  <Text style={{ fontSize: 16, fontWeight: 300, opacity: enabled ? 1 : 0.5 }}>
                    {t(Strings.ACTIVITY_SCHEDULE_FORM_INTERACTIONS_LABEL)}
                  </Text>
                </View>
              )}
              name="interactionsAreEnabled"
            />
          </View>
          {hoverMessage ? (
            <MessageOnHover targetRef={targetHoverMessageRef} message={hoverMessage} />
          ) : null}
        </View>
      </View>
    );
  };

  return (
    <View style={isMobile ? styles.mainContainerMobile : styles.mainContainer}>
      <View style={[styles.formRow]}>
        <View style={[styles.fieldContainer]}>
          <Text style={styles.labelText}>{t(Strings.NAME)}</Text>
          <Controller
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { value, onChange } }) => (
              <TextInput
                style={styles.input}
                value={value || ''}
                onChangeText={onChange}
                maxLength={50}
              />
            )}
            name="name"
          />
          {errors.name && (
            <Text style={styles.requiredText}>{t(Strings.THIS_FIELD_IS_REQUIRED)}</Text>
          )}
        </View>
      </View>
      <View style={isMobile ? styles.formCol : [styles.formRow, { gap: 20 }]}>
        <View style={[styles.fieldContainer, { flex: 3 }]}>
          <Text style={styles.labelText}>{t(Strings.DATE)}</Text>
          <Controller
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, value } }) => (
              <ActivityDatePicker now={now} setValue={onChange} value={value} />
            )}
            name="date"
          />
          {errors.date && (
            <Text style={styles.requiredText}>{t(Strings.THIS_FIELD_IS_REQUIRED)}</Text>
          )}
        </View>
        <View style={[styles.fieldContainer, { flex: 2 }]}>
          <Text style={styles.labelText}>{t(Strings.TIME_SLOT)}</Text>
          <View style={[styles.timeRangeContainer, { gap: 10 }]}>
            <View style={{ flex: 1 }}>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, value } }) => (
                  <ActivityTimePicker
                    min={now}
                    setValue={onChange}
                    value={value}
                    max={moment(dateWatcher).set('h', 23).set('m', 30)}
                  />
                )}
                name="startDatetime"
              />
              {errors.startDatetime && (
                <Text style={styles.requiredText}>{t(Strings.THIS_FIELD_IS_REQUIRED)}</Text>
              )}
            </View>
            <Text styles={styles.timeSeparatorText}>-</Text>
            <View style={{ flex: 1 }}>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, value } }) => (
                  <ActivityTimePicker
                    min={moment(startDatetimeWatcher).add(15, 'm')}
                    setValue={onChange}
                    value={value}
                  />
                )}
                name="endDatetime"
              />
              {errors.endDatetime && (
                <Text style={styles.requiredText}>{t(Strings.THIS_FIELD_IS_REQUIRED)}</Text>
              )}
            </View>
          </View>
        </View>
      </View>
      <View style={[styles.formRow, { marginTop: 10, zIndex: 10 }]}>
        <View style={[styles.fieldContainer]}>
          <Text style={styles.labelText}>{t(Strings.ROOM)}</Text>
          <Controller
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, value } }) => (
              <ActivityRoomPicker
                value={value}
                setValue={onChange}
                open={openRoomPicker}
                setOpen={setOpenRoomPicker}
                availableRooms={availableRooms}
              />
            )}
            name="_room"
          />
          {errors._room && (
            <Text style={styles.requiredText}>{t(Strings.THIS_FIELD_IS_REQUIRED)}</Text>
          )}
        </View>
      </View>
      {userCanLive && ( // HERE
        <Controller
          control={control}
          defaultValue={true}
          render={({ field: { onChange, value } }) => (
            <View style={[styles.formRow, { marginTop: 10, marginBottom: 5 }]}>
              <CheckBox style={{ marginRight: 10 }} value={value} onValueChange={onChange} />
              <Text style={{ fontSize: 16, fontWeight: 300 }}>
                {t(Strings.BROADCAST_THE_VIDEO)}
              </Text>
            </View>
          )}
          name="shouldLive"
        />
      )}
      <View style={[styles.formRow, { marginTop: 0, zIndex: 5 }]}>
        <View
          style={
            shouldLiveWatcher
              ? [styles.fieldContainer, { marginTop: 10, marginBottom: 10 }]
              : { display: 'none' }
          }
        >
          <Text style={styles.labelText}>{t(Strings.WHERE_TO_BROADCAST_THE_VIDEO)}</Text>
          <Controller
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, value } }) => (
              <ActivityProfilePicker
                value={value}
                setValue={onChange}
                open={openLiveProfilePicker}
                setOpen={setOpenLiveProfilePicker}
                userLiveProfiles={userLiveProfiles ? userLiveProfiles.filter(isLiveProfile) : []}
                roomLiveProfiles={roomLiveProfiles ? roomLiveProfiles.filter(isLiveProfile) : []}
                formIsReady={formIsReady}
              />
            )}
            name="_liveProfile"
          />
          {errors._liveProfile && (
            <Text style={styles.requiredText}>{t(Strings.THIS_FIELD_IS_REQUIRED)}</Text>
          )}
        </View>
      </View>
      {userCanUpload && ( // HERE
        <Controller
          control={control}
          defaultValue={true}
          render={({ field: { onChange, value } }) => (
            <View style={[styles.formRow, { marginTop: 0, marginBottom: 5 }]}>
              <CheckBox style={{ marginRight: 10 }} value={value} onValueChange={onChange} />
              <Text style={{ fontSize: 16, fontWeight: 300 }}>{t(Strings.RECORD_THE_VIDEO)}</Text>
            </View>
          )}
          name="shouldRecord"
        />
      )}
      <View style={[styles.formRow, { marginTop: 10, zIndex: 5 }]}>
        <View
          style={
            shouldRecordWatcher
              ? [styles.fieldContainer, { marginBottom: 10 }]
              : { display: 'none' }
          }
        >
          <Text style={styles.labelText}>{t(Strings.WHERE_TO_RECORD_THE_VIDEO)}</Text>
          <Controller
            control={control}
            rules={{
              validate: (value) => !(shouldRecordWatcher && !value),
            }}
            render={({ field: { onChange, value } }) => (
              <ActivityProfilePicker
                value={value}
                setValue={onChange}
                open={openUploadProfilePicker}
                setOpen={setOpenUploadProfilePicker}
                userLiveProfiles={userLiveProfiles ? userLiveProfiles.filter(isUploadProfile) : []}
                roomLiveProfiles={roomLiveProfiles ? roomLiveProfiles.filter(isUploadProfile) : []}
                formIsReady={formIsReady}
                disabled={!shouldRecordWatcher}
              />
            )}
            name="_uploadProfile"
          />
          {errors._uploadProfile && (
            <Text style={styles.requiredText}>{t(Strings.THIS_FIELD_IS_REQUIRED)}</Text>
          )}
        </View>
      </View>
      <View style={[styles.formRow]}>
        <View style={[styles.fieldContainer]}>
          <Text style={styles.labelText}>{t(Strings.VISIBILITY)}</Text>
          <Controller
            control={control}
            defaultValue={true}
            render={({ field: { onChange, value } }) => (
              <View style={[styles.formRow, { marginTop: 5 }]}>
                <CheckBox style={{ marginRight: 10 }} value={value} onValueChange={onChange} />
                <Text style={{ fontSize: 16, fontWeight: 300 }}>{t(Strings.PRIVATE)}</Text>
              </View>
            )}
            name="isPrivate"
          />
        </View>
      </View>
      {renderInteractionsSection()}
    </View>
  );
};

ScheduleForm.defaultProps = {
  isMobile: false,
};

ScheduleForm.propTypes = {
  now: PropTypes.object.isRequired,
  formProps: PropTypes.object.isRequired,
  organization: PropTypes.object.isRequired,
  userId: PropTypes.string,
  availableRooms: PropTypes.array.isRequired,
  // Live
  userLiveProfiles: PropTypes.array,
  roomLiveProfiles: PropTypes.array,
  // Optional
  defaultData: PropTypes.object,
  isMobile: PropTypes.bool,

  // Action
  requestAvailableRooms: PropTypes.func.isRequired,
  requestUserLiveProfiles: PropTypes.func.isRequired,
  requestRoomLiveProfiles: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  organization: organizationSelectors.makeSelectOrganization(),
  userId: userSelectors.makeSelectUserId(),
  availableRooms: activitySelectors.makeSelectAvailableRooms(),
  userLiveProfiles: userSelectors.makeSelectLiveProfiles(),
  roomLiveProfiles: activitySelectors.makeSelectRoomLiveProfiles(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    requestAvailableRooms: (isoStrings, ignoredActivities) =>
      dispatch(activityActions.requestAvailableRooms(isoStrings, ignoredActivities)),
    requestUserLiveProfiles: () => dispatch(userActions.requestLiveProfiles()),
    requestRoomLiveProfiles: (_room) => dispatch(activityActions.requestRoomLiveProfiles(_room)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleForm);
