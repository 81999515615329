import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  // Request API
  requestLiveProfiles: [],
  requestCreateLiveProfile: ['liveProfile'],
  requestUpdateLiveProfile: ['_id', 'body'],

  // Socket connection
  selectLiveProfile: ['liveProfile'],
});

export { Types };
export default Creators;
