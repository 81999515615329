import { StyleSheet, TouchableOpacity, useWindowDimensions, View } from 'react-native';
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { createStructuredSelector } from 'reselect';
import Chat from '../../containers/Chat';
import ConnectedUsers from '../../containers/ConnectedUsers';
import roomActions from '../../App/actions/room';
import roomSelectors from '../../App/selectors/room';

import colors from '../../constants/colors';
import { sNavBarTop } from '../../styles/fixed';

import IconChat from '../../assets/blue/icon-chat-white.svg';
import IconClose from '../../assets/blue/close-chat.svg';
import IconMember from '../../assets/blue/icon-membres.svg';
import CounterViewers from '../../components/User/counterViewers';
import Badge, { BADGE_MODE } from '../../components/Generic/badge';

const CHAT = 'chat';
const MEMBER = 'member';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    borderLeftWidth: 0.5,
    borderLeftColor: colors.getGraySantas(),
    maxHeight: 'var(--app-height)',
  },
  connectedUsersContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    backdropFilter: 'blur(10px) brightness(50%)',
  },
  logo: {
    justifyContent: 'center',
    alignContent: 'center',
    marginRight: 42,
  },
  line: {
    backgroundColor: colors.getMainPurple(),
    height: 4,
    width: 45,
    position: 'absolute',
    left: -10,
  },
  containerheader: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: colors.getMirage(),
    alignItems: 'center',
    borderBottomWidth: 0.5,
    borderBottomColor: colors.getGraySantas(),
    paddingLeft: 45,
    paddingRight: 10,
  },
  leftContainerHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  rightContainerHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  viewers: {
    marginRight: 15,
  },
  body: {
    flex: 11,
    height: '100%',
  },
});

const stylesSmallMode = StyleSheet.create({
  containerheader: {
    height: sNavBarTop.SMALL.height,
    minHeight: sNavBarTop.SMALL.height,
    maxHeight: sNavBarTop.SMALL.height,
  },
  logo: {
    transform: 'scale(0.7) translateX(30%)',
    marginRight: 20,
  },
  viewers: {
    transform: 'scale(0.85) translateX(15%)',
    marginRight: 0,
  },
  close: {
    transform: 'scale(0.5) translateX(50%)',
  },
  line: {
    bottom: -14,
  },
});

const stylesMediumMode = StyleSheet.create({
  containerheader: {
    height: sNavBarTop.MEDIUM.height,
    minHeight: sNavBarTop.MEDIUM.height,
    maxHeight: sNavBarTop.MEDIUM.height,
  },
  logo: {
    transform: 'scale(0.9) translateX(10%)',
    marginRight: 30,
  },
  viewers: {
    marginRight: 10,
  },
  close: {
    transform: 'scale(0.7) translateX(30%)',
  },
  line: {
    bottom: -20,
  },
});

const stylesLargeMode = StyleSheet.create({
  containerheader: {
    height: sNavBarTop.LARGE.height,
    minHeight: sNavBarTop.LARGE.height,
    maxHeight: sNavBarTop.LARGE.height,
  },
  logo: {},
  viewers: {},
  close: {},
  line: {
    bottom: -32,
  },
});

const getResponsiveStyles = (width, height) => {
  let result = stylesLargeMode;
  if (height < 500) result = stylesSmallMode;
  else if (height < 700) result = stylesMediumMode;
  return result;
};

const RightMenu = ({ unReadMessages, onDisplayChange, clearCounterUnreadMessages }) => {
  const [selectedTab, setSelectedTab] = useState(CHAT);
  const { width, height } = useWindowDimensions();
  const responsiveStyles = getResponsiveStyles(width, height);
  const inputRef = useRef();

  const renderConnectedUsers = () => {
    return (
      <View style={styles.connectedUsersContainer}>
        <ConnectedUsers />
      </View>
    );
  };

  const onClick = () => {
    // Triggered if the user clicked somewhere in the chat
    // or on the go to the end button
    const isTouchDevice = () => {
      return (
        'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0
      );
    };
    if (!isTouchDevice()) {
      // Focusing when clicking randomly in the chat or
      // on the chat icon can be very annoying on touch screens
      // eslint-disable-next-line no-unused-expressions
      inputRef.current?.focus();
    }
  };

  const displayTab = () => {
    return (
      <View style={{ flex: 1 }}>
        <Chat onClick={onClick} inputRef={inputRef} />
        {selectedTab === MEMBER && renderConnectedUsers()}
      </View>
    );
  };

  useEffect(() => {
    if (selectedTab === CHAT) {
      clearCounterUnreadMessages();
      onClick();
    }
  }, [selectedTab, unReadMessages]);

  const selectedTabLine = (tabSelected) => {
    if (selectedTab === tabSelected) return <View style={[styles.line, responsiveStyles.line]} />;
    return null;
  };

  return (
    <View style={styles.container}>
      <View style={[styles.containerheader, responsiveStyles.containerheader]}>
        <View style={styles.leftContainerHeader}>
          <View style={[styles.logo, responsiveStyles.logo]}>
            <TouchableOpacity onPress={() => setSelectedTab(CHAT)}>
              <IconChat width={25} />
              <Badge mode={BADGE_MODE.NUMBER} maxNumber={9} number={unReadMessages} />
            </TouchableOpacity>
            {selectedTabLine(CHAT)}
          </View>
          <View style={[styles.logo, responsiveStyles.logo]}>
            <TouchableOpacity onPress={() => setSelectedTab(MEMBER)}>
              <IconMember />
            </TouchableOpacity>
            {selectedTabLine(MEMBER)}
          </View>
        </View>
        <View style={styles.rightContainerHeader}>
          <CounterViewers style={[styles.viewers, responsiveStyles.viewers]} />
          <TouchableOpacity
            onPress={() => {
              if (onDisplayChange) onDisplayChange(false);
            }}
          >
            <IconClose style={[styles.close, responsiveStyles.close]} />
          </TouchableOpacity>
        </View>
      </View>
      <View style={{ flex: 1 }}>{displayTab()}</View>
    </View>
  );
};

RightMenu.propTypes = {
  onDisplayChange: PropTypes.func,

  // Select
  unReadMessages: PropTypes.any.isRequired,
  // Actions
  clearCounterUnreadMessages: PropTypes.func.isRequired,
};

RightMenu.defaultProps = {
  unReadMessages: 0,
  clearCounterUnreadMessages: () => {},
};

const mapStateToProps = createStructuredSelector({
  unReadMessages: roomSelectors.makeSelectCounterUnreadMessage(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    clearCounterUnreadMessages: () => dispatch(roomActions.clearCounterUnreadMessages()),
  };
};

// export default connect(mapStateToProps, mapDispatchToProps)(RightMenu);
export default connect(mapStateToProps, mapDispatchToProps)(RightMenu);
