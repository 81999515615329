import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import { createStackNavigator } from '@react-navigation/stack';

import userActions from '../../App/actions/user';
import userSelectors from '../../App/selectors/user';

import { screenName } from '../../constants/navigation';

import LogoutPage from '../../pages/LogoutPage';
import LoginPage from '../../pages/LoginPage';
import SchedulePage from '../../pages/SchedulePage';
import ActivityPage from '../../pages/ActivityPage';
import ActivityRedirectionPage from '../../pages/ActivityRedirectionPage';
import CalendarPage from '../../pages/CalendarPage';
import ComingSoonPage from '../../pages/ComingSoonPage';
import { getToken } from '../../helpers/auth';
import LoadingPage from '../../pages/LoadingPage';
import ForbiddenPage from '../../pages/Forbidden';
import ResetPasswordPage from '../../pages/ResetPasswordPage';
import { userRoleIsGTE } from '../../helpers/role';
import { USER_ROLE } from '../../constants/global';

export const Stack = createStackNavigator();
const ActivityStack = createStackNavigator();

const ExtendedActivityScreens = () => {
  return (
    <ActivityStack.Navigator screenOptions={{ headerShown: false }}>
      <ActivityStack.Screen name={screenName.SCHEDULE} component={SchedulePage} />
      <ActivityStack.Screen name={screenName.ACTIVITY} component={ActivityPage} />
      <ActivityStack.Screen
        name={screenName.ACTIVITY_REDIRECTION}
        component={ActivityRedirectionPage}
      />
    </ActivityStack.Navigator>
  );
};

const LimitedActivityScreens = () => {
  return (
    <ActivityStack.Navigator screenOptions={{ headerShown: false }}>
      <ActivityStack.Screen name={screenName.ACTIVITY} component={ActivityPage} />
      <ActivityStack.Screen
        name={screenName.ACTIVITY_REDIRECTION}
        component={ActivityRedirectionPage}
      />
    </ActivityStack.Navigator>
  );
};

const AuthAwareStack = ({ isAuthenticated, userRole, requestLoginLti, setIsAuthenticated }) => {
  const publicScreens = [
    <Stack.Screen key={screenName.LOGIN} name={screenName.LOGIN} component={LoginPage} />,
    <Stack.Screen
      key={screenName.COMING_SOON}
      name={screenName.COMING_SOON}
      component={ComingSoonPage}
    />,
    <Stack.Screen
      key={screenName.FORBIDDEN}
      name={screenName.FORBIDDEN}
      component={ForbiddenPage}
    />,
    <Stack.Screen
      key={screenName.ACTIVITY_SCREENS}
      name={screenName.ACTIVITY_SCREENS}
      component={LimitedActivityScreens}
    />,
    <Stack.Screen
      key={screenName.RESET_PASSWORD}
      name={screenName.RESET_PASSWORD}
      component={ResetPasswordPage}
    />,
  ];

  const limitedScreens = [
    <Stack.Screen key={screenName.LOGIN} name={screenName.LOGIN} component={LoginPage} />,
    <Stack.Screen
      key={screenName.CALENDAR}
      name={screenName.CALENDAR}
      component={CalendarPage}
      initialParams={{ section_name: 'activities' }}
    />,
    <Stack.Screen
      key={screenName.FORBIDDEN}
      name={screenName.FORBIDDEN}
      component={ForbiddenPage}
    />,
    <Stack.Screen
      key={screenName.ACTIVITY_SCREENS}
      name={screenName.ACTIVITY_SCREENS}
      component={LimitedActivityScreens}
    />,
    <Stack.Screen key={screenName.LOGOUT} name={screenName.LOGOUT} component={LogoutPage} />,
  ];

  const extendedScreens = [
    <Stack.Screen key={screenName.LOGIN} name={screenName.LOGIN} component={LoginPage} />,
    <Stack.Screen
      key={screenName.CALENDAR}
      name={screenName.CALENDAR}
      component={CalendarPage}
      initialParams={{ section_name: 'activities' }}
    />,
    <Stack.Screen
      key={screenName.ACTIVITY_SCREENS}
      name={screenName.ACTIVITY_SCREENS}
      component={ExtendedActivityScreens}
    />,
    <Stack.Screen key={screenName.LOGOUT} name={screenName.LOGOUT} component={LogoutPage} />,
  ];

  const [isLti, setIsLti] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);

  // Explaination for the isInitialized :
  //
  // When mounting the AuthAwareStack, the URL is checked so we know wether
  // an LTI token is provided or not, if it is, then the public pages are not
  // loaded. If they were, the URL params would be consumed and lost.
  // The problem was, the useEffect responsible for checking the URL, is called
  // AFTER the first AuthAwareStack render, meaning what, the public page
  // were loaded before we know if the connection is an LTI one or not. The URL
  // params were consummed, the stack content was unmounted while fetching the
  // user data, then mounted again with private pages, without the LTI params.
  // As a consequence, the LTI user was seeing the calendar instead of the kapture
  // scheduler. (It's the login page that decides wether to redirect to the calendar
  // or the scheduler depending if it has LTI params or not)
  //
  // This issue is fixed if we add an initialized state to the AuthAwareStack
  // that is only set to true when the URL was checked. Before that, no
  // pages are loaded.

  // TODO : is Use callback necessary here?
  const getScreens = useCallback(() => {
    if (isAuthenticated && userRole !== undefined) {
      if (userRoleIsGTE(userRole, USER_ROLE.ORGANIZER)) {
        return extendedScreens;
      }
      return limitedScreens;
    }
    return publicScreens;
  }, [isAuthenticated, userRole]);

  const getLoadingScreen = () => {
    return <LoadingPage />;
  };

  useEffect(() => {
    const currentURL = new URL(window.location);
    if (currentURL.pathname === '/login') {
      let token = currentURL.searchParams.get('lti');
      if (token) {
        setIsLti(true);
        requestLoginLti(token);
      } else {
        setIsLti(false);
        token = getToken();
        if (token) {
          setIsAuthenticated(true);
        }
      }
    }
    setIsInitialized(true);
  }, []);

  /*
  console.log(
    'Refreshing AuthAwareStack with isAuthenticated(',
    isAuthenticated,
    ') and userRole(',
    userRole,
    ')',
    ' and isLti(',
    isLti,
    ') and isInitialized(',
    isInitialized,
    ')',
  );
  */

  // If connected thro LTI : AS TO be auth, sending loading screen otherwise
  // If connected outside LTI : doesn't have to be auth, in this case it will get public screens
  // In both case, if authenticated, userRole has to be defined before loading private screens
  if (!isInitialized || (isLti && !isAuthenticated) || (isAuthenticated && userRole === undefined))
    return getLoadingScreen();

  return <Stack.Navigator screenOptions={{ headerShown: false }}>{getScreens()}</Stack.Navigator>;
};

AuthAwareStack.propTypes = {
  userRole: PropTypes.string,
  isAuthenticated: PropTypes.bool.isRequired,

  // Actions
  requestLoginLti: PropTypes.func.isRequired,
  setIsAuthenticated: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return {
    requestLoginLti: (token) => dispatch(userActions.requestLoginLti(token)),
    setIsAuthenticated: (bool) => dispatch(userActions.setIsAuthenticated(bool)),
  };
};

const mapStateToProps = createStructuredSelector({
  isAuthenticated: userSelectors.makeSelectIsAuthenticated(),
  userRole: userSelectors.makeSelectRole(),
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthAwareStack);
