import { Picker } from '@react-native-picker/picker';
import PropTypes from 'prop-types';
import React from 'react';
import { StyleSheet } from 'react-native';
import colors from '../../constants/colors';
import fonts from '../../constants/fonts';

const styles = StyleSheet.create({
  pickerItemContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    marginLeft: 5,
    marginTop: 3,
    marginBottom: 3,
  },
  pickerDropdownContainer: {
    border: `solid 1px ${colors.getBlueOxford(0.14)}`,
    borderRadius: 4,
  },
  pickerText: {
    fontFamily: fonts.getSignika(),
    fontSize: 16,
    fontWeight: 600,
  },
  picker: {
    color: 'black',
    display: 'flex',
    flexFlow: 'row',
    backgroundColor: colors.getMainWhite(),
    borderRadius: 8,
    border: `solid 1px ${colors.getBlueOxford(0.14)}`,
    height: 40,
  },
});

const ActivityRoomPicker = ({ value, setValue, open, setOpen, availableRooms }) => {
  const getItems = () => {
    return availableRooms?.map((room) => (
      <Picker.Item key={room._id} label={room.name} value={room._id} />
    ));
  };

  return (
    <Picker
      selectedValue={value}
      onValueChange={setValue}
      style={[styles.picker]}
      itemStyle={styles.pickerItemContainer}
    >
      {getItems()}
    </Picker>
  );
};

ActivityRoomPicker.propTypes = {
  value: PropTypes.string,
  setValue: PropTypes.func.isRequired,
  open: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
  availableRooms: PropTypes.array.isRequired,
};

export default ActivityRoomPicker;
