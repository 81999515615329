import platformHelpers from './platform';
import { generateId } from './utils';

const UNIQUE_LOCAL_ID_KEY = 'unique_local_id';

export const setUniqueLocalId = (id = generateId(32)) =>
  platformHelpers.storageSetter(UNIQUE_LOCAL_ID_KEY, id);

export const getUniqueLocalId = () => platformHelpers.storageGetter(UNIQUE_LOCAL_ID_KEY);

export const removeUniqueLocalId = () => platformHelpers.storageRemover(UNIQUE_LOCAL_ID_KEY);

export const initUniqueLocalId = () => {
  let uniqueLocalId = getUniqueLocalId();
  if (!uniqueLocalId) {
    uniqueLocalId = generateId(32);
    setUniqueLocalId(uniqueLocalId);
  }
  return uniqueLocalId;
};
