import { requestAPI, getHeadersAPI } from '../../helpers/request';

const paths = {
  getSocketToken: () => {
    return '/socket/token';
  }, // GET
  getSocketTokenGuest: () => {
    return '/socket/token/guest';
  }, // GET
};

// ------------------------------------------------------- //
// --------------------- REQUEST ------------------------- //
// ------------------------------------------------------- //

// ---------- GET
const requestSocketToken = async () => {
  return requestAPI(paths.getSocketToken(), getHeadersAPI('GET', true));
};
const requestSocketTokenGuest = async (nickname = null) => {
  const params = nickname ? { nickname } : {};
  return requestAPI(paths.getSocketTokenGuest(), getHeadersAPI('GET', false), params);
};

// ---------- POST

// ---------- PATCH

// ---------- DELETE

export default {
  requestSocketToken,
  requestSocketTokenGuest,
};
