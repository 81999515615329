export const VodState = {
  Idle: 'idle',
  Starting: 'starting',
  RecordOngoing: 'record_ongoing',
  RecordError: 'record_error',
  RecordStopWaitingDevice: 'record_stop_waiting_device',
  RecordStopError: 'record_stop_error',
  UploadStartWaitingDevice: 'upload_start_waiting_device',
  UploadStartError: 'upload_start_error',
  UploadOngoing: 'upload_ongoing',
  UploadError: 'upload_error',
  PublishStartWaitingService: 'publish_start_waiting_service',
  PublishStartError: 'publish_start_error',
  PublishOngoing: 'publish_ongoing',
  PublishError: 'publish_error',
  Terminated: 'terminated',
};

export const isErrorVodState = (state) => {
  return [
    VodState.RecordError,
    VodState.RecordStopError,
    VodState.UploadStartError,
    VodState.UploadError,
    VodState.PublishStartError,
    VodState.PublishError,
  ].includes(state);
};

export default {
  VodState,
  isErrorVodState,
};
