import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { StyleSheet, Text, TextInput, View } from 'react-native';
import colors from '../../constants/colors';

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  textIndicator: {
    paddingLeft: 10,
    paddingBottom: 2,
    fontSize: 11,
  },
  textIndicatorLimitReached: {
    color: colors.getTorchRed(),
  },
  textInput: {
    width: '100%',
    borderRadius: 10,
    paddingVertical: 5,
    paddingHorizontal: 10,
  },
});

const TextInputLimited = ({
  limit,
  onLimit,
  hideIndicatorIfEmpty,
  showIndicator,
  textInputStyle,
  textIndicatorStyle,
  textIndicatorLimitReachedStyle,
  style,
  ...textInputProps
}) => {
  const [currLength, setCurrLength] = useState(textInputProps?.defaultValue?.length ?? 0);
  const [limitReached, setLimitReached] = useState(false);
  /** @type {React.LegacyRef<TextInput>} */
  const textInputRef = useRef(null);
  const currTextValueRef = useRef(textInputProps?.defaultValue ?? '');

  useEffect(() => {
    setLimitReached(currLength >= limit);
  }, [currLength]);

  useEffect(() => {
    if (onLimit) onLimit(limitReached);
  }, [limitReached]);

  const renderIndicator = () => {
    if (!showIndicator) return null;
    const currStyle = [styles.textIndicator];
    currStyle.push(
      ...(Array.isArray(textIndicatorStyle) ? textIndicatorStyle : [textIndicatorStyle]),
    );
    if (limitReached) {
      currStyle.push(styles.textIndicatorLimitReached);
      if (textIndicatorLimitReachedStyle) {
        currStyle.push(
          ...(Array.isArray(textIndicatorLimitReachedStyle)
            ? textIndicatorLimitReachedStyle
            : [textIndicatorLimitReachedStyle]),
        );
      }
    }
    if (hideIndicatorIfEmpty && currLength === 0) {
      currStyle.push({ visibility: 'hidden' });
    }
    const text = `${currLength}/${limit}`;
    return <Text style={currStyle}>{text}</Text>;
  };

  const viewStyle = [styles.container];
  if (style) viewStyle.push(...(Array.isArray(style) ? style : [style]));
  const currTextInputStyle = [styles.textInput];
  if (textInputStyle)
    currTextInputStyle.push(...(Array.isArray(textInputStyle) ? textInputStyle : [textInputStyle]));
  return (
    <View style={viewStyle}>
      {renderIndicator()}
      <TextInput
        ref={(el) => {
          textInputRef.current = el;
          if (textInputProps?.ref) {
            /* eslint-disable no-param-reassign */
            if (typeof textInputProps.ref === 'function') textInputProps.ref(el);
            else textInputProps.ref.current = el;
          }
        }}
        style={currTextInputStyle}
        {...textInputProps}
        onChangeText={(text) => {
          if (text.length > limit) {
            textInputRef.current.setNativeProps({ text: currTextValueRef.current });
            setCurrLength(currTextValueRef.current.length);
            return;
          }
          currTextValueRef.current = text;
          setCurrLength(currTextValueRef.current.length);
          if (textInputProps?.onChangeText) {
            textInputProps.onChangeText(text);
          }
        }}
      />
    </View>
  );
};

TextInputLimited.propTypes = {
  limit: PropTypes.number.isRequired,
  onLimit: PropTypes.func,
  hideIndicatorIfEmpty: PropTypes.bool,
  showIndicator: PropTypes.bool,
  textInputStyle: PropTypes.any,
  textIndicatorStyle: PropTypes.any,
  textIndicatorLimitReachedStyle: PropTypes.any,
  style: PropTypes.any,
};

TextInputLimited.defaultProps = {
  hideIndicatorIfEmpty: true,
  showIndicator: true,
};

export default TextInputLimited;
