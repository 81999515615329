import PropTypes from 'prop-types';
import React, { useEffect, useImperativeHandle, useState } from 'react';

const RefeshContainer = React.forwardRef(({ children, refreshEveryMs, onRefresh }, forwardRef) => {
  const [lastRefresh, setLastRefresh] = useState(new Date());

  const forwardedRef = {
    refresh: () => setLastRefresh(new Date()),
    getLastRefresh: () => lastRefresh,
  };
  useImperativeHandle(forwardRef, () => forwardedRef);

  useEffect(() => {
    let timeout;
    if (refreshEveryMs > 0) {
      timeout = setTimeout(() => {
        setLastRefresh(new Date());
      }, refreshEveryMs);
    }
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  });

  return children();
});

RefeshContainer.displayName = 'RefeshContainer';

RefeshContainer.propTypes = {
  children: PropTypes.func.isRequired,
  refreshEveryMs: PropTypes.number,
  onRefresh: PropTypes.func,
};

RefeshContainer.defaultProps = {
  refreshEveryMs: -1,
};

export default RefeshContainer;
