import { useEffect, useRef } from 'react';

export const useOnClick = (ref, callback) => {
  // initialize mutable ref, which stores callback
  const callbackRef = useRef();

  // update cb on each render, so second useEffect has access to current value
  useEffect(() => {
    callbackRef.current = callback;
  });
  // update cb on each render, so second useEffect has access to current value
  useEffect(() => {
    const getPositions = (e) => ({
      relative: {
        x: e?.clientX,
        y: e?.clientY,
      },
      absolute: {
        x: e?.pageX,
        y: e?.pageY,
      },
    });
    let handleClick;
    const element = ref.current;
    if (element) {
      handleClick = (e) => {
        if (callbackRef.current) {
          callbackRef.current({
            positions: getPositions(e),
            mouseEvent: e,
            target: element,
          });
        }
      };
      element.addEventListener('click', handleClick);
    }

    return () => {
      if (handleClick && element) {
        element.removeEventListener('click', handleClick);
      }
    };
  });
};

export const useOuterClick = (callback) => {
  const callbackRef = useRef(); // initialize mutable ref, which stores callback
  const innerRef = useRef(); // returned to client, who marks "border" element

  // update cb on each render, so second useEffect has access to current value
  useEffect(() => {
    callbackRef.current = callback;
  });

  useEffect(() => {
    const handleClick = (e) => {
      if (!document.body.contains(e.target)) return; // target doesn't exist in visible DOM
      if (innerRef.current && callbackRef.current && !innerRef.current.contains(e.target))
        callbackRef.current(e);
    };
    document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
  }, []); // no dependencies -> stable click listener

  return innerRef; // convenience for client (doesn't need to init ref himself)
};

export const useOuterClickManyTargets = (refs, callback) => {
  // initialize mutable ref, which stores callback
  const callbackRef = useRef(undefined);

  // update cb on each render, so second useEffect has access to current value
  useEffect(() => {
    callbackRef.current = callback;
  });

  useEffect(() => {
    const handleClick = (e) => {
      let isOuter;
      refs.forEach((ref) => {
        if (!document.body.contains(e.target)) return; // target doesn't exist in visible DOM
        if (ref.current) {
          if (isOuter === undefined || isOuter === true) {
            isOuter = !ref.current.contains(e.target);
          }
        }
      });
      if (isOuter === true) {
        if (callbackRef.current) callbackRef.current(e);
      }
    };
    document.addEventListener('mousedown', handleClick);
    document.addEventListener('touchstart', handleClick, { passive: true });
    return () => {
      document.removeEventListener('mousedown', handleClick);
      document.removeEventListener('touchstart', handleClick);
    };
  });
};

export default {
  useOuterClick,
  useOuterClickManyTargets,
  useOnClick,
};
