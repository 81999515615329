import { createSelector } from 'reselect';

const userSelector = (globalState) => globalState.get('user');
const isAuthenticatedSelector = (state) => userSelector(state).get('isAuthenticated');
const organizationsSelector = (state) => userSelector(state).get('organizations');
const activitiesSelector = (state) => userSelector(state).get('activities');
const liveProfilesSelector = (state) => userSelector(state).get('liveProfiles');
const infoSelector = (state) => userSelector(state).get('info');
const isRefreshingToken = (state) => userSelector(state).get('isRefreshingToken');
const passwordResetState = (state) => userSelector(state).get('passwordResetState');
// User's info
const authTypeSelector = (state) => infoSelector(state).get('_type');
const usernameSelector = (state) => infoSelector(state).get('username');
const roleSelector = (state) => infoSelector(state).get('role');
const userIdSelector = (state) => infoSelector(state).get('_id');
// Selectors

const makeSelectUserInfo = () => createSelector(infoSelector, (state) => state.toJS());

const makeSelectUserId = () => createSelector(userIdSelector, (state) => state);

const makeSelectIsAuthenticated = () => createSelector(isAuthenticatedSelector, (state) => state);

const makeSelectOrganizations = () =>
  createSelector(organizationsSelector, (state) => state.toJS());

const makeSelectActivities = () => createSelector(activitiesSelector, (state) => state.toJS());

const makeSelectLiveProfiles = () => createSelector(liveProfilesSelector, (state) => state?.toJS());

const makeSelectAuthType = () => createSelector(authTypeSelector, (state) => state);

const makeSelectUsername = () => createSelector(usernameSelector, (state) => state);

const makeSelectRole = () => createSelector(roleSelector, (state) => state);

const makeSelectIsRefreshingToken = () => createSelector(isRefreshingToken, (state) => state);

const makeSelectPasswordResetState = () => createSelector(passwordResetState, (state) => state);

// const makeSelectIsAdmin = () => createSelector(isAdminSelector, (state) => state);

export default {
  makeSelectUserInfo,
  makeSelectUserId,
  makeSelectUsername,
  // makeSelectIsAdmin,
  makeSelectIsAuthenticated,
  makeSelectOrganizations,
  makeSelectActivities,
  makeSelectLiveProfiles,
  makeSelectAuthType,
  makeSelectRole,
  makeSelectIsRefreshingToken,
  makeSelectPasswordResetState,
};
