import React, { useEffect } from 'react';
import 'react-native-gesture-handler';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// stylesheet
import './App.css';
import './assets/font/HalisR.otf';
// fonts
import './assets/font/Signika.ttf';
import OverlayContainer from './components/Generic/overlayContainer';
import AuthAwareNavigation from './containers/AuthAwareNavigation';
import { initUniqueLocalId } from './helpers/localId';
import { configureStore } from './helpers/store';

// The viewport height is bigger than the screen on mobile IOS.
// The following code is a workaround, use 'height: var(--app-height)'
// instead of 'height: 100vh' when you encounter this issue.
const appHeight = () => {
  const doc = document.documentElement;
  doc.style.setProperty('--app-height', `${window.innerHeight}px`);
};
window.addEventListener('resize', appHeight);
appHeight();

const store = configureStore();
const App = () => {
  useEffect(() => {
    // Add in local storage a unique id
    initUniqueLocalId();
  });
  return (
    <Provider store={store}>
      <AuthAwareNavigation />
      <ToastContainer
        position="bottom-right"
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
      />
      <OverlayContainer />
    </Provider>
  );
};

export default App;
