import { FlatList, StyleSheet, View } from 'react-native';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import roomSelectors from '../../App/selectors/room';
import ConnectedElement from '../../components/ConnectedElement';

import UserAvatar from '../../components/User/avatar';

const styles = StyleSheet.create({
  topContainer: {
    flex: 1.5,
    flexDirection: 'column',
  },
  container: {
    flex: 10,
    flexDirection: 'column',
    paddingLeft: 15,
    paddingRight: 15,
    width: '100%',
  },
});

const ConnectedUsers = ({ connectedUsers }) => {
  const buildAvatar = (user) => {
    return <UserAvatar user={user} style={{ width: 50 }} />;
  };

  const renderItem = (user) => <ConnectedElement text={user?.username} icon={buildAvatar(user)} />;

  return (
    <View style={styles.topContainer}>
      <FlatList
        style={styles.container}
        data={connectedUsers}
        renderItem={({ item }) => renderItem(item)}
        keyExtractor={(item, index) => `${index}`}
      />
    </View>
  );
};

ConnectedUsers.propTypes = {
  connectedUsers: PropTypes.array.isRequired,
};

const mapStateToProps = createStructuredSelector({
  connectedUsers: roomSelectors.makeSelectConnectedUsers(),
});

export default connect(mapStateToProps, null)(ConnectedUsers);
