import PropTypes from 'prop-types';
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import colors from '../../constants/colors';
import {
  addAudioDeviceIdToMediaConstraints,
  addVideoDeviceIdToMediaConstraints,
  getAudioTrack,
  getDisplayMedia,
  getUserMedia,
  getVideoTrack,
  removeAllAudioTracks,
  removeAllTracks,
  removeAllVideoTracks,
  setAudioTrack,
  setVideoTrack,
  updateConstraintsForMedia,
} from '../../helpers/media';
import { Strings } from '../../helpers/strings';
import {
  useAudioInputMediaDevicesChange,
  useAudioVolumeAnalyzer,
  useTrackEndedMediaStream,
  useVideoInputMediaDevicesChange,
} from '../../hooks/media';
import { useEventListeners } from '../../hooks/utils';
import {
  VideoCaptureElementType,
  VideoCaptureEvent,
  VideoCaptureScreenStatus,
  VIRTUAL_SCREEN_DEVICE_AUDIO_ID,
  VIRTUAL_SCREEN_DEVICE_GROUP_ID,
  VIRTUAL_SCREEN_DEVICE_VIDEO_ID,
} from './videoCapture.constant';
import VideoCaptureElement from './videoCaptureElement';

const styles = StyleSheet.create({
  support: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
    overflow: 'hidden',
  },
  videoContainer: {
    flex: 1,
    width: '100%',
  },
  srcSelectorsContainer: {
    backgroundColor: colors.getMirage(),
    padding: 5,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 10,
  },
  buttonsContainer: {
    position: 'absolute',
    width: '100%',
    left: 0,
    bottom: 5,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 20,
  },
});

const VideoCapture = React.forwardRef(
  (
    {
      onMedia,
      onVideoEnable,
      onAudioEnable,
      onVideoDevice,
      onAudioDevice,
      onVideoDevices,
      onAudioDevices,
      onAudioVolume,
      onScreenSharing,
      onScreenSharingStatus,
      onVideoError,
      onMediaCaptureError,
      onDestroy,
      enableVideoElement,
      enableAudioElement,
      screenSharingElement,
      listVideoDevicesElement,
      listAudioDevicesElement,
      videoElement,
      style,
    },
    forwardRef,
  ) => {
    const { t } = useTranslation();
    const eventListeners = useEventListeners();
    const mediaRef = useRef(new MediaStream());
    const previewMediaRef = useRef(new MediaStream());
    /** @type { React.MutableRefObject<MediaStream> } */
    const mediaScreenRef = useRef(null);
    const microphoneIconRef = useRef();
    const mediaConstraintsRef = useRef({
      video: true,
      audio: true,
    });

    const [mediaScreenStatus, setMediaScreenStatus] = useState(VideoCaptureScreenStatus.WAITING);
    const [screenSharing, setScreenSharing] = useState(false);
    const [videoIsEnabled, setVideoIsEnabled] = useState(true);
    const [audioIsEnabled, setAudioIsEnabled] = useState(true);
    const videoIsEnableRef = useRef(videoIsEnabled);
    const audioIsEnabledRef = useRef(audioIsEnabled);

    // Videos
    /** @type { MediaDeviceInfo } */
    const videoVirtualScreenShareDevice = {
      deviceId: VIRTUAL_SCREEN_DEVICE_VIDEO_ID,
      groupId: VIRTUAL_SCREEN_DEVICE_GROUP_ID,
      kind: 'videoinput',
      label: t(Strings.VIDEO_CAPTURE_DEVICE_SHARE_SCREEN_LABEL),
    };
    const videoDevices = useVideoInputMediaDevicesChange();
    const [currVideoDevice, setCurrVideoDevice] = useState();
    const lastVideoDeviceRef = useRef();
    const isVideoVirtualScreenShareDevice = (device) => {
      if (!device) return false;
      return (
        device.groupId === VIRTUAL_SCREEN_DEVICE_GROUP_ID &&
        device.deviceId === VIRTUAL_SCREEN_DEVICE_VIDEO_ID
      );
    };
    // Audio
    /** @type { MediaDeviceInfo } */
    const audioVirtualScreenShareDevice = {
      deviceId: VIRTUAL_SCREEN_DEVICE_AUDIO_ID,
      groupId: VIRTUAL_SCREEN_DEVICE_GROUP_ID,
      kind: 'audioinput',
      label: t(Strings.VIDEO_CAPTURE_DEVICE_SHARE_SCREEN_LABEL),
    };
    const audioDevices = useAudioInputMediaDevicesChange();
    const [currAudioDevice, setCurrAudioDevice] = useState();
    const lastAudioDeviceRef = useRef();
    const isAudioVirtualScreenShareDevice = (device) => {
      if (!device) return false;
      return (
        device.groupId === VIRTUAL_SCREEN_DEVICE_GROUP_ID &&
        device.deviceId === VIRTUAL_SCREEN_DEVICE_AUDIO_ID
      );
    };

    const updateMediaConstrains = async (constraints) => {
      if (!constraints) return;
      const promises = [];
      if (constraints?.video !== undefined) {
        mediaConstraintsRef.current.video = constraints?.video;
      }
      if (constraints?.audio !== undefined) {
        mediaConstraintsRef.current.audio = constraints?.audio;
      }
      if (mediaRef.current)
        promises.push(updateConstraintsForMedia(mediaRef.current, mediaConstraintsRef.current));
      if (previewMediaRef.current)
        promises.push(
          updateConstraintsForMedia(previewMediaRef.current, mediaConstraintsRef.current),
        );
      if (mediaScreenRef.current)
        promises.push(
          updateConstraintsForMedia(mediaScreenRef.current, mediaConstraintsRef.current),
        );
    };

    const forwardedRef = {
      updateMediaConstraints: async (constraints) => updateMediaConstrains(constraints),
      getMediaConstraints: () => mediaConstraintsRef.current,
      getMedia: () => mediaRef.current,
      getPreviewMedia: () => previewMediaRef.current,
      getMediaScreen: () => mediaScreenRef.current,
      getVideoDevices: () => videoDevices,
      getAudioDevices: () => audioDevices,
      setVideoDevice: (device) => setCurrVideoDevice(device),
      setAudioDevice: (device) => setCurrAudioDevice(device),
      isVideoVirtualScreenShareDevice: (device) => isVideoVirtualScreenShareDevice(device),
      isAudioVirtualScreenShareDevice: (device) => isAudioVirtualScreenShareDevice(device),
      enableVideo: (enable) => setVideoIsEnabled(enable),
      enableAudio: (enable) => setAudioIsEnabled(enable),
      startScreenSharing: () => setScreenSharing(true),
      stopSreenSharing: () => setScreenSharing(false),
      addEventListener: (key, callback) => eventListeners.addEventListerner(key, callback),
      removeEventListener: (key, callback) => eventListeners.addEventListerner(key, callback),
    };
    const selfRef = useRef(forwardedRef);
    useImperativeHandle(forwardRef, () => forwardedRef);

    // ---------------------------------------------------------------------- //
    // ---------------------------   EVENTS ----------------------------- //
    // ---------------------------------------------------------------------- //

    const triggerOnMedia = (media, track) => {
      if (onMedia) onMedia(media, track);
      eventListeners.triggersEventListeners(VideoCaptureEvent.MEDIA, media, track);
    };

    const triggerOnVideoEnable = (enabled) => {
      if (onVideoEnable) onVideoEnable(enabled);
      eventListeners.triggersEventListeners(VideoCaptureEvent.VIDEO_ENABLE, enabled);
    };

    const triggerOnAudioEnable = (enabled) => {
      if (onAudioEnable) onAudioEnable(enabled);
      eventListeners.triggersEventListeners(VideoCaptureEvent.AUDIO_ENABLE, enabled);
    };

    const triggerOnVideoDevice = (device) => {
      if (onVideoDevice) onVideoDevice(device);
      eventListeners.triggersEventListeners(VideoCaptureEvent.VIDEO_DEVICE, device);
    };

    const triggerOnAudioDevice = (device) => {
      if (onAudioDevice) onAudioDevice(device);
      eventListeners.triggersEventListeners(VideoCaptureEvent.AUDIO_DEVICE, device);
    };

    const triggerOnVideoDevices = (devices) => {
      if (onVideoDevices) onVideoDevices(devices);
      eventListeners.triggersEventListeners(VideoCaptureEvent.VIDEO_DEVICES, devices);
    };

    const triggerOnAudioDevices = (devices) => {
      if (onAudioDevices) onAudioDevices(devices);
      eventListeners.triggersEventListeners(VideoCaptureEvent.AUDIO_DEVICES, devices);
    };

    const triggerOnAudioVolume = (volume) => {
      if (onAudioVolume) onAudioVolume(volume);
      eventListeners.triggersEventListeners(VideoCaptureEvent.AUDIO_VOLUME, volume);
    };

    const triggerOnScreenSharing = (running) => {
      if (onScreenSharing) onScreenSharing(running);
      eventListeners.triggersEventListeners(VideoCaptureEvent.SCREEN_SHARING, running);
    };

    const triggerOnScreenSharingStatus = (status) => {
      if (onScreenSharingStatus) onScreenSharingStatus(status);
      eventListeners.triggersEventListeners(VideoCaptureEvent.SCREEN_SHARING_STATUS, status);
    };

    const triggerOnDestroy = () => {
      if (onDestroy) onDestroy();
      eventListeners.triggersEventListeners(VideoCaptureEvent.DESTROY);
    };

    // ---------------------------------------------------------------------- //
    // ---------------------------   UTILS ----------------------------- //
    // ---------------------------------------------------------------------- //

    useAudioVolumeAnalyzer(previewMediaRef.current, (volume) => {
      if (microphoneIconRef.current) {
        microphoneIconRef.current.setVolume(volume);
      }
      triggerOnAudioVolume(volume);
    });

    useTrackEndedMediaStream(mediaScreenRef.current, (track) => {
      setScreenSharing(false);
      setMediaScreenStatus(VideoCaptureScreenStatus.WAITING);
    });

    const cleanMediaScreen = () => {
      if (!mediaScreenRef.current) return;
      const tracks = mediaScreenRef.current.getTracks();
      tracks.forEach((track) => {
        track.stop();
      });
      mediaScreenRef.current = undefined;
    };

    // Video
    const assignVideoMedia = (media) => {
      if (!media) return;
      const videoTrack = getVideoTrack(media);
      if (!videoTrack) return;
      const previewVideoTrack = videoTrack.clone();
      videoTrack.enabled = videoIsEnableRef.current;
      setVideoTrack(mediaRef.current, videoTrack);
      setVideoTrack(previewMediaRef.current, previewVideoTrack);
      triggerOnMedia(mediaRef.current, videoTrack);
    };

    const assignVideoMediaFromDeviceId = async (deviceId) => {
      const constraints = addVideoDeviceIdToMediaConstraints(deviceId, {
        video: mediaConstraintsRef.current.video,
        audio: false,
      });
      // Free current device : Avoid DOMException: Could not start video source
      if (mediaRef.current) removeAllVideoTracks(mediaRef.current);
      if (previewMediaRef.current) removeAllVideoTracks(previewMediaRef.current);
      const media = await getUserMedia(constraints);
      if (!media) throw new Error('Impossible to getUserMedia: Media is undefined');
      assignVideoMedia(media);
    };

    // Audio
    const assignAudioMedia = (media) => {
      if (!media) return;
      const audioTrack = getAudioTrack(media);
      if (!audioTrack) return;
      const previewAudioTrack = audioTrack.clone();
      audioTrack.enabled = audioIsEnabledRef.current;
      setAudioTrack(mediaRef.current, audioTrack);
      setAudioTrack(previewMediaRef.current, previewAudioTrack);
      triggerOnMedia(mediaRef.current, audioTrack);
    };

    const assignAudioMediaFromDeviceId = async (deviceId) => {
      const constraints = addAudioDeviceIdToMediaConstraints(deviceId, {
        video: false,
        audio: mediaConstraintsRef.current.audio,
      });
      // Free current device : Avoid DOMException: Concurrent mic process limit.
      if (mediaRef.current) removeAllAudioTracks(mediaRef.current);
      if (previewMediaRef.current) removeAllAudioTracks(previewMediaRef.current);
      const media = await getUserMedia(constraints);
      if (!media) throw new Error('Impossible to getUserMedia: Media is undefined');
      assignAudioMedia(media);
    };

    const assignMediaFromScreen = async () => {
      setMediaScreenStatus(VideoCaptureScreenStatus.PROCESSING);
      const constraint = mediaConstraintsRef.current;
      try {
        const media = await getDisplayMedia(constraint);
        mediaScreenRef.current = media;
        setMediaScreenStatus(
          media ? VideoCaptureScreenStatus.RUNNING : VideoCaptureScreenStatus.ERROR,
        );
        setCurrVideoDevice(videoVirtualScreenShareDevice);
      } catch (err) {
        setMediaScreenStatus(VideoCaptureScreenStatus.ERROR);
      }
    };

    const destroy = () => {
      triggerOnDestroy();
      // Clean all medias
      if (mediaRef.current) removeAllTracks(mediaRef.current);
      if (mediaScreenRef.current) removeAllTracks(mediaScreenRef.current);
      if (previewMediaRef.current) removeAllTracks(previewMediaRef.current);
    };

    // ---------------------------------------------------------------------- //
    // ---------------------------   USE EFFECT ----------------------------- //
    // ---------------------------------------------------------------------- //

    useEffect(() => {
      return () => {
        destroy();
      };
    }, []);

    // Video
    useEffect(() => {
      if (!currVideoDevice) return;
      const update = async () => {
        try {
          if (isVideoVirtualScreenShareDevice(currVideoDevice)) {
            assignVideoMedia(mediaScreenRef.current.clone()); // clone to avoid the track be closed when device change
            triggerOnVideoDevice(currVideoDevice);
            return;
          }
          await assignVideoMediaFromDeviceId(currVideoDevice.deviceId);
          triggerOnVideoDevice(currVideoDevice);
        } catch (err) {
          if (onMediaCaptureError) onMediaCaptureError('video', err);
          if (lastVideoDeviceRef.current?.deviceId !== currVideoDevice.deviceId)
            setCurrVideoDevice(lastVideoDeviceRef.current);
          return;
        }
        if (lastVideoDeviceRef.current?.deviceId !== currVideoDevice.deviceId) {
          lastVideoDeviceRef.current = currVideoDevice;
        }
      };
      update();
    }, [currVideoDevice]);

    // Audio
    useEffect(() => {
      if (!currAudioDevice) return;
      const update = async () => {
        try {
          if (isAudioVirtualScreenShareDevice(currAudioDevice)) {
            assignAudioMedia(mediaScreenRef.current.clone()); // clone to avoid the track be closed when device change
            triggerOnAudioDevice(currAudioDevice);
            return;
          }
          await assignAudioMediaFromDeviceId(currAudioDevice.deviceId);
          triggerOnAudioDevice(currAudioDevice);
        } catch (err) {
          if (onMediaCaptureError) onMediaCaptureError('audio', err);
          if (lastAudioDeviceRef.current?.deviceId !== currAudioDevice.deviceId)
            setCurrAudioDevice(lastAudioDeviceRef.current);
        }
        if (lastAudioDeviceRef.current?.deviceId !== currAudioDevice.deviceId) {
          lastAudioDeviceRef.current = currAudioDevice;
        }
      };
      update();
    }, [currAudioDevice]);

    // Video
    useEffect(() => {
      if (!currVideoDevice) {
        [lastVideoDeviceRef.current] = videoDevices;
        setCurrVideoDevice(videoDevices[0]);
      }
    }, [videoDevices]);

    // Audio
    useEffect(() => {
      if (!currAudioDevice) {
        [lastAudioDeviceRef.current] = audioDevices;
        setCurrAudioDevice(audioDevices[0]);
      }
    }, [audioDevices]);

    // Video
    useEffect(() => {
      videoIsEnableRef.current = videoIsEnabled;
      triggerOnVideoEnable(videoIsEnabled);
      const videoTrack = getVideoTrack(mediaRef.current);
      if (!videoTrack) return;
      videoTrack.enabled = videoIsEnabled;
    }, [videoIsEnabled]);

    // Audio
    useEffect(() => {
      audioIsEnabledRef.current = audioIsEnabled;
      triggerOnAudioEnable(audioIsEnabled);
      const audioTrack = getAudioTrack(mediaRef.current);
      if (!audioTrack) return;
      audioTrack.enabled = audioIsEnabled;
    }, [audioIsEnabled]);

    // Mode
    useEffect(() => {
      if (screenSharing) {
        if (mediaScreenStatus === VideoCaptureScreenStatus.WAITING) {
          assignMediaFromScreen();
        } else if (mediaScreenStatus === VideoCaptureScreenStatus.ERROR) {
          setScreenSharing(false);
        }
      } else if (mediaScreenStatus !== VideoCaptureScreenStatus.WAITING) {
        cleanMediaScreen();
        setMediaScreenStatus(VideoCaptureScreenStatus.WAITING);
      }
    }, [screenSharing, mediaScreenStatus]);

    useEffect(() => {
      triggerOnScreenSharingStatus(mediaScreenStatus);
    }, [mediaScreenStatus]);

    useEffect(() => {
      triggerOnScreenSharing(screenSharing);
      if (!screenSharing) {
        let videoDevice = currVideoDevice;
        if (isVideoVirtualScreenShareDevice(currVideoDevice)) {
          videoDevice = lastVideoDeviceRef.current;
          if (!videoDevice && videoDevices) {
            [videoDevice] = videoDevices;
          }
        }
        let audioDevice = currAudioDevice;
        if (isAudioVirtualScreenShareDevice(currAudioDevice)) {
          audioDevice = lastAudioDeviceRef.current;
          if (!audioDevice && audioDevices) {
            [audioDevice] = audioDevices;
          }
        }
        setCurrVideoDevice(videoDevice);
        setCurrAudioDevice(audioDevice);
      }
    }, [screenSharing]);

    useEffect(() => {
      const devices = [];
      if (mediaScreenStatus === VideoCaptureScreenStatus.RUNNING) {
        const screenSharingVideoTrack = getVideoTrack(mediaScreenRef.current);
        if (screenSharingVideoTrack) {
          devices.push(videoVirtualScreenShareDevice);
        }
      }
      devices.push(...videoDevices);
      triggerOnVideoDevices(devices);
    }, [mediaScreenStatus, videoDevices]);

    useEffect(() => {
      const devices = [];
      if (mediaScreenStatus === VideoCaptureScreenStatus.RUNNING) {
        const screenSharingAudioTrack = getAudioTrack(mediaScreenRef.current);
        if (screenSharingAudioTrack) {
          devices.push(audioVirtualScreenShareDevice);
        }
      }
      devices.push(...audioDevices);
      triggerOnAudioDevices(devices);
    }, [mediaScreenStatus, audioDevices]);

    // ---------------------------------------------------------------------- //
    // ----------------------------- RENDERING ------------------------------ //
    // ---------------------------------------------------------------------- //

    const viewStyle = [styles.support];
    if (style) viewStyle.push(...(Array.isArray(style) ? style : [style]));
    return (
      <View style={viewStyle}>
        <View style={styles.videoContainer}>
          {videoElement ? (
            <VideoCaptureElement
              videoCaptureRef={selfRef}
              onVideoError={onVideoError}
              type={VideoCaptureElementType.VIDEO}
              style={{ width: '100%', height: '100%' }}
            />
          ) : null}
          <View style={styles.buttonsContainer}>
            {enableVideoElement ? (
              <VideoCaptureElement
                videoCaptureRef={selfRef}
                type={VideoCaptureElementType.ENABLE_VIDEO_BUTTON}
              />
            ) : null}
            {enableAudioElement ? (
              <VideoCaptureElement
                videoCaptureRef={selfRef}
                type={VideoCaptureElementType.ENABLE_AUDIO_BUTTON}
              />
            ) : null}
            {screenSharingElement ? (
              <VideoCaptureElement
                videoCaptureRef={selfRef}
                type={VideoCaptureElementType.SCREEN_SHARING_BUTTON}
              />
            ) : null}
          </View>
        </View>
        {listVideoDevicesElement || listAudioDevicesElement ? (
          <View style={styles.srcSelectorsContainer}>
            {listVideoDevicesElement ? (
              <VideoCaptureElement
                videoCaptureRef={selfRef}
                type={VideoCaptureElementType.LIST_VIDEO_DEVICES}
              />
            ) : null}
            {listAudioDevicesElement ? (
              <VideoCaptureElement
                videoCaptureRef={selfRef}
                type={VideoCaptureElementType.LIST_AUDIO_DEVICES}
              />
            ) : null}
          </View>
        ) : null}
      </View>
    );
  },
);

VideoCapture.displayName = 'VideoCapture';

VideoCapture.propTypes = {
  onMedia: PropTypes.func,
  onVideoEnable: PropTypes.func,
  onAudioEnable: PropTypes.func,
  onVideoDevice: PropTypes.func,
  onAudioDevice: PropTypes.func,
  onVideoDevices: PropTypes.func,
  onAudioDevices: PropTypes.func,
  onAudioVolume: PropTypes.func,
  onScreenSharing: PropTypes.func,
  onScreenSharingStatus: PropTypes.func,
  onVideoError: PropTypes.func,
  onMediaCaptureError: PropTypes.func,
  onDestroy: PropTypes.func,
  enableVideoElement: PropTypes.bool,
  enableAudioElement: PropTypes.bool,
  screenSharingElement: PropTypes.bool,
  listVideoDevicesElement: PropTypes.bool,
  listAudioDevicesElement: PropTypes.bool,
  videoElement: PropTypes.bool,
  style: PropTypes.any,
};

VideoCapture.defaultProps = {
  enableVideoElement: true,
  enableAudioElement: true,
  screenSharingElement: true,
  listVideoDevicesElement: true,
  listAudioDevicesElement: true,
  videoElement: true,
  style: undefined,
};

export default VideoCapture;
