import { call, put, takeLatest } from 'redux-saga/effects';

import api from '../api/_index';
import errorActions from '../actions/error';
import appActions from '../actions/app';
import userActions from '../actions/user';
import { Types as liveProfileTypes } from '../actions/liveprofile';
import { submitRequest } from './user';

const { REQUEST_USER_LIVE_PROFILES, REQUEST_CREATE_LIVE_PROFILE, REQUEST_UPDATE_LIVE_PROFILE } =
  liveProfileTypes;

function* requestCreateLiveProfile({ liveProfile }) {
  try {
    yield put(appActions.addCurrentlySending(`${REQUEST_CREATE_LIVE_PROFILE}`));

    const createdLiveProfile = yield call(
      submitRequest,
      api.liveprofile.requestCreateLiveProfile,
      liveProfile,
    );
    yield put(userActions.addLiveProfile(createdLiveProfile));
  } catch (error) {
    yield put(errorActions.handleError(error));
  } finally {
    yield put(appActions.removeCurrentlySending(`${REQUEST_CREATE_LIVE_PROFILE}`));
  }
}

function* requestUpdateLiveProfile({ _id, body }) {
  try {
    yield put(appActions.addCurrentlySending(`${REQUEST_UPDATE_LIVE_PROFILE}`));

    const updatedLiveProfile = yield call(
      submitRequest,
      api.liveprofile.requestUpdateLiveProfile,
      _id,
      body,
    );

    yield put(userActions.updateLiveProfile(updatedLiveProfile._id, updatedLiveProfile));
  } catch (error) {
    yield put(errorActions.handleError(error));
  } finally {
    yield put(appActions.removeCurrentlySending(`${REQUEST_UPDATE_LIVE_PROFILE}`));
  }
}

function* watcherRequestCreateLiveProfile() {
  yield takeLatest(REQUEST_CREATE_LIVE_PROFILE, requestCreateLiveProfile);
}

function* watcherRequestUpdateLiveProfile() {
  yield takeLatest(REQUEST_UPDATE_LIVE_PROFILE, requestUpdateLiveProfile);
}

export default [watcherRequestCreateLiveProfile(), watcherRequestUpdateLiveProfile()];
