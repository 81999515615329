import {
  Player as PlayerKastModule,
  PlayerAntmedia as PlayerAntmediaKastModule,
  PlayerOvenMedia as PlayerOvenMediaKastModule,
  PlayerWebRTC as PlayerWebRTCKastModule,
  VideoAntmediaMode,
  VideoState,
} from '@kalyzee/kast-react-player-module';
import PropTypes from 'prop-types';
import React, { useEffect, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Lottie from 'react-lottie-player';
import { ActivityIndicator, ImageBackground, StyleSheet, Text, View } from 'react-native';
import { TouchableOpacity } from 'react-native-web';
import { connect } from 'react-redux';
import deviceActions, { Types as deviceTypes } from '../../App/actions/device';
import PublishButton from '../../assets/animations/readyToRecord.json';
import IconClose from '../../assets/navigation/close.svg';
import IconExpendWindow from '../../assets/navigation/expend_window.svg';
import IconReduceWindow from '../../assets/navigation/reduce_window.svg';
import MoveableContainer from '../../components/Generic/moveableContainer';
import colors from '../../constants/colors';
import fonts from '../../constants/fonts';
import { getToken } from '../../helpers/auth';
import { getBaseUrl, getWebRTCSocketUrl } from '../../helpers/request';
import { useReduxAction } from '../../helpers/store';
import { Strings } from '../../helpers/strings';
import { isValidHttpUrl, isValidWebSocketUrl } from '../../helpers/utils';
import { useWindowSize } from '../../hooks/element';
import VideoWebRTCOverlay from './videoWebRTCOverlay';

const backgroundImage = require('../../assets/blue/thumbnail-example.png');

const DEBUG_CONTROLS = false;

const WAITING_DETAILS = {
  CONTROL_RIGHTS: {
    LIVE: {
      image: backgroundImage,
      title: Strings.WAITING_FOR_THE_LIVE_TO_START,
      description: Strings.YOU_CAN_START_THE_LIVE_AT_ANY_TIME,
    },
    DISCONNECTED: {
      image: backgroundImage,
      title: Strings.THE_CAMERA_DOES_NOT_SEEM_TO_BE_ONLINE,
      description: Strings.CHECK_IF_THE_CAMERA_IS_CONNECTED_TO_THE_INTERNET,
    },
  },
  NO_CONTROL_RIGHTS: {
    LIVE: {
      image: backgroundImage,
      title: Strings.WAITING_FOR_THE_LIVE_TO_START,
      description: '',
    },
  },
  SOURCE: {
    NO_URL: {
      image: backgroundImage,
      title: Strings.THE_LIVE_SOURCE_WAS_NOT_DEFINED,
      description: '',
    },
    INVALID_URL: {
      image: backgroundImage,
      title: Strings.THE_LIVE_SOURCE_IS_NOT_VALID,
      description: '',
    },
  },
};

export const PREVIEW_MODE = {
  HIDDEN: 'HIDDEN',
  NORMAL: 'NORMAL',
  EXTENDED: 'EXTENDED',
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    justifyContent: 'center',
    flex: 1,
  },
  mainPlayerContainer: {
    backgroundColor: colors.getCodGray(),
    position: 'relative',
    flexDirection: 'column',
    justifyContent: 'center',
    display: 'flex',
    flex: 1,
    flexBasis: 0,
    width: '100%',
    zIndex: 2,
    fontSize: 16,
  },
  streamingContent: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
  webRTCContent: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
  playerVideo: {
    width: '100%',
    height: '100%',
    backgroundColor: 'transparent',
  },
  loadingStreaming: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    fontFamily: fonts.getSignika(),
    fontSize: '1em',
  },
  loadingStreamingMsg: {
    fontFamily: fonts.getSignika(),
    fontSize: '1em',
    color: colors.getMainWhite(),
    marginTop: '5px',
  },
  loadingStreamingIndicator: {
    marginTop: '10px',
  },
});

const waitingStyles = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
    flex: 1,
  },
  image: {
    flex: 1,
    justifyContent: 'center',
  },
  title: {
    color: colors.getMainPurple(),
    fontSize: '1.5em',
    fontWeight: 'bold',
    fontFamily: fonts.getSignika(),
    textAlign: 'center',
    paddingHorizontal: '20px',
  },
  description: {
    color: colors.getMainPurple(0.9),
    fontFamily: fonts.getSignika(),
    textAlign: 'center',
    fontSize: '1em',
    paddingHorizontal: '20px',
  },
});

const sourceIsHls = (src) => {
  if (!src) return false;
  let result = false;
  try {
    const url = new URL(src);
    if (url.pathname.endsWith('.m3u8')) {
      result = true;
    }
  } catch (err) {
    result = false;
  }
  return result;
};

const Player = React.forwardRef(function Player(
  {
    activityIsOngoing,
    activityCanLive,
    activityCanRecord,
    activityState,
    device,
    source,
    enableControls,
    move,
    stopMove,
    zoom,
    stopZoom,
    switchScene,
    switchView,
    setView,
    containerRef,
    defaultPreviewMode,
    onPreviewMode,
  },
  ref,
) {
  const { t } = useTranslation();
  const [view, updateView] = useState(null);
  const [previewMode, setPreviewMode] = useState(defaultPreviewMode);
  const [webRTCState, setWebRTCState] = useState(VideoState.STOPPED);
  const windowSize = useWindowSize();

  useEffect(() => onPreviewMode?.(previewMode), [previewMode]);

  useImperativeHandle(ref, () => ({
    setPreviewMode,
  }));

  useReduxAction((action) => {
    if (action.deviceId === device?.id) {
      updateView(action.viewId);
    }
  }, deviceTypes.SET_VIEW);

  const deviceIsOnline = DEBUG_CONTROLS || device?.online;
  const showControls = enableControls && deviceIsOnline;
  const videoContext = device?.context?.videoContext;
  const isInLive = DEBUG_CONTROLS || !!(activityState?.liveStatus === 'active');
  const isRecording = DEBUG_CONTROLS || !!(activityState?.recordStatus === 'active');
  const currentScene = videoContext?.currentScene;

  const formatSource = (src) => {
    if (!src) return null;
    let json = null;
    try {
      json = JSON.parse(src);
    } catch (err) {
      // can't parse src
    }
    if (json) return json;
    if (isValidHttpUrl(src)) return src;
    return null;
  };

  const renderWaitingStreaming = (srcIsValid = true) => {
    const getDetails = () => {
      if (!source) return WAITING_DETAILS.SOURCE.NO_URL;
      if (!srcIsValid) return WAITING_DETAILS.SOURCE.INVALID_URL;
      if (!enableControls) return WAITING_DETAILS.NO_CONTROL_RIGHTS.LIVE;
      if (!deviceIsOnline) return WAITING_DETAILS.CONTROL_RIGHTS.DISCONNECTED;
      return WAITING_DETAILS.CONTROL_RIGHTS.LIVE;
    };
    const details = getDetails();
    return (
      <View style={waitingStyles.container}>
        <ImageBackground source={details.image} resizeMode="cover" style={waitingStyles.image}>
          <Text style={waitingStyles.title}>{t(details.title)}</Text>
          <Text style={waitingStyles.description}>{t(details.description)}</Text>
        </ImageBackground>
      </View>
    );
  };

  const renderOverlayByState = (state, retry) => (
    <VideoWebRTCOverlay state={state} onRetry={retry} />
  );

  const renderWebRTCPlayer = () => (
    <View style={styles.webRTCContent}>
      <PlayerWebRTCKastModule
        style={StyleSheet.flatten(styles.playerVideo)}
        src={{
          url: getWebRTCSocketUrl(),
          generateTokenEndpoint: `${getBaseUrl()}/socket/token/webrtc/${device.id}`,
          generateTokenWithUserToken: getToken(),
        }}
        live
        autoPlay
        play
        enableCameraControls={showControls}
        enableVideoControls
        scene={currentScene}
        view={view}
        onMove={(direction) => move(device, direction)}
        onStopMove={() => {
          stopMove(device);
        }}
        onZoom={(direction) => zoom(device, direction)}
        onStopZoom={() => stopZoom(device)}
        onScene={(scene) => switchScene(device, scene)}
        onView={(v) => {
          updateView(v);
          switchView(device, v);
        }}
        onAssignView={(v) => {
          updateView(v);
          setView(device, v);
        }}
        onStateChange={(state) => setWebRTCState(state)}
        retryAmountOnError={3}
        retryDelay={3000}
      >
        {(state, retry) => renderOverlayByState(state, retry)}
      </PlayerWebRTCKastModule>
    </View>
  );

  const renderStreaming = (isPreview = false) => {
    const src = formatSource(source);
    let srcIsValid = true;
    if (deviceIsOnline && isInLive) {
      if (src) {
        if (typeof src === 'string') {
          srcIsValid = true;
          return (
            <View style={styles.streamingContent}>
              <PlayerKastModule
                style={StyleSheet.flatten(styles.playerVideo)}
                src={source}
                srcIsHls={sourceIsHls(src)}
                live
                enableCameraControls={!isPreview && showControls}
                enableVideoControls={!isPreview}
                play
              />
            </View>
          );
        }
        if (src.type === 'antmedia') {
          const { baseUrl, application, id } = src;
          if (isValidHttpUrl(baseUrl) && application && id) {
            const mode = src.mode ?? VideoAntmediaMode.MIXED;
            return (
              <View style={styles.streamingContent}>
                <PlayerAntmediaKastModule
                  style={StyleSheet.flatten(styles.playerVideo)}
                  mode={mode}
                  antmediaBaseUrl={baseUrl}
                  antmediaApplication={application}
                  antmediaId={id}
                  live
                  enableCameraControls={!isPreview && showControls}
                  enableVideoControls={!isPreview}
                  play
                />
              </View>
            );
          }
        } else if (src.type === 'ovenmedia') {
          const { url } = src;
          if (isValidHttpUrl(url) || isValidWebSocketUrl(url)) {
            return (
              <View style={styles.streamingContent}>
                <PlayerOvenMediaKastModule
                  style={StyleSheet.flatten(styles.playerVideo)}
                  src={url}
                  live
                  enableCameraControls={!isPreview && showControls}
                  enableVideoControls={!isPreview}
                  play
                />
              </View>
            );
          }
        }
      }
      srcIsValid = false;
    }
    return renderWaitingStreaming(srcIsValid);
  };

  const renderPreviewPlayer = (content) => {
    const minWidthPreview = 700;
    if (previewMode === PREVIEW_MODE.HIDDEN) return null;
    const backgroundColor = colors.getBlueOxford();
    const size = {
      width: '320px',
      height: '180px',
    };
    if (previewMode === PREVIEW_MODE.EXTENDED && windowSize?.width > minWidthPreview) {
      size.width = '640px';
      size.height = '360px';
    }
    const supportSyle = {
      position: 'absolute',
      top: '-10px',
      right: '-10px',
      zIndex: 1,
      overflow: 'hidden',
      borderRadius: '10px',
      boxShadow: `0px 0px 5px ${colors.getMirage()}`,
      maxWidth: '95%',
      maxHeight: '95%',
    };
    const containerStyle = {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      overflow: 'hidden',
      backgroundColor,
      fontSize: '12px',
    };
    const toolbarStyle = {
      position: 'relative',
      width: '100%',
      height: '30px',
      display: 'flex',
      alignItems: 'center',
      borderBottom: `1px solid ${colors.getMirage()}`,
    };
    const toolbarTitleStyle = {
      width: '100%',
      color: colors.getMainWhite(),
      fontFamily: fonts.getHalisR(),
      textAlign: 'center',
      fontSize: '12px',
    };
    const toolbarButtonContainer = {
      position: 'absolute',
      height: '100%',
      right: '10px',
      top: '0px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: '5px',
    };
    const contentStyle = {
      width: size.width,
      height: size.height,
      boxSizing: 'border-box',
    };

    const renderSwitchPreviewMode = () => {
      if (windowSize?.width < minWidthPreview) return null;
      if (previewMode === PREVIEW_MODE.EXTENDED) {
        return (
          <TouchableOpacity
            onPress={(e) => {
              setPreviewMode(PREVIEW_MODE.NORMAL);
              e.stopPropagation();
            }}
          >
            <IconExpendWindow width="15px" heigh="15px" />
          </TouchableOpacity>
        );
      }
      return (
        <TouchableOpacity
          onPress={(e) => {
            setPreviewMode(PREVIEW_MODE.EXTENDED);
            e.stopPropagation();
          }}
        >
          <IconReduceWindow width="15px" heigh="15px" />
        </TouchableOpacity>
      );
    };

    const renderToolBar = () => (
      <div style={toolbarStyle}>
        <div style={toolbarTitleStyle}>{t(Strings.PREVIEW_OF_THE_LIVE)}</div>
        <div style={toolbarButtonContainer}>
          {renderSwitchPreviewMode()}
          <TouchableOpacity onPress={() => setPreviewMode(PREVIEW_MODE.HIDDEN)}>
            <IconClose width="15px" heigh="15px" />
          </TouchableOpacity>
        </div>
      </div>
    );

    return (
      <MoveableContainer style={supportSyle} parentContainerRef={containerRef}>
        <div style={containerStyle}>
          {renderToolBar()}
          <ImageBackground
            // eslint-disable-next-line global-require
            source={require('../../assets/backgrounds/kast.png')}
            resizeMode="cover"
            style={contentStyle}
          >
            <View style={styles.loadingStreaming}>
              <Text style={styles.loadingStreamingMsg}>
                {t(Strings.LOADING_PREVIEW_OF_THE_LIVE)}
              </Text>
              <ActivityIndicator
                animating={true}
                size="small"
                color={colors.getMainWhite()}
                style={styles.loadingStreamingIndicator}
              />
            </View>
            {content}
          </ImageBackground>
        </div>
      </MoveableContainer>
    );
  };

  const renderMainPlayer = () => {
    // eslint-disable-next-line global-require
    const background = require('../../assets/backgrounds/kast.png');
    if (showControls && deviceIsOnline) {
      const windowWidth = windowSize?.width ?? 0;
      let fontSize = '18px';
      if (windowWidth < 700) fontSize = '16px';
      if (windowWidth < 600) fontSize = '14px';
      if (windowWidth < 500) fontSize = '12px';
      const renderMessage = () => (
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            paddingLeft: '15px',
            paddingRight: '45px',
            paddingTop: '15px',
            paddingBottom: '15px',
            backgroundColor: '#33333388',
            backdropFilter: 'blur(3px)',
            borderRadius: '10px',
            maxWidth: '80%',
          }}
        >
          <div
            style={{
              color: 'white',
              fontFamily: fonts.getSignika(),
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              fontSize,
            }}
          >
            {t(Strings.YOU_CAN_START_THE_PUBLICATION_AT_ANY_TIME)}
          </div>
          <Lottie
            animationData={PublishButton}
            loop
            play
            style={{
              position: 'absolute',
              top: '-5px',
              right: '-5px',
              width: '60px',
              height: '60px',
            }}
            rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
          />
        </div>
      );
      return (
        <ImageBackground source={background} resizeMode="cover" style={styles.mainPlayerContainer}>
          {renderWebRTCPlayer()}
          {activityCanLive && renderPreviewPlayer(renderStreaming(true))}
          {webRTCState === VideoState.RUNNING &&
          !isInLive &&
          !isRecording &&
          activityIsOngoing &&
          (activityCanLive || activityCanRecord)
            ? renderMessage()
            : null}
        </ImageBackground>
      );
    }
    return (
      <ImageBackground source={background} resizeMode="cover" style={styles.mainPlayerContainer}>
        <View style={styles.loadingStreaming}>
          <Text style={styles.loadingStreamingMsg}>{t(Strings.LOADING_LIVE)}</Text>
          <ActivityIndicator
            animating={true}
            size="small"
            color={colors.getMainWhite()}
            style={styles.loadingStreamingIndicator}
          />
        </View>
        {renderStreaming(false)}
      </ImageBackground>
    );
  };

  return <View style={[styles.container]}>{renderMainPlayer()}</View>;
});

Player.propTypes = {
  activityIsOngoing: PropTypes.bool,
  activityCanLive: PropTypes.bool,
  activityCanRecord: PropTypes.bool,
  activityState: PropTypes.object.isRequired,
  device: PropTypes.object.isRequired,
  source: PropTypes.string,
  enableControls: PropTypes.bool,
  move: PropTypes.func.isRequired,
  stopMove: PropTypes.func.isRequired,
  zoom: PropTypes.func.isRequired,
  stopZoom: PropTypes.func.isRequired,
  switchScene: PropTypes.func.isRequired,
  switchView: PropTypes.func.isRequired,
  setView: PropTypes.func.isRequired,
  containerRef: PropTypes.any,
  defaultPreviewMode: PropTypes.oneOf([
    PREVIEW_MODE.HIDDEN,
    PREVIEW_MODE.NORMAL,
    PREVIEW_MODE.EXTENDED,
  ]),
  onPreviewMode: PropTypes.func,
};

Player.defaultProps = {
  activityIsOngoing: false,
  source: '',
  enableControls: false,
  containerRef: undefined,
  defaultPreviewMode: PREVIEW_MODE.HIDDEN,
  onPreviewMode: undefined,
};

const mapDispatchToProps = (dispatch) => {
  return {
    move: (device, direction) => dispatch(deviceActions.move(device.id, direction)),
    stopMove: (device) => dispatch(deviceActions.stopMove(device.id)),
    zoom: (device, direction) => dispatch(deviceActions.zoom(device.id, direction)),
    stopZoom: (device) => dispatch(deviceActions.stopZoom(device.id)),
    switchScene: (device, scene) => dispatch(deviceActions.switchScene(device.id, scene)),
    switchView: (device, view) => dispatch(deviceActions.switchView(device.id, view)),
    setView: (device, view) => dispatch(deviceActions.setView(device.id, view)),
  };
};

export default connect(null, mapDispatchToProps, null, { forwardRef: true })(Player);
