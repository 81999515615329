import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-native';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { openAuthSessionAsync, maybeCompleteAuthSession } from 'expo-web-browser';
import { parse, createURL } from 'expo-linking';

import colors from '../../constants/colors';
import { screen, screenName } from '../../constants/navigation';
import userActions, { Types as userTypes } from '../../App/actions/user';
import appSelectors from '../../App/selectors/app';

const { REQUEST_LOGIN_CAS } = userTypes;

// Will close the modal of Expo Web Browser after redirection! (Only work/useful for web)
try {
  maybeCompleteAuthSession();
} catch (error) {
  // TODO !!!
  // throws "SecurityError: Permission denied to access property "transfer" on cross-origin object"
  // when used with moodle, i have no idea why for now
}

const LoginCAS = ({ method, currentlySending, requestLoginCas }) => {
  const redirect = async () => {
    const currURL = createURL('/');
    const currLoginPath = screen[screenName.LOGIN].path;

    const serviceParam = `service=${currURL}${currLoginPath}`;
    const { origin, loginPath } = method;

    const result = await openAuthSessionAsync(`${origin}${loginPath}?${serviceParam}`);

    if (result.type === 'success' && result.url) {
      const { queryParams } = parse(result.url);
      const { ticket } = queryParams;
      if (ticket !== undefined) requestLoginCas(ticket);
    }
  };

  return (
    <Button
      title="CAS"
      color={colors.getMainGreen()}
      onPress={redirect}
      disabled={currentlySending}
    />
  );
};

LoginCAS.propTypes = {
  method: PropTypes.object.isRequired,
  currentlySending: PropTypes.bool.isRequired,

  // Actions
  requestLoginCas: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  currentlySending: appSelectors.makeSelectCurrentlySending([REQUEST_LOGIN_CAS]),
});

const mapDispatchToProps = (dispatch) => {
  return {
    requestLoginCas: (ticket) => dispatch(userActions.requestLoginCas(ticket)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginCAS);
