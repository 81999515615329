import { call, put, takeLatest } from 'redux-saga/effects';
import errorActions from '../actions/error';
import appActions, { Types as appTypes } from '../actions/app';
import { submitRequest } from './user';
import api from '../api/_index';

const { REQUEST_BACKEND_API_VERSION } = appTypes;

function* requestBackendApiVersion() {
  try {
    yield put(appActions.addCurrentlySending(`${REQUEST_BACKEND_API_VERSION}`));
    const apiVersion = yield call(submitRequest, api.app.requestApiVersion);
    yield put(appActions.setBackendApiVersion(apiVersion?.version));
  } catch (error) {
    yield put(errorActions.handleError(error));
  } finally {
    yield put(appActions.removeCurrentlySending(`${REQUEST_BACKEND_API_VERSION}`));
  }
}

function* watcherRequestBackendApiVersion() {
  yield takeLatest(REQUEST_BACKEND_API_VERSION, requestBackendApiVersion);
}

export default [watcherRequestBackendApiVersion()];
