export const START_TIME_STEP = 15;

export const LIST_LOAD_LIMIT = 20;

export const STATE = {
  UNDEFINED: -1,
  COMING: 0,
  IN_PROGRESS: 1,
  OVER: 2,
};

export const VIDEO_STATE = {
  INACTIVE: 'inactive',
  STARTING: 'starting',
  ACTIVE: 'active',
  STOPPING: 'stopping',
  STOPPED: 'stopped',
};

export const ACTIVITY_COUNTDOWN = {
  FIVE_MINUTES: 'activity_countdown_five_minutes',
  ONE_MINUTE: 'activity_countdown_one_minute',
  TEN_SECONDS: 'activity_countdown_ten_seconds',
};

export default {
  START_TIME_STEP,
  LIST_LOAD_LIMIT,
  STATE,
};
