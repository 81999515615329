import PropTypes from 'prop-types';
import React, { useImperativeHandle, useRef } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { connect } from 'react-redux';
import colors from '../../constants/colors';
import fonts from '../../constants/fonts';
import ElementInOverlay from './elementInOverlay';
import ElementOnHover from './elementOnHover';

const styles = StyleSheet.create({
  messageHoveredContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 10,
    backgroundColor: colors.getBlueCornflower(),
    padding: 7,
    borderRadius: 5,
    shadowRadius: 2,
    shadowColor: colors.getMainBlack(0.5),
    overflow: 'visible', // marker
    marginBottom: 10,
    left: '50%',
    bottom: '100%',
    transform: 'translate(-50%, 5px)',
  },
  messageHoveredMarker: {
    position: 'absolute',
    backgroundColor: colors.getBlueCornflower(),
    width: 8,
    height: 8,
    left: '50%',
    bottom: -4,
    transform: 'translate(-5px, 0px) rotate(45deg)',
  },
  messageHoveredText: {
    fontFamily: fonts.getSignika(),
    fontSize: 16,
    color: colors.getMainWhite(),
  },
});

const MessageOnHover = React.forwardRef(
  (
    {
      icon,
      message,
      targetRef,
      hoverOnParentLevel,
      delay,
      enable,
      enableClick,
      positionFixed,
      className,
      style,
    },
    forwardRef,
  ) => {
    const elementOnHoverRef = useRef();

    const forwardedRef = {
      enable: (v) => elementOnHoverRef.current?.enable(v),
    };
    useImperativeHandle(forwardRef, () => forwardedRef);

    return (
      <ElementInOverlay>
        <ElementOnHover
          ref={elementOnHoverRef}
          targetRef={targetRef}
          hoverOnParentLevel={hoverOnParentLevel}
          delay={delay}
          enable={enable}
          enableClick={enableClick}
          positionFixed={positionFixed}
          className={className}
          style={style}
        >
          <View style={styles.messageHoveredContainer}>
            {icon ?? null}
            <Text style={styles.messageHoveredText}>{message}</Text>
            <View style={styles.messageHoveredMarker} />
          </View>
        </ElementOnHover>
      </ElementInOverlay>
    );
  },
);

MessageOnHover.displayName = 'MessageOnHover';

MessageOnHover.propTypes = {
  icon: PropTypes.any,
  message: PropTypes.string,
  targetRef: PropTypes.any,
  positionFixed: PropTypes.any,
  hoverOnParentLevel: PropTypes.number,
  enable: PropTypes.bool,
  enableClick: PropTypes.bool,
  delay: PropTypes.number,
  className: PropTypes.string,
  style: PropTypes.any,
};

MessageOnHover.defaultProps = {};

export default connect(null, null)(MessageOnHover);
