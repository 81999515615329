import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import colors from '../../constants/colors';
import fonts from '../../constants/fonts';

import userActions from '../../App/actions/user';

import MicrosoftLogo from '../../assets/logo/microsoft-logo.svg';
import { Strings } from '../../helpers/strings';

const styles = StyleSheet.create({
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 15,
  },
  button: {
    border: '1px solid #8C8C8C',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 12,
    paddingTop: 5,
    paddingBottom: 5,
  },
  font: {
    fontFamily: fonts.getSignika(),
    fontWeight: 600,
    color: '#5E5E5E',
  },
  error: {
    marginTop: 5,
    color: colors.getRed(),
  },
});

const LoginMicrosoft = ({ error, requestLoginAzureAd }) => {
  const { t } = useTranslation();

  return (
    <View style={styles.buttonContainer}>
      <TouchableOpacity onPress={requestLoginAzureAd} style={styles.button}>
        <MicrosoftLogo style={{ height: 20 }} />
        <Text style={styles.font}>{t(Strings.SIGN_IN_WITH)} Microsoft</Text>
      </TouchableOpacity>
      {error && <Text style={styles.error}>{error}</Text>}
    </View>
  );
};

LoginMicrosoft.propTypes = {
  error: PropTypes.string,

  // Requests :
  requestLoginAzureAd: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return {
    requestLoginAzureAd: () => dispatch(userActions.requestLoginAzureAd()),
  };
};

export default connect(null, mapDispatchToProps)(LoginMicrosoft);
