import { call, put, takeLatest } from 'redux-saga/effects';

import api from '../api/_index';
import errorActions from '../actions/error';
import appActions from '../actions/app';
import activityActions, { Types as activityTypes } from '../actions/activity';
import roomActions from '../actions/room';
import { navigate } from '../../helpers/navigation';
import { screenName } from '../../constants/navigation';
import { PUBLISH_TARGET_MODEL } from '../../constants/global';
import { getAuthType } from '../../helpers/auth';
import { submitRequest } from './user';

const {
  REQUEST_SCHEDULE_ACTIVITY,
  REQUEST_UPDATE_ACTIVITY,
  REQUEST_AVAILABLE_ROOMS,
  REQUEST_ACTIVITY,
  REQUEST_DELETE_ACTIVITY,
  REQUEST_ACTIVITY_FROM_SHORT_ID,
  REQUEST_ROOM_LIVE_PROFILES,
} = activityTypes;

/**
 * TODO: In the future. Improvement
 * Should be done by socket.io, to avoid conflict if two user are on the same datetimes + room
 * Edit button
 * OnEdit remove _room from activity database, redirect to /activity/schedule?edit=${activity._id} + PATCH
 *
 */

function* requestScheduleActivity({ formData, externalId, isFromKapture }) {
  try {
    const authType = yield call(getAuthType);
    yield put(appActions.addCurrentlySending(`${REQUEST_SCHEDULE_ACTIVITY}`));
    const body = formData;
    if (externalId !== undefined) body.externalId = externalId;
    const activity = yield call(submitRequest, api.activity.requestScheduleActivity, body);

    yield put(activityActions.setActivityInfo(activity));
    yield put(activityActions.setActivityDevices([])); // Devices are requested if going on activity page

    // If not from Kapture, using calendar display
    if (isFromKapture) navigate(screenName.ACTIVITY, { id: activity._id });
    else navigate(screenName.CALENDAR, { section_name: 'activities' });
  } catch (error) {
    yield put(errorActions.handleError(error));
  } finally {
    yield put(appActions.removeCurrentlySending(`${REQUEST_SCHEDULE_ACTIVITY}`));
  }
}

function* requestUpdateActivity({ _id, formData, isFromKapture }) {
  try {
    const authType = yield call(getAuthType);

    yield put(appActions.addCurrentlySending(`${REQUEST_UPDATE_ACTIVITY}`));
    const body = formData;

    const activity = yield call(submitRequest, api.activity.requestUpdateActivity, _id, body);
    const devices = activity.devices || [];

    const { room, liveStatus, recordStatus } = activity;
    delete activity?.devices;
    delete activity?.liveStatus;
    delete activity?.recordStatus;

    yield put(activityActions.setActivityInfo(activity));
    yield put(activityActions.setActivityState({ liveStatus, recordStatus }));
    yield put(activityActions.setActivityDevices(devices));
    if (room) {
      yield put(roomActions.setRoom(room));
    }

    // If not from Kapture, using calendar display
    if (isFromKapture) navigate(screenName.ACTIVITY, { id: activity._id });
    else navigate(screenName.CALENDAR, { section_name: 'activities' });
  } catch (error) {
    yield put(errorActions.handleError(error));
  } finally {
    yield put(appActions.removeCurrentlySending(`${REQUEST_UPDATE_ACTIVITY}`));
  }
}

function* requestActivityFromShortId({ shortId }) {
  try {
    yield put(appActions.addCurrentlySending(`${REQUEST_ACTIVITY_FROM_SHORT_ID}`));

    const activity = yield call(submitRequest, api.activity.requestActivityByShortId, shortId);

    if (activity) {
      yield put(activityActions.setActivityInfo(activity));
    }
  } catch (error) {
    yield put(errorActions.handleError(error));
  } finally {
    yield put(appActions.removeCurrentlySending(`${REQUEST_ACTIVITY_FROM_SHORT_ID}`));
  }
}

function* requestAvailableRooms({ datetimes, ignoredActivities }) {
  try {
    yield put(appActions.addCurrentlySending(`${REQUEST_AVAILABLE_ROOMS}`));

    const params = {
      startDatetime: datetimes[0],
      endDatetime: datetimes[1],
    };

    if (ignoredActivities && ignoredActivities.length > 0) {
      params.ignoredActivities = ignoredActivities;
    }

    const rooms = yield call(submitRequest, api.activity.requestAvailableRooms, params);

    yield put(activityActions.setAvailableRooms(rooms));
  } catch (error) {
    yield put(errorActions.handleError(error));
  } finally {
    yield put(appActions.removeCurrentlySending(`${REQUEST_AVAILABLE_ROOMS}`));
  }
}

function* requestActivity({ _id }) {
  try {
    yield put(appActions.addCurrentlySending(`${REQUEST_ACTIVITY}`));

    const activity = yield call(submitRequest, api.activity.requestActivity, _id);

    const devices = activity.devices || [];

    const { room, liveStatus, recordStatus } = activity;
    delete activity?.devices;
    delete activity?.liveStatus;
    delete activity?.recordStatus;

    yield put(activityActions.setActivityInfo(activity));
    yield put(activityActions.setActivityState({ liveStatus, recordStatus }));
    yield put(activityActions.setActivityDevices(devices));
    if (room) {
      yield put(roomActions.setRoom(room));
    }
  } catch (error) {
    yield put(errorActions.handleError(error));
  } finally {
    yield put(appActions.removeCurrentlySending(`${REQUEST_ACTIVITY}`));
  }
}

function* requestDeleteActivity({ _id }) {
  try {
    yield put(appActions.addCurrentlySending(`${REQUEST_DELETE_ACTIVITY}`));

    yield call(submitRequest, api.activity.requestDeleteActivity, _id);
  } catch (error) {
    yield put(errorActions.handleError(error));
  } finally {
    yield put(appActions.removeCurrentlySending(`${REQUEST_DELETE_ACTIVITY}`));
  }
}

function* requestRoomLiveProfiles({ _room }) {
  try {
    yield put(appActions.addCurrentlySending(`${REQUEST_ROOM_LIVE_PROFILES}`));

    const liveProfiles = yield call(
      submitRequest,
      api.publishprofile.requestPublishProfiles,
      PUBLISH_TARGET_MODEL.ROOM,
      _room,
    );

    yield put(activityActions.setRoomLiveProfiles(liveProfiles));
  } catch (error) {
    yield put(errorActions.handleError(error));
  } finally {
    yield put(appActions.removeCurrentlySending(`${REQUEST_ROOM_LIVE_PROFILES}`));
  }
}

function* watcherRequestScheduleActivity() {
  yield takeLatest(REQUEST_SCHEDULE_ACTIVITY, requestScheduleActivity);
}

function* watcherRequestUpdateActivity() {
  yield takeLatest(REQUEST_UPDATE_ACTIVITY, requestUpdateActivity);
}

function* watcherRequestRedirectToActivity() {
  yield takeLatest(REQUEST_ACTIVITY_FROM_SHORT_ID, requestActivityFromShortId);
}

function* watcherRequestAvailableRooms() {
  yield takeLatest(REQUEST_AVAILABLE_ROOMS, requestAvailableRooms);
}

function* watcherRequestActivity() {
  yield takeLatest(REQUEST_ACTIVITY, requestActivity);
}

function* watcherRequestDeleteActivity() {
  yield takeLatest(REQUEST_DELETE_ACTIVITY, requestDeleteActivity);
}

function* watcherRequestRoomLiveProfiles() {
  yield takeLatest(REQUEST_ROOM_LIVE_PROFILES, requestRoomLiveProfiles);
}

export default [
  watcherRequestScheduleActivity(),
  watcherRequestUpdateActivity(),
  watcherRequestRedirectToActivity(),
  watcherRequestAvailableRooms(),
  watcherRequestActivity(),
  watcherRequestDeleteActivity(),
  watcherRequestRoomLiveProfiles(),
];
