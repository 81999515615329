import React, { useEffect, useState } from 'react';
import { StyleSheet, View, Text } from 'react-native';
import PropTypes from 'prop-types';
import moment from 'moment';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import userSelectors from '../../App/selectors/user';
import colors from '../../constants/colors';
import UserAvatar from '../User/avatar';
import MessageReaction from './messageReaction';
import { userRoleIsGTE } from '../../helpers/role';
import { USER_ROLE } from '../../constants/global';
import { useOuterClick } from '../../hooks/click';

const styles = StyleSheet.create({
  textMessage: {
    color: 'white',
    fontFamily: 'Signika',
  },
  textDatetime: {
    fontSize: 10,
    fontWeight: '300',
    fontStyle: 'normal',
    letterSpacing: 0.2,
    color: colors.getGraySantas(),
    marginLeft: 15,
  },
  textAuthor: {
    color: colors.getGraySantas(),
    marginLeft: 10,
    fontSize: 12,
    fontWeight: '500',
    fontStyle: 'normal',
    letterSpacing: 0.2,
  },
  message: {
    borderRadius: 13,
    padding: 20,
    fontSize: 16,
    fontWeight: '300',
  },
  reactions: {
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
});

const REFRESH_MESSAGE_SINCE_LABEL_INTERVAL = 30000; // 30 sec

const Message = ({ message, userId, style }) => {
  const { id, content, datetime, author, reactions } = message;
  const [showAllReactions, setShowAllReactions] = useState(false);
  const [detailMessageSince, setDetailMessageSince] = useState(moment(datetime).fromNow());
  const outsideClickTargetRef = useOuterClick((e) => {
    setShowAllReactions(false);
  });

  // if id is undefined, message is setted localy and waiting the response (confirmation) of server.
  const messageSending = !id;
  const me = author?.id === userId;

  useEffect(() => {
    const interval = setInterval(() => {
      setDetailMessageSince(moment(datetime).fromNow());
    }, REFRESH_MESSAGE_SINCE_LABEL_INTERVAL);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const switchShowAllReactionState = () => {
    if (!userId) return;
    setShowAllReactions(!showAllReactions);
  };

  const getBackgroundColor = () => {
    let backgroundColor = colors.getClayEbony();
    if (me) {
      backgroundColor = colors.getMainPurple(messageSending ? 0.7 : 1);
    } else if (userRoleIsGTE(author?.role, USER_ROLE.ORGANIZER)) {
      backgroundColor = colors.getSalem();
    }
    return backgroundColor;
  };

  const backgroundColor = getBackgroundColor();
  const renderMessage = () => {
    return (
      <View style={[styles.message, { backgroundColor }]}>
        <Text style={styles.textMessage}>{content}</Text>
      </View>
    );
  };

  const renderDateTime = () => {
    return (
      <View style={{ marginTop: 5 }}>
        <Text style={styles.textDatetime}>{detailMessageSince}</Text>
      </View>
    );
  };

  const renderMeContent = () => {
    return (
      <View>
        {renderMessage()}
        {renderDateTime()}
      </View>
    );
  };
  const renderUserContent = () => {
    return (
      <View>
        <UserAvatar user={author} style={{ width: 50 }} />
        <View style={{ marginLeft: 60, marginTop: -50 }}>
          <Text style={styles.textAuthor}>{author?.username}</Text>
          {renderMessage()}
          {renderDateTime()}
        </View>
      </View>
    );
  };

  const viewStyle = style ? [styles.global, style] : styles.global;
  return (
    <View
      ref={outsideClickTargetRef}
      style={viewStyle}
      onClick={() => switchShowAllReactionState()}
    >
      {me ? renderMeContent() : renderUserContent()}
      <MessageReaction
        backgroundColor={backgroundColor}
        message={message}
        showAll={showAllReactions}
        style={styles.reactions}
        onReactionClicked={() => setShowAllReactions(false)}
      />
    </View>
  );
};

Message.propTypes = {
  message: PropTypes.object.isRequired,
  userId: PropTypes.string,
  style: PropTypes.number,
};

Message.defaultProps = {};

const mapStateToProps = createStructuredSelector({
  userId: userSelectors.makeSelectUserId(),
});

export default connect(mapStateToProps, null)(Message);
