import { createSelector } from 'reselect';

const roomSelector = (globalState) => globalState.get('room');
const messagesSelector = (state) => roomSelector(state).get('messages');
const usersSelector = (state) => roomSelector(state).get('users');
const displayRightMenuSelector = (state) => roomSelector(state).get('displayRightMenu');
const roomInfoSelector = (state) =>
  roomSelector(state).filterNot((val, key) =>
    ['messages', 'users', 'displayRightMenu'].includes(key),
  );

const counterUnreadMessageSelector = (state) => roomSelector(state).get('counterUnreadMessages');

// Selectors
const makeSelectRoom = () => createSelector(roomInfoSelector, (state) => state.toJS());

const makeSelectMessages = () => createSelector(messagesSelector, (state) => state.toJS());

const makeSelectConnectedUsers = () => createSelector(usersSelector, (state) => state.toJS());

const makeSelectDisplayRightMenu = () => createSelector(displayRightMenuSelector, (state) => state);

const makeSelectCounterUnreadMessage = () =>
  createSelector(counterUnreadMessageSelector, (state) => state);

const makeSelectUserConnected = () =>
  createSelector(
    usersSelector,
    (_, userId) => userId,
    (users, userId) => !!users.find((u) => u.get('id') === userId),
  );

const makeSelectMessage = () =>
  createSelector(
    messagesSelector,
    (_, message) => message,
    (messages, message) => {
      const result = message
        ? messages.find((m) => {
            return message.id
              ? m.get('id') === message.id
              : m.get('correlationId') === message.correlationId;
          })
        : null;
      return result?.toJS();
    },
  );

export default {
  makeSelectUserConnected,
  makeSelectRoom,
  makeSelectMessages,
  makeSelectConnectedUsers,
  makeSelectDisplayRightMenu,
  makeSelectMessage,
  makeSelectCounterUnreadMessage,
};
