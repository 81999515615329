import { fromJS } from 'immutable';
import { createReducer } from 'reduxsauce';

import { Types } from '../actions/vod';

const initialState = fromJS({
  vodInfo: {},
});

export const setVodInfo = (state = initialState, { vod }) => state.set('vodInfo', fromJS(vod));

export default createReducer(initialState, {
  [Types.SET_VOD_INFO]: setVodInfo,
});
