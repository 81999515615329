import { StyleSheet, View, TouchableOpacity } from 'react-native';
import { FlashList } from '@shopify/flash-list';
import React, { memo, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

import { ActivityIndicator } from 'react-native-web';
import roomSelectors from '../../App/selectors/room';
import userSelectors from '../../App/selectors/user';
import Message from '../../components/Chat/message';
import colors from '../../constants/colors';

import IconChevronDown from '../../assets/activities/icon-chevron-down-outline.svg';

const styles = StyleSheet.create({
  container: {
    flex: 8,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 15,
  },
  toEndButtonContainer: {
    position: 'absolute',
    bottom: 20,
    left: 20,
    zIndex: 1,
  },
  toEndButton: {
    backgroundColor: colors.getPurpleHeart(),
    borderRadius: '50%',
    opacity: 0.7,
    width: 35,
    height: 35,
    padding: 5,
  },
  containerMessageMe: {
    position: 'relative',
    marginBottom: 25,
    width: '80%',
    alignSelf: 'flex-end',
    marginRight: 10,
  },
  containerMessageUser: {
    position: 'relative',
    marginBottom: 25,
    width: '80%',
    alignSelf: 'flex-start',
  },
  flatList: {
    width: '100%',
    flexDirection: 'column-reverse',
  },
  flatListContainer: {
    paddingRight: 5, // add space to scrollbar
  },
});

const getMessageUniqueKey = (msg) => {
  return msg.id || msg.correlationId;
};

const shouldUpdateMessage = (prev, next) => {
  return prev.message.id === next.message.id;
};

const MessageMemo = memo(Message, shouldUpdateMessage);

const Messages = ({ messages, userId, onScrollToEnd }) => {
  const createMessage = (message) => {
    const me = message?.author?.id === userId;
    return (
      <MessageMemo
        key={getMessageUniqueKey(message)}
        message={message}
        style={me ? styles.containerMessageMe : styles.containerMessageUser}
      />
    );
  };

  const data = [...messages].reverse();

  const listRef = useRef();

  const [chatReady, setChatReady] = useState(false);
  const chatSetupTimeoutRef = useRef();

  const [lastId, setLastId] = useState(undefined);
  const [isEnd, setIsEnd] = useState(true);

  const goToEnd = (animated = false) => {
    if (messages && messages.length > 0) {
      const lastIndex = messages?.length ? messages.length - 1 : 0;
      return listRef.current?.scrollToIndex({ animated, index: lastIndex });
    }
    return null;
  };

  useEffect(() => {
    if (!messages || messages.length === 0) return;
    const lastMessage = messages[0];
    const messageIsSending = lastMessage.id === undefined;
    if (messageIsSending || lastMessage.id !== lastId) {
      if (isEnd) {
        goToEnd(); // We are at the end, so we automatically follow the discussion
        return;
      }
      if (lastMessage.author?.id === userId) {
        goToEnd(); // it's our message so we automatically scroll
      }
    }
    setLastId(lastMessage.id);
  }, [messages]);

  /**
   * If we don't add a delay, the list components don't have time to
   * fully render and we scroll to the end without knowing the exact list length,
   * so we end up few items before the end, depending on the device performance.
   * (1 half item above the end on computer, 3 items on an iPad when tested)
   *
   * If you know a way to get a callback indicating when all the list components were fully
   * rendered, please fix this. We tried "onLayout" on the FlashList but it did not do the trick,
   * as we more specifically need the signal of each child...
   *
   * If you don't want the chat to scroll fully on first opening, you can uncomment
   * the "initialScrollIndex" below. This way the chat scroll will be initially closer
   * to the end, but not totally the end, for the reason explained above. It was judged
   * that it looks better scrolling the whole chat than just scrolling few items
   * which makes it look like a glitch... So it was commented.
   */
  // useEffect(() => {
  //   if (!messages || messages.length === 0) return;
  //   setTimeout(() => {
  //     goToEnd(false); // no animation so it's faster
  //     setChatReady(true);
  //   }, 1000);
  // }, []);

  /**
   * Solution to problem cited above :
   *
   * We use the 'onContentSizeChange' callback,
   * we give the messages some time to render, the FlatList will
   * call onContentSizeChange couple of times depending on the number of messages,
   * as they are being rendered progressively.
   *
   * When we don't receive any size change for 200ms, we consider that the chat must be
   * fully rendered, we scroll to the end and we remove the loading overlay.
   */
  const chatSetup = () => {
    goToEnd(false); // no animation so it's faster
    setChatReady(true);
  };

  const onScroll = (event) => {
    const yOffset = event.nativeEvent?.contentOffset?.y;
    if (yOffset === undefined) return;

    const contenthHeight = event.nativeEvent?.contentSize?.height;
    if (contenthHeight === undefined) return;
    const layoutHeight = event.nativeEvent?.layoutMeasurement?.height;
    if (layoutHeight === undefined) return;
    const scrollableHeight = contenthHeight - layoutHeight;

    setIsEnd(yOffset >= scrollableHeight);
    // >= and not just == because on IOS you can scroll out of boundaries
  };

  const onContentSizeChange = () => {
    if (chatReady) {
      return;
    }
    if (chatSetupTimeoutRef.current) {
      clearTimeout(chatSetupTimeoutRef.current);
    }
    const timeout = setTimeout(() => {
      chatSetup();
    }, 200);
    chatSetupTimeoutRef.current = timeout;
  };

  const handleToEnd = () => {
    if (onScrollToEnd) onScrollToEnd();
    goToEnd(true);
  };

  const renderToEndButton = () => {
    return (
      <View style={styles.toEndButtonContainer}>
        <TouchableOpacity style={styles.toEndButton} onPressOut={handleToEnd}>
          <IconChevronDown />
        </TouchableOpacity>
      </View>
    );
  };

  const renderLoadingOverlay = () => {
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          backgroundColor: colors.getMirage(),
          color: 'white',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            gap: 10,
            alignItems: 'center',
            fontSize: 20,
          }}
        >
          <ActivityIndicator />
        </div>
      </div>
    );
  };

  const estimatedItemSize = 120;
  return (
    <View style={styles.container}>
      {isEnd || renderToEndButton()}
      <div style={{ width: '100%', height: '100%', display: 'flex', position: 'relative' }}>
        <FlashList
          listView
          onScroll={onScroll}
          ref={listRef}
          data={data}
          estimatedItemSize={estimatedItemSize}
          contentContainerStyle={styles.flatListContainer}
          renderItem={({ item }) => createMessage(item)}
          keyExtractor={(item) => getMessageUniqueKey(item)}
          onContentSizeChange={onContentSizeChange}
          // initialScrollIndex={messages.length - 1}
        />
        {chatReady || (
          <div style={{ position: 'absolute', left: 0, top: 0, width: '100%', height: '100%' }}>
            {renderLoadingOverlay()}
          </div>
        )}
      </div>
    </View>
  );
};

Messages.propTypes = {
  userId: PropTypes.string,
  messages: PropTypes.array.isRequired,
  onScrollToEnd: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  userId: userSelectors.makeSelectUserId(),
  messages: roomSelectors.makeSelectMessages(),
});

export default connect(mapStateToProps, null)(Messages);
