import { useEffect } from 'react';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import activityActions from '../../App/actions/activity';
import activitySelectors from '../../App/selectors/activity';

import { reset } from '../../helpers/navigation';
import { screenName } from '../../constants/navigation';

const ActivityRedirectionPage = ({ activityInfo, route, requestActivityFromShortId }) => {
  useEffect(() => {
    if (route?.params?.short_id) {
      requestActivityFromShortId(route.params.short_id);
    }
  }, []);
  useEffect(() => {
    if (activityInfo) {
      reset(screenName.ACTIVITY, { id: activityInfo._id });
    }
  }, [activityInfo]);
  return null;
};

ActivityRedirectionPage.propTypes = {
  activityInfo: PropTypes.object,
  route: PropTypes.object.isRequired,
  // Actions
  requestActivityFromShortId: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  activityInfo: activitySelectors.makeSelectActivityInfo(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    requestActivityFromShortId: (shortId) =>
      dispatch(activityActions.requestActivityFromShortId(shortId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivityRedirectionPage);
