import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { View, StyleSheet, Text, useWindowDimensions } from 'react-native';
import { useForm } from 'react-hook-form';
import { ScrollView, TouchableOpacity } from 'react-native-web';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import activityActions from '../../App/actions/activity';

import colors from '../../constants/colors';
import fonts from '../../constants/fonts';
import { useActivityStartTime } from '../../hooks/activity';
import ScheduleForm from '../../components/ScheduleForm';
import { Strings } from '../../helpers/strings';

const styles = StyleSheet.create({
  formContainer: {
    backgroundColor: colors.getGrayAthens(),
    padding: 20,
    borderRadius: 20,
  },
  saveButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 20,
    borderRadius: 10,
    backgroundColor: colors.getMainPurple(),
  },
  saveButtonText: {
    fontFamily: fonts.getSignika(),
    fontSize: 22,
    fontWeight: 600,
    color: colors.getMainWhite(),
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
});

const MobileWidth = 800;

const ActivityCreate = ({ requestScheduleActivity }) => {
  const now = useActivityStartTime();
  const { t } = useTranslation();

  const formProps = useForm({
    defaultValues: {
      name: undefined,
      _liveProfile: undefined,
      _uploadProfile: undefined,
      _room: undefined,
      date: now,
      startDatetime: now,
      endDatetime: now,
      isPrivate: true,
      shouldLive: true,
      shouldRecord: false,
      interactionsAreEnabled: false,
    },
  });
  const { width } = useWindowDimensions();

  const submit = (data) => {
    requestScheduleActivity({
      name: data.name,
      _liveProfile: data._liveProfile,
      _uploadProfile: data._uploadProfile,
      _room: data._room,
      startDatetime: moment(data.startDatetime).toISOString(),
      endDatetime: moment(data.endDatetime).toISOString(),
      isPrivate: data.isPrivate,
      shouldLive: data.shouldLive,
      shouldRecord: data.shouldRecord,
      interactionsAreEnabled: data.interactionsAreEnabled,
    });
  };

  const buttonWidth = { width: width <= MobileWidth ? '50%' : 384 };

  return (
    <ScrollView style={{ height: 0 }}>
      <View style={styles.formContainer}>
        <ScheduleForm formProps={{ ...formProps }} now={now} isMobile={width <= MobileWidth} />
      </View>
      <View style={[styles.row, { marginTop: 31, zIndex: -1 }]}>
        <TouchableOpacity
          style={[styles.saveButtonContainer, buttonWidth]}
          onPress={formProps.handleSubmit(submit)}
        >
          <Text style={styles.saveButtonText}>{t(Strings.SAVE)}</Text>
        </TouchableOpacity>
      </View>
    </ScrollView>
  );
};

ActivityCreate.propTypes = {
  // Action
  requestScheduleActivity: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return {
    requestScheduleActivity: (formData) =>
      dispatch(activityActions.requestScheduleActivity(formData)),
  };
};

export default connect(null, mapDispatchToProps)(ActivityCreate);
