import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  // Request API
  requestUpdateDevice: ['_id', 'body'],

  // Request Socket
  move: ['deviceId', 'direction'],
  stopMove: ['deviceId'],
  zoom: ['deviceId', 'direction'],
  stopZoom: ['deviceId'],
  switchScene: ['deviceId', 'sceneId'],
  switchView: ['deviceId', 'viewId'],
  setView: ['deviceId', 'viewId'],

  // Set reducers
  setDevice: ['device'],
});

export { Types };
export default Creators;
