import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  // Request API
  requestScheduleActivity: ['formData', 'externalId', 'isFromKapture'],
  requestUpdateActivity: ['_id', 'formData', 'isFromKapture'],
  requestActivityFromShortId: ['shortId'],
  requestAvailableRooms: ['datetimes', 'ignoredActivities'],
  requestActivity: ['_id'],
  requestDeleteActivity: ['_id'],
  requestRoomLiveProfiles: ['_room'],

  // Request Socket
  socketJoinActivity: ['activityId'],
  socketLeaveActivity: ['activityId'],
  socketStartPublish: ['deviceId', 'activity'],
  socketStopPublish: ['deviceId', 'activity'],
  socketEnableInteractions: ['activityId', 'enable'],
  socketRequestInteraction: ['activityId', 'interactionType', 'message'],
  socketAcceptRequestInteraction: ['activityId', 'interactionId', 'message'],
  socketDeclineRequestInteraction: ['activityId', 'interactionId', 'message'],
  socketJoinInteraction: ['activityId', 'interactionId', 'deviceId', 'message'],
  socketStopInteraction: ['activityId', 'interactionId', 'message'],
  socketCancelInteraction: ['activityId', 'interactionId', 'message'],

  // Events
  onInteractionEvent: ['activityId', 'interaction', 'event'],
  onMediaRequestInteraction: ['activityId', 'interaction', 'data'],
  onActivityState: ['activityId', 'state'],

  // Client Side Events
  playerMessageEvent: ['messageType'],

  // Set reducers
  setAvailableRooms: ['rooms'],
  setActivityInfo: ['activityInfo'],
  setActivityState: ['activityState'],
  setRoomLiveProfiles: ['roomLiveProfiles'],
  setActivityDevices: ['devices'],
  updateActivityDevice: ['device'],
  setActivityInteractions: ['interactions'],
  updateActivityInteraction: ['activityId', 'interaction'],
  enableActivityInteractions: ['activityId', 'enabled'],
  updateVodState: ['vodId', 'state'],

  resetActivity: [],
});

export { Types };

export default Creators;
