export const WEB_PLATFORM = 'web';

// Take care if you change the following, also change the hard coded string in scripts/start.js
export const ENV_LOCAL = 'local';
export const ENV_STAGING = 'staging';
export const ENV_PROD = 'prod';

const APP_DOMAIN = 'kast.app';
export const DOMAIN_BY_ENV = {
  [ENV_LOCAL]: `local.${APP_DOMAIN}`,
  [ENV_STAGING]: `staging.${APP_DOMAIN}`,
  [ENV_PROD]: `${APP_DOMAIN}`,
};

export const AUTH_TYPE = {
  LOCAL: 'local',
  LTI: 'lti',
  CAS: 'cas',
  GOOGLE: 'google',
  AZURE_AD: 'azure_ad',
};

/* export const USER_ROLE = {
  ADMIN: 'admin',
  TECHNICIAN: 'technician',
  PROFESSOR: 'professor',
  STUDENT: 'student',
  GUEST: 'guest',
}; */

export const USER_ROLE = {
  KADMIN: 'kadmin', // kalyzee admin
  ADMIN: 'admin',
  TECHNICIAN: 'technician',
  ORGANIZER: 'organizer',
  ATTENDEE: 'attendee',
  GUEST: 'guest',
};

export const USER_ROLE_TO_INT = {
  [USER_ROLE.KADMIN]: 0,
  [USER_ROLE.ADMIN]: 1,
  [USER_ROLE.TECHNICIAN]: 2,
  [USER_ROLE.ORGANIZER]: 3,
  [USER_ROLE.ATTENDEE]: 4,
  [USER_ROLE.GUEST]: 5,
};

export const PUBLISH_TARGET_MODEL = {
  USER: 'user',
  ROOM: 'room',
};

export const PUBLISH_TYPE = {
  LIVE: 'live',
  UPLOAD: 'upload',
};

export const RESPONSIVE_THRESHOLD_SIZE = {
  SMALL_WIDTH: 800,
  MEDIUM_WIDTH: 1200,
};

export const RESPONSIVE_THRESHOLD = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
};

export default {
  WEB_PLATFORM,
  AUTH_TYPE,
  ENV_LOCAL,
  ENV_STAGING,
  ENV_PROD,
  DOMAIN_BY_ENV,
  USER_ROLE,
  USER_ROLE_TO_INT,
  PUBLISH_TARGET_MODEL,
  PUBLISH_TYPE,
  RESPONSIVE_THRESHOLD_SIZE,
  RESPONSIVE_THRESHOLD,
};
