import { requestAPI } from '../../helpers/request';
import { getAuthHeader } from '../../helpers/auth';

const basePath = '/publishprofile';

const requestPublishProfiles = (targetModel, _target, type) => {
  let path = `${basePath}/${targetModel}/in/${_target}`;
  if (type) path = `${path}?type=${type}`;

  const authHeader = getAuthHeader(); // { Authorization: Bearer xyz }

  return requestAPI(path, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      ...authHeader,
    },
  })
    .then((response) => Promise.resolve(response))
    .catch((error) => {
      throw error;
    });
};

const requestPublishProfile = (targetModel, _id) => {
  const path = `${basePath}/${targetModel}/${_id}`;
  const authHeader = getAuthHeader(); // { Authorization: Bearer xyz }

  return requestAPI(path, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      ...authHeader,
    },
  })
    .then((response) => Promise.resolve(response))
    .catch((error) => {
      throw error;
    });
};

const requestCreatePublishProfile = (targetModel, publishProfile) => {
  const path = `${basePath}/${targetModel}`;
  const authHeader = getAuthHeader(); // { Authorization: Bearer xyz }

  return requestAPI(path, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...authHeader,
    },
    body: JSON.stringify(publishProfile),
  })
    .then((response) => Promise.resolve(response))
    .catch((error) => {
      throw error;
    });
};

const requestUpdatePublishProfile = (targetModel, _id, publishProfile) => {
  const path = `${basePath}/${targetModel}/${_id}`;
  const authHeader = getAuthHeader(); // { Authorization: Bearer xyz }

  return requestAPI(path, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      ...authHeader,
    },
    body: JSON.stringify(publishProfile),
  })
    .then((response) => Promise.resolve(response))
    .catch((error) => {
      throw error;
    });
};

export default {
  requestPublishProfiles,
  requestPublishProfile,
  requestCreatePublishProfile,
  requestUpdatePublishProfile,
};
