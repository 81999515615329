import { useEffect } from 'react';
import { connect } from 'react-redux';
import 'react-native-gesture-handler';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';

import { isEmpty } from 'lodash';
import appActions, { Types as appTypes } from '../../App/actions/app';
import activitySelectors from '../../App/selectors/activity';
import userSelectors from '../../App/selectors/user';
import { useReduxAction } from '../../helpers/store';
import { usePrevious } from '../../hooks/utils';

const WithSocket = ({
  isAuthenticated,
  nickname,
  activityInfo,
  onSocketStatus,
  socketConnect,
  socketDisconnect,
}) => {
  const wasAuthenticated = usePrevious(isAuthenticated);
  if (onSocketStatus) {
    useReduxAction((action) => {
      onSocketStatus(action.status);
    }, appTypes.ON_SOCKET_STATUS);
  }

  useEffect(() => {
    let forceReconnect = false;
    if (!wasAuthenticated && isAuthenticated) {
      forceReconnect = true;
    }
    // !isEmpty(activityInfo) for guest in activity page
    if (isAuthenticated || !isEmpty(activityInfo)) {
      socketConnect(isAuthenticated, nickname, forceReconnect);
    } else {
      socketDisconnect();
    }
  }, [isAuthenticated, activityInfo]);

  return null;
};

WithSocket.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  nickname: PropTypes.string, // Is used for guest only
  onSocketStatus: PropTypes.func,
  activityInfo: PropTypes.object,

  // Actions
  socketConnect: PropTypes.func.isRequired,
  socketDisconnect: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  isAuthenticated: userSelectors.makeSelectIsAuthenticated(),
  activityInfo: activitySelectors.makeSelectActivityInfo(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    socketConnect: (isAuthenticated, nickname, forceReconnect) =>
      dispatch(appActions.socketConnect(isAuthenticated, nickname, forceReconnect)),
    socketDisconnect: () => dispatch(appActions.socketDisconnect()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WithSocket);
