import { FlashList } from '@shopify/flash-list';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  StyleSheet,
  Switch,
  Text,
  TouchableOpacity,
  useWindowDimensions,
  View,
} from 'react-native';
import { useTranslation } from 'react-i18next';
import IconSeparator from '../../assets/navigation/chevron.svg';
import colors from '../../constants/colors';
import fonts from '../../constants/fonts';
import {
  filterActivityInteractionsEnded,
  filterActivityInteractionsInRunning,
  filterActivityInteractionsInWaiting,
} from '../../helpers/interactions';
import LoadingContainer from '../Generic/loadingContainer';
import ActivityInteractionItem, { ActivityInteractionResponsiveMode } from './interactionItem';
import ElementInOverlay from '../Generic/elementInOverlay';
import ElementOnHover from '../Generic/elementOnHover';
import { Strings } from '../../helpers/strings';

const SPACE_BETWEEN_ITEMS = 15;

const styles = StyleSheet.create({
  support: {
    display: 'flex',
    height: 500,
    maxHeight: '90%',
    width: 800,
    maxWidth: '90%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
    overflow: 'hidden',
    shadowRadius: 20,
    shadowColor: colors.getMainWhite(0.03),
    backdropFilter: 'blur(5px)',
  },
  supportHeader: {
    height: 50,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.getMirage(0.98),
  },
  textHeader: {
    fontFamily: fonts.getSignika(),
    fontSize: 16,
    color: colors.getMainWhite(),
    fontWeight: 'bold',
  },
  raiseHandButtonHeader: {
    position: 'absolute',
    right: 20,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '100%',
  },
  supportFooter: {
    height: 50,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.getMirage(0.98),
  },
  buttonFooter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    textAlign: 'center',
    fontFamily: fonts.getSignika(),
    fontSize: 16,
    color: colors.getBlueCornflower(),
    fontWeight: 'bold',
  },
  supportContent: {
    flex: 1,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundColor: colors.getLightMirage(0.95),
  },
  supportItem: {
    width: '100%',
    paddingLeft: SPACE_BETWEEN_ITEMS,
    paddingRight: SPACE_BETWEEN_ITEMS,
    paddingBottom: SPACE_BETWEEN_ITEMS,
  },
  supportEnable: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingTop: 15,
    paddingLeft: 30,
    paddingRight: 20,
    paddingBottom: 20,
  },
  containerDescriptionEnable: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    gap: 10,
  },
  titleEnable: {
    fontWeight: 'bold',
    fontFamily: fonts.getSignika(),
    fontSize: 16,
    color: colors.getMainWhite(),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '100%',
  },
  descriptionEnable: {
    fontFamily: fonts.getSignika(),
    fontSize: 12,
    color: colors.getMainWhite(),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '100%',
  },
  containerSwitchEnable: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: 80,
  },
  messageHoveredContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 10,
    backgroundColor: colors.getBlueCornflower(),
    padding: 7,
    borderRadius: 5,
    shadowRadius: 2,
    shadowColor: colors.getMainBlack(0.5),
    overflow: 'visible', // marker
    maxWidth: '40vw',
    marginBottom: 10,
  },
  messageHoveredMarker: {
    position: 'absolute',
    backgroundColor: colors.getBlueCornflower(),
    width: 8,
    height: 8,
    left: '50%',
    bottom: -4,
    transform: 'translate(-5px, 0px) rotate(45deg)',
  },
  messageHoveredText: {
    fontFamily: fonts.getSignika(),
    fontSize: 16,
    color: colors.getMainWhite(),
  },
});

const stylesSmallMode = StyleSheet.create({});
const stylesXSmallMode = StyleSheet.create({});

const getResponsiveStyles = (width, height) => {
  let result;
  let mode = ActivityInteractionResponsiveMode.NORMAL;
  if (width < 400) {
    mode = ActivityInteractionResponsiveMode.XSMALL;
    result = stylesXSmallMode;
  } else if (width < 600) {
    mode = ActivityInteractionResponsiveMode.SMALL;
    result = stylesSmallMode;
  }
  return [
    mode,
    result,
    (name) => {
      return [styles[name], result?.[name]];
    },
  ];
};

const SEPARATORS = {
  IN_RUNNING: 'separator_in_running',
  IN_WAITING: 'separator_in_waiting',
  ENDED: 'separator_ended',
};

const ActivityInteractions = ({
  enabled,
  interactions,
  onEnable,
  onClose,
  onRequest,
  onJoin,
  onStop,
  onCancel,
  onRaiseHand,
  me,
}) => {
  const { t } = useTranslation();
  const { width, height } = useWindowDimensions();
  const [responsiveMode, , getStyles] = getResponsiveStyles(width, height);

  const [separatorsState, setSeparatorsState] = useState({
    [SEPARATORS.IN_RUNNING]: 'opened',
    [SEPARATORS.IN_WAITING]: 'opened',
    [SEPARATORS.ENDED]: 'closed',
  });

  const interactionsInRunning = filterActivityInteractionsInRunning(interactions);
  const interactionsInWaiting = filterActivityInteractionsInWaiting(interactions);
  const interactionsEnded = filterActivityInteractionsEnded(interactions);

  const interactionsInProcessingCount = interactionsInRunning.length + interactionsInWaiting.length;

  const data = {
    [SEPARATORS.IN_RUNNING]: interactionsInRunning,
    [SEPARATORS.IN_WAITING]: interactionsInWaiting,
    [SEPARATORS.ENDED]: interactionsEnded,
  };

  const refreshSeparatorStates = () => {
    let refreshState = false;
    const newSeparatorState = { ...separatorsState };
    if (!enabled) {
      if (
        !data[SEPARATORS.IN_RUNNING]?.length &&
        separatorsState[SEPARATORS.IN_RUNNING] === 'opened'
      ) {
        refreshState = true;
        newSeparatorState[SEPARATORS.IN_RUNNING] = 'closed';
      }
      if (
        !data[SEPARATORS.IN_WAITING]?.length &&
        separatorsState[SEPARATORS.IN_WAITING] === 'opened'
      ) {
        refreshState = true;
        newSeparatorState[SEPARATORS.IN_WAITING] = 'closed';
      }
      if (separatorsState[SEPARATORS.ENDED] === 'opened') {
        refreshState = true;
        newSeparatorState[SEPARATORS.ENDED] = 'closed';
      }
    }

    if (
      data[SEPARATORS.IN_RUNNING]?.length &&
      separatorsState[SEPARATORS.IN_RUNNING] === 'closed'
    ) {
      refreshState = true;
      newSeparatorState[SEPARATORS.IN_RUNNING] = 'opened';
    }
    if (
      data[SEPARATORS.IN_WAITING]?.length &&
      separatorsState[SEPARATORS.IN_WAITING] === 'closed'
    ) {
      refreshState = true;
      newSeparatorState[SEPARATORS.IN_WAITING] = 'opened';
    }
    if (refreshState) setSeparatorsState(newSeparatorState);
  };

  useEffect(() => {
    refreshSeparatorStates();
  }, [enabled, interactions]);

  const renderMessageHovered = (
    targetRef,
    icon,
    message,
    delay = undefined,
    enableClick = true,
  ) => (
    <ElementInOverlay>
      <ElementOnHover targetRef={targetRef} delay={delay} enableClick={enableClick}>
        <View style={getStyles('messageHoveredContainer')}>
          {icon}
          <Text style={getStyles('messageHoveredText')}>{message}</Text>
          <View style={getStyles('messageHoveredMarker')} />
        </View>
      </ElementOnHover>
    </ElementInOverlay>
  );

  const renderHeader = () => {
    let text =
      interactionsInProcessingCount > 1
        ? t(Strings.ACTIVITY_INTERACTION_HOST_CONTROLLER_TITLE_MANY_RAISE_HANDS, {
            count: interactionsInProcessingCount,
          })
        : t(Strings.ACTIVITY_INTERACTION_HOST_CONTROLLER_TITLE_RAISE_HAND, {
            count: interactionsInProcessingCount,
          });
    if (!enabled)
      text = `${text} ${t(Strings.ACTIVITY_INTERACTION_HOST_CONTROLLER_TITLE_DISABLED)}`;
    return (
      <View style={getStyles('supportHeader')}>
        <Text style={getStyles('textHeader')}>{text}</Text>
      </View>
    );
  };

  const renderFooter = () => (
    <View style={getStyles('supportFooter')}>
      <TouchableOpacity onPress={onClose} style={{ width: '100%', height: '100%' }}>
        <Text style={getStyles('buttonFooter')}>
          {t(Strings.ACTIVITY_INTERACTION_HOST_CONTROLLER_CLOSE_BUTTON)}
        </Text>
      </TouchableOpacity>
    </View>
  );
  const keyExtractorItem = (item) => {
    if (typeof item === 'string') return item;
    return item.id;
  };

  const renderSeparator = (separator, index) => {
    const titles = {
      [SEPARATORS.IN_RUNNING]: t(
        data[SEPARATORS.IN_RUNNING].length > 1
          ? Strings.ACTIVITY_INTERACTION_HOST_CONTROLLER_INTERACTIONS_ONGOING
          : Strings.ACTIVITY_INTERACTION_HOST_CONTROLLER_INTERACTION_ONGOING,
        { count: data[SEPARATORS.IN_RUNNING].length },
      ),
      [SEPARATORS.IN_WAITING]: t(
        data[SEPARATORS.IN_WAITING].length > 1
          ? Strings.ACTIVITY_INTERACTION_HOST_CONTROLLER_INTERACTIONS_PENDING
          : Strings.ACTIVITY_INTERACTION_HOST_CONTROLLER_INTERACTION_PENDING,
        { count: data[SEPARATORS.IN_WAITING].length },
      ),
      [SEPARATORS.ENDED]: t(
        data[SEPARATORS.ENDED].length > 1
          ? Strings.ACTIVITY_INTERACTION_HOST_CONTROLLER_INTERACTIONS_COMPLETED
          : Strings.ACTIVITY_INTERACTION_HOST_CONTROLLER_INTERACTION_COMPLETED,
      ),
    };
    const noDataMessage = {
      [SEPARATORS.IN_RUNNING]: t(
        Strings.ACTIVITY_INTERACTION_HOST_CONTROLLER_NO_INTERACTION_ONGOING,
      ),
      [SEPARATORS.IN_WAITING]: t(
        Strings.ACTIVITY_INTERACTION_HOST_CONTROLLER_NO_INTERACTION_PENDING,
      ),
      [SEPARATORS.ENDED]: undefined,
    };
    const opacity = {
      [SEPARATORS.IN_RUNNING]: enabled || data[SEPARATORS.IN_RUNNING].length ? 1 : 0.7,
      [SEPARATORS.IN_WAITING]: enabled || data[SEPARATORS.IN_WAITING].length ? 1 : 0.7,
      [SEPARATORS.ENDED]: enabled ? 1 : 0.7,
    };
    return (
      <View style={{ opacity: opacity[separator] }}>
        <TouchableOpacity
          onPress={() => {
            setSeparatorsState({
              ...separatorsState,
              [separator]: separatorsState[separator] === 'opened' ? 'closed' : 'opened',
            });
          }}
        >
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: 10,
              marginLeft: 10,
              marginRight: 10,
              marginBottom:
                separatorsState[separator] === 'opened' && data[separator].length ? 10 : 0,
            }}
          >
            <View
              style={{
                transform: `rotate(${separatorsState[separator] === 'closed' ? 0 : 180}deg)`,
              }}
            >
              <IconSeparator width="10px" fill={colors.getMainWhite()} />
            </View>
            <Text
              style={{
                fontFamily: fonts.getSignika(),
                fontSize: 16,
                color: colors.getMainWhite(),
              }}
            >
              {titles[separator]}
            </Text>
            <View
              style={{
                backgroundColor: colors.getMainWhite(0.05),
                flex: 1,
                height: 1,
              }}
            />
          </View>
        </TouchableOpacity>
        {noDataMessage[separator] &&
        !data[separator]?.length &&
        separatorsState[separator] === 'opened' ? (
          <Text
            style={{
              textAlign: 'center',
              fontFamily: fonts.getSignika(),
              fontSize: 16,
              color: colors.getMainWhite(0.8),
              marginTop: 10,
              marginBottom: 10,
            }}
          >
            {noDataMessage[separator]}
          </Text>
        ) : null}
      </View>
    );
  };

  const renderInteraction = (interaction, index) => {
    const supportItemStyles = [
      ...getStyles('supportItem'),
      { marginTop: index === 0 ? SPACE_BETWEEN_ITEMS : 0 },
    ];
    return (
      <View style={supportItemStyles}>
        <ActivityInteractionItem
          interaction={interaction}
          me={me}
          responsiveMode={responsiveMode}
          onClose={onClose}
          onRequest={onRequest}
          onJoin={onJoin}
          onStop={onStop}
          onCancel={onCancel}
        />
      </View>
    );
  };

  const renderContent = () => {
    const items = [null]; // first item marge
    items.push(SEPARATORS.IN_RUNNING);
    if (separatorsState[SEPARATORS.IN_RUNNING] === 'opened') {
      items.push(...interactionsInRunning);
    }
    items.push(SEPARATORS.IN_WAITING);
    if (separatorsState[SEPARATORS.IN_WAITING] === 'opened') {
      items.push(...interactionsInWaiting);
    }
    if (interactionsEnded.length) {
      items.push(SEPARATORS.ENDED);
      if (separatorsState[SEPARATORS.ENDED] === 'opened') {
        items.push(...interactionsEnded);
      }
    }

    const renderEnableInteractions = () => (
      <View style={getStyles('supportEnable')}>
        <View style={getStyles('containerDescriptionEnable')}>
          <Text numberOfLines={2} ellipsizeMode="tail" style={getStyles('titleEnable')}>
            {t(Strings.ACTIVITY_INTERACTION_HOST_CONTROLLER_ENABLE_DESCRIPTION_TITLE)}
          </Text>
          <Text numberOfLines={3} ellipsizeMode="tail" style={getStyles('descriptionEnable')}>
            {t(Strings.ACTIVITY_INTERACTION_HOST_CONTROLLER_ENABLE_DESCRIPTION_MESSAGE)}
          </Text>
        </View>
        <LoadingContainer style={getStyles('containerSwitchEnable')}>
          {(_, setLoading) => (
            <Switch
              trackColor={{ false: colors.getGrayDusty(0.7), true: colors.getMainGreen() }}
              thumbColor={colors.getGrayDusty()}
              ios_backgroundColor={colors.getMainWhite()}
              onValueChange={async () => {
                setLoading(true);
                await onEnable(!enabled);
                setLoading(false);
              }}
              value={enabled}
            />
          )}
        </LoadingContainer>
      </View>
    );

    const renderMargeFirstElement = () => <View style={{ width: '100%', height: 15 }} />;
    return (
      <View style={getStyles('supportContent')}>
        {renderEnableInteractions()}
        <div
          style={{ position: 'relative', flex: 1, width: '100%', minHeight: 0, display: 'flex' }}
        >
          <div
            style={{
              // Linder gradient to hide top of list (scrolling)
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '15px',
              background: `linear-gradient(180deg, ${colors.getMirage(0.7)}, ${colors.getMirage(
                0,
              )})`,
              zIndex: 1,
            }}
          />
          <FlashList
            listView
            estimatedItemSize={80}
            data={items}
            renderItem={({ item, index }) => {
              if (index === 0) return renderMargeFirstElement();
              if (typeof item === 'string') return renderSeparator(item, index);
              return renderInteraction(item, index);
            }}
            keyExtractor={(item, index) => {
              if (index === 0) return 'first_list_element';
              return keyExtractorItem(item);
            }}
          />
        </div>
      </View>
    );
  };
  return (
    <View style={getStyles('support')}>
      {renderHeader()}
      {renderContent()}
      {renderFooter()}
    </View>
  );
};

ActivityInteractions.propTypes = {
  enabled: PropTypes.bool.isRequired,
  interactions: PropTypes.array.isRequired,
  onEnable: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onRequest: PropTypes.func.isRequired,
  onJoin: PropTypes.func.isRequired,
  onStop: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onRaiseHand: PropTypes.func,
  me: PropTypes.object,
};

ActivityInteractions.defaultProps = {};

export default ActivityInteractions;
