import { Video } from '@kalyzee/kast-react-player-module';
import { Picker } from '@react-native-picker/picker';
import PropTypes from 'prop-types';
import React, { createRef, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import IconCameraDisable from '../../assets/blue/icon-camera-disable.svg';
import IconCameraEnable from '../../assets/blue/icon-camera-enable.svg';
import IconInfo from '../../assets/blue/icon-info.svg';
import IconScreenEnable from '../../assets/blue/icon-screen-enable.svg';
import colors from '../../constants/colors';
import fonts from '../../constants/fonts';
import { displayMediaIsAvailable } from '../../helpers/media';
import { Strings } from '../../helpers/strings';
import ElementInOverlay from '../Generic/elementInOverlay';
import ElementOnHover from '../Generic/elementOnHover';
import MicrophoneDynamic from './microphone';
import {
  VideoCaptureElementType,
  VideoCaptureEvent,
  VideoCaptureScreenStatus,
} from './videoCapture.constant';

const styles = StyleSheet.create({
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 20,
  },
  button: {
    width: 40,
    height: 40,
    padding: 8,
    borderRadius: 10,
    overflow: 'hidden',
    shadowRadius: 20,
    shadowColor: colors.getMainBlack(0.3),
    backgroundColor: colors.getMirage(0.7),
  },
  buttonDisabled: {
    backgroundColor: colors.getTorchRed(0.7),
  },
  buttonError: {
    backgroundColor: colors.getTorchRed(0.7),
  },
  buttonProcessing: {
    backgroundColor: colors.getSunglow(0.7),
  },
  buttonSuccess: {
    backgroundColor: colors.getMainGreen(0.7),
  },
  messageHoveredContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 10,
    backgroundColor: colors.getBlueCornflower(),
    padding: 7,
    borderRadius: 5,
    shadowRadius: 2,
    shadowColor: colors.getMainBlack(0.5),
    overflow: 'visible', // marker
    marginBottom: 10,
  },
  messageHoveredMarker: {
    position: 'absolute',
    backgroundColor: colors.getBlueCornflower(),
    width: 8,
    height: 8,
    left: '50%',
    bottom: -4,
    transform: 'translate(-5px, 0px) rotate(45deg)',
  },
  messageHoveredText: {
    fontFamily: fonts.getSignika(),
    fontSize: 16,
    color: colors.getMainWhite(),
  },
  srcSelectorsContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 10,
  },
  srcSelectorContainer: {
    height: 30,
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderRadius: 5,
    overflow: 'hidden',
    backgroundColor: colors.getLightMirage(),
    paddingLeft: 10,
    paddingRight: 10,
    gap: 10,
  },
  srcPicker: {
    flex: 1,
    minWidth: 0,
    height: '100%',
    fontSize: 14,
    color: colors.getMainWhite(),
    backgroundColor: 'transparent',
    borderColor: undefined,
    borderWidth: 0,
    paddingLeft: 0,
    paddingTop: 0,
    paddingRight: 5,
    paddingBottom: 0,
    cursor: 'pointer',
  },
  messageSupport: {
    position: 'absolute',
    backgroundColor: colors.getGrayDusty(0.05),
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  messageContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 10,
    backgroundColor: colors.getGrayDusty(0.8),
    padding: 7,
    borderRadius: 5,
    shadowRadius: 2,
    shadowColor: colors.getMainBlack(0.5),
    marginTop: 10,
  },
  message: {
    fontFamily: fonts.getSignika(),
    fontSize: 16,
    color: colors.getMainWhite(),
  },
});

const VideoCaptureElement = ({ videoCaptureRef, onVideoError, type, style }) => {
  const { t } = useTranslation();
  const videoCaptureListenersRef = useRef(false);
  const mediaRef = useRef(null);
  const previewMediaRef = useRef(null);
  const microphoneIconRef = useRef();

  const [videoIsEnabled, setVideoIsEnabled] = useState(true);
  const [audioIsEnabled, setAudioIsEnabled] = useState(true);

  const [videoDevices, setVideoDevices] = useState([]);
  const [audioDevices, setAudioDevices] = useState([]);

  const [videoDevice, setVideoDevice] = useState(null);
  const [audioDevice, setAudioDevice] = useState(null);

  const [mediaScreenStatus, setMediaScreenStatus] = useState(VideoCaptureScreenStatus.WAITING);
  const [screenSharing, setScreenSharing] = useState(false);

  const screenSharingIsAvailable = displayMediaIsAvailable();

  useEffect(() => {
    const listeners = new Map();
    if (videoCaptureRef?.current) {
      previewMediaRef.current = videoCaptureRef.current.getPreviewMedia();

      listeners.set(VideoCaptureEvent.MEDIA, (media) => {
        mediaRef.current = media;
      });
      listeners.set(VideoCaptureEvent.VIDEO_ENABLE, (enabled) => setVideoIsEnabled(enabled));
      listeners.set(VideoCaptureEvent.AUDIO_ENABLE, (enabled) => setAudioIsEnabled(enabled));
      listeners.set(VideoCaptureEvent.VIDEO_DEVICE, (device) => setVideoDevice(device));
      listeners.set(VideoCaptureEvent.AUDIO_DEVICE, (device) => setAudioDevice(device));
      listeners.set(VideoCaptureEvent.VIDEO_DEVICES, (devices) => setVideoDevices(devices));
      listeners.set(VideoCaptureEvent.AUDIO_DEVICES, (devices) => setAudioDevices(devices));
      listeners.set(VideoCaptureEvent.AUDIO_VOLUME, (volume) => {
        if (microphoneIconRef.current) microphoneIconRef.current.setVolume(volume);
      });
      listeners.set(VideoCaptureEvent.SCREEN_SHARING, (running) => setScreenSharing(running));
      listeners.set(VideoCaptureEvent.SCREEN_SHARING_STATUS, (status) =>
        setMediaScreenStatus(status),
      );

      listeners.forEach((cb, event) => {
        videoCaptureRef.current.addEventListener(event, cb);
      });

      videoCaptureListenersRef.current = listeners;
    }
    return () => {
      if (!videoCaptureRef?.current) return;
      listeners.forEach((cb, event) => {
        videoCaptureRef.current.removeEventListener(event, cb);
      });
    };
  });

  const renderMessageHovered = (targetRef, icon, message, delay = 1000, enableClick = false) => (
    <ElementInOverlay>
      <ElementOnHover targetRef={targetRef} delay={delay} enableClick={enableClick}>
        <View style={styles.messageHoveredContainer}>
          {icon}
          <Text style={styles.messageHoveredText}>{message}</Text>
          <View style={styles.messageHoveredMarker} />
        </View>
      </ElementOnHover>
    </ElementInOverlay>
  );

  const iconOnHoverSize = 20;
  const renderEnableVideoButton = (additionnalStyle) => {
    const iconCameraButtonRef = createRef();
    const currStyle = [styles.button];
    if (!videoIsEnabled) currStyle.push(styles.buttonDisabled);
    if (additionnalStyle)
      currStyle.push(...(Array.isArray(additionnalStyle) ? additionnalStyle : [additionnalStyle]));
    return (
      <TouchableOpacity
        ref={iconCameraButtonRef}
        onPress={() => videoCaptureRef?.current?.enableVideo(!videoIsEnabled)}
        style={currStyle}
      >
        {videoIsEnabled ? (
          <IconCameraEnable width={'100%'} height={'100%'} />
        ) : (
          <IconCameraDisable width={'100%'} height={'100%'} />
        )}
        {renderMessageHovered(
          iconCameraButtonRef,
          <IconCameraEnable width={iconOnHoverSize} height={iconOnHoverSize} />,
          t(Strings.VIDEO_CAPTURE_ELEMENT_ENABLE_DISABLE_VIDEO_HOVER_MESSAGE),
        )}
      </TouchableOpacity>
    );
  };

  const renderEnableAudioButton = (additionnalStyle) => {
    const iconMicroButtonRef = createRef();
    const currStyle = [styles.button];
    if (!audioIsEnabled) currStyle.push(styles.buttonDisabled);
    if (additionnalStyle)
      currStyle.push(...(Array.isArray(additionnalStyle) ? additionnalStyle : [additionnalStyle]));
    return (
      <TouchableOpacity
        ref={iconMicroButtonRef}
        onPress={() => videoCaptureRef?.current?.enableAudio(!audioIsEnabled)}
        style={currStyle}
      >
        <MicrophoneDynamic
          enabled={audioIsEnabled}
          ref={microphoneIconRef}
          width={'100%'}
          height={'100%'}
          strokeColor={colors.getMainWhite()}
          fillColor={audioIsEnabled ? colors.getMainGreen() : colors.getGrayDusty()}
        />
        {renderMessageHovered(
          iconMicroButtonRef,
          <MicrophoneDynamic
            width={iconOnHoverSize}
            height={iconOnHoverSize}
            strokeColor={colors.getMainWhite()}
            fillColor={colors.getMainGreen()}
          />,
          t(Strings.VIDEO_CAPTURE_ELEMENT_ENABLE_DISABLE_AUDIO_HOVER_MESSAGE),
        )}
      </TouchableOpacity>
    );
  };

  const renderScreenSharingButton = (additionnalStyle) => {
    if (!screenSharingIsAvailable) return null;
    const iconScreenButtonRef = createRef();
    const currStyle = [styles.button];
    if (screenSharing) {
      if (mediaScreenStatus === VideoCaptureScreenStatus.PROCESSING) {
        currStyle.push(styles.buttonProcessing);
      } else if (mediaScreenStatus === VideoCaptureScreenStatus.RUNNING) {
        currStyle.push(styles.buttonSuccess);
      }
    }
    if (additionnalStyle)
      currStyle.push(...(Array.isArray(additionnalStyle) ? additionnalStyle : [additionnalStyle]));
    return (
      <TouchableOpacity
        ref={iconScreenButtonRef}
        onPress={() => {
          if (!videoCaptureRef?.current) return;
          if (screenSharing) videoCaptureRef.current.stopSreenSharing();
          else videoCaptureRef.current.startScreenSharing();
        }}
        style={currStyle}
      >
        <IconScreenEnable width={'100%'} height={'100%'} />
        {renderMessageHovered(
          iconScreenButtonRef,
          <IconScreenEnable width={iconOnHoverSize} height={iconOnHoverSize} />,
          t(Strings.VIDEO_CAPTURE_ELEMENT_ENABLE_DISABLE_SCREEN_SHARING_HOVER_MESSAGE),
        )}
      </TouchableOpacity>
    );
  };

  const renderControlButtons = (additionnalStyle) => {
    const currStyle = [styles.buttonsContainer];
    if (additionnalStyle)
      currStyle.push(...(Array.isArray(additionnalStyle) ? additionnalStyle : [additionnalStyle]));
    return (
      <View style={currStyle}>
        {renderEnableVideoButton()}
        {renderEnableAudioButton()}
        {renderScreenSharingButton()}
      </View>
    );
  };

  const renderListVideoDevices = (additionnalStyle) => {
    const currStyle = [styles.srcSelectorContainer];
    if (additionnalStyle)
      currStyle.push(...(Array.isArray(additionnalStyle) ? additionnalStyle : [additionnalStyle]));
    const value = videoDevice?.deviceId;
    const renderIcon = () => <IconCameraEnable width={20} height={20} />;
    const renderPicker = () => (
      <Picker
        selectedValue={value}
        onValueChange={(deviceId) => {
          const device = videoDevices.find((curr) => curr.deviceId === deviceId);
          if (!device) return;
          if (videoCaptureRef?.current) videoCaptureRef.current.setVideoDevice(device);
        }}
        style={styles.srcPicker}
      >
        {videoDevices.map((device) => (
          <Picker.Item key={device.deviceId} label={device.label || '-'} value={device.deviceId} />
        ))}
      </Picker>
    );
    return (
      <View style={currStyle}>
        {renderIcon()}
        {renderPicker()}
      </View>
    );
  };

  const renderListAudioDevices = (additionnalStyle) => {
    const currStyle = [styles.srcSelectorContainer];
    if (additionnalStyle)
      currStyle.push(...(Array.isArray(additionnalStyle) ? additionnalStyle : [additionnalStyle]));
    const value = audioDevice?.deviceId;
    const renderIcon = () => (
      <MicrophoneDynamic
        width={20}
        height={20}
        strokeColor={colors.getMainWhite()}
        fillColor={colors.getMainWhite()}
        volume={0}
      />
    );
    const renderPicker = () => (
      <Picker
        selectedValue={value}
        onValueChange={(deviceId) => {
          const device = audioDevices.find((curr) => curr.deviceId === deviceId);
          if (!device) return;
          if (videoCaptureRef?.current) videoCaptureRef.current.setAudioDevice(device);
        }}
        style={styles.srcPicker}
      >
        {audioDevices.map((device) => (
          <Picker.Item key={device.deviceId} label={device.label || '-'} value={device.deviceId} />
        ))}
      </Picker>
    );
    return (
      <View style={currStyle}>
        {renderIcon()}
        {renderPicker()}
      </View>
    );
  };

  const renderListDevices = (additionnalStyle) => {
    const currStyle = [styles.srcSelectorsContainer];
    if (additionnalStyle)
      currStyle.push(...(Array.isArray(additionnalStyle) ? additionnalStyle : [additionnalStyle]));
    return (
      <View style={currStyle}>
        {renderListVideoDevices()}
        {renderListAudioDevices()}
      </View>
    );
  };

  const renderVideo = (additionnalStyle) => {
    const currStyle = [styles.srcSelectorsContainer];
    if (additionnalStyle)
      currStyle.push(...(Array.isArray(additionnalStyle) ? additionnalStyle : [additionnalStyle]));
    const renderMessage = () => {
      let message;
      if (!videoIsEnabled && !audioIsEnabled) {
        message = t(Strings.VIDEO_CAPTURE_ELEMENT_NO_VIDEO_AND_AUDIO_MESSAGE);
      } else if (!videoIsEnabled) {
        message = t(Strings.VIDEO_CAPTURE_ELEMENT_NO_VIDEO_MESSAGE);
      } else if (!audioIsEnabled) {
        message = t(Strings.VIDEO_CAPTURE_ELEMENT_NO_AUDIO_MESSAGE);
      }
      if (!message) return null;
      return (
        <View style={styles.messageSupport}>
          <View style={styles.messageContainer}>
            <IconInfo width={15} />
            <Text style={styles.message}>{message}</Text>
          </View>
        </View>
      );
    };
    return (
      <View style={currStyle}>
        <Video
          ref={(ref) => {
            const videoElement = ref?.getHTMLVideoElement();
            if (videoElement) videoElement.setAttribute('playsinline', ''); // Safari
          }}
          style={{ width: '100%', height: '100%', objectFit: 'cover', background: 'black' }}
          mute
          play
          autoPlay
          media={previewMediaRef.current}
          onError={(event) => {
            if (onVideoError) onVideoError(event);
          }}
        />
        {renderMessage()}
      </View>
    );
  };

  if (type === VideoCaptureElementType.ENABLE_VIDEO_BUTTON) {
    return renderEnableVideoButton(style);
  }
  if (type === VideoCaptureElementType.ENABLE_AUDIO_BUTTON) {
    return renderEnableAudioButton(style);
  }
  if (type === VideoCaptureElementType.SCREEN_SHARING_BUTTON) {
    return renderScreenSharingButton(style);
  }
  if (type === VideoCaptureElementType.CONTROL_BUTTONS) {
    return renderControlButtons(style);
  }
  if (type === VideoCaptureElementType.LIST_VIDEO_DEVICES) {
    return renderListVideoDevices(style);
  }
  if (type === VideoCaptureElementType.LIST_AUDIO_DEVICES) {
    return renderListAudioDevices(style);
  }
  if (type === VideoCaptureElementType.LIST_DEVICES) {
    return renderListDevices(style);
  }
  if (type === VideoCaptureElementType.VIDEO) {
    return renderVideo(style);
  }
  return null;
};

VideoCaptureElement.propTypes = {
  videoCaptureRef: PropTypes.any.isRequired,
  onVideoError: PropTypes.func,
  type: PropTypes.string,
  style: PropTypes.any,
};

VideoCaptureElement.defaultProps = {
  type: undefined,
  style: undefined,
};

export default VideoCaptureElement;
