import { clientRedirect, getHeadersAPI, requestAPI } from '../../helpers/request';

const basePath = '/user';

const paths = {
  getMe: () => {
    return `${basePath}/me`;
  }, // GET
  getRecoveryState: (key) => {
    return `${basePath}/recovery/${key}`;
  }, // GET
  loginLocal: () => {
    return `${basePath}/login/local`;
  }, // POST
  loginCas: () => {
    return `${basePath}/login/cas`;
  }, // POST
  loginGoogle: () => {
    return `${basePath}/login/federated/google`;
  }, // GET
  loginAzureAd: () => {
    return `${basePath}/login/federated/azure-ad`;
  }, // GET
  refreshToken: () => {
    return `${basePath}/token/refresh`;
  }, // POST
  createRecovery: () => {
    return `${basePath}/recovery`;
  }, // POST
  terminateRecovery: (key) => {
    return `${basePath}/recovery/${key}`;
  }, // POST
};

// ------------------------------------------------------- //
// --------------------- REQUEST ------------------------- //
// ------------------------------------------------------- //

// ---------- GET
const requestUser = async () => {
  return requestAPI(paths.getMe(), getHeadersAPI('GET', true));
};

// ---------- POST
const requestLoginLocal = async (email, password) => {
  const body = { email, password };
  return requestAPI(paths.loginLocal(), getHeadersAPI('POST', false, JSON.stringify(body)));
};

const requestLoginCas = async (ticket) => {
  const body = { ticket };
  return requestAPI(paths.loginCas(), getHeadersAPI('POST', false, JSON.stringify(body)));
};

const requestLoginGoogle = async () => {
  return clientRedirect(paths.loginGoogle());
};

const requestLoginAzureAd = async () => {
  return clientRedirect(paths.loginAzureAd());
};

const requestRefreshToken = async (refreshToken) => {
  const body = { refreshToken };
  return requestAPI(paths.refreshToken(), getHeadersAPI('POST', true, JSON.stringify(body)));
};

const requestCreateRecovery = async (email) => {
  const body = { email };
  return requestAPI(paths.createRecovery(), getHeadersAPI('POST', false, JSON.stringify(body)));
};

const requestTerminateRecovery = async (key, password) => {
  const body = { password };
  return requestAPI(
    paths.terminateRecovery(key),
    getHeadersAPI('POST', false, JSON.stringify(body)),
  );
};

const requestGetRecoveryState = async (key) => {
  return requestAPI(paths.getRecoveryState(key), getHeadersAPI('GET', false));
};

// ---------- PATCH

// ---------- DELETE

export default {
  requestLoginLocal,
  requestLoginCas,
  requestLoginGoogle,
  requestLoginAzureAd,
  requestUser,
  requestRefreshToken,
  requestCreateRecovery,
  requestTerminateRecovery,
  requestGetRecoveryState,
};
