import { generateRandomId } from '@kalyzee/kast-react-player-module';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { addComponentOnOverlay, removeComponentOnOverlay } from './overlayContainer';

const ElementInOverlay = ({ children }) => {
  useEffect(() => {
    addComponentOnOverlay(children, generateRandomId());
    return () => {
      removeComponentOnOverlay(children);
    };
  }, [children]);
  return null;
};

ElementInOverlay.propTypes = {
  children: PropTypes.any,
};
ElementInOverlay.defaultProps = {};

export default ElementInOverlay;
