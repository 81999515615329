import { StyleSheet, View } from 'react-native';
import React from 'react';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Messages from './Messages';
import Footer from './Footer';
import userSelectors from '../../App/selectors/user';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    maxHeight: '100%',
  },
});

const Chat = ({ isAuthenticated, inputRef, onClick }) => {
  const handleClick = () => {
    if (onClick) onClick();
  };

  return (
    <View style={styles.container} onClick={handleClick}>
      <Messages onScrollToEnd={handleClick} />
      {isAuthenticated ? <Footer inputRef={inputRef} /> : null}
    </View>
  );
};

Chat.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  inputRef: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  isAuthenticated: userSelectors.makeSelectIsAuthenticated(),
});

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Chat);
