import React from 'react';

const LoadingSvg = (props) => (
  <svg {...props} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 53 40">
    <circle cx="7" cy="19" r="6">
      <animate
        attributeName="opacity"
        dur="1s"
        values="0;1;0"
        repeatCount="indefinite"
        begin="0.1"
      ></animate>
      <animateTransform
        attributeName="transform"
        dur="1s"
        type="translate"
        values="0 15 ; 0 -15; 0 15"
        repeatCount="indefinite"
        begin="0.1"
      ></animateTransform>
    </circle>
    <circle cx="27" cy="19" r="6">
      <animate
        attributeName="opacity"
        dur="1s"
        values="0;1;0"
        repeatCount="indefinite"
        begin="0.2"
      ></animate>
      <animateTransform
        attributeName="transform"
        dur="1s"
        type="translate"
        values="0 10 ; 0 -10; 0 10"
        repeatCount="indefinite"
        begin="0.2"
      ></animateTransform>
    </circle>
    <circle cx="47" cy="19" r="6">
      <animate
        attributeName="opacity"
        dur="1s"
        values="0;1;0"
        repeatCount="indefinite"
        begin="0.3"
      ></animate>
      <animateTransform
        attributeName="transform"
        dur="1s"
        type="translate"
        values="0 5 ; 0 -5; 0 5"
        repeatCount="indefinite"
        begin="0.3"
      ></animateTransform>
    </circle>
  </svg>
);

export default LoadingSvg;
