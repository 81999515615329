import React from 'react';
import PropTypes from 'prop-types';
import { Datepicker } from '@mobiscroll/react';
import { getMobiscrollLocale } from '../../localization';

const ActivityDatePicker = ({ now, setValue, value, disabled }) => {
  return (
    <Datepicker
      theme="ios"
      themeVariant="light"
      inputStyle="outline"
      labelStyle="floating"
      dateFormat="DDDD D MMMM"
      locale={getMobiscrollLocale()}
      min={now}
      value={value}
      onChange={(event) => {
        setValue(event.value);
      }}
      returnFormat="moment"
      disabled={disabled}
    />
  );
};

ActivityDatePicker.defaultProps = {
  disabled: false,
};

ActivityDatePicker.propTypes = {
  now: PropTypes.object.isRequired,
  value: PropTypes.object.isRequired,
  setValue: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default ActivityDatePicker;
