import React, { useEffect, useImperativeHandle, useState } from 'react';
import { View } from 'react-native';
import PropTypes from 'prop-types';
import colors from '../../constants/colors';

const VOLUME_LIMIT_MAX = 200;
const calculateVolumePercentage = (volume) => {
  return Math.max(0, Math.min(1, volume / VOLUME_LIMIT_MAX));
};

const MicrophoneDynamic = React.forwardRef(
  ({ enabled, volume, strokeColor, fillColor, style, ...svgProps }, forwardRef) => {
    const [volumePercentage, setVolumePercentage] = useState(calculateVolumePercentage(volume));
    const forwardedRef = {
      setVolume: (v) => setVolumePercentage(calculateVolumePercentage(v)),
    };
    useImperativeHandle(forwardRef, () => forwardedRef);

    useEffect(() => {
      setVolumePercentage(calculateVolumePercentage(volume));
    }, [volume]);

    const convertVolumePourcentageToHeightPercentage = (percentage) => {
      const minHeight = 45;
      const maxHeight = 100;
      return minHeight + percentage * (maxHeight - minHeight);
    };

    const getHeightParentDiv = (percentage) => {
      return `${convertVolumePourcentageToHeightPercentage(percentage).toFixed(2)}%`;
    };

    const getTranslateYChild = (percentage) => {
      return `${(convertVolumePourcentageToHeightPercentage(percentage) - 100).toFixed(2)}%`;
    };

    const renderDefaultContentSvg = () => (
      <g>
        <path d="M16.003 22.377c3.231 0 5.851-2.619 5.851-5.851v-10.639c0-3.231-2.62-5.85-5.851-5.85s-5.851 2.619-5.851 5.85v10.639c0 3.231 2.62 5.851 5.851 5.851zM11.216 5.888c0-2.639 2.147-4.786 4.787-4.786s4.787 2.147 4.787 4.786v10.639c0 2.64-2.147 4.787-4.787 4.787s-4.787-2.147-4.787-4.787v-10.639z"></path>
        <path d="M23.978 11.207v5.319c0 4.399-3.579 7.978-7.978 7.978s-7.978-3.579-7.978-7.978v-5.319h-1.064v5.319c0 4.83 3.81 8.776 8.581 9.018h-0.068v5.354h-4.79v1.064h10.637v-1.064h-4.784v-5.354h-0.073c4.771-0.243 8.581-4.189 8.581-9.018v-5.319h-1.064z"></path>
        {!enabled ? <path d="M 3 3 L 29 29" strokeWidth={2} strokeLinecap="round" /> : null}
      </g>
    );

    const renderFillContentSvg = () => (
      <g transform="translate(0, 2)">
        <path d="M16.003 22.377c3.231 0 5.851-2.619 5.851-5.851v-10.639c0-3.231-2.62-5.85-5.851-5.85s-5.851 2.619-5.851 5.85v10.639c0 3.231 2.62 5.851 5.851 5.851z"></path>
      </g>
    );

    const renderSvg = (content, stroke, fill) => (
      <svg
        stroke={stroke}
        strokeWidth={0.5}
        fill={fill}
        {...svgProps}
        viewBox="0 0 34 34"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g transform="translate(1, 1)">{content}</g>
      </svg>
    );

    const renderInsideSvgFromPercentage = (percentage, fill, containerStyle) => (
      <div
        style={{
          position: 'absolute',
          zIndex: -1,
          bottom: 0,
          left: 0,
          width: '100%',
          height: getHeightParentDiv(percentage),
          overflow: 'hidden',
          ...(containerStyle ?? {}),
        }}
      >
        <div
          style={{
            transform: `translateY(${getTranslateYChild(percentage)})`,
          }}
        >
          {renderSvg(renderFillContentSvg(), strokeColor, fill)}
        </div>
      </div>
    );

    return (
      <View style={style}>
        {renderSvg(renderDefaultContentSvg(), strokeColor, strokeColor)}
        {renderInsideSvgFromPercentage(volumePercentage, fillColor)}
      </View>
    );
  },
);

MicrophoneDynamic.displayName = 'MicrophoneDynamic';

MicrophoneDynamic.propTypes = {
  enabled: PropTypes.bool,
  volume: PropTypes.number,
  strokeColor: PropTypes.string,
  fillColor: PropTypes.string,
  style: PropTypes.any,
};

MicrophoneDynamic.defaultProps = {
  enabled: true,
  volume: 100,
  strokeColor: colors.getMainBlack(),
  fillColor: colors.getMainWhite(),
  style: undefined,
};

export default MicrophoneDynamic;
