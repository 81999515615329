import { useRoute } from '@react-navigation/native';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, Text, View } from 'react-native';
import { connect } from 'react-redux';
import IconDisabled from '../../assets/navigation/disabled.svg';
import IconBanned from '../../assets/navigation/nospeaker.svg';
import IconTrialPeriodIsOver from '../../assets/navigation/trail-period-is-over.svg';
import BackButton from '../../components/Generic/backButton';
import colors from '../../constants/colors';
import fonts from '../../constants/fonts';
import { Strings } from '../../helpers/strings';

const styles = StyleSheet.create({
  main: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    height: 80,
    width: 80,
    padding: 30,
  },
  title: {
    fontFamily: fonts.getSignika(),
    color: colors.getBluePrussian(),
    fontSize: 20,
    fontWeight: 600,
    paddingHorizontal: '15px',
    marginBottom: '15px',
    textAlign: 'center',
  },
  subTitle: {
    fontFamily: fonts.getSignika(),
    color: colors.getBluePrussian(),
    fontSize: 18,
    fontWeight: 300,
    paddingHorizontal: '15px',
    textAlign: 'center',
  },
});

const ForbiddenPage = () => {
  const { t } = useTranslation();
  const route = useRoute();
  const reason = route?.params?.r;
  const detailsReasonMapping = {
    ubanned: {
      icon: <IconBanned style={styles.icon} />,
      title: t(Strings.FORBIDDEN_PAGE_USER_BANNED_TITLE),
      message: t(Strings.FORBIDDEN_PAGE_USER_BANNED_MESSAGE),
    },
    udisabled: {
      icon: <IconDisabled style={styles.icon} />,
      title: t(Strings.FORBIDDEN_PAGE_USER_DISABLED_TITLE),
      message: t(Strings.FORBIDDEN_PAGE_USER_DISABLED_MESSAGE),
    },
    utrialperiodisover: {
      icon: <IconTrialPeriodIsOver style={styles.icon} />,
      title: t(Strings.FORBIDDEN_PAGE_USER_TRIAL_PERIOD_IS_OVER_TITLE),
      message: t(Strings.FORBIDDEN_PAGE_USER_TRIAL_PERIOD_IS_OVER_MESSAGE),
    },
    odisabled: {
      icon: <IconDisabled style={styles.icon} />,
      title: t(Strings.FORBIDDEN_PAGE_ORGANIZATION_DISABLED_TITLE),
      message: t(Strings.FORBIDDEN_PAGE_ORGANIZATION_DISABLED_MESSAGE),
    },
    otrialperiodisover: {
      icon: <IconTrialPeriodIsOver style={styles.icon} />,
      title: t(Strings.FORBIDDEN_PAGE_ORGANIZATION_TRIAL_PERIOD_IS_OVER_TITLE),
      message: t(Strings.FORBIDDEN_PAGE_ORGANIZATION_TRIAL_PERIOD_IS_OVER_MESSAGE),
    },
    default: {
      icon: <IconDisabled style={styles.icon} />,
      title: t(Strings.FORBIDDEN_PAGE_ERROR_TITLE),
      message: t(Strings.FORBIDDEN_PAGE_ERROR_MESSAGE),
    },
  };
  const details = detailsReasonMapping[reason] ?? detailsReasonMapping.default;
  return (
    <View style={styles.main}>
      <BackButton />
      {details.icon}
      <Text style={styles.title}>{details.title}</Text>
      <Text style={styles.subTitle}>{details.message}</Text>
      <Text style={styles.contact}>{t(Strings.FORBIDDEN_PAGE_CONTACT_ADMINISTRATOR)}</Text>
    </View>
  );
};

ForbiddenPage.propTypes = {};

export default connect(null, null)(ForbiddenPage);
