import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, Text, TextInput, Button, View } from 'react-native';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useTranslation } from 'react-i18next';
import colors from '../../constants/colors';

import userActions, { Types as userTypes } from '../../App/actions/user';
import appSelectors from '../../App/selectors/app';
import { Strings } from '../../helpers/strings';
import fonts from '../../constants/fonts';
import { navigate } from '../../helpers/navigation';
import { screenName } from '../../constants/navigation';

const { REQUEST_LOGIN_LOCAL } = userTypes;

const styles = StyleSheet.create({
  label: {
    fontSize: 20,
    fontWeight: 600,
    color: colors.getBluePrussian(),
  },
  input: {
    marginTop: 10,
    marginBottom: 15,
    height: 60,
    padding: 10,
    borderRadius: 10,
    border: `solid 1px ${colors.getGraySantas(0.14)}`,
    backgroundColor: colors.getMainWhite(),
  },
  error: {
    marginBottom: 15,
    color: colors.getRed(),
  },
  button: {
    borderRadius: 10,
    backdropFilter: 'blur(25px)',
    backgroundColor: colors.getMainPurple(),
    paddingVertical: 22,
    paddingHorizontal: 130,
  },
  forgotPasswordContainer: {
    display: 'inline-block',
    marginBottom: 15,
    color: 'blue',
  },
  forgotPasswordText: {
    color: 'blue',
    textDecorationLine: 'underline',
    fontFamily: fonts.getSignika(),
  },
});

const LoginLocal = ({ currentlySending, requestLoginLocal }) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorEmail, setErrorEmail] = useState(null);
  const [errorPassword, setErrorPassword] = useState(null);

  const validateEmail = () => {
    if (email.includes('@')) {
      if (errorEmail !== '') setErrorEmail(null);
      return true;
    }
    setErrorEmail(t(Strings.ERROR_EMAIL));
    return false;
  };

  const validatePassword = () => {
    if (password !== '') {
      if (errorPassword !== '') setErrorPassword(null);
      return true;
    }
    setErrorPassword(t(Strings.ERROR_PASSWORD));
    return false;
  };

  const validateForm = () => {
    const validatedEmail = validateEmail();
    const validatedPassword = validatePassword();
    return validatedEmail && validatedPassword;
  };

  const handleSubmit = () => {
    if (validateForm()) requestLoginLocal(email, password);
    return null;
  };

  const onClickPasswordReset = () => {
    navigate(screenName.RESET_PASSWORD);
  };

  return (
    <>
      <Text style={styles.label}>{t(Strings.EMAIL)}</Text>
      <TextInput
        placeholder={t(Strings.EXEMPLE_EMAIL)}
        style={styles.input}
        onChangeText={setEmail}
      />
      {errorEmail !== null && <Text style={styles.error}>{errorEmail}</Text>}
      <Text style={styles.label}>{t(Strings.PASSWORD)}</Text>
      <TextInput
        placeholder={t(Strings.EXEMPLE_PASSWORD)}
        secureTextEntry={true}
        style={styles.input}
        onChangeText={setPassword}
      />
      {errorPassword !== null && <Text style={styles.error}>{errorPassword}</Text>}
      <View style={styles.forgotPasswordContainer}>
        <Text onPress={onClickPasswordReset} style={styles.forgotPasswordText}>
          {t(Strings.I_FORGOT_MY_PASSWORD)}
        </Text>
      </View>
      <Button
        title={t(Strings.LOGIN)}
        onPress={handleSubmit}
        disabled={currentlySending}
        color="#5b41f0"
      />
    </>
  );
};

LoginLocal.propTypes = {
  currentlySending: PropTypes.bool.isRequired,

  // Actions
  requestLoginLocal: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  currentlySending: appSelectors.makeSelectCurrentlySending([REQUEST_LOGIN_LOCAL]),
});

const mapDispatchToProps = (dispatch) => {
  return {
    requestLoginLocal: (email, password) =>
      dispatch(userActions.requestLoginLocal(email, password)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginLocal);
