import PropTypes from 'prop-types';
import { View, StyleSheet, TouchableOpacity, Platform, useWindowDimensions } from 'react-native';
import { connect } from 'react-redux';
import Lottie from 'react-lottie-player';

import React, { useState } from 'react';
import { sVideoControlRow } from '../../styles/fixed';

import activityActions from '../../App/actions/activity';
import LiveProfileSelector from './liveProfiles';

import PublishButton from '../../assets/animations/readyToRecord.json';
import StopButton from '../../assets/animations/recording.json';

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'default',
    overflow: 'hidden',
  },
  icon: {
    transform: 'scale(2)',
    cursor: 'pointer',
  },
});

const stylesSmallMode = StyleSheet.create({
  container: {
    height: sVideoControlRow.height * 0.5,
  },
  icon: {
    height: sVideoControlRow.height * 0.5,
    width: sVideoControlRow.height * 0.5,
  },
});

const stylesMediumMode = StyleSheet.create({
  container: {
    height: sVideoControlRow.height * 0.7,
  },
  icon: {
    height: sVideoControlRow.height * 0.7,
    width: sVideoControlRow.height * 0.7,
  },
});

const stylesLargeMode = StyleSheet.create({
  container: {
    height: sVideoControlRow.height,
  },
  icon: {
    height: sVideoControlRow.height,
    width: sVideoControlRow.height,
  },
});

const getResponsiveStyles = (width, height) => {
  let result = stylesLargeMode;
  if (height < 500) result = stylesSmallMode;
  else if (height < 700) result = stylesMediumMode;
  return result;
};

const VideoControl = ({ device, activity, activityState, startPublish, stopPublish }) => {
  const [showProfiles, setShowProfiles] = useState(false);
  const [selectedLiveProfile, selectLiveProfile] = useState(null);
  const { width, height } = useWindowDimensions();
  const responsiveStyles = getResponsiveStyles(width, height);

  const deviceIsOnline = () => {
    return device?.online;
  };

  const isPublishing = () => {
    const isInLive = !!(activityState?.liveStatus === 'active');
    const isRecording = !!(activityState?.recordStatus === 'active');
    // const shouldRecord = !!activity.shouldRecord;
    return isInLive || isRecording;
    // return isInLive && ((shouldRecord && isRecording) || !shouldRecord);
  };

  const onClickLiveControl = () => {
    if (deviceIsOnline()) {
      if (isPublishing()) stopPublish(device.id, activity);
      else startPublish(device.id, activity);
    }
  };

  const getIconLiveControl = () => {
    if (!deviceIsOnline()) return null;
    if (Platform.OS === 'web') {
      return (
        <Lottie
          animationData={isPublishing() ? StopButton : PublishButton}
          loop
          play
          style={{
            ...StyleSheet.flatten(styles.icon),
            ...StyleSheet.flatten(responsiveStyles.icon),
          }}
          rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
        />
      );
    }
    if (isPublishing()) return <StopButton />;
    return <PublishButton />;
  };

  const showProfileSelector = () => {
    return (
      <LiveProfileSelector
        isVisible={showProfiles}
        setVisible={setShowProfiles}
        selectLiveProfile={selectLiveProfile}
      />
    );
  };

  return (
    <View>
      <View style={[styles.container, responsiveStyles.container]}>
        {showProfileSelector()}
        <TouchableOpacity onPress={onClickLiveControl}>{getIconLiveControl()}</TouchableOpacity>
      </View>
    </View>
  );
};

VideoControl.propTypes = {
  device: PropTypes.object.isRequired,
  activity: PropTypes.object.isRequired,
  activityState: PropTypes.object.isRequired,
  startPublish: PropTypes.func.isRequired,
  stopPublish: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return {
    startPublish: (deviceId, activity) =>
      dispatch(activityActions.socketStartPublish(deviceId, activity)),
    stopPublish: (deviceId, activity) =>
      dispatch(activityActions.socketStopPublish(deviceId, activity)),
  };
};

export default connect(null, mapDispatchToProps)(VideoControl);
