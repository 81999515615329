import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { StyleSheet, View, Text, TouchableOpacity, useWindowDimensions } from 'react-native';
import { useForm } from 'react-hook-form';
import { isEmpty } from 'lodash';

import Lottie from 'react-lottie-player';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import activitySelectors from '../../App/selectors/activity';
import userSelectors from '../../App/selectors/user';
import activityActions from '../../App/actions/activity';
import '@mobiscroll/react/dist/css/mobiscroll.react.min.css';

import LiveButton from '../../assets/animations/readyToRecord.json';
import ArrowIcon from '../../assets/settings/icon-title-arrow.svg';

import ScheduleForm from '../../components/ScheduleForm';

import colors from '../../constants/colors';
import fonts from '../../constants/fonts';
import { userCanEditActivity } from '../../helpers/activity';
import { navigate } from '../../helpers/navigation';
import { screenName } from '../../constants/navigation';
import { useActivityStartTime } from '../../hooks/activity';
import { Strings } from '../../helpers/strings';
import AppVersion from '../../components/Generic/appVersion';

const styles = StyleSheet.create({
  background: {
    height: 'var(--app-height)',
    overflow: 'auto',
  },
  formContainer: {
    backgroundColor: colors.getGrayAthens(),
    padding: 20,
    borderRadius: 20,
  },
  logoContainer: {
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center',
    marginRight: 20,
    backgroundColor: colors.getMainWhite(),
    borderRadius: 20,
  },
  saveButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 20,
    borderRadius: 10,
    backgroundColor: colors.getMainPurple(),
  },
  saveButtonText: {
    fontFamily: fonts.getSignika(),
    fontSize: 22,
    fontWeight: 600,
    color: colors.getMainWhite(),
  },
  logoText: {
    fontFamily: fonts.getHalisR(),
    fontSize: 29,
    fontWeight: 900,
    letterSpacing: 'normal',
    marginRight: 20,
    color: colors.getBluePrussian(),
  },
  titleText: {
    fontFamily: fonts.getHalisR(),
    fontSize: 24,
    fontWeight: 900,
    color: colors.getMountainMeadow(),
  },
  arrowIcon: {
    margin: 10,
    width: 15,
    height: 15,
  },
  formRow: {
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center',
    marginTop: 10,
  },
});

const MobileWidth = 800;

const SchedulePage = ({
  route,
  requestScheduleActivity,
  requestUpdateActivity,
  activityInfo,
  requestActivity,
  user,
}) => {
  const { t } = useTranslation();
  const [externalId, setExternalId] = useState(undefined);
  const [isCreateMode, setIsCreateMode] = useState(false);

  useEffect(() => {
    if (route?.params?.id) {
      if (activityInfo?._id === route.params.id) {
        if (!isEmpty(user)) {
          if (userCanEditActivity(activityInfo, user)) {
            // Check if the activity in the past, if user is the creator
            setIsCreateMode(false);
          } else {
            navigate(screenName.SCHEDULE, { id: undefined }); // removes the ID
          }
        }
      } else {
        requestActivity(route.params.id);
      }
    } else {
      setIsCreateMode(true);
    }
  }, [route, activityInfo, user]);

  useEffect(() => {
    if (route?.params?.external_id) setExternalId(route.params.external_id);
  }, []);

  const now = useActivityStartTime();

  const formProps = useForm({
    defaultValues: {
      name: undefined,
      _liveProfile: undefined,
      _uploadProfile: undefined,
      _room: undefined,
      date: now,
      startDatetime: now,
      endDatetime: now,
      isPrivate: true,
      shouldLive: true,
      shouldRecord: false,
    },
  });

  const { width } = useWindowDimensions();

  const submit = (data) => {
    if (isCreateMode)
      requestScheduleActivity(
        {
          name: data.name,
          _liveProfile: data._liveProfile,
          _uploadProfile: data._uploadProfile,
          _room: data._room,
          startDatetime: moment(data.startDatetime).toISOString(),
          endDatetime: moment(data.endDatetime).toISOString(),
          isPrivate: data.isPrivate,
          shouldRecord: data.shouldRecord,
          shouldLive: data.shouldLive,
        },
        externalId,
      );
    else if (!isEmpty(activityInfo)) {
      requestUpdateActivity(activityInfo._id, {
        name: data.name,
        _liveProfile: data._liveProfile,
        _uploadProfile: data._uploadProfile,
        _room: data._room,
        startDatetime: moment(data.startDatetime).toISOString(),
        endDatetime: moment(data.endDatetime).toISOString(),
        isPrivate: data.isPrivate,
        shouldRecord: data.shouldRecord,
        shouldLive: data.shouldLive,
      });
    }
  };

  const buttonWidth = { width: width <= MobileWidth ? '50%' : 384 };

  const renderLogo = (isMobile) => {
    const renderAnimation = () => {
      return (
        <Lottie
          style={{
            margin: -10,
            height: 100,
            width: 100,
          }}
          animationData={LiveButton}
          loop
          play
          rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
        />
      );
    };
    if (isMobile) {
      return renderAnimation();
    }
    return (
      <View style={styles.logoContainer}>
        {renderAnimation()}
        <Text style={styles.logoText}>KAPTURE</Text>
      </View>
    );
  };

  const titleText = isCreateMode ? t(Strings.CREATE_AN_EVENT) : t(Strings.MODIFY_AN_EVENT);

  return (
    <View style={styles.background}>
      <View>
        <View style={styles.formContainer}>
          <View style={styles.formRow}>
            {renderLogo(width <= MobileWidth)}
            <ArrowIcon style={styles.arrowIcon} />
            <Text style={styles.titleText}>{titleText}</Text>
          </View>
          {isCreateMode || activityInfo?._id ? (
            <ScheduleForm
              formProps={{ ...formProps }}
              defaultData={activityInfo}
              now={now}
              isMobile={width <= MobileWidth}
            />
          ) : (
            <Text>{t(Strings.WAITING)}</Text>
          )}
        </View>
        <View
          style={[
            styles.formRow,
            { justifyContent: 'center', marginTop: 63, marginBottom: 10, zIndex: -1 },
          ]}
        >
          <TouchableOpacity
            style={[styles.saveButtonContainer, buttonWidth]}
            onPress={formProps.handleSubmit(submit)}
          >
            <Text style={styles.saveButtonText}>{t(Strings.SAVE)}</Text>
          </TouchableOpacity>
        </View>
        <AppVersion />
      </View>
    </View>
  );
};

SchedulePage.propTypes = {
  route: PropTypes.object.isRequired,
  activityInfo: PropTypes.object,
  user: PropTypes.object.isRequired,

  // Action
  requestActivity: PropTypes.func.isRequired,
  requestScheduleActivity: PropTypes.func.isRequired,
  requestUpdateActivity: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  activityInfo: activitySelectors.makeSelectActivityInfo(),
  user: userSelectors.makeSelectUserInfo(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    requestActivity: (_id) => dispatch(activityActions.requestActivity(_id)),
    requestScheduleActivity: (data, externalId) =>
      dispatch(activityActions.requestScheduleActivity(data, externalId, true)),
    requestUpdateActivity: (_id, data) =>
      dispatch(activityActions.requestUpdateActivity(_id, data, true)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SchedulePage);
