import React from 'react';
import { StyleSheet, View } from 'react-native';
import { TouchableOpacity } from 'react-native-web';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { navigationRef } from '../../helpers/navigation';

import IconBack from '../../assets/navigation/chevron.svg';

const styles = StyleSheet.create({
  button: {
    position: 'absolute',
    top: 20,
    left: 5,
    zIndex: 1,
    padding: 10,
    '-webkit-filter': 'drop-shadow( 4px 4px 4px rgba(0, 0, 0, .5))',
    filter: 'drop-shadow( 4px 4px 4px rgba(0, 0, 0, .5))',
  },
  defaultIconContainer: {
    width: '15px',
    height: '20px',
  },
});

const BackButton = ({
  enableRedirectionIfCantGoBack,
  redirectionIfCantGoBack,
  paramsIfCantGoBack,
  children,
  style,
}) => {
  const viewStyle = [styles.button];
  if (style) viewStyle.push(...(Array.isArray(style) ? style : [style]));
  if (!navigationRef.current.canGoBack() && !enableRedirectionIfCantGoBack) return null;
  return (
    <TouchableOpacity
      style={viewStyle}
      onPress={() => {
        if (!navigationRef.current) return;
        if (!navigationRef.current.canGoBack()) {
          if (enableRedirectionIfCantGoBack)
            navigationRef.current.navigate(redirectionIfCantGoBack, paramsIfCantGoBack);
          return;
        }
        navigationRef.current.goBack();
      }}
    >
      {children ?? (
        <View style={styles.defaultIconContainer}>
          <IconBack width="20" height="15" fill="white" style={{ transform: 'rotate(-90deg)' }} />
        </View>
      )}
    </TouchableOpacity>
  );
};

BackButton.propTypes = {
  enableRedirectionIfCantGoBack: PropTypes.bool,
  redirectionIfCantGoBack: PropTypes.string,
  paramsIfCantGoBack: PropTypes.object,
  children: PropTypes.element,
  style: PropTypes.any,
};

BackButton.defaultProps = {
  enableRedirectionIfCantGoBack: false,
  redirectionIfCantGoBack: 'login',
  paramsIfCantGoBack: undefined,
};

export default connect(null, null)(BackButton);
