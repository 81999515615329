import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Text, TextInput, View } from 'react-native';
import { useTranslation } from 'react-i18next';
import { createStructuredSelector } from 'reselect';
import { StyleSheet } from 'react-native-web';
import { useFocusEffect } from '@react-navigation/native';
import userActions, { Types as userTypes } from '../../App/actions/user';
import appSelectors from '../../App/selectors/app';
import colors from '../../constants/colors';
import { Strings } from '../../helpers/strings';
import fonts from '../../constants/fonts';
import userSelectors from '../../App/selectors/user';
import { PASSWORD_RESET_STATE } from '../../App/reducers/user';
import BackButton from '../../components/Generic/backButton';
import { screenName } from '../../constants/navigation';

const { REQUEST_CREATE_RECOVERY, REQUEST_TERMINATE_RECOVERY } = userTypes;

const styles = StyleSheet.create({
  formContainer: {
    width: 'min(80vw, 450px)',
    borderRadius: 20,
    backdropFilter: 'blur(45px)',
    border: `solid 0.5px ${colors.getGraySantas(0.2)}`,
    backgroundColor: colors.getGrayAthens(),
    paddingHorizontal: 30,
    paddingVertical: 33,
  },
  form: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  label: {
    fontSize: 20,
    fontWeight: 600,
    color: colors.getBluePrussian(),
  },
  input: {
    marginTop: 10,
    marginBottom: 15,
    height: 60,
    padding: 10,
    borderRadius: 10,
    border: `solid 1px ${colors.getGraySantas(0.14)}`,
    backgroundColor: colors.getMainWhite(),
  },
  error: {
    marginBottom: 15,
    color: colors.getRed(),
  },
  button: {
    borderRadius: 10,
    backdropFilter: 'blur(25px)',
    backgroundColor: colors.getMainPurple(),
    paddingVertical: 22,
    paddingHorizontal: 130,
  },
  infoBox: {
    border: `solid 0.5px ${colors.getGraySantas(0.2)}`,
    backgroundColor: colors.getMainGreen(0.2),
    borderRadius: 10,
    padding: 10,
  },
  infoBoxError: {
    backgroundColor: colors.getMainRed(0.2),
  },
  infoBoxText: {
    fontFamily: fonts.getSignika(),
    fontColor: 'white',
  },
});

const ResetPasswordPage = ({
  route,
  requestGetRecoveryState,
  requestCreateRecovery,
  requestTerminateRecovery,
  passwordResetState,
  resetPasswordResetState,
  currentlySendingCreateRecovery,
  currentlySendingTerminateRecovery,
}) => {
  const { t } = useTranslation();

  const [recoveryKey, setRecoveryKey] = useState(undefined);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');

  const [emailError, setEmailError] = useState(undefined);
  const [passwordError, setPasswordError] = useState(undefined);
  const [passwordConfirmError, setPasswordConfirmError] = useState(undefined);

  const resetFields = () => {
    if (recoveryKey) setRecoveryKey(undefined);
    if (email) setEmail('');
    if (password) setPassword('');
    if (passwordConfirm) setPasswordConfirm('');
  };

  const resetErrors = () => {
    if (emailError) setEmailError('');
    if (passwordError) setPasswordError('');
    if (passwordConfirmError) setPasswordConfirmError('');
  };

  useFocusEffect(
    useCallback(() => {
      resetPasswordResetState();
      resetErrors();
      resetFields();
    }, [route]),
  );

  useEffect(() => {
    if (route?.params?.recovery_key) {
      requestGetRecoveryState(route.params.recovery_key);
      setRecoveryKey(route.params.recovery_key);
    }
  }, []);

  useEffect(() => {
    resetErrors();
  }, [email, password, passwordConfirm]);

  const validateEmail = () => {
    if (email.includes('@')) {
      if (emailError !== '') setEmailError(undefined);
      return true;
    }
    setEmailError(t(Strings.ERROR_EMAIL));
    return false;
  };

  const validatePasswords = () => {
    if (password) {
      if (password === passwordConfirm) {
        return true;
      }
      setPasswordConfirmError(t(Strings.NOT_SAME_PASSWORD));
    } else {
      setPasswordError(t(Strings.ERROR_PASSWORD_RESET));
    }
    return false;
  };

  const handleSubmitEmail = () => {
    if (validateEmail()) requestCreateRecovery(email);
  };

  const handleSubmitPassword = () => {
    if (validatePasswords()) requestTerminateRecovery(recoveryKey, password);
  };

  const showInfoBox = () =>
    passwordResetState !== PASSWORD_RESET_STATE.IDLE &&
    passwordResetState !== PASSWORD_RESET_STATE.USER_INPUT;

  const renderPasswordResetForm = () => {
    return (
      <>
        <Text style={styles.label}>{t(Strings.PASSWORD)}</Text>
        <TextInput
          placeholder={t(Strings.EXEMPLE_PASSWORD)}
          secureTextEntry={true}
          style={styles.input}
          onChangeText={setPassword}
          disabled={showInfoBox()}
        />
        {passwordError !== null && <Text style={styles.error}>{passwordError}</Text>}
        <Text style={styles.label}>
          {t(Strings.CONFIRM)} {t(Strings.PASSWORD)}
        </Text>
        <TextInput
          placeholder={t(Strings.EXEMPLE_PASSWORD)}
          secureTextEntry={true}
          style={styles.input}
          onChangeText={setPasswordConfirm}
          disabled={showInfoBox()}
        />
        {passwordConfirmError !== null && <Text style={styles.error}>{passwordConfirmError}</Text>}
      </>
    );
  };

  const renderEmailForm = () => {
    return (
      <>
        <Text style={styles.label}>{t(Strings.EMAIL)}</Text>
        <TextInput
          placeholder={t(Strings.EMAIL)}
          style={styles.input}
          onChangeText={setEmail}
          disabled={showInfoBox()}
        />
        {emailError !== null && <Text style={styles.error}>{emailError}</Text>}
      </>
    );
  };

  const renderForm = () => {
    return recoveryKey ? renderPasswordResetForm() : renderEmailForm();
  };

  const renderSubmitButton = () => {
    return recoveryKey ? (
      <Button
        disabled={currentlySendingTerminateRecovery}
        title={t(Strings.CONFIRM)}
        onPress={handleSubmitPassword}
        color="#5b41f0"
      />
    ) : (
      <Button
        disabled={currentlySendingCreateRecovery}
        title={t(Strings.CONFIRM)}
        onPress={handleSubmitEmail}
        color="#5b41f0"
      />
    );
  };

  const renderInfoBox = () => {
    if (passwordResetState === PASSWORD_RESET_STATE.EMAIL_SENT) {
      return (
        <View style={styles.infoBox}>
          <Text>
            {t(Strings.PASSWORD_RESET_EMAIL_SENT)} <b>{email}</b>.
          </Text>
        </View>
      );
    }
    if (passwordResetState === PASSWORD_RESET_STATE.COMPLETED) {
      return (
        <View style={styles.infoBox}>
          <Text>{t(Strings.PASSWORD_RESET_SUCCESS)}</Text>
        </View>
      );
    }
    if (passwordResetState === PASSWORD_RESET_STATE.RESET_REFUSED) {
      return (
        <View style={[styles.infoBox, styles.infoBoxError]}>
          <Text>{t(Strings.PASSWORD_RESET_REFUSED)}</Text>
        </View>
      );
    }
    return (
      <View style={[styles.infoBox, styles.infoBoxError]}>
        <Text>{t(Strings.PASSWORD_RESET_INVALID_KEY)}</Text>
      </View>
    );
  };

  if (recoveryKey && passwordResetState === PASSWORD_RESET_STATE.IDLE) return null;
  // Waiting for user input or key refused

  return (
    <View style={{ width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
      <BackButton enableRedirectionIfCantGoBack redirectionIfCantGoBack={screenName.LOGIN} />
      <View style={styles.form}>
        <View style={styles.formContainer}>
          {renderForm()}
          {showInfoBox() ? renderInfoBox() : renderSubmitButton()}
        </View>
      </View>
    </View>
  );
};

ResetPasswordPage.propTypes = {
  route: PropTypes.object,
  requestGetRecoveryState: PropTypes.func,
  requestCreateRecovery: PropTypes.func,
  requestTerminateRecovery: PropTypes.func,
  resetPasswordResetState: PropTypes.func,
  passwordResetState: PropTypes.string,
  currentlySendingCreateRecovery: PropTypes.bool,
  currentlySendingTerminateRecovery: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
  passwordResetState: userSelectors.makeSelectPasswordResetState(),
  currentlySendingCreateRecovery: appSelectors.makeSelectCurrentlySending([
    REQUEST_CREATE_RECOVERY,
  ]),
  currentlySendingTerminateRecovery: appSelectors.makeSelectCurrentlySending([
    REQUEST_TERMINATE_RECOVERY,
  ]),
});

const mapDispatchToProps = (dispatch) => {
  return {
    resetPasswordResetState: () =>
      dispatch(userActions.setPasswordResetState(PASSWORD_RESET_STATE.IDLE)),
    requestGetRecoveryState: (key) => dispatch(userActions.requestGetRecoveryState(key)),
    requestCreateRecovery: (email) => dispatch(userActions.requestCreateRecovery(email)),
    requestTerminateRecovery: (key, password) =>
      dispatch(userActions.requestTerminateRecovery(key, password)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordPage);
