import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  // Request API
  requestAuthInfo: [],
  requestOrganization: [],
  requestRooms: [],
  requestDevices: [],

  // Set reducers
  setAuthInfo: ['authInfo'],
  setOrganization: ['organization'],
  setRooms: ['rooms'],
  setDevices: ['devices'],
  setOnlineDevices: ['ids'],

  resetOrganization: [],
});

export { Types };
export default Creators;
