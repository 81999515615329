import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, Text, TouchableOpacity, useWindowDimensions, View } from 'react-native';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import activitySelectors from '../../App/selectors/activity';
import activityActions from '../../App/actions/activity';
import appSelectors from '../../App/selectors/app';
import organizationSelectors from '../../App/selectors/organization';
import roomSelectors from '../../App/selectors/room';
import userSelectors from '../../App/selectors/user';
import RaiseHandSvg from '../../components/Svg/raiseHand';
import { ACTIVITY_COUNTDOWN } from '../../constants/activity';
import colors from '../../constants/colors';
import fonts from '../../constants/fonts';
import { USER_ROLE } from '../../constants/global';
import { screenName } from '../../constants/navigation';
import ActivityInteractionsContainer from '../../containers/Activity/interactions';
import NavBarTop from '../../containers/NavBarTop';
import Player, { PREVIEW_MODE } from '../../containers/Player';
import VideoControl from '../../containers/Player/videoControl';
import { ActivityInteractionStatus } from '../../helpers/interactions';
import { userRoleIsGTE } from '../../helpers/role';
import { SocketStatus } from '../../helpers/socket';
import { Strings } from '../../helpers/strings';
import { sGlobal } from '../../styles/fixed';
import RightMenu from './rightMenu';

const RAISE_HAND_COLOR_MAP = {
  [ActivityInteractionStatus.WAITING_RESPONSE]: colors.getSunglow(), // waiting
  [ActivityInteractionStatus.WAITING_MEDIA]: colors.getSunglow(), // waiting
  [ActivityInteractionStatus.WAITING_TO_JOIN]: colors.getSunglow(), // waiting
  [ActivityInteractionStatus.RUNNING]: colors.getTorchRed(), // running
  [ActivityInteractionStatus.REFUSED]: colors.getMainPurple(), // stopped
  [ActivityInteractionStatus.TERMINATED]: colors.getMainPurple(), // stopped
  [ActivityInteractionStatus.CANCELED]: colors.getMainPurple(), // stopped
  default: colors.getMainPurple(),
};

const ENABLE_PREVIEW = true;

const LIMIT_WIDTH_FOR_CHAT = 400;
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.getMirage(),
    flexDirection: 'row',
  },
  main: {
    flexDirection: 'column',
    flex: 5,
    paddingLeft: sGlobal.padding,
    paddingRight: sGlobal.padding,
  },
  rightMenu: {
    backgroundColor: colors.getMirage(),
  },
  bottomControls: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: sGlobal.padding,
  },
  elements: {
    flex: 5,
    maxWidth: '20%',
    maxHeight: '100%',
    overflow: 'auto',
    borderRightWidth: 0.5,
    borderRightColor: colors.getGraySantas(),
  },
  connexionLostMessage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: fonts.getHalisR(),
    fontSize: 18,
    height: 40,
    color: colors.getMainWhite(),
  },
  interactionContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    maxHeight: '100%',
    maxWidth: '100%',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
  interactionToastContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    gap: 5,
  },
  interactionToastDescription: {
    fontFamily: fonts.getSignika(),
    fontSize: 16,
    color: colors.getMainWhite(),
  },
  interactionToastMessage: {
    fontFamily: fonts.getSignika(),
    fontSize: 14,
    color: colors.getGrayLevel(0.6),
  },
});

const stylesSmallMode = StyleSheet.create({
  rightMenu: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
});

const stylesLargeMode = StyleSheet.create({
  rightMenu: {
    minWidth: LIMIT_WIDTH_FOR_CHAT,
    flex: 2,
  },
});

const getResponsiveStyles = (width, height) => {
  let result = stylesLargeMode;
  if (width < 600) result = stylesSmallMode;
  return result;
};

const CountdownSteps = [
  { value: 10000, message: ACTIVITY_COUNTDOWN.TEN_SECONDS },
  { value: 60000, message: ACTIVITY_COUNTDOWN.ONE_MINUTE },
  { value: 300000, message: ACTIVITY_COUNTDOWN.FIVE_MINUTES },
];

const ActivityPlayer = ({
  user,
  activity,
  activityState,
  interactions,
  devices,
  unReadMessages,
  socketStatus,
  playerMessageEvent,
}) => {
  const hasControlRights = () => userRoleIsGTE(user.role, USER_ROLE.ORGANIZER);
  const showControls = () => socketStatus === SocketStatus.CONNECTED && hasControlRights();

  const { t } = useTranslation();
  const organizationSettings = organizationSelectors.useSelectorOrganizationSettings();
  const playerContainerRef = useRef();
  const playerRef = useRef();
  const [isOngoing, setIsOngoing] = useState(false);
  const [displayRightMenu, setDisplayRightMenu] = useState(false);
  const [displayPreviewButton, setDisplayPreviewButton] = useState(false);
  const [displayInteractionsButton, setDisplayInteractionsButton] = useState(true);
  const [enableInteractions, setEnableInteractions] = useState(activity.interactionsAreEnabled);
  const [displayInteractions, setDisplayInteractions] = useState(false);
  const [displayRequestInteraction, setDisplayRequestInteraction] = useState(false);
  const [currentInteractionStatus, setCurrentInteractionStats] = useState();
  const currentInteractionRef = useRef();

  const interactionAreEnabled = organizationSettings?.interactions?.status === 'enabled';

  const { width, height } = useWindowDimensions();
  const responsiveStyles = getResponsiveStyles(width, height);
  const chatInFullscreen = responsiveStyles === stylesSmallMode && displayRightMenu;

  const getDevice = () => {
    // TODO :
    // if activityState.isRecording || activityState.isInLive
    //   return activityState._device
    // else
    //  no device connected - take first
    return devices.find((d) => d.online) || devices[0];
  };

  const activityIsReady = () => {
    const device = getDevice();
    if (!device) return false;
    return device.online && isOngoing;
  };

  const alertedSteps = useRef([]);

  const clockActions = (init = false) => {
    const start = new Date(activity.startDatetime).getTime();
    const end = new Date(activity.endDatetime).getTime();
    const now = Date.now();

    const isOngoingUpdated = start <= now && now <= end;
    if (isOngoingUpdated !== isOngoing) setIsOngoing(isOngoingUpdated);

    const left = end - now;
    if (left <= 0) return;
    // eslint-disable-next-line no-restricted-syntax
    for (const step of CountdownSteps) {
      if (left <= step.value) {
        if (!alertedSteps.current.includes(step.value)) {
          if (!init) playerMessageEvent(step.message);
          alertedSteps.current.push(step.value);
        }
        return; // useless to go further
      }
    }
  };

  useEffect(() => {
    clockActions(true); // we consume steps that were already reached, without alerting
    const timeout = setInterval(() => {
      clockActions();
    }, 1000);
    return () => clearInterval(timeout);
  }, [activity]);

  const renderRightMenu = () => {
    return (
      <View style={[styles.rightMenu, responsiveStyles.rightMenu]}>
        <RightMenu
          onDisplayChange={(value) => {
            setDisplayRightMenu(value);
          }}
        />
      </View>
    );
  };

  const getSource = () => activity.liveProfile?.urlPlayer;

  const isInLive = () => {
    return activityState?.liveStatus === 'active';
  };

  const getLiveStartedAt = () => {
    return activityState?.liveStatus === 'active'
      ? getDevice().context?.videoContext?.liveStartedAt
      : undefined;
  };

  const getRecordStartedAt = () => {
    return activityState?.recordStatus === 'active'
      ? getDevice().context?.videoContext?.recordStartedAt
      : undefined;
  };

  const renderTopNavigationBar = () => {
    if (socketStatus === SocketStatus.CONNECTED) {
      return (
        <NavBarTop
          liveStartedAt={getLiveStartedAt()}
          recordStartedAt={getRecordStartedAt()}
          activityStartsAt={activity?.startDatetime}
          activityEndsAt={activity?.endDatetime}
          activityState={activityState}
          activityCanLive={activity?.shouldLive}
          displayChatMenu={displayRightMenu}
          chatBadge={unReadMessages}
          onDisplayChatMenuChange={(value) => {
            setDisplayRightMenu(value);
          }}
          displayBackButton={!chatInFullscreen}
          backButtonOptions={{
            enableRedirectionIfCantGoBack: true,
            redirectionIfCantGoBack: screenName.ACTIVITY_SCREENS,
            paramsIfCantGoBack: {
              screen: 'activity',
              params: { id: activity._id },
            },
          }}
          displayPreviewButton={ENABLE_PREVIEW && activityIsReady() && displayPreviewButton}
          onDisplayPreviewButton={() => {
            setDisplayPreviewButton(false);
            // eslint-disable-next-line no-unused-expressions
            playerRef.current?.setPreviewMode(PREVIEW_MODE.NORMAL);
          }}
          displayInteractionsButton={
            hasControlRights() && interactionAreEnabled && displayInteractionsButton
          }
          enableInteractions={enableInteractions}
          interactions={interactions}
          onInteractionsButton={() => {
            setDisplayInteractions(!displayInteractions);
          }}
        />
      );
    }
    if (socketStatus === SocketStatus.LOADING) {
      return (
        <Text style={styles.connexionLostMessage}>{t(Strings.SOCKET_CONNEXION_LOST_RETRYING)}</Text>
      );
    }
    return <Text style={styles.connexionLostMessage}>{t(Strings.SOCKET_CONNEXION_LOST)}</Text>;
  };

  const renderBottomControls = () => {
    /* const interactionIsMine = currentInteractionRef.current?.user?.id === user?.id;
    const interactionIsProcessing = currentInteractionStatus === ActivityInteractionStatus.WAITING_RESPONSE ||
    currentInteractionStatus === ActivityInteractionStatus.WAITING_MEDIA ||
    currentInteractionStatus === ActivityInteractionStatus.WAITING_TO_JOIN ||
    currentInteractionStatus === ActivityInteractionStatus.RUNNING; */
    const displayInteractionForAttendee =
      interactionAreEnabled &&
      isInLive() &&
      activityIsReady() &&
      displayInteractionsButton &&
      activity.interactionsAreEnabled;
    const colorRaiseHand =
      RAISE_HAND_COLOR_MAP[currentInteractionStatus] ?? RAISE_HAND_COLOR_MAP.default;
    return (
      <View style={styles.bottomControls}>
        {hasControlRights() &&
        activityIsReady() &&
        (activity.shouldLive || activity.shouldRecord) ? (
          <VideoControl device={getDevice()} activity={activity} activityState={activityState} />
        ) : null}
        {displayInteractionForAttendee ? (
          <TouchableOpacity
            onPress={() => {
              setDisplayInteractions(false);
              setDisplayRequestInteraction(!displayRequestInteraction);
            }}
            style={{
              padding: 10,
            }}
          >
            <RaiseHandSvg
              fill={colorRaiseHand}
              stroke={colors.getMainBlack()}
              strokeWidth={0.5}
              encircling={true}
              enabled={true}
              height={75}
            />
          </TouchableOpacity>
        ) : null}
      </View>
    );
  };

  return (
    <View style={styles.container}>
      <View style={[styles.main]} ref={playerContainerRef}>
        {renderTopNavigationBar()}
        <Player
          ref={playerRef}
          activityIsOngoing={isOngoing}
          activityCanLive={activity?.shouldLive}
          activityCanRecord={activity?.shouldRecord}
          activityState={activityState}
          containerRef={playerContainerRef}
          defaultPreviewMode={
            ENABLE_PREVIEW && hasControlRights() ? PREVIEW_MODE.HIDDEN : PREVIEW_MODE.NORMAL
          }
          onPreviewMode={(mode) => {
            if (hasControlRights() && mode === PREVIEW_MODE.HIDDEN) {
              setDisplayPreviewButton(true);
            }
          }}
          device={getDevice()}
          source={getSource()}
          enableControls={showControls()}
        />
        {renderBottomControls()}
      </View>
      {displayRightMenu ? renderRightMenu() : null}
      <ActivityInteractionsContainer
        enableInteractions={enableInteractions}
        onEnableInteractions={setEnableInteractions}
        displayInteractions={displayInteractions}
        onDisplayInteractions={setDisplayInteractions}
        displayRequestInteraction={displayRequestInteraction}
        onDisplayRequestInteraction={setDisplayRequestInteraction}
        onCurrentInteractionStatus={(interactionId, status) => {
          currentInteractionRef.current = interactions?.find((curr) => curr?.id === interactionId);
          setCurrentInteractionStats(status);
        }}
        interactions={interactions}
        activity={activity}
        device={getDevice()}
      />
    </View>
  );
};

ActivityPlayer.propTypes = {
  user: PropTypes.object.isRequired,
  activity: PropTypes.object.isRequired,
  activityState: PropTypes.object.isRequired,
  playerMessageEvent: PropTypes.func.isRequired,
  interactions: PropTypes.array.isRequired,
  devices: PropTypes.array.isRequired,
  unReadMessages: PropTypes.number.isRequired,
  socketStatus: PropTypes.string.isRequired,
};

const mapStateToProps = createStructuredSelector({
  user: userSelectors.makeSelectUserInfo(),
  devices: activitySelectors.makeSelectActivityDevices(),
  unReadMessages: roomSelectors.makeSelectCounterUnreadMessage(),
  socketStatus: appSelectors.makeSelectSocketStatus(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    playerMessageEvent: (messageType) => dispatch(activityActions.playerMessageEvent(messageType)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivityPlayer);
