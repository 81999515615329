import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import colors from '../../constants/colors';
import fonts from '../../constants/fonts';

export const BADGE_MODE = {
  NUMBER: 'number',
  STRING: 'string',
};

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    top: 0,
    right: 0,
    borderRadius: 5,
    backgroundColor: colors.getRed(),
    transform: 'translate(10%, -25%)',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    paddingVertical: 1,
    paddingHorizontal: 2,
  },
  hide: {
    display: 'none',
  },
  empty: {
    height: 15,
    width: 15,
    borderRadius: 10,
  },
  text: {
    fontFamily: fonts.getSignika(),
    fontSize: 14,
    color: colors.getMainWhite(),
    textAlign: 'center',
    minWidth: 15,
  },
});

const Badge = ({ empty, value, number, maxNumber, prefix, suffix, mode, hide, style }) => {
  const viewStyle = [styles.container];
  if (style) viewStyle.push(...(Array.isArray(style) ? style : [style]));

  let text = null;
  if (empty) {
    viewStyle.push(styles.empty);
  } else {
    if (mode === BADGE_MODE.STRING) {
      text = value;
    } else if (mode === BADGE_MODE.NUMBER) {
      text = number;
      if (maxNumber && maxNumber > 0 && number > maxNumber) text = `+${maxNumber}`;
    }

    if (!text || hide) viewStyle.push(styles.hide);
  }

  const render = () => {
    return <Text style={styles.text}>{(prefix || '') + text + (suffix || '')}</Text>;
  };
  return <View style={viewStyle}>{empty ? null : render()}</View>;
};

Badge.propTypes = {
  empty: PropTypes.bool,
  value: PropTypes.string,
  number: PropTypes.number,
  maxNumber: PropTypes.number,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  mode: PropTypes.oneOf([BADGE_MODE.NUMBER, BADGE_MODE.STRING]),
  hide: PropTypes.bool,
  style: PropTypes.any,
};

Badge.defaultProps = {
  empty: false,
  maxNumber: -1,
  mode: BADGE_MODE.STRING,
  prefix: '',
  suffix: '',
  hide: false,
};

export default connect(null, null)(Badge);
