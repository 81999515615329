import { StyleSheet, View, TextInput } from 'react-native';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import moment from 'moment';

import { useTranslation } from 'react-i18next';
import colors from '../../constants/colors';

import roomActions from '../../App/actions/room';
import userSelectors from '../../App/selectors/user';

import SendButton from '../../assets/blue/icon-send-message.svg';
import { Strings } from '../../helpers/strings';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 15,
    paddingRight: 15,
    paddingBottom: 10,
    minHeight: 56, // fix height (10 padding bottom + 46 by default for the input)
    maxHeight: 56, // fix height (10 padding bottom + 46 by default for the input)
  },
  inputContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.getClayEbony(0.75),
    borderRadius: 10,
    padding: 13,
  },
  inputText: {
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    height: '100%',
    width: '85%',
    color: 'white',
    marginRight: 20,
    outlineWidth: 0,
    outlineStyle: 'none',
  },
  inputSubmit: {
    minHeight: 20, // fix icon size
    maxHeight: 20, // fix icon size
    minWidth: 20, // fix icon size
    maxWidth: 20, // fix icon size
    marginLeft: 5,
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
});

const Footer = (props) => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState('');

  // const [number, setNumber] = useState(0);

  // const genRandomText = () => {
  //   const alpha = 'akdp md dddd aa,on rg eee"n hd';
  //   const alphaLen = alpha.length;
  //   const randInt = Math.floor(Math.random() * 200);
  //   let msg = '';
  //   for (let i = 0; i < randInt; i++) {
  //     msg += alpha[Math.floor(Math.random() * alphaLen)];
  //   }
  //   return msg;
  // }

  // const isIOS = () => {
  //   const toMatch = [/iPhone/i, /iPad/i, /iPod/i];
  //   return toMatch.some((toMatchItem) => navigator.userAgent.match(toMatchItem));
  // };

  // useEffect(() => {
  //   if (!isIOS()) {
  //     if (number > 50) return;
  //     const id = setTimeout(() => {
  //     setInputValue('message [' + number + ']: ' + genRandomText());
  //     setNumber((n) => n + 1);
  //     }, 250);
  //     return () => clearTimeout(id);
  //   }
  // }, [number]);

  // useEffect(() => {
  //   if (!isIOS()) {
  //     if (inputValue === '') return;
  //     handleSubmit();
  //   }
  // }, [inputValue]);

  useEffect(() => props.inputRef.current?.focus(), [inputValue]);

  const handleChange = (event) => {
    event.preventDefault();
    setInputValue(event.target.value);
  };

  const handleSubmit = () => {
    const inputValueCopy = inputValue;
    setInputValue('');

    if (inputValueCopy.trim() === '') return;

    const message = {
      id: undefined,
      content: inputValueCopy,
      datetime: moment().format(),
      reactions: undefined,
      author: {
        id: props.user.id,
        username: props.user.username,
        role: props.user.role,
        avatar: props.user.avatar,
      },
    };
    props.setOwnMessage(message);
  };

  const chatInput = () => {
    return (
      <View style={styles.inputContainer}>
        <TextInput
          ref={props.inputRef}
          type="text"
          placeholder={t(Strings.SEND_A_MESSAGE)}
          value={inputValue}
          onChange={handleChange}
          style={styles.inputText}
          onSubmitEditing={handleSubmit} // Allow "Enter" key to submit the message
        />
        <SendButton onClick={handleSubmit} style={styles.inputSubmit} />
      </View>
    );
  };

  return <View style={styles.container}>{chatInput()}</View>;
};

Footer.propTypes = {
  user: PropTypes.object.isRequired,
  inputRef: PropTypes.object,

  // Actions
  setOwnMessage: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  user: userSelectors.makeSelectUserInfo(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    setOwnMessage: (message) => dispatch(roomActions.setOwnMessage(message)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
