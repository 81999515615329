import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { useElementSize, useWindowSize } from '../../hooks/element';

// --------------------------------
// REACT WEB
// --------------------------------

const containerStyle = {
  cursor: 'grab',
};

const MoveableContainer = ({
  horizontal,
  vertical,
  enable,
  parentContainerRef,
  onPosition,
  children,
  style,
}) => {
  const parentSize = parentContainerRef ? useElementSize(parentContainerRef) : useWindowSize();
  const containerRef = useRef();
  useElementSize(containerRef);
  const dataRef = useRef({
    alreadyGrabbed: false,
    grabbing: false,
    initialContainerPostion: { x: 0, y: 0 },
    initialGrabPosition: { x: 0, y: 0 },
    position: { left: 0, right: 0, top: 0, bottom: 0 },
    relativePosition: { left: 0, right: 0, top: 0, bottom: 0 },
    side: { x: 'left', y: 'top' },
  });

  const updateStyleToElement = (currStyle) => {
    if (!containerRef.current) return;
    const element = containerRef.current;
    Object.assign(element.style, currStyle);
  };

  const positionIsInElement = (x, y, element) => {
    const rect = element.getBoundingClientRect();
    if (x < rect.left || x > rect.left + rect.width) return false;
    if (y < rect.top || y > rect.top + rect.height) return false;
    return true;
  };

  const getParentContainer = () => parentContainerRef?.current ?? document.body;

  const updatePosition = (sideLocked = true) => {
    const element = containerRef.current;
    if ((!dataRef.current.grabbing && !dataRef.current.alreadyGrabbed) || !containerRef.current)
      return;
    const data = dataRef.current;
    const rect = element.getBoundingClientRect();
    const { position, relativePosition, side } = data;
    const newPosition = onPosition?.(position.left, position.top, rect);
    if (newPosition) {
      Object.assign(element.style, {
        left: `${newPosition.x}px))`,
        top: `${newPosition.y}px))`,
      });
    } else {
      const parentRect = getParentContainer().getBoundingClientRect();

      let horizontalSide = side.x;
      let verticalSide = side.y;
      if (!sideLocked) {
        horizontalSide = relativePosition.left <= relativePosition.right ? 'left' : 'right';
        verticalSide = relativePosition.top <= relativePosition.bottom ? 'top' : 'bottom';
      }
      data.side = { x: horizontalSide, y: verticalSide };

      if (horizontalSide === 'left') {
        // left side
        const minX = parentRect.left;
        const maxX = parentRect.right - rect.width;
        const left = relativePosition.left + minX;
        Object.assign(element.style, {
          left: `max(${minX}px, min(${maxX}px, ${left}px))`,
          right: null,
        });
      } else {
        // right side
        const minX = document.body.clientWidth - parentRect.right;
        const maxX = document.body.clientWidth - (parentRect.left + rect.width);
        const right = relativePosition.right + minX;
        Object.assign(element.style, {
          right: `max(${minX}px, min(${maxX}px, ${right}px))`,
          left: null,
        });
      }

      if (verticalSide === 'top') {
        // top side
        const minY = parentRect.top;
        const maxY = parentRect.bottom - rect.height;
        const top = relativePosition.top + minY;
        Object.assign(element.style, {
          top: `max(${minY}px, min(${maxY}px, ${top}px))`,
          bottom: null,
        });
      } else {
        // bottom side
        const minY = document.body.clientHeight - parentRect.bottom;
        const maxY = document.body.clientHeight - (parentRect.top + rect.height);
        const bottom = relativePosition.bottom + minY;
        Object.assign(element.style, {
          bottom: `max(${minY}px, min(${maxY}px, ${bottom}px))`,
          top: null,
        });
      }
    }
  };

  const actionDown = (posX, posY) => {
    const data = dataRef.current;
    const element = containerRef.current;

    const rect = element.getBoundingClientRect();
    const parentRect = getParentContainer().getBoundingClientRect();
    data.initialContainerPostion = { x: rect.left, y: rect.top };
    data.position = {
      left: rect.left,
      right: rect.right,
      top: rect.top,
      bottom: rect.bottom,
    };
    data.relativePosition = {
      left: data.position.left - parentRect.left,
      right: parentRect.right - data.position.right,
      top: data.position.top - parentRect.top,
      bottom: parentRect.bottom - data.position.bottom,
    };
    data.initialGrabPosition = { x: posX, y: posY };
    updateStyleToElement({
      position: 'fixed',
      bottom: null,
      right: null,
      top: null,
      left: null,
      cursor: 'grabbing',
    });
    data.grabbing = true;
    data.alreadyGrabbed = true;
    updatePosition();
  };

  const actionMove = (posX, posY) => {
    const data = dataRef.current;
    const element = containerRef.current;

    const rect = element.getBoundingClientRect();
    const parentRect = getParentContainer().getBoundingClientRect();
    const left = data.initialContainerPostion.x + (posX - data.initialGrabPosition.x);
    const top = data.initialContainerPostion.y + (posY - data.initialGrabPosition.y);

    data.position = {
      left,
      right: left + rect.width,
      top,
      bottom: top + rect.height,
    };
    data.relativePosition = {
      left: data.position.left - parentRect.left,
      right: parentRect.right - data.position.right,
      top: data.position.top - parentRect.top,
      bottom: parentRect.bottom - data.position.bottom,
    };
    updatePosition(false);
  };

  const actionUp = () => {
    if (!dataRef.current.grabbing || !containerRef.current) return;
    const data = dataRef.current;
    updateStyleToElement({
      cursor: 'grab',
    });
    data.grabbing = false;
  };

  const mouseDown = (event) => {
    const posX = event.pageX;
    const posY = event.pageY;
    if (dataRef.current.grabbing || !containerRef.current) return;
    // eslint-disable-next-line no-bitwise
    const leftButtonClicked = event.buttons & 0x1;
    if (!leftButtonClicked) return;
    const element = containerRef.current;
    if (!positionIsInElement(posX, posY, element)) return;
    event.preventDefault();
    actionDown(posX, posY);
    event.stopPropagation();
  };
  const mouseMove = (event) => {
    const posX = event.pageX;
    const posY = event.pageY;
    if (!dataRef.current.grabbing || !containerRef.current) return;
    // eslint-disable-next-line no-bitwise
    const leftButtonClicked = event.buttons & 0x1;
    if (!leftButtonClicked) {
      actionUp();
      return;
    }
    event.preventDefault();
    actionMove(posX, posY);
  };
  const mouseUp = (event) => {
    // eslint-disable-next-line no-bitwise
    const leftButtonClicked = event.buttons & 0x1;
    if (leftButtonClicked) return;
    actionUp();
  };

  const touchDown = (event) => {
    if (!event.touches?.length) return;
    const posX = event.touches[0].pageX;
    const posY = event.touches[0].pageY;
    if (dataRef.current.grabbing || !containerRef.current) return;
    const element = containerRef.current;
    if (!positionIsInElement(posX, posY, element)) return;
    event.preventDefault();
    actionDown(posX, posY);
    event.stopPropagation();
  };
  const touchMove = (event) => {
    if (!event.touches?.length) return;
    const posX = event.touches[0].pageX;
    const posY = event.touches[0].pageY;
    if (!dataRef.current.grabbing || !containerRef.current) return;
    event.preventDefault();
    actionMove(posX, posY);
  };
  const touchUp = (event) => {
    actionUp();
  };

  useEffect(() => {
    document.body.addEventListener('mousedown', mouseDown);
    document.body.addEventListener('mousemove', mouseMove);
    document.body.addEventListener('mouseup', mouseUp);

    document.body.addEventListener('touchstart', touchDown);
    document.body.addEventListener('touchmove', touchMove);
    document.body.addEventListener('touchend', touchUp);
    return () => {
      document.body.removeEventListener('mousedown', mouseDown);
      document.body.removeEventListener('mousemove', mouseMove);
      document.body.removeEventListener('mouseup', mouseUp);
      // document.body.removeEventListener('mouseleave', actionCancel);
    };
  }, []);

  updatePosition();

  const currStyle = { ...containerStyle };
  if (style) Object.assign(currStyle, style);
  return (
    <div style={currStyle} ref={containerRef}>
      {children}
    </div>
  );
};

MoveableContainer.propTypes = {
  horizontal: PropTypes.bool,
  vertical: PropTypes.bool,
  enable: PropTypes.bool,
  parentContainerRef: PropTypes.any,
  onPosition: PropTypes.func,
  children: PropTypes.element,
  style: PropTypes.any,
};

MoveableContainer.defaultProps = {
  horizontal: true,
  vertical: true,
  enable: true,
  parentContainerRef: undefined,
  onPosition: undefined,
  style: undefined,
};

export default connect(null, null)(MoveableContainer);
