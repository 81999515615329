import React from 'react';
import { StyleSheet } from 'react-native';
import { ActivityIndicator } from 'react-native-web';

const styles = StyleSheet.create({
  loading: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const LoadingPage = () => {
  return <ActivityIndicator size="large" style={styles.loading} />;
};

export default LoadingPage;
