import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import colors from '../../constants/colors';

const LogoAnimatedSvg = ({ colorLeft, colorRight }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1219.703 340.342">
    <title>WEB_KALYZEE_LOGOTYPE</title>
    <g>
      <path
        d="M1418.993,221.669l-5.531-18.944a8.407,8.407,0,0,0-11.16-5.463l-59.511,23.52a8.409,8.409,0,0,0-5,10.1l2.5,8.864a8.407,8.407,0,0,0,9.859,5.941l62.546-13.44A8.408,8.408,0,0,0,1418.993,221.669Z"
        transform="translate(-400.267 -196.67)"
        fill={colorRight}
      />
      <path
        d="M1032.24,437.684v95.455a3.873,3.873,0,0,1-3.873,3.873H986.258a3.872,3.872,0,0,1-3.872-3.873V437.684a3.806,3.806,0,0,0-.375-1.647l-78.8-163.572a3.873,3.873,0,0,1,3.5-5.534h46.122a3.874,3.874,0,0,1,3.5,2.211l46.741,98.331a3.872,3.872,0,0,0,7-.013l46.287-98.306a3.873,3.873,0,0,1,3.5-2.223H1106a3.873,3.873,0,0,1,3.505,5.521l-76.9,163.585A3.874,3.874,0,0,0,1032.24,437.684Z"
        transform="translate(-400.267 -196.67)"
        fill={colorRight}
      />
      <path
        d="M1133.428,270.327c1.01-2.35,2.218-3.851,4.338-3.851h143.422a3.85,3.85,0,0,1,3.388,5.661l-101.6,210.079a3.851,3.851,0,0,0,3.388,5.662h94.828a3.845,3.845,0,0,1,3.838,3.851V533.12a3.845,3.845,0,0,1-3.838,3.851H1104.348a3.851,3.851,0,0,1-3.385-5.667l102.045-210.068a3.85,3.85,0,0,0-3.384-5.667h-81.053c-2.12,0-4.787-1.782-3.839-3.851C1114.732,311.718,1129.049,280.515,1133.428,270.327Z"
        transform="translate(-400.267 -196.67)"
        fill={colorRight}
      />
      <path
        d="M1452.491,311.543V270.289a3.838,3.838,0,0,0-3.838-3.839H1309.536a3.838,3.838,0,0,0-3.838,3.839V533.174a3.838,3.838,0,0,0,3.838,3.838h139.117a3.838,3.838,0,0,0,3.838-3.838V491.439a3.838,3.838,0,0,0-3.838-3.838h-90.186a3.838,3.838,0,0,1-3.838-3.838V431.472a3.838,3.838,0,0,1,3.838-3.838h65.242a3.839,3.839,0,0,0,3.839-3.838V382.541a3.838,3.838,0,0,0-3.839-3.838h-65.242a3.839,3.839,0,0,1-3.838-3.838V319.22a3.838,3.838,0,0,1,3.838-3.838h90.186A3.838,3.838,0,0,0,1452.491,311.543Z"
        transform="translate(-400.267 -196.67)"
        fill={colorRight}
      />
      <path
        d="M1525.946,378.7h65.242a3.838,3.838,0,0,1,3.838,3.838V423.8a3.838,3.838,0,0,1-3.838,3.838h-65.242a3.838,3.838,0,0,0-3.838,3.838v52.291a3.839,3.839,0,0,0,3.838,3.838h90.186a3.838,3.838,0,0,1,3.838,3.838v41.735a3.838,3.838,0,0,1-3.838,3.838H1477.016a3.838,3.838,0,0,1-3.839-3.838V270.289a3.839,3.839,0,0,1,3.839-3.838h139.116a3.838,3.838,0,0,1,3.838,3.838v41.255a3.838,3.838,0,0,1-3.838,3.838h-90.186a3.838,3.838,0,0,0-3.838,3.838v55.645A3.838,3.838,0,0,0,1525.946,378.7Z"
        transform="translate(-400.267 -196.67)"
        fill={colorRight}
      />
      <path
        d="M871.57,483.171V271.359a4.43,4.43,0,0,0-4.43-4.43H827.067a4.43,4.43,0,0,0-4.43,4.43v261.21a4.43,4.43,0,0,0,4.43,4.43H957.325a4.43,4.43,0,0,0,4.43-4.43V492.031a4.43,4.43,0,0,0-4.43-4.43H876A4.43,4.43,0,0,1,871.57,483.171Z"
        transform="translate(-400.267 -196.67)"
        fill={colorRight}
      />
      <path
        d="M678.581,475.549a31.043,31.043,0,1,0,24.372,24.37A31.072,31.072,0,0,0,678.581,475.549Z"
        transform="translate(-400.267 -196.67)"
        fill={colorRight}
      />
      <animate attributeName="opacity" values="0" dur="0.2s" />
      <animate attributeName="opacity" values="0;1" begin="0.2" dur="0.6s" />
    </g>
    <g transform="translate(-400.267 -196.67)" fill={colorLeft}>
      <path d="M749.556,534.725A3.837,3.837,0,0,0,753,536.867h44.923a3.837,3.837,0,0,0,3.443-5.534L693.788,312.925l-18.94-38.688a3.838,3.838,0,0,0-6.915.043L649.382,313.4,574.215,472.572a3.838,3.838,0,0,1-6.942,0l-32.479-68.885a3.844,3.844,0,0,1,0-3.274l60.281-127.853a3.838,3.838,0,0,0-3.471-5.475H543.06a3.84,3.84,0,0,0-3.472,2.2L481.451,392.591l-3.687,7.82a3.837,3.837,0,0,0,0,3.274l3.687,7.819,58.137,123.307a3.838,3.838,0,0,0,3.472,2.2H595.2a3.813,3.813,0,0,0,3.474-2.239l32.455-69.335c-.053-.025,37.776-80.373,37.776-80.373a3.839,3.839,0,0,1,6.915-.059Z">
        <animateTransform
          attributeName="transform"
          attributeType="XML"
          type="scale"
          begin="0.0s"
          dur="0.4s"
          from="0.7 1"
          to="1 1"
        />
        <animate attributeName="opacity" values="0" begin="0s" dur="0.3s" />
        <animate attributeName="opacity" values="0.4;1" begin="0.3s" dur="0.2s" />
      </path>
    </g>
    <rect y="70.413" width="51.584" height="269.783" rx="3.838" fill={colorLeft}>
      <animateTransform
        attributeName="transform"
        attributeType="XML"
        type="scale"
        begin="0s"
        dur="0.3s"
        from="1 0"
        to="1 1"
      />
      <animate attributeName="opacity" values="0;1" dur="0.4s" />
    </rect>
  </svg>
);

LogoAnimatedSvg.propTypes = {
  colorLeft: PropTypes.string,
  colorRight: PropTypes.string,
};

LogoAnimatedSvg.defaultProps = {
  colorLeft: colors.getMainWhite(),
  colorRight: colors.getMainGreen(),
};

export default connect(null, null)(LogoAnimatedSvg);
