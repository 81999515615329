import React from 'react';
import { StyleSheet, View, Text } from 'react-native';
import PropTypes from 'prop-types';

import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import roomSelectors from '../../App/selectors/room';
import colors from '../../constants/colors';
import IconView from '../../assets/blue/icon-view.svg';
import fonts from '../../constants/fonts';

const styles = StyleSheet.create({
  global: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  image: {
    marginRight: 5,
  },
  label: {
    color: colors.getMainGreen(),
    fontFamily: fonts.getSignika(),
  },
});

const CounterViewers = ({ usersConnected, style }) => {
  const counter = usersConnected?.length;
  const viewStyle = style
    ? [styles.global, ...(Array.isArray(style) ? style : [style])]
    : styles.global;
  return (
    <View style={viewStyle}>
      <IconView style={styles.image} />
      <Text style={styles.label}>{counter}</Text>
    </View>
  );
};

CounterViewers.propTypes = {
  style: PropTypes.any,
  usersConnected: PropTypes.array.isRequired,
};

const mapStateToProps = createStructuredSelector({
  usersConnected: roomSelectors.makeSelectConnectedUsers(),
});

export default connect(mapStateToProps, null)(CounterViewers);
