import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { StyleSheet } from 'react-native';
import { useTranslation } from 'react-i18next';
import { Picker } from '@react-native-picker/picker';
import colors from '../../constants/colors';
import fonts from '../../constants/fonts';

import { Strings } from '../../helpers/strings';

const styles = StyleSheet.create({
  pickerItemContainer: {
    color: 'black',
    alignItems: 'center',
    flexDirection: 'row',
    marginLeft: 5,
    marginTop: 3,
    marginBottom: 3,
  },
  pickerDropdownContainer: {
    border: `solid 1px ${colors.getBlueOxford(0.14)}`,
    borderRadius: 4,
  },
  pickerParentItemContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    gap: 5,
    backgroundColor: colors.getGraySantas(),
  },
  pickerText: {
    fontFamily: fonts.getSignika(),
    fontSize: 16,
    fontWeight: 600,
    whiteSpace: 'nowrap',
  },
  picker: {
    display: 'flex',
    flexFlow: 'row',
    color: 'black',
    backgroundColor: colors.getMainWhite(),
    borderRadius: 8,
    border: `solid 1px ${colors.getBlueOxford(0.14)}`,
    height: 40,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  pickerDisabled: {
    opacity: 0.5,
  },
  icon: {
    width: 20,
    height: 20,
  },
});

const ActivityProfilePicker = ({
  value,
  setValue,
  open,
  setOpen,
  userLiveProfiles,
  roomLiveProfiles,
  formIsReady,
  disabled,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (!formIsReady) return;
    if (value) {
      if (userLiveProfiles.some((profile) => profile._id === value)) return;
      if (roomLiveProfiles.some((profile) => profile._id === value)) return;
    }
    if (userLiveProfiles.length > 0) setValue(userLiveProfiles[0]._id);
    else if (roomLiveProfiles.length > 0) setValue(roomLiveProfiles[0]._id);
    else setValue(undefined);
  }, [userLiveProfiles, roomLiveProfiles]);

  const getItems = () => {
    const userProfiles = userLiveProfiles.map((profile) => (
      <Picker.Item
        key={profile._id}
        label={`${profile.name} (${t(Strings.USER)})`}
        value={profile._id}
        style={{ backgroundColor: 'red' }}
      />
    ));

    const roomProfiles = roomLiveProfiles.map((profile) => (
      <Picker.Item
        key={profile._id}
        label={`${profile.name} (${t(Strings.ROOM)})`}
        value={profile._id}
      />
    ));

    return [...userProfiles, ...roomProfiles];
  };

  return (
    <Picker
      selectedValue={value}
      onValueChange={setValue}
      style={[styles.picker, disabled ? styles.pickerDisabled : null]}
      itemStyle={styles.pickerItemContainer}
      disabled={disabled}
    >
      {getItems()}
    </Picker>
  );
};

ActivityProfilePicker.defaultProps = {
  disabled: false,
};

ActivityProfilePicker.propTypes = {
  value: PropTypes.string,
  setValue: PropTypes.func.isRequired,
  open: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
  userLiveProfiles: PropTypes.array.isRequired,
  roomLiveProfiles: PropTypes.array.isRequired,
  formIsReady: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};

export default ActivityProfilePicker;
