const LOGIN = 'login';
const LOGOUT = 'logout';
const HOME = 'embedded';
const ORGANIZATIONS = 'organizations';
const ROOMS = 'rooms';
const SETTINGS = 'settings';
const MEDIAS = 'medias';
const HELP = 'help';
const ACTIVITY_SCREENS = 'activityScreens';
const SCHEDULE = 'schedule';
const ACTIVITY = 'activity';
const ACTIVITY_REDIRECTION = 'redirect';
const PANEL = 'panel';
const CALENDAR = 'calendar';
const COMING_SOON = 'coming_soon';
const FORBIDDEN = 'forbidden';
const LOADING = 'loading';
const VOD = 'vod';
const RESET_PASSWORD = 'reset_password';

export const screenName = {
  LOGIN,
  LOGOUT,
  HOME,
  ORGANIZATIONS,
  ROOMS,
  SETTINGS,
  MEDIAS,
  HELP,
  ACTIVITY_SCREENS,
  SCHEDULE,
  ACTIVITY,
  ACTIVITY_REDIRECTION,
  PANEL,
  CALENDAR,
  COMING_SOON,
  FORBIDDEN,
  LOADING,
  VOD,
  RESET_PASSWORD,
};

// TODO: Add not found page!

// TODO : Translate ?
export const screenTitle = {
  [LOGIN]: 'Login',
  [LOGOUT]: 'Logout',
  [HOME]: 'Home',
  [ORGANIZATIONS]: 'Organizations',
  [ROOMS]: 'Rooms',
  [SETTINGS]: 'Settings',
  [MEDIAS]: 'Medias',
  [HELP]: 'Help',
  [ACTIVITY_SCREENS]: 'Activity',
  [SCHEDULE]: 'Schedule',
  [ACTIVITY]: 'Activity',
  [ACTIVITY_REDIRECTION]: 'Activity',
  [PANEL]: 'Panel',
  [CALENDAR]: 'Calendar',
  [COMING_SOON]: 'Coming soon',
  [FORBIDDEN]: 'Forbidden',
  [LOADING]: 'Loading',
  [VOD]: 'VOD',
  [RESET_PASSWORD]: 'Reset Password',
};

export const screen = {
  [LOGIN]: { path: 'login' },
  [LOGOUT]: { path: 'logout' },
  [HOME]: { path: 'embedded' },
  [ORGANIZATIONS]: { path: 'organizations' },
  [ROOMS]: { path: 'rooms' },
  [SETTINGS]: { path: 'settings' },
  [MEDIAS]: { path: 'medias' },
  [HELP]: { path: 'help' },
  [ACTIVITY_SCREENS]: {
    path: 'activity',
    screens: {
      [SCHEDULE]: { path: 'schedule' },
      [ACTIVITY]: {
        path: 'activity',
        exact: true, // Without it, the path is activity/activity
      },
      [ACTIVITY_REDIRECTION]: {
        path: 'kapt/:short_id',
        exact: true,
      },
    },
  },
  [PANEL]: { path: 'panel' },
  // Using '?' so the route also works if section_name is missing
  // In this case, 'activities' will be put by default inside initialParams
  [CALENDAR]: { path: 'calendar/:section_name?' },
  [COMING_SOON]: { path: 'soon' },
  [FORBIDDEN]: { path: 'forbidden' },
  [LOADING]: { path: 'loading' },
  [VOD]: { path: 'vod' },
  [RESET_PASSWORD]: { path: 'reset_password/:recovery_key?' },
};

export const publicScreen = {
  // [LOGIN]: screen[LOGIN],
  [LOADING]: screen[LOADING],
  [COMING_SOON]: screen[COMING_SOON],
};

// export const privateScreen = {
//   [ROOMS]: screen[ROOMS],
//   [HOME]: screen[HOME],
//   [SETTINGS]: screen[SETTINGS],
//   [MEDIAS]: screen[MEDIAS],
//   [HELP]: screen[HELP],
//   [LOGOUT]: screen[LOGOUT],
//   [ACTIVITY_SCREENS]: screen[ACTIVITY_SCREENS],
//   [PANEL]: screen[PANEL],
//   [CALENDAR]: screen[CALENDAR],
// };

export const attendeeScreen = {
  [LOADING]: screen[LOADING],
  [COMING_SOON]: screen[COMING_SOON],
  [LOGOUT]: screen[LOGOUT],
  [ACTIVITY_SCREENS]: screen[ACTIVITY_SCREENS],
  [VOD]: screen[VOD],
};

export const organizerScreen = {
  [LOADING]: screen[LOADING],
  [COMING_SOON]: screen[COMING_SOON],
  [LOGOUT]: screen[LOGOUT],
  [ACTIVITY_SCREENS]: screen[ACTIVITY_SCREENS],
  [CALENDAR]: screen[CALENDAR],
  [VOD]: screen[VOD],
};

export const adminScreen = {
  [LOADING]: screen[LOADING],
  [COMING_SOON]: screen[COMING_SOON],
  [ORGANIZATIONS]: screen[ORGANIZATIONS],
  [ROOMS]: screen[ROOMS],
  [HOME]: screen[HOME],
  [SETTINGS]: screen[SETTINGS],
  [MEDIAS]: screen[MEDIAS],
  [HELP]: screen[HELP],
  [LOGOUT]: screen[LOGOUT],
  [VOD]: screen[VOD],
};

export default {
  screenName,
  screen,
  publicScreen,
  // privateScreen,
  adminScreen,
};
