import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, View } from 'react-native';
import { useTranslation } from 'react-i18next';
import { createStructuredSelector } from 'reselect';
import { openAuthSessionAsync } from 'expo-web-browser';
import organizationSelectors from '../../App/selectors/organization';
import userSelectors from '../../App/selectors/user';
import userActions from '../../App/actions/user';
import colors from '../../constants/colors';
import { AUTH_TYPE } from '../../constants/global';
import AppVersion from '../../components/Generic/appVersion';

import BackButton from '../../components/Generic/backButton';
import { Strings } from '../../helpers/strings';

const LogoutPage = ({ authType, authMethodCas, requestLogout }) => {
  const { t } = useTranslation();

  const preLogoutCas = async () => {
    const { origin, logoutPath } = authMethodCas.params;
    await openAuthSessionAsync(`${origin}${logoutPath}`);
  };

  const onLogout = () => {
    if (authType === AUTH_TYPE.CAS) requestLogout(preLogoutCas);
    else requestLogout();
  };

  return (
    <View style={{ width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
      <BackButton />
      <View style={{ width: 300, height: 100 }}>
        <Button title={t(Strings.DISCONNECT)} color={colors.getMainGreen()} onPress={onLogout} />
      </View>
      <AppVersion></AppVersion>
    </View>
  );
};

LogoutPage.propTypes = {
  authType: PropTypes.string,
  authMethodCas: PropTypes.object,

  requestLogout: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  authType: userSelectors.makeSelectAuthType(),
  authMethodCas: organizationSelectors.makeSelectAuthMethodCas(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    requestLogout: (preLogout, postLogout) =>
      dispatch(userActions.requestLogout(preLogout, postLogout)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LogoutPage);
