import { createReducer } from 'reduxsauce';
import { fromJS } from 'immutable';

import { Types } from '../actions/room';

/**
 * {
 *  _id: '',
 *  name: '',
 *  urlPlayer: '',
 *  messages: [],
 *  users: []
 * }
 */
const initialState = fromJS({
  messages: [],
  counterUnreadMessages: 0,
  users: [],
  displayRightMenu: false,
  devices: [],
});

const sortMessages = (messages) => {
  return messages.sort((a, b) => {
    return new Date(b.datetime) - new Date(a.datetime);
  });
};

const messageAreEquals = (m1, m2) => {
  return m1.correlationId && m2.correlationId && m1.correlationId === m2.correlationId;
};

const insertMessage = (messages, newMessage) => {
  let result = messages;
  if (result && newMessage) {
    // if message come from server
    if (newMessage.id) {
      // remove same message added localy before
      result = result.filter((m) => !messageAreEquals(m, newMessage));

      // remove message if present - need to update if already present, so we remove it before if needed
      result = result.filter((m) => m.id !== newMessage.id);
      result.push(newMessage);
    } else {
      // message added localy. Push message waiting receive this same message from server
      result.push(newMessage);
    }
  }
  return result ? sortMessages(result) : result;
};

export const setRoom = (state = initialState, { room }) => state.merge(fromJS(room));

// expect to have the first message be the earliest one, hence the reverse()
export const setMessages = (state = initialState, { messages }) =>
  state.set('messages', fromJS(sortMessages(messages)));

export const addMessages = (state = initialState, { messages }) =>
  state.update('messages', (oldMessages) =>
    fromJS(sortMessages(oldMessages.merge(messages).toJS())),
  );

// setOwnMessage & setIncomingMessage are identical, but their is a distinction for the middleware sockets!
export const setOwnMessage = (state = initialState, { message }) => {
  return state.update('messages', (messages) => fromJS(insertMessage(messages.toJS(), message)));
};

export const clearOwnMessage = (state = initialState, { correlationId }) => {
  return state.update('messages', (messages) =>
    messages.filterNot((m) => m.get('correlationId') === correlationId),
  );
};

export const setIncomingMessage = (state = initialState, { message }) =>
  state.update('messages', (messages) => fromJS(insertMessage(messages.toJS(), message)));

export const addMessageReaction = (state = initialState, { messageId, userId, reaction }) =>
  state.update('messages', (messages) => {
    const result = [];
    if (messages && messageId) {
      messages.toJS().forEach((m) => {
        const message = { ...m };
        if (message.id === messageId) {
          if (message.reactions) {
            message.reactions = message.reactions.filter((r) => r.author?.id !== userId);
            message.reactions.push({
              id: undefined,
              type: reaction,
              author: {
                id: userId,
              },
            });
          }
        }
        result.push(message);
      });
    }
    return fromJS(result);
  });

export const clearMessageReaction = (state = initialState, { messageId, userId }) =>
  state.update('messages', (messages) => {
    const result = [];
    if (messages && messageId) {
      messages.toJS().forEach((m) => {
        const message = { ...m };
        if (message.id === messageId) {
          if (message.reactions) {
            message.reactions = message.reactions.filter((r) => r.author?.id !== userId);
          }
        }
        result.push(message);
      });
    }
    return fromJS(result);
  });

export const clearCounterUnreadMessages = (state = initialState) =>
  state.set('counterUnreadMessages', 0);
export const incrementCounterUnreadMessages = (state = initialState) =>
  state.update('counterUnreadMessages', (value) => value + 1);

export const setConnectedUsers = (state = initialState, { users }) => {
  const result = [];
  // remove duplicate users
  // eslint-disable-next-line no-unused-expressions
  users?.forEach((user) => {
    if (!result.find((u) => u.id === user.id)) {
      result.push(user);
    }
  });
  return state.set('users', fromJS(result));
};

export const setConnectedUser = (state = initialState, { user }) =>
  state.update('users', (users) => {
    let result = users;
    if (!users.find((u) => u.get('id') === user.id)) {
      result = users.insert(0, fromJS(user));
    }
    return result;
  });

export const removeConnectedUser = (state = initialState, { user }) =>
  state.update('users', (users) => users.filterNot((el) => el.get('id') === user.id));

export const setDisplayRightMenu = (state = initialState, { bool }) =>
  state.set('displayRightMenu', bool);

export const setRoomDevices = (state = initialState, { devices }) =>
  state.set('devices', fromJS(devices));

export default createReducer(initialState, {
  [Types.SET_ROOM]: setRoom,
  [Types.SET_MESSAGES]: setMessages,
  [Types.ADD_MESSAGES]: addMessages,
  [Types.SET_OWN_MESSAGE]: setOwnMessage,
  [Types.CLEAR_OWN_MESSAGE]: clearOwnMessage,
  [Types.SET_INCOMING_MESSAGE]: setIncomingMessage,
  [Types.ADD_MESSAGE_REACTION]: addMessageReaction,
  [Types.CLEAR_MESSAGE_REACTION]: clearMessageReaction,
  [Types.CLEAR_COUNTER_UNREAD_MESSAGES]: clearCounterUnreadMessages,
  [Types.INCREMENT_COUNTER_UNREAD_MESSAGES]: incrementCounterUnreadMessages,
  [Types.SET_CONNECTED_USERS]: setConnectedUsers,
  [Types.SET_CONNECTED_USER]: setConnectedUser,
  [Types.REMOVE_CONNECTED_USER]: removeConnectedUser,
  [Types.SET_DISPLAY_RIGHT_MENU]: setDisplayRightMenu,
  [Types.SET_ROOM_DEVICES]: setRoomDevices,
});
