import React from 'react';
import { Text, View, StyleSheet } from 'react-native';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import colors from '../../constants/colors';
import fonts from '../../constants/fonts';

import ContentModal from '../Generic/contentModal';
import { Strings } from '../../helpers/strings';

const styles = StyleSheet.create({
  deleteModalContentContainer: {
    textAlign: 'center',
    backgroundColor: colors.getGrayAthens(),
    padding: 40,
  },
  deleteModalText: {
    fontFamily: fonts.getSignika(),
    fontWeight: 600,
    fontSize: 20,
    color: colors.getBluePrussian(),
  },
  purpleText: {
    fontFamily: fonts.getSignika(),
    fontSize: 22,
    fontWeight: 600,
    color: colors.getMainPurple(),
  },
  redText: {
    fontFamily: fonts.getSignika(),
    fontSize: 22,
    fontWeight: 600,
    color: colors.getDeleteRed(),
  },
});

const ActivityDeleteModal = ({ onConfirm, onCancel, isVisible, setIsVisible }) => {
  const { t } = useTranslation();

  const renderDeleteModalContent = () => {
    return (
      <View style={styles.deleteModalContentContainer}>
        <Text style={styles.deleteModalText}>
          {t(Strings.ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_ACTIVITY)}
        </Text>
      </View>
    );
  };

  return (
    <ContentModal
      name={t(Strings.DELETE)}
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      renderContent={renderDeleteModalContent}
      buttons={[
        { text: t(Strings.CANCEL), textStyle: styles.purpleText, onClick: onCancel },
        {
          text: t(Strings.DELETE),
          textStyle: styles.redText,
          onClick: onConfirm,
        },
      ]}
    />
  );
};

ActivityDeleteModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  setIsVisible: PropTypes.func.isRequired,
};

export default ActivityDeleteModal;
