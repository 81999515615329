import { call, put, race, select, take, takeLatest } from 'redux-saga/effects';

import appActions from '../actions/app';
import errorActions from '../actions/error';
import userActions, { Types as userTypes } from '../actions/user';
import userSelectors from '../selectors/user';
import roomActions from '../actions/room';
import userApi from '../api/user';
import organizationActions from '../actions/organization';
import api from '../api/_index';

import {
  setToken,
  removeToken,
  setAuthType,
  removeAuthType,
  getRefreshToken,
  setRefreshToken,
  removeRefreshToken,
} from '../../helpers/auth';
import { AUTH_TYPE, PUBLISH_TARGET_MODEL } from '../../constants/global';
import { ErrorType } from '../../constants/errors';
import { ResponseError } from '../../helpers/errors';
import { PASSWORD_RESET_STATE } from '../reducers/user';

const { SET_IS_REFRESHING_TOKEN, REFRESH_TOKEN_FAILED } = userTypes;

const {
  REQUEST_LOGIN_LOCAL,
  REQUEST_LOGIN_LTI,
  REQUEST_LOGIN_CAS,
  REQUEST_LOGIN_GOOGLE,
  REQUEST_LOGIN_AZURE_AD,
  REQUEST_LOGOUT,
  REQUEST_USER,
  REQUEST_ORGANIZATIONS,
  REQUEST_USER_ACTIVITIES,
  REQUEST_DELETE_USER_ACTIVITY,
  REQUEST_LIVE_PROFILES,
  RETRY_REQUEST,
  REQUEST_CREATE_RECOVERY,
  REQUEST_TERMINATE_RECOVERY,
  REQUEST_GET_RECOVERY_STATE,
  AUTH_USER,
} = userTypes;

function* authUser({ token, refreshToken }) {
  try {
    yield call(setToken, token);
    yield call(setRefreshToken, refreshToken);
    yield put(userActions.setIsAuthenticated(true)); // could use !!getToken() from helpers/auth also...
  } catch (error) {
    yield put(errorActions.handleError(error));
  }
}

function* resetStore(resetUser = true) {
  yield put(roomActions.resetRoom());
  yield put(organizationActions.resetOrganization());
  yield call(removeAuthType);
  if (resetUser) yield put(userActions.resetUser()); // Always do it in last (reset the isAuthenticated bool to redirect to login)
}

function* requestAndUpdateToken() {
  // Lock logic so we can check if there is concurrency
  try {
    yield put(userActions.setIsRefreshingToken(true));

    const rToken = yield call(getRefreshToken);
    if (!rToken) {
      throw new Error();
    }

    const { token, refreshToken } = yield call(userApi.requestRefreshToken, rToken);

    yield call(setToken, token);
    yield call(setRefreshToken, refreshToken);
  } catch (error) {
    yield put(userActions.refreshTokenFailed());
    throw new ResponseError(ErrorType.TOKEN_ERROR);
  } finally {
    yield put(userActions.setIsRefreshingToken(false));
  }
}

export function* submitRequest(sender, ...args) {
  let response = {};

  try {
    response = yield call(sender, ...args);
  } catch (error) {
    const { code, errorType } = error;
    if (errorType === ErrorType.API_ERROR && code === 460) {
      // Token needs a refresh
      const isRefreshing = yield select(userSelectors.makeSelectIsRefreshingToken());
      if (isRefreshing) {
        // Token is already being refreshed
        // We wait to know if the refresh worked
        const winner = yield race({
          refreshSuccess: take(SET_IS_REFRESHING_TOKEN),
          refreshFail: take(REFRESH_TOKEN_FAILED),
        });
        if (winner.refreshSuccess) {
          response = yield call(sender, ...args);
        }
      } else {
        // We need to update token ourself
        yield call(requestAndUpdateToken);
        response = yield call(sender, ...args);
      }
    } else if (errorType === ErrorType.NETWORK_ERROR) {
      yield put(errorActions.setError({ isNetwork: true }));
      yield take(RETRY_REQUEST);
      response = yield call(submitRequest, sender, ...args);
      yield put(errorActions.setError(undefined));
    } else {
      throw error;
    }
  }

  return response;
}

function* requestLoginLocal({ email, password }) {
  try {
    yield put(appActions.addCurrentlySending(`${REQUEST_LOGIN_LOCAL}`));

    const { token, refreshToken } = yield call(
      submitRequest,
      api.user.requestLoginLocal,
      email,
      password,
    );

    yield call(setAuthType, AUTH_TYPE.LOCAL);
    yield call(authUser, { token, refreshToken });
  } catch (error) {
    yield put(errorActions.handleError(error));
  } finally {
    yield put(appActions.removeCurrentlySending(`${REQUEST_LOGIN_LOCAL}`));
  }
}

function* requestLoginLti({ token }) {
  try {
    yield put(appActions.addCurrentlySending(`${REQUEST_LOGIN_LTI}`));

    // Removing token and resetting store the so isAuthenticated is set to false
    // and then set to true by authUser, triggering a state change,
    // after which /user/me is requested to retrieve the new user informations
    yield call(removeToken);
    yield call(resetStore);
    yield call(authUser, { token });

    yield call(setAuthType, AUTH_TYPE.LTI);
  } catch (error) {
    yield put(errorActions.handleError(error));
  } finally {
    yield put(appActions.removeCurrentlySending(`${REQUEST_LOGIN_LTI}`));
  }
}

function* requestLoginCas({ ticket }) {
  try {
    yield put(appActions.addCurrentlySending(`${REQUEST_LOGIN_CAS}`));

    const { token } = yield call(api.user.requestLoginCas, ticket);
    yield call(setAuthType, AUTH_TYPE.CAS);
    yield call(authUser, { token });
  } catch (error) {
    yield put(errorActions.handleError(error));
  } finally {
    yield put(appActions.removeCurrentlySending(`${REQUEST_LOGIN_CAS}`));
  }
}

function* requestLoginGoogle() {
  try {
    yield put(appActions.addCurrentlySending(`${REQUEST_LOGIN_GOOGLE}`));

    yield call(api.user.requestLoginGoogle);
  } catch (error) {
    yield put(errorActions.handleError(error));
  } finally {
    yield put(appActions.removeCurrentlySending(`${REQUEST_LOGIN_GOOGLE}`));
  }
}

function* requestLoginAzureAd() {
  try {
    yield put(appActions.addCurrentlySending(`${REQUEST_LOGIN_AZURE_AD}`));

    yield call(api.user.requestLoginAzureAd);
  } catch (error) {
    yield put(errorActions.handleError(error));
  } finally {
    yield put(appActions.removeCurrentlySending(`${REQUEST_LOGIN_AZURE_AD}`));
  }
}

function* requestLogout({ preLogout, postLogout }) {
  try {
    yield put(appActions.addCurrentlySending(`${REQUEST_LOGOUT}`));

    if (preLogout) preLogout();

    yield call(removeToken);
    yield call(removeRefreshToken);
    yield call(resetStore);

    if (postLogout) postLogout();
  } catch (error) {
    yield put(errorActions.handleError(error));
  } finally {
    yield put(appActions.removeCurrentlySending(`${REQUEST_LOGOUT}`));
  }
}

function* requestUser() {
  try {
    yield put(appActions.addCurrentlySending(`${REQUEST_USER}`));
    const user = yield call(submitRequest, api.user.requestUser);
    yield put(userActions.setUser(user));
  } catch (error) {
    yield put(errorActions.handleError(error));
  } finally {
    yield put(appActions.removeCurrentlySending(`${REQUEST_USER}`));
  }
}

function* requestOrganizations() {
  try {
    yield put(appActions.addCurrentlySending(`${REQUEST_ORGANIZATIONS}`));

    const organizations = yield call(api.organization.requestOrganizations);

    yield put(userActions.setOrganizations(organizations));
  } catch (error) {
    yield put(errorActions.handleError(error));
  } finally {
    yield put(appActions.removeCurrentlySending(`${REQUEST_ORGANIZATIONS}`));
  }
}

function* requestUserActivities({ skip, limit, sortMethod, sortOrder, reset }) {
  try {
    yield put(appActions.addCurrentlySending(`${REQUEST_USER_ACTIVITIES}`));

    const activities = yield call(submitRequest, api.activity.requestActivities, {
      skip,
      limit,
      sortMethod,
      sortOrder,
    });

    if (reset) yield put(userActions.setActivities(activities));
    else yield put(userActions.addActivities(activities));
  } catch (error) {
    yield put(errorActions.handleError(error));
  } finally {
    yield put(appActions.removeCurrentlySending(`${REQUEST_USER_ACTIVITIES}`));
  }
}

function* requestDeleteUserActivity({ _id }) {
  try {
    yield put(appActions.addCurrentlySending(`${REQUEST_DELETE_USER_ACTIVITY}`));
    yield call(submitRequest, api.activity.requestDeleteActivity, _id);
    yield put(userActions.deleteActivity(_id));
  } catch (error) {
    yield put(errorActions.handleError(error));
  } finally {
    yield put(appActions.removeCurrentlySending(`${REQUEST_DELETE_USER_ACTIVITY}`));
  }
}

function* requestLiveProfiles() {
  const user = yield select(userSelectors.makeSelectUserInfo());

  try {
    yield put(appActions.addCurrentlySending(`${REQUEST_LIVE_PROFILES}`));

    const liveProfiles = yield call(
      submitRequest,
      api.publishprofile.requestPublishProfiles,
      PUBLISH_TARGET_MODEL.USER,
      user._id,
    );

    yield put(userActions.setLiveProfiles(liveProfiles));
  } catch (error) {
    yield put(errorActions.handleError(error));
  } finally {
    yield put(appActions.removeCurrentlySending(`${REQUEST_LIVE_PROFILES}`));
  }
}

function* requestCreateRecovery({ email }) {
  try {
    yield put(appActions.addCurrentlySending(`${REQUEST_CREATE_RECOVERY}`));
    const result = yield call(submitRequest, api.user.requestCreateRecovery, email);
    const state = result?.allowed
      ? PASSWORD_RESET_STATE.EMAIL_SENT
      : PASSWORD_RESET_STATE.RESET_REFUSED;
    yield put(userActions.setPasswordResetState(state));
  } catch (error) {
    yield put(errorActions.handleError(error));
  } finally {
    yield put(appActions.removeCurrentlySending(`${REQUEST_CREATE_RECOVERY}`));
  }
}

function* requestTerminateRecovery({ key, password }) {
  try {
    yield put(appActions.addCurrentlySending(`${REQUEST_TERMINATE_RECOVERY}`));
    const result = yield call(submitRequest, api.user.requestTerminateRecovery, key, password);
    const state = result?.allowed
      ? PASSWORD_RESET_STATE.COMPLETED
      : PASSWORD_RESET_STATE.KEY_REFUSED;
    yield put(userActions.setPasswordResetState(state));
  } catch (error) {
    yield put(errorActions.handleError(error));
  } finally {
    yield put(appActions.removeCurrentlySending(`${REQUEST_TERMINATE_RECOVERY}`));
  }
}

function* requestGetRecoveryState({ key }) {
  try {
    yield put(appActions.addCurrentlySending(`${REQUEST_GET_RECOVERY_STATE}`));
    const result = yield call(submitRequest, api.user.requestGetRecoveryState, key);
    const state = result?.allowed
      ? PASSWORD_RESET_STATE.USER_INPUT
      : PASSWORD_RESET_STATE.KEY_REFUSED;
    yield put(userActions.setPasswordResetState(state));
  } catch (error) {
    yield put(errorActions.handleError(error));
  } finally {
    yield put(appActions.removeCurrentlySending(`${REQUEST_GET_RECOVERY_STATE}`));
  }
}

function* watcherRequestLoginLocal() {
  yield takeLatest(REQUEST_LOGIN_LOCAL, requestLoginLocal);
}

function* watcherRequestLoginLti() {
  yield takeLatest(REQUEST_LOGIN_LTI, requestLoginLti);
}

function* watcherRequestLoginCas() {
  yield takeLatest(REQUEST_LOGIN_CAS, requestLoginCas);
}

function* watcherRequestLoginGoogle() {
  yield takeLatest(REQUEST_LOGIN_GOOGLE, requestLoginGoogle);
}

function* watcherRequestLoginAzureAd() {
  yield takeLatest(REQUEST_LOGIN_AZURE_AD, requestLoginAzureAd);
}

function* watcherRequestLogout() {
  yield takeLatest(REQUEST_LOGOUT, requestLogout);
}

function* watcherRequestUser() {
  yield takeLatest(REQUEST_USER, requestUser);
}

function* watcherRequestOrganizations() {
  yield takeLatest(REQUEST_ORGANIZATIONS, requestOrganizations);
}

function* watcherRequestUserActivities() {
  yield takeLatest(REQUEST_USER_ACTIVITIES, requestUserActivities);
}

function* watcherRequestDeleteUserActivity() {
  yield takeLatest(REQUEST_DELETE_USER_ACTIVITY, requestDeleteUserActivity);
}

function* watcherRequestLiveProfiles() {
  yield takeLatest(REQUEST_LIVE_PROFILES, requestLiveProfiles);
}

function* watcherRequestCreateRecovery() {
  yield takeLatest(REQUEST_CREATE_RECOVERY, requestCreateRecovery);
}

function* watcherRequestTerminateRecovery() {
  yield takeLatest(REQUEST_TERMINATE_RECOVERY, requestTerminateRecovery);
}

function* watcherRequestGetRecoveryState() {
  yield takeLatest(REQUEST_GET_RECOVERY_STATE, requestGetRecoveryState);
}

function* watcherAuthUser() {
  yield takeLatest(AUTH_USER, authUser);
}

export default [
  watcherRequestLoginLocal(),
  watcherRequestLoginLti(),
  watcherRequestLoginCas(),
  watcherRequestLoginGoogle(),
  watcherRequestLoginAzureAd(),
  watcherRequestLogout(),
  watcherRequestUser(),
  watcherRequestOrganizations(),
  watcherRequestUserActivities(),
  watcherRequestDeleteUserActivity(),
  watcherRequestLiveProfiles(),
  watcherRequestCreateRecovery(),
  watcherRequestTerminateRecovery(),
  watcherRequestGetRecoveryState(),
  watcherAuthUser(),
];
