import {
  ActivityGetStateActionHandler,
  ActivityAddMessageActionHandler,
  ActivityAddMessageReactionActionHandler,
  ActivityDisableInteractionsActionHandler,
  ActivityEnableInteractionsActionHandler,
  ActivityGetDevicesActionHandler,
  ActivityGetMessagesActionHandler,
  ActivityGetUsersActionHandler,
  ActivityInteractionOnMediaRequestActionHandler,
  ActivityOnJoinActionHandler,
  ActivityOnLeaveActionHandler,
  ActivityInteractionOnRequestActionHandler,
  ActivityInteractionOnAcceptRequestActionHandler,
  ActivityInteractionOnDeclineRequestActionHandler,
  ActivityInteractionOnJoinActionHandler,
  ActivityInteractionOnStopActionHandler,
  ActivityInteractionOnCancelActionHandler,
  DeviceOnStateChangeActionHandler,
  VodOnStateUpdateActionHandler,
  WebSocket,
} from '@kalyzee/kast-websocket-module';
import { ActivityOnInteractionActionHandler } from '@kalyzee/kast-websocket-module/dist/esm/commands/activity/handlers';
import activityActions, { Types as activityTypes } from '../App/actions/activity';
import appActions, { Types as appTypes } from '../App/actions/app';
import deviceActions, { Types as deviceTypes } from '../App/actions/device';
import roomActions, { Types as roomTypes } from '../App/actions/room';
import vodActions, { Types as vodTypes } from '../App/actions/vod';
import { getToken } from './auth';
import { getUniqueLocalId } from './localId';
import { getBaseUrl, getUserSocketUrl } from './request';
import { toastError } from './toast';
import { generateId } from './utils';

export const SocketStatus = {
  LOADING: 'LOADING',
  CONNECTED: 'CONNECTED',
  DISCONNECTED: 'DISCONNECTED',
};

const log = (...args) => {
  // eslint-disable-next-line no-console
  console.log('[SOCKET] ', ...args);
};

const handleError = (name, message) => {
  const errorMessage = message || 'Unknown error';
  toastError(message); // TO REMOVE ?
};

const reduxActionHandlers = [
  // -------------------------
  // SOCKET
  // -------------------------
  {
    actionType: appTypes.SOCKET_CONNECT,
    handle: async (/** @type { WebSocket } */ socket, store, action, next) => {
      next(action);
      let result = false;
      try {
        if (!socket.connected || action.force) {
          await socket.disconnect();
          if (action.isAuthenticated) {
            const userToken = getToken() || '';
            const generateTokenEndpoint = `${getBaseUrl()}/socket/token`;
            result = await socket.connect(userToken, generateTokenEndpoint);
          } else {
            const generateTokenEndpoint = `${getBaseUrl()}/socket/token/guest`;
            result = await socket.connectAsGuest(action.nickname ?? 'Guest', generateTokenEndpoint);
          }
        }
      } catch (err) {
        // handleError('Network', 'Error while fetching socket token');
        store.dispatch(appActions.setSocketStatus(SocketStatus.DISCONNECTED));
      }
      return result;
    },
  },
  {
    actionType: appTypes.SOCKET_DISCONNECT,
    handle: async (/** @type { WebSocket } */ socket, store, action, next) => {
      next(action);
      return socket.disconnect();
    },
  },
  // -------------------------
  // ACTIVITY
  // -------------------------
  {
    actionType: activityTypes.SOCKET_JOIN_ACTIVITY,
    handle: async (/** @type { WebSocket } */ socket, store, action, next) => {
      next(action);
      const result = await socket.emitter.activity.join({ activityId: action.activityId });
      const activity = result.response;
      if (!result.error && activity) {
        const { id, state, messages, devices, users, interactions, interactionsAreEnabled } =
          activity;

        // eslint-disable-next-line no-unused-expressions
        devices?.forEach((d) => {
          const device = {
            id: d.deviceId,
          };
          if (d.context) {
            if ('online' in d.context) device.online = d.context.online;
            device.context = d.context;
          }
          store.dispatch(activityActions.updateActivityDevice(device));
        });

        store.dispatch(activityActions.setActivityState(state));
        store.dispatch(activityActions.setActivityInteractions(interactions));
        store.dispatch(activityActions.enableActivityInteractions(id, interactionsAreEnabled));
        store.dispatch(roomActions.setMessages(messages));
        store.dispatch(roomActions.setConnectedUsers(users));
        store.dispatch(roomActions.clearCounterUnreadMessages());
      }
      return result;
    },
  },
  {
    actionType: activityTypes.SOCKET_LEAVE_ACTIVITY,
    handle: async (/** @type { WebSocket } */ socket, store, action, next) => {
      next(action);
      const result = await socket.emitter.activity.leave({ activityId: action.activityId });
      return result;
    },
  },
  {
    actionType: roomTypes.SET_OWN_MESSAGE,
    handle: async (/** @type { WebSocket } */ socket, store, action, next) => {
      const correlationId = generateId();
      const newAction = { ...action };
      newAction.message.correlationId = correlationId;
      next(newAction);
      const result = await socket.emitter.activity.addMessage({ message: action.message.content });
      const message = result.response;
      // Remove local message and replace it be message from server (if exists)
      store.dispatch(roomActions.clearOwnMessage(correlationId));
      if (!result.error && message) store.dispatch(roomActions.setIncomingMessage(message));
      else toastError('An error occurs while sending the message.');
      return result;
    },
  },
  {
    actionType: roomTypes.ADD_MESSAGE_REACTION,
    handle: async (/** @type { WebSocket } */ socket, store, action, next) => {
      next(action);
      const result = await socket.emitter.activity.addMessageReaction({
        messageId: action.messageId,
        reaction: action.reaction,
      });
      if (result.error) {
        // Clear reaction added
        const clearMessageReactionAction = roomActions.clearMessageReaction(
          action.messageId,
          action.userId,
        );
        clearMessageReactionAction.noSocketCommands = true;
        store.dispatch(clearMessageReactionAction);
        toastError('An error occurs while sending the reaction.');
      }
      return result;
    },
  },
  {
    actionType: roomTypes.CLEAR_MESSAGE_REACTION,
    handle: async (/** @type { WebSocket } */ socket, store, action, next) => {
      const result = await socket.emitter.activity.addMessageReaction({
        messageId: action.messageId,
      });
      if (result.error) {
        toastError('An error occurs while remove the reaction.');
      } else {
        next(action);
      }
      return result;
    },
  },
  {
    actionType: activityTypes.SOCKET_START_PUBLISH,
    handle: async (/** @type { WebSocket } */ socket, store, action, next) => {
      next(action);
      const result = await socket.emitter.activity.startPublish({
        deviceId: action.deviceId,
        activityId: action.activity.id,
      });
      return result;
    },
  },
  {
    actionType: activityTypes.SOCKET_STOP_PUBLISH,
    handle: async (/** @type { WebSocket } */ socket, store, action, next) => {
      next(action);
      const result = await socket.emitter.activity.stopPublish({
        deviceId: action.deviceId,
        activityId: action.activity.id,
      });
      return result;
    },
  },
  {
    actionType: activityTypes.SOCKET_ENABLE_INTERACTIONS,
    handle: async (/** @type { WebSocket } */ socket, store, action, next) => {
      next(action);
      const { activityId } = action;
      const enable = action.enable === true;
      let result;
      if (enable) {
        result = await socket.emitter.activity.enableInteractions({
          activityId,
        });
      } else {
        result = await socket.emitter.activity.disableInteractions({
          activityId,
        });
      }
      if (!result.error) {
        store.dispatch(activityActions.enableActivityInteractions(activityId, enable));
      }
      return result;
    },
  },
  // -------------------------
  // ACTIVITY - INTERACTIONS
  // -------------------------
  {
    actionType: activityTypes.SOCKET_REQUEST_INTERACTION,
    handle: async (/** @type { WebSocket } */ socket, store, action, next) => {
      next(action);
      const { activityId } = action;
      const result = await socket.emitter.activity.interaction.request({
        activityId,
        type: action.interactionType,
        platformId: getUniqueLocalId(),
        data: {
          message: action.message,
        },
      });
      if (!result.error) {
        const { interaction } = result.response;
        store.dispatch(activityActions.updateActivityInteraction(activityId, interaction));
      }
      return result;
    },
  },
  {
    actionType: activityTypes.SOCKET_ACCEPT_REQUEST_INTERACTION,
    handle: async (/** @type { WebSocket } */ socket, store, action, next) => {
      next(action);
      const { activityId } = action;
      const { interactionId } = action;
      const result = await socket.emitter.activity.interaction.acceptRequest({
        activityId,
        interactionId,
        data: {
          message: action.message,
        },
      });
      if (!result.error) {
        const { interaction } = result.response;
        store.dispatch(activityActions.updateActivityInteraction(activityId, interaction));
      }
      return result;
    },
  },
  {
    actionType: activityTypes.SOCKET_DECLINE_REQUEST_INTERACTION,
    handle: async (/** @type { WebSocket } */ socket, store, action, next) => {
      next(action);
      const { activityId } = action;
      const { interactionId } = action;
      const result = await socket.emitter.activity.interaction.declineRequest({
        activityId,
        interactionId,
        data: {
          message: action.message,
        },
      });
      if (!result.error) {
        const { interaction } = result.response;
        store.dispatch(activityActions.updateActivityInteraction(activityId, interaction));
      }
      return result;
    },
  },
  {
    actionType: activityTypes.SOCKET_JOIN_INTERACTION,
    handle: async (/** @type { WebSocket } */ socket, store, action, next) => {
      next(action);
      const { activityId } = action;
      const { interactionId } = action;
      const { deviceId } = action;
      const result = await socket.emitter.activity.interaction.join({
        activityId,
        interactionId,
        data: {
          device: deviceId,
          message: action.message,
        },
      });
      if (!result.error) {
        const { interaction } = result.response;
        store.dispatch(activityActions.updateActivityInteraction(activityId, interaction));
      }
      return result;
    },
  },
  {
    actionType: activityTypes.SOCKET_STOP_INTERACTION,
    handle: async (/** @type { WebSocket } */ socket, store, action, next) => {
      next(action);
      const { activityId } = action;
      const { interactionId } = action;
      const result = await socket.emitter.activity.interaction.stop({
        activityId,
        interactionId,
        data: {
          message: action.message,
        },
      });
      if (!result.error) {
        const { interaction } = result.response;
        store.dispatch(activityActions.updateActivityInteraction(activityId, interaction));
      }
      return result;
    },
  },
  {
    actionType: activityTypes.SOCKET_CANCEL_INTERACTION,
    handle: async (/** @type { WebSocket } */ socket, store, action, next) => {
      next(action);
      const { activityId } = action;
      const { interactionId } = action;
      const result = await socket.emitter.activity.interaction.cancel({
        activityId,
        interactionId,
        data: {
          message: action.message,
        },
      });
      if (!result.error) {
        const { interaction } = result.response;
        store.dispatch(activityActions.updateActivityInteraction(activityId, interaction));
      }
      return result;
    },
  },
  // -------------------------
  // VOD
  // -------------------------
  {
    actionType: vodTypes.RECOVER,
    handle: async (/** @type { WebSocket } */ socket, store, action, next) => {
      next(action);
      const result = await socket.emitter.vod.recover({ vodId: action.vodId });
      return result;
    },
  },
  // -------------------------
  // DEVICE
  // -------------------------
  {
    actionType: deviceTypes.MOVE,
    handle: async (/** @type { WebSocket } */ socket, store, action, next) => {
      next(action);
      const { deviceId } = action;
      const result = await socket.emitter.device.move({ deviceId, direction: action.direction });
      if (!result.error) {
        // Camera moved, View is unset
        store.dispatch(deviceActions.setView(deviceId, -1));
      }
      return result;
    },
  },
  {
    actionType: deviceTypes.STOP_MOVE,
    handle: async (/** @type { WebSocket } */ socket, store, action, next) => {
      next(action);
      const { deviceId } = action;
      const result = await socket.emitter.device.stopMove({ deviceId });
      return result;
    },
  },
  {
    actionType: deviceTypes.ZOOM,
    handle: async (/** @type { WebSocket } */ socket, store, action, next) => {
      next(action);
      const { deviceId } = action;
      const result = await socket.emitter.device.zoom({ deviceId, direction: action.direction });
      if (!result.error) {
        store.dispatch(deviceActions.setView(deviceId, -1));
      }
      return result;
    },
  },
  {
    actionType: deviceTypes.STOP_ZOOM,
    handle: async (/** @type { WebSocket } */ socket, store, action, next) => {
      next(action);
      const { deviceId } = action;
      const result = await socket.emitter.device.stopZoom({ deviceId });
      return result;
    },
  },
  {
    actionType: deviceTypes.SWITCH_SCENE,
    handle: async (/** @type { WebSocket } */ socket, store, action, next) => {
      next(action);
      const result = await socket.emitter.device.switchScene({
        deviceId: action.deviceId,
        scene: action.sceneId,
      });
      return result;
    },
  },
  {
    actionType: deviceTypes.SWITCH_VIEW,
    handle: async (/** @type { WebSocket } */ socket, store, action, next) => {
      next(action);
      const result = await socket.emitter.device.switchView({
        deviceId: action.deviceId,
        view: action.viewId,
      });
      return result;
    },
  },
  {
    actionType: deviceTypes.SET_VIEW,
    handle: async (/** @type { WebSocket } */ socket, store, action, next) => {
      next(action);
      if (action.viewId >= 0) {
        const result = await socket.emitter.device.setView({
          deviceId: action.deviceId,
          view: action.viewId,
        });
        return result;
      }
      return undefined;
    },
  },
];

const reduxActionMatcher = (socket, store, action, next) => {
  // log('Action TRIGGERED : ', action);
  if (action.noSocketCommands !== true) {
    // eslint-disable-next-line no-restricted-syntax
    for (const handler of reduxActionHandlers) {
      if (handler.actionType === action?.type) {
        // log('Action MATCHED : ', action);
        const result = handler.handle(socket, store, action, next);
        if (result instanceof Promise) return result;
        return undefined;
      }
    }
  }
  return undefined;
};

const socketActionHandlers = (store) => {
  const handlers = [];

  // -------------------------
  // ACTIVITY
  // -------------------------

  {
    const stateHandler = new ActivityGetStateActionHandler();
    stateHandler.onReceive = ({ content }) =>
      store.dispatch(activityActions.setActivityState(content));
    handlers.push(stateHandler);

    const userJoinHandler = new ActivityOnJoinActionHandler();
    userJoinHandler.onReceive = ({ content }) =>
      store.dispatch(roomActions.setConnectedUser(content));
    handlers.push(userJoinHandler);

    const userLeaveHandler = new ActivityOnLeaveActionHandler();
    userLeaveHandler.onReceive = ({ content }) =>
      store.dispatch(roomActions.removeConnectedUser(content));
    handlers.push(userLeaveHandler);

    const usersHandler = new ActivityGetUsersActionHandler();
    usersHandler.onReceive = ({ content }) =>
      store.dispatch(roomActions.setConnectedUsers(content));
    handlers.push(usersHandler);

    const messagesHandler = new ActivityGetMessagesActionHandler();
    messagesHandler.onReceive = ({ content }) => store.dispatch(roomActions.setMessages(content));
    handlers.push(messagesHandler);

    const devicesHandler = new ActivityGetDevicesActionHandler();
    devicesHandler.onReceive = ({ content }) => {
      // eslint-disable-next-line no-unused-expressions
      // eslint-disable-next-line no-unused-expressions
      content?.forEach((d) => {
        const id = d.deviceId;
        const device = {
          id,
        };
        if (d.context) {
          if ('online' in d.context) device.online = d.context.online;
          device.context = d.context;
        }
        store.dispatch(activityActions.updateActivityDevice(device));
      });
    };
    handlers.push(devicesHandler);

    const messageHandler = new ActivityAddMessageActionHandler();
    messageHandler.onReceive = ({ content }) => {
      store.dispatch(roomActions.setIncomingMessage(content));
      store.dispatch(roomActions.incrementCounterUnreadMessages());
    };
    handlers.push(messageHandler);

    const messageReactionHandler = new ActivityAddMessageReactionActionHandler();
    messageReactionHandler.onReceive = ({ content }) =>
      store.dispatch(roomActions.setIncomingMessage(content));
    handlers.push(messageReactionHandler);
  }

  // -------------------------
  // ACTIVITY - INTERACTIONS
  // -------------------------

  {
    const interactionsAreEnabledHandler = new ActivityEnableInteractionsActionHandler();
    interactionsAreEnabledHandler.onReceive = ({ content }) =>
      store.dispatch(activityActions.enableActivityInteractions(content.activityId, true));
    handlers.push(interactionsAreEnabledHandler);

    const interactionsAreDisabledHandler = new ActivityDisableInteractionsActionHandler();
    interactionsAreDisabledHandler.onReceive = ({ content }) =>
      store.dispatch(activityActions.enableActivityInteractions(content.activityId, false));
    handlers.push(interactionsAreDisabledHandler);

    const interactionOnMediaRequestHandler = new ActivityInteractionOnMediaRequestActionHandler();
    interactionOnMediaRequestHandler.onReceive = ({ content }) => {
      store.dispatch(
        activityActions.onMediaRequestInteraction(content.activityId, content.interaction, content),
      );
    };
    handlers.push(interactionOnMediaRequestHandler);

    const onInteractionHandler = new ActivityOnInteractionActionHandler();
    onInteractionHandler.onReceive = ({ content }) =>
      store.dispatch(
        activityActions.updateActivityInteraction(content.activityId, content.interaction),
      );
    handlers.push(onInteractionHandler);

    // BY EVENTS
    const onRequestInteractionHandler = new ActivityInteractionOnRequestActionHandler();
    onRequestInteractionHandler.onReceive = ({ content }) =>
      store.dispatch(
        activityActions.onInteractionEvent(content.activityId, content.interaction, content.event),
      );
    handlers.push(onRequestInteractionHandler);

    const onAcceptRequestInteractionHandler = new ActivityInteractionOnAcceptRequestActionHandler();
    onAcceptRequestInteractionHandler.onReceive = ({ content }) =>
      store.dispatch(
        activityActions.onInteractionEvent(content.activityId, content.interaction, content.event),
      );
    handlers.push(onAcceptRequestInteractionHandler);

    const onDeclineRequestInteractionHandler =
      new ActivityInteractionOnDeclineRequestActionHandler();
    onDeclineRequestInteractionHandler.onReceive = ({ content }) =>
      store.dispatch(
        activityActions.onInteractionEvent(content.activityId, content.interaction, content.event),
      );
    handlers.push(onDeclineRequestInteractionHandler);

    const onJoinInteractionHandler = new ActivityInteractionOnJoinActionHandler();
    onJoinInteractionHandler.onReceive = ({ content }) =>
      store.dispatch(
        activityActions.onInteractionEvent(content.activityId, content.interaction, content.event),
      );
    handlers.push(onJoinInteractionHandler);

    const onStopInteractionHandler = new ActivityInteractionOnStopActionHandler();
    onStopInteractionHandler.onReceive = ({ content }) =>
      store.dispatch(
        activityActions.onInteractionEvent(content.activityId, content.interaction, content.event),
      );
    handlers.push(onStopInteractionHandler);

    const onCancelInteractionHandler = new ActivityInteractionOnCancelActionHandler();
    onCancelInteractionHandler.onReceive = ({ content }) =>
      store.dispatch(
        activityActions.onInteractionEvent(content.activityId, content.interaction, content.event),
      );
    handlers.push(onCancelInteractionHandler);
  }

  // -------------------------
  // DEVICE
  // -------------------------

  {
    const deviceStateHandler = new DeviceOnStateChangeActionHandler();
    deviceStateHandler.onReceive = ({ content }) => {
      const { deviceId, context } = content;
      const device = {
        id: deviceId,
      };
      if (context) {
        if ('online' in context) device.online = context.online;
        device.context = context;
      }
      store.dispatch(activityActions.updateActivityDevice(device));
    };
    handlers.push(deviceStateHandler);
  }

  // -------------------------
  // VOD
  // -------------------------

  {
    const vodStateHandler = new VodOnStateUpdateActionHandler();
    vodStateHandler.onReceive = ({ content }) => {
      const { state, vodId } = content;
      store.dispatch(vodActions.stateUpdate(vodId, state));
      // TODO : temporary, need to be unified
      store.dispatch(activityActions.updateVodState(vodId, state));
      store.dispatch(activityActions.playerMessageEvent(state));
    };
    handlers.push(vodStateHandler);
  }

  return handlers;
};

export const socketMiddleware = (store) => {
  const socket = new WebSocket({ url: getUserSocketUrl(), retryAmount: 15 });

  socket.registerActionHandlers(socketActionHandlers(store));

  socket.onConnect = () => {
    log('Connected');
    store.dispatch(appActions.setSocketStatus(SocketStatus.CONNECTED));
  };

  socket.onDisconnect = () => {
    log('Disconnected');
    store.dispatch(appActions.setSocketStatus(SocketStatus.LOADING));
  };

  socket.onConnectionFailed = (error) => {
    log('Connection Failed');
    store.dispatch(appActions.setSocketStatus(SocketStatus.LOADING));
  };

  socket.onAbandon = () => {
    log('Abandonned');
    store.dispatch(appActions.setSocketStatus(SocketStatus.DISCONNECTED));
  };

  return (next) => async (action) => {
    const result = reduxActionMatcher(socket, store, action, next);
    if (result) return result; // Promise<ResultEmittedMessage>
    return next(action);
  };
};

export default {
  socketMiddleware,
};
