import React, { useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { useRender } from '../../hooks/component';

let containerRef;
let components = [];
let timeout;

// Allow to avoid to rerender many time from an another component
// Allow to avoid error "Cannot update a component while rendering a different component warning"
const rerender = () => {
  if (timeout) clearTimeout(timeout);
  timeout = setTimeout(() => {
    if (containerRef) containerRef.render();
  }, 100);
};

export const addComponentOnOverlay = (component, key) => {
  components.push({
    component,
    key,
  });
  rerender();
};

export const removeComponentOnOverlay = (component) => {
  components = components.filter((curr) => curr.component !== component);
  rerender();
};

const OverlayContainer = React.forwardRef(({ className, style }, forwardRef) => {
  const render = useRender();
  const forwardedRef = {
    render,
  };
  useImperativeHandle(forwardRef, () => forwardedRef);
  containerRef = forwardedRef;

  return (
    <div
      className={className}
      style={{
        position: 'absolute',
        zIndex: 999999,
        width: 0,
        height: 0,
        ...(style ?? {}),
      }}
    >
      {components.map((curr) => (
        <div key={curr.key}>{curr.component}</div>
      ))}
    </div>
  );
});

OverlayContainer.displayName = 'OverlayContainer';

OverlayContainer.propTypes = {
  className: PropTypes.string,
  style: PropTypes.any,
};

OverlayContainer.defaultProps = {
  className: undefined,
  style: undefined,
};

export default OverlayContainer;
