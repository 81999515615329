import { useEffect, useRef, useState } from 'react';
import { useWindowDimensions } from 'react-native-web';
import { RESPONSIVE_THRESHOLD, RESPONSIVE_THRESHOLD_SIZE } from '../constants/global';

const { SMALL, MEDIUM, LARGE } = RESPONSIVE_THRESHOLD;
const { MEDIUM_WIDTH, SMALL_WIDTH } = RESPONSIVE_THRESHOLD_SIZE;

// Hook
export function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener('resize', handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}

export function useElementSize(ref, enable) {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const windowSize = useWindowSize();
  const [size, setSize] = useState(windowSize);
  const [result, setResult] = useState(size);

  useEffect(() => {
    let observer;
    if (ref.current) {
      const onSize = () => {
        if (ref.current?.clientWidth !== size.width || ref.current?.clientHeight !== size.height) {
          setSize({
            width: ref.current?.clientWidth,
            height: ref.current?.clientHeight,
          });
        }
      };
      onSize();
      observer = new ResizeObserver(() => {
        onSize();
      });
      observer.observe(ref.current);
    }
    return () => observer?.disconnect();
  });

  useEffect(() => {
    if (enable) {
      setResult(size);
    }
  }, [size]);

  return result;
}

export const useWindowUnfocused = (callback) => {
  // initialize mutable ref, which stores callback
  const callbackRef = useRef();

  // update cb on each render, so second useEffect has access to current value
  useEffect(() => {
    callbackRef.current = callback;
  });

  useEffect(() => {
    const handleWindowUnfocus = () => {
      if (callbackRef.current) callbackRef.current();
    };
    window.addEventListener('blur', handleWindowUnfocus);
    // cleanup this component
    return () => {
      window.removeEventListener('blur', handleWindowUnfocus);
    };
  }, []);
};

export const elementHoveredOptionsDefault = {
  enableTouch: false,
  hoverOnParentLevel: 0,
};

/**
 * Allow to detect hover on target or on parent of target.
 * @argument ref reference of target
 * @argument onChange callback trigger on every change
 * @argument opts
 */
export function useElementHovered(ref, onChange, opts) {
  const options = { ...elementHoveredOptionsDefault, ...(opts ?? {}) };
  const detailsRef = useRef({
    isHovered: false,
    isTouching: false,
    positions: {},
    mouseEvent: undefined,
    target: undefined,
  });

  useEffect(() => {
    let element = ref.current;
    for (let level = 0; level < options.hoverOnParentLevel; level += 1) {
      if (!element) {
        element = null;
        break;
      }
      element = element.parentElement;
    }
    detailsRef.current.target = element;
    if (element) {
      const boundingClientRect = element.getBoundingClientRect();
      const getPositions = (e) => ({
        relative: {
          x: e?.clientX,
          y: e?.clientY,
        },
        absolute: {
          x: e?.pageX,
          y: e?.pageY,
        },
        target: {
          x: boundingClientRect?.left,
          y: boundingClientRect?.top,
        },
      });
      const handleTouchStart = (e) => {
        if (!options.enableTouch) return;
        if (!detailsRef.current.isTouching) {
          if (e.touches?.length) {
            const touch = e.touches[0];
            detailsRef.current.positions = getPositions(touch);
          }
          detailsRef.current.isTouching = true;
          if (onChange) onChange(detailsRef.current);
        }
      };
      const handleTouchEnd = (_) => {
        if (!options.enableTouch) return;
        if (detailsRef.current.isTouching) {
          setTimeout(() => {
            detailsRef.current.isTouching = false;
            if (onChange) onChange(detailsRef.current);
          }, 100); // hack to have touchEnd after click event
        }
      };
      element.addEventListener('touchstart', handleTouchStart, { passive: false });
      element.addEventListener('touchend', handleTouchEnd, { passive: true });
      const handleMouseEnter = (e) => {
        if (e?.sourceCapabilities?.firesTouchEvents) return;
        detailsRef.current.isHovered = true;
        detailsRef.current.positions = getPositions(e);
        detailsRef.current.mouseEvent = e;
        if (onChange) onChange(detailsRef.current);
      };
      const handleMouseMove = (e) => {
        if (e?.sourceCapabilities?.firesTouchEvents) return;
        detailsRef.current.positions = getPositions(e);
        detailsRef.current.mouseEvent = e;
        if (onChange) onChange(detailsRef.current);
      };
      const handleMouseExit = (e) => {
        if (e?.sourceCapabilities?.firesTouchEvents) return;
        detailsRef.current.isHovered = false;
        detailsRef.current.positions = getPositions(e);
        if (onChange) onChange(detailsRef.current);
      };
      element.addEventListener('mouseenter', handleMouseEnter, { passive: true });
      element.addEventListener('mousemove', handleMouseMove, { passive: true });
      element.addEventListener('mouseleave', handleMouseExit, { passive: true });

      return () => {
        if (element) element.removeEventListener('touchstart', handleTouchStart);
        if (element) element.removeEventListener('touchend', handleTouchEnd);
        if (element) element.removeEventListener('mouseenter', handleMouseEnter);
        if (element) element.removeEventListener('mousemove', handleMouseMove);
        if (element) element.removeEventListener('mouseleave', handleMouseExit);
      };
    }
    return () => null;
  });
}

export const useHorizontalScroll = (sensibility = 1) => {
  const elRef = useRef(null);
  useEffect(() => {
    const el = elRef.current;
    if (el) {
      const onWheel = (e) => {
        if (e.deltaY === 0) return;
        e.preventDefault();
        el.scrollTo({
          left: el.scrollLeft + e.deltaY * sensibility,
          behavior: 'smooth',
        });
      };
      el.addEventListener('wheel', onWheel);
      return () => el.removeEventListener('wheel', onWheel);
    }
    return () => {};
  }, []);
  return elRef;
};

export const useResponsiveThreshold = () => {
  const { width } = useWindowDimensions();
  if (width > MEDIUM_WIDTH) return LARGE;
  if (width > SMALL_WIDTH) return MEDIUM;
  return SMALL;
};

export default {
  useElementSize,
  useWindowSize,
  useWindowUnfocused,
  useElementHovered,
  useHorizontalScroll,
  useResponsiveThreshold,
};
