import { createReducer } from 'reduxsauce';
import { fromJS } from 'immutable';

import { Types } from '../actions/error';

const initialState = fromJS({
  error: undefined, // { isNetwork: boolean, code: number, message: string }
});

export const setError = (state = initialState, { error }) => state.set('error', error);

export default createReducer(initialState, {
  [Types.SET_ERROR]: setError,
});
