import { requestAPI, getHeadersAPI } from '../../helpers/request';

const basePath = '/device';

const paths = {
  getDevices: () => {
    return `${basePath}`;
  }, // GET
  updateDeviceById: (id) => {
    return `${basePath}/${id}`;
  }, // PATCH
};

// ------------------------------------------------------- //
// --------------------- REQUEST ------------------------- //
// ------------------------------------------------------- //

// ---------- GET
const requestDevices = async (params) => {
  return requestAPI(paths.getDevices(), getHeadersAPI('GET', true), params);
};
// ---------- POST

// ---------- PATCH
const requestUpdateDevice = async (id, body) => {
  return requestAPI(paths.updateDeviceById(id), getHeadersAPI('PATCH', true, JSON.stringify(body)));
};
// ---------- DELETE

export default {
  requestDevices,
  requestUpdateDevice,
};
