import React, { useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useOnClick, useOuterClickManyTargets } from '../../hooks/click';
import { useElementHovered, useElementSize, useWindowSize } from '../../hooks/element';

const DEFAULT_CLASSNAME = 'element-on-hover';

const containerStyle = {
  position: 'fixed',
  zIndex: 10000,
  maxWidth: '100vw',
  maxHeight: '100vh',
};

const ElementOnHover = React.forwardRef(
  (
    {
      targetRef,
      hoverOnParentLevel,
      delay,
      enable,
      enableClick,
      positionFixed,
      children,
      className,
      style,
    },
    forwardRef,
  ) => {
    const windowSize = useWindowSize();
    const [show, setShow] = useState(false);
    const [enabled, setEnabled] = useState(enable);
    const showUpdatedAt = useRef();
    const divRef = useRef(null);
    const delayTimeout = useRef();
    const positionsRef = useRef();

    const forwardedRef = {
      enable: (v) => setEnabled(v),
    };
    useImperativeHandle(forwardRef, () => forwardedRef, [enabled]);

    useEffect(() => {
      if (!enabled) setShow(false);
    }, [enabled]);

    const getDivPosition = () => {
      if (!divRef.current || !positionsRef.current?.absolute) return null;
      const divElement = divRef.current;
      let { x, y } = positionsRef.current.absolute;
      const margeY = 10;
      const margeX = 20;

      if (divElement && windowSize.width && windowSize.height) {
        const width = divElement.clientWidth;
        const height = divElement.clientHeight;

        if (width > 0 && height > 0) {
          if (y - margeY - height > margeY) {
            y = y - margeY - height;
          } else {
            y += margeY;
          }

          if (x - width / 2 < margeX) {
            x = margeX;
          } else {
            x -= width / 2;
          }

          if (x + width > windowSize.width - margeX) {
            x = windowSize.width - width - margeX;
          }

          return [x, y];
        }
      }
      return null;
    };

    useElementHovered(
      targetRef,
      (details) => {
        if (!enabled) return;
        positionsRef.current = details.positions;
        const pos = getDivPosition();
        if (divRef.current && pos) {
          if (positionFixed) {
            Object.assign(divRef.current.style, positionFixed);
          } else {
            const [x, y] = pos;
            Object.assign(divRef.current.style, {
              top: `${y}px`,
              left: `${x}px`,
            });
          }
          if (show) {
            Object.assign(divRef.current.style, {
              visible: 'visible',
            });
          }
        }
        if (details.isHovered) {
          if (!show) {
            if (delay) {
              if (!delayTimeout.current) {
                delayTimeout.current = setTimeout(() => {
                  clearTimeout(delayTimeout.current);
                  delayTimeout.current = undefined;
                  setShow(true);
                }, delay);
              }
            } else {
              setShow(true);
            }
          }
        } else {
          if (delayTimeout.current) clearTimeout(delayTimeout.current);
          delayTimeout.current = undefined;
          if (show) setShow(false);
        }
      },
      { enableTouch: false, hoverOnParentLevel },
    );

    useOnClick(targetRef, (details) => {
      if (!enableClick) return;
      if (show) return;
      positionsRef.current = details.positions;
      const pos = getDivPosition();
      if (divRef.current && pos) {
        if (positionFixed) {
          Object.assign(divRef.current.style, positionFixed);
          Object.assign(divRef.current.style, {
            visible: 'visible',
          });
        } else {
          const [x, y] = pos;
          Object.assign(divRef.current.style, {
            visibility: 'visible',
            top: `${y}px`,
            left: `${x}px`,
          });
        }
      }
      setShow(true);
    });

    const outerClickTargets = [divRef];
    if (enableClick) outerClickTargets.push(targetRef);
    useOuterClickManyTargets(
      outerClickTargets,
      useCallback(() => {
        if (show) {
          if (
            showUpdatedAt.current &&
            new Date().getTime() - showUpdatedAt.current.getTime() > 100
          ) {
            setShow(false);
          }
        }
      }, [show, setShow]),
    );
    useElementSize(divRef);

    useEffect(() => {
      showUpdatedAt.current = new Date();
    }, [show]);

    useEffect(() => {
      return () => {
        if (delayTimeout.current) {
          clearTimeout(delayTimeout.current);
          delayTimeout.current = undefined;
        }
      };
    }, []);

    const render = () => {
      const divStyle = { ...containerStyle };
      if (style) Object.assign(divStyle, style);
      Object.assign(divStyle, { visibility: 'hidden' });
      // Set position
      if (positionFixed) {
        Object.assign(divStyle, positionFixed);
        Object.assign(divStyle, {
          visibility: 'visible',
        });
      } else if (positionsRef.current?.absolute) {
        const pos = getDivPosition();
        if (pos) {
          const [x, y] = pos;
          Object.assign(divStyle, {
            visibility: 'visible',
            top: `${y}px`,
            left: `${x}px`,
          });
        }
      }

      if (!show) Object.assign(divStyle, { display: 'none', pointerEvents: 'none' });
      return (
        <div
          ref={divRef}
          style={divStyle}
          className={className}
          role="button"
          tabIndex={0}
          onClick={(e) => {
            if (show) setShow(false);
            e.preventDefault();
            e.stopPropagation();
          }}
          onKeyDown={(e) => {
            if (e.code === 'Enter') {
              if (show) setShow(false);
              e.preventDefault();
              e.stopPropagation();
            }
          }}
        >
          {children}
        </div>
      );
    };

    return render();
  },
);

ElementOnHover.displayName = 'ElementOnHover';

ElementOnHover.propTypes = {
  targetRef: PropTypes.any,
  positionFixed: PropTypes.any,
  children: PropTypes.any,
  hoverOnParentLevel: PropTypes.number,
  enable: PropTypes.bool,
  enableClick: PropTypes.bool,
  delay: PropTypes.number,
  className: PropTypes.string,
  style: PropTypes.any,
};

ElementOnHover.defaultProps = {
  hoverOnParentLevel: 0,
  enable: true,
  enableClick: true,
  delay: 500,
  className: DEFAULT_CLASSNAME,
  style: undefined,
};

export default ElementOnHover;
