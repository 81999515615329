import { fromJS } from 'immutable';
import { createReducer } from 'reduxsauce';

import { Types } from '../actions/device';

const initialState = fromJS({
  info: {},
});

export const setDevice = (state = initialState, { device }) => state.set('info', fromJS(device));

export default createReducer(initialState, {
  [Types.SET_DEVICE]: setDevice,
});
