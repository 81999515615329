import React, { useMemo, useState } from 'react';
import { Image, Platform, View } from 'react-native';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';
import roomSelectors from '../../App/selectors/room';
import IconUser from '../../assets/user/user-placeholder.svg';
import { WEB_PLATFORM } from '../../constants/global';
import colors from '../../constants/colors';

const getImageStyle = () => ({
  width: '100%',
  height: '100%',
  aspectRatio: '1/1',
  borderRadius: '100%',
});

const getBadgeStyle = () => ({
  width: '33%',
  height: '33%',
  borderRadius: '100%',
  position: 'absolute',
  backgroundColor: colors.getMainGreen(),
  left: '75%',
  bottom: '33%',
  transform: 'translate(0, 100%)',
  border: `solid 1px ${colors.getBluePrussian()}`,
});

const UserAvatar = ({ user, defaultImage, displayBadge, style }) => {
  const [imageError, setImageError] = useState(false);
  const selectUserConnected = useMemo(roomSelectors.makeSelectUserConnected, []);
  const isConnected = useSelector((state) => selectUserConnected(state, user?.id));
  const image = user?.avatar;

  const onImageNotFound = () => {
    setImageError(true);
  };

  const showDefaultImage = () => {
    return !image || imageError;
  };
  const getSrc = () => {
    return showDefaultImage() ? defaultImage : image;
  };
  const getImage = () => {
    if (showDefaultImage() && !defaultImage) {
      return <IconUser style={[getImageStyle(), style]} />;
    }
    if (Platform.OS === WEB_PLATFORM) {
      return (
        <img
          src={getSrc()}
          style={{ ...getImageStyle(), ...(style ?? {}) }}
          onError={() => onImageNotFound()}
        />
      );
    }
    return (
      <Image source={getSrc()} style={[getImageStyle(), style]} onError={() => onImageNotFound()} />
    );
  };

  if (displayBadge) {
    return (
      <View style={{ ...getImageStyle(), ...(style ?? {}) }}>
        {getImage()}
        <View style={[getBadgeStyle(), { visibility: isConnected ? 'visible' : 'hidden' }]}></View>
      </View>
    );
  }
  return getImage();
};

UserAvatar.propTypes = {
  user: PropTypes.object,
  defaultImage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  displayBadge: PropTypes.bool,
  style: PropTypes.any,
};

UserAvatar.defaultProps = {
  displayBadge: true,
};

export default UserAvatar;
