import React from 'react';
import PropTypes from 'prop-types';
import { Datepicker } from '@mobiscroll/react';
import { START_TIME_STEP } from '../../constants/activity';

import { getMobiscrollLocale } from '../../localization';

const ActivityTimePicker = ({ min, max, value, setValue, disabled }) => {
  return (
    <Datepicker
      theme="ios"
      themeVariant="light"
      inputStyle="outline"
      controls={['time']}
      stepMinute={START_TIME_STEP}
      locale={getMobiscrollLocale()}
      min={min}
      max={max}
      value={value}
      onChange={(event) => {
        setValue(event.value);
      }}
      returnFormat="moment"
      timeFormat="H:mm"
      disabled={disabled}
    />
  );
};

ActivityTimePicker.defaultProps = {
  disabled: false,
};

ActivityTimePicker.propTypes = {
  min: PropTypes.object.isRequired,
  max: PropTypes.object,
  value: PropTypes.object.isRequired,
  setValue: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default ActivityTimePicker;
