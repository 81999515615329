import { fromJS } from 'immutable';
import { createReducer } from 'reduxsauce';
import { Types } from '../actions/system';

const initialState = fromJS({
  video: {
    resolution: 'hd',
    scene: {
      ordreNI: 1,
      ordreVMM: 1,
      Scene: 1,
    },
    focus: true,
    alpha: {
      Angle: 30,
      BW: 30,
    },
    hdmi: {
      ordinateur: 30,
      avx: 30,
    },
    flip: false,
  },
  audio: {
    hdmi: { volume: 0, muted: true },
    xlr: { volume: 0, muted: true },
    line: { volume: 0, muted: true },
  },
  Ftp: {
    Host: null,
    Username: null,
    Password: null,
    Port: null,
    Nom: null,
  },
  Smtp: {
    Url: null,
    Cle: null,
    Nom: null,
    Protege: null,
    identifiant: null,
    mdp: null,
  },
});

export const switchResolution = (state = initialState, { string }) =>
  state.setIn(['video', 'resolution'], string);
export const switchScene = (state = initialState, { object }) =>
  state.setIn(['video', 'scene'], fromJS(object));
export const switchFocus = (state = initialState, { boolean }) =>
  state.setIn(['video', 'focus'], boolean);
export const sendAlpha = (state = initialState, { object }) =>
  state.setIn(['video', 'alpha'], fromJS(object));
export const sendSoundVideo = (state = initialState, { object }) =>
  state.setIn(['audio', 'hdmi'], fromJS(object));
export const setFlip = (state = initialState, { boolean }) =>
  state.setIn(['video', 'flip'], boolean);

export const sendSound = (state = initialState, { object }) => state.set('audio', fromJS(object));

export const sendFtp = (state = initialState, { object }) => state.set('Ftp', fromJS(object));

export const sendSmtp = (state = initialState, { object }) => state.set('Smtp', fromJS(object));

export default createReducer(initialState, {
  [Types.SWITCH_RESOLUTION]: switchResolution,
  [Types.SWITCH_SCENE]: switchScene,
  [Types.SWITCH_FOCUS]: switchFocus,
  [Types.SEND_ALPHA]: sendAlpha,
  [Types.SEND_SOUND_VIDEO]: sendSoundVideo,
  [Types.SET_FLIP]: setFlip,
  [Types.SEND_SOUND]: sendSound,
  [Types.SEND_FTP]: sendFtp,
  [Types.SEND_SMTP]: sendSmtp,
});
