import React, { useState, useEffect } from 'react';
import { StyleSheet, ActivityIndicator } from 'react-native';
import { View, Text, TouchableOpacity } from 'react-native-web';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { usePrevious } from '../../hooks/utils';
import colors from '../../constants/colors';
import fonts from '../../constants/fonts';

import IconNoVideo from '../../assets/player/no_camera.svg';
import IconNoConnected from '../../assets/player/no_connected.svg';
import IconRetry from '../../assets/player/reload.svg';
import { Strings } from '../../helpers/strings';

const SHOW_RETRY_LOADING_STATE_DELAY = 15000; // in ms
const BACKGROUND_COLOR = colors.transparent;

const styles = StyleSheet.create({
  container: {
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  retryButton: {
    position: 'absolute',
    bottom: '10px',
    right: '10px',
    width: '20px',
    height: '20px',
  },
  loading: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    backgroundColor: BACKGROUND_COLOR,
    fontFamily: fonts.getSignika(),
    fontSize: '1em',
  },
  loadingMsg: {
    fontFamily: fonts.getSignika(),
    fontSize: '1em',
    color: colors.getMainWhite(),
    marginTop: '5px',
  },
  loadingIndicator: {
    marginTop: '10px',
  },
  noVideo: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    backgroundColor: BACKGROUND_COLOR,
  },
  noVideoMsg: {
    fontFamily: fonts.getSignika(),
    fontSize: '1em',
    color: colors.getMainWhite(),
    marginTop: '5px',
  },
  noVideoRetryButton: {
    marginTop: '10px',
    borderRadius: '10px',
    paddingVertical: '5px',
    paddingHorizontal: '10px',
    backgroundColor: colors.getBlueCornflower(),
  },
  noConnected: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    backgroundColor: BACKGROUND_COLOR,
  },
  noConnectedMsg: {
    fontFamily: fonts.getSignika(),
    fontSize: '1em',
    color: colors.getMainWhite(),
    marginTop: '5px',
  },
});

const VideoWebRTCOverlay = ({ state, onRetry }) => {
  const { t } = useTranslation();
  const [showRetry, setShowRetry] = useState(false);
  const previewState = usePrevious(state);

  useEffect(() => {
    let timeout;
    if (state === 'stopped') {
      setShowRetry(true);
    } else if (state === 'loading') {
      if (previewState !== state) setShowRetry(false);
      timeout = setTimeout(() => {
        setShowRetry(true);
      }, SHOW_RETRY_LOADING_STATE_DELAY);
    } else {
      setShowRetry(false);
    }
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [state, showRetry]);

  const retry = () => {
    setShowRetry(false);
    if (onRetry) onRetry();
  };

  const renderRetryButton = () => {
    if (!showRetry) return null;
    return (
      <TouchableOpacity style={styles.retryButton} onPress={retry}>
        <IconRetry width="20" height="20" />
      </TouchableOpacity>
    );
  };

  const renderLoading = () => (
    <View style={styles.loading}>
      <Text style={styles.loadingMsg}>{t(Strings.LOADING_VIDEO_EEDBACK)}</Text>
      <ActivityIndicator
        animating={true}
        size="small"
        color={colors.getMainWhite()}
        style={styles.loadingIndicator}
      />
      {renderRetryButton()}
    </View>
  );

  const renderRunning = () => null;

  const renderStopped = () => (
    <View style={styles.noVideo}>
      <IconNoVideo width="40" height="30" />
      <Text style={styles.noVideoMsg}>{t(Strings.NO_VIDEO_SIGNAL)}</Text>
      <TouchableOpacity style={styles.noVideoRetryButton} onPress={retry}>
        {t(Strings.NO_VIDEO_SIGNAL_RETRY)}
      </TouchableOpacity>
      {renderRetryButton()}
    </View>
  );

  const renderNoConnected = () => (
    <View style={styles.noConnected}>
      <IconNoConnected width="40" height="30" />
      <Text style={styles.noConnectedMsg}>{t(Strings.DEVICE_IS_NOT_CONNECTED)}</Text>
    </View>
  );

  const renderContent = () => {
    if (state === 'loading') return renderLoading();
    if (state === 'running') return renderRunning();
    if (state === 'stopped') return renderStopped();
    if (state === 'no_connected') return renderNoConnected();
    return null;
  };

  const content = renderContent();
  if (!content) return null;
  return <View style={styles.container}>{content}</View>;
};

VideoWebRTCOverlay.propTypes = {
  state: PropTypes.string.isRequired,
  onRetry: PropTypes.func,
};

export default connect(null, null)(VideoWebRTCOverlay);
