import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  addCurrentlySending: ['name'],
  removeCurrentlySending: ['name'],

  socketConnect: ['isAuthenticated', 'nickname', 'force'], // "force" disconnect socket if already connected
  socketDisconnect: [],

  requestBackendApiVersion: [],

  setSocketStatus: ['status'], // see SocketStatus
  setBackendApiVersion: ['apiVersion'],

  // Events
  onSocketStatus: ['status'], // see SocketStatus
});

export { Types };
export default Creators;
