import organization from './organization';
import user from './user';
import activity from './activity';
import device from './device';
import publishprofile from './publishprofile';
import socket from './socket';
import vod from './vod';
import app from './app';

const api = {
  organization,
  user,
  activity,
  device,
  publishprofile,
  socket,
  vod,
  app,
};

export default api;
