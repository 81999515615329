import React from 'react';
import { StyleSheet, Text, TouchableOpacity } from 'react-native';
import PropTypes from 'prop-types';

import Modal from 'modal-enhanced-react-native-web';
import { View } from 'react-native-web';
import { useTranslation } from 'react-i18next';
import fonts from '../../constants/fonts';
import colors from '../../constants/colors';
import { Strings } from '../../helpers/strings';

const styles = StyleSheet.create({
  modal: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  mainContainer: {
    width: 'min(80vw, 550px)',
    borderRadius: 20,
    overflow: 'hidden', // So children corners overflow don't mask the border radius!
    backdropFilter: 'blur(15px)',
    boxShadow: '0 0 64px 0 rgba(0, 0, 0, 0.15)',
    backgroundColor: 'rgba(245, 245, 245, 0.4)',
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: colors.getMainWhite(),
    padding: 20,
  },
  footer: {
    flexDirection: 'row',
    backgroundColor: colors.getMainWhite(),
  },
  button: {
    paddingTop: 20,
    paddingBottom: 20,
    textAlign: 'center',
    flex: 1,
  },
  nameText: {
    fontFamily: fonts.getSignika(),
    fontSize: 20,
    fontWeight: 600,
    color: colors.getBluePrussian(),
  },
  closeText: {
    fontFamily: fonts.getSignika(),
    fontWeight: 600,
    fontSize: 18,
    color: colors.getMainPurple(),
  },
});

/**
 * buttons : [{
 *  text,
 *  textStyle,
 *  onClick,
 * }]
 */
const ContentModal = ({ name, renderContent, buttons, isVisible, setIsVisible }) => {
  const { t } = useTranslation();

  const modalContent = () => {
    return (
      <View style={styles.mainContainer}>
        <View style={styles.header}>
          <Text style={styles.nameText}>{name}</Text>
          <TouchableOpacity
            onPress={() => {
              setIsVisible(false);
            }}
          >
            <Text style={styles.closeText}>{t(Strings.CLOSE)}</Text>
          </TouchableOpacity>
        </View>
        <View style={styles.body}>{renderContent()}</View>
        {buttons?.length > 0 ? (
          <View style={styles.footer}>
            {buttons.map((button) => (
              <TouchableOpacity style={styles.button} onPress={button.onClick} key={button.text}>
                <Text style={button.textStyle}>{button.text}</Text>
              </TouchableOpacity>
            ))}
          </View>
        ) : null}
      </View>
    );
  };

  return (
    <Modal style={styles.modal} isVisible={isVisible}>
      {modalContent()}
    </Modal>
  );
};

ContentModal.defaultProps = {
  isVisible: false,
};

ContentModal.propTypes = {
  name: PropTypes.string.isRequired,
  renderContent: PropTypes.func.isRequired,
  buttons: PropTypes.array,
  isVisible: PropTypes.bool.isRequired,
  setIsVisible: PropTypes.func.isRequired,
};

export default ContentModal;
