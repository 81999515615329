import { createSelector } from 'reselect';

const errorSelector = (globalState) => globalState.get('error');
const errorObjectSelector = (state) => errorSelector(state).get('error');

const makeSelectError = () => createSelector(errorObjectSelector, (state) => state);

export default {
  makeSelectError,
};
