import { StyleSheet, View, Text, TouchableWithoutFeedback } from 'react-native';
import React from 'react';
import PropTypes from 'prop-types';

const styles = StyleSheet.create({
  element: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 10,
    marginBottom: 10,
    overflow: 'hidden',
    width: '100%',
  },
  text: {
    flex: 1,
    color: 'white',
    fontSize: 16,
    marginLeft: 10,
    paddingVertical: 'auto',
  },
});

const ConnectedElement = ({ text, icon, onPress }) => {
  return (
    <TouchableWithoutFeedback onPress={(e) => onPress(e)}>
      <View style={styles.element}>
        {icon}
        <Text style={styles.text} numberOfLines={1}>
          {text}
        </Text>
      </View>
    </TouchableWithoutFeedback>
  );
};

ConnectedElement.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.object, // can also be null
  onPress: PropTypes.func,
};

ConnectedElement.defaultProps = {
  onPress: () => {},
};

export default ConnectedElement;
