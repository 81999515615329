export const DARK = '#000000';
export const DARK_BLUE = '#161e2a';
export const PURPLE = '#5b41f0';
export const GREY = 'rgba(255, 255, 255, 0.7)';
export const WHITE = 'white';

/*
 * Use this template:
 *
 * export default {
 *  getDark: (opacity = 1) => `rgba(r, g, b, ${opacity})`, // #HEXCODE
 *  getDarkBlue: (opacity = 1) => `rgba(r, g, b, ${opacity})`, // #HEXCODE
 * }
 *
 * It will force a common "code" (rgba, over HEX or css) and allow nuances via opacity
 *
 * To get color name use this site: https://chir.ag/projects/name-that-color/#FFFFFF
 */

export default {
  getMainWhite: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`, // #FFFFFF
  getMainGreen: (opacity = 1) => `rgba(23, 191, 135, ${opacity})`, // #17BF87
  getMainPurple: (opacity = 1) => `rgba(91, 65, 240, ${opacity})`, // #5B41F0
  getMainBlack: (opacity = 1) => `rgba(0, 0, 0, ${opacity})`, // #000000
  getMainRed: (opacity = 1) => `rgba(255, 0, 0, ${opacity})`, // #FF0000

  getMirage: (opacity = 1) => `rgba(22, 30, 42, ${opacity})`, // #161E2A
  getLightMirage: (opacity = 1) => `rgba(28, 38, 53, ${opacity})`, // #32435e

  getGallery: (opacity = 1) => `rgba(235, 235, 235, ${opacity})`, // #EBEBEB

  // Red
  getRed: (opacity = 1) => `rgba(237, 12, 12, ${opacity})`, // #ED0C0C
  getDeleteRed: (opacity = 1) => `rgba(255, 14, 14, ${opacity})`, // #FF0E0E
  getTorchRed: (opacity = 1) => `rgba(255, 14, 67, ${opacity})`, // #FF0E43

  // Yellow
  getTreePoppy: (opacity = 1) => `rgba(255, 152, 32, ${opacity})`, // #FFCC33

  // Orange
  getSunglow: (opacity = 1) => `rgba(255, 204, 51, ${opacity})`, // #FF9820

  // Blue
  getClayEbony: (opacity = 1) => `rgba(37, 49, 68, ${opacity})`, // #253144
  getBlueBayoux: (opacity = 1) => `rgba(80, 94, 117, ${opacity})`, // #505e75
  getBluePrussian: (opacity = 1) => `rgba(0, 36, 90, ${opacity})`, // #00245A
  getBlueOxford: (opacity = 1) => `rgba(56, 67, 84, ${opacity})`, // #384354
  getBlueDodger: (opacity = 1) => `rgba(0, 186, 255, ${opacity})`, // #00BAFF
  getBlueCornflower: (opacity = 1) => `rgba(112, 87, 255, ${opacity})`, // #7057FF

  // Grey
  getGrayLevel: (level, opacity = 1) => {
    const value = Math.max(0, Math.min(Math.floor(level * 255)));
    return `rgba(${value}, ${value}, ${value}, ${opacity})`;
  }, // level : 0 -> 1
  getGraySantas: (opacity = 1) => `rgba(156, 153, 177, ${opacity})`, // #9C99B1
  getGrayAthens: (opacity = 1) => `rgba(248, 248, 249, ${opacity})`, // #F8F8F9
  getGrayDusty: (opacity = 1) => `rgba(151, 151, 151, ${opacity})`, // #979797
  getCodGray: (opacity = 1) => `rgba(17, 17, 17, ${opacity})`, // #111111

  getSilverChalice: (opacity = 1) => `rgba(171, 171, 171, ${opacity})`, // #ABABAB

  // Purple
  getPurpleHeart: (opacity = 1) => `rgba(82, 59, 209, ${opacity})`, // #523BD1

  // Green
  getMountainMeadow: (opacity = 1) => `rgba(23, 191, 136, ${opacity})`, // #17bf88
  getSalem: (opacity = 1) => `rgba(12, 131, 92, ${opacity})`, // #0C835C

  // Rose
  getRoseOld: (opacity = 1) => `rgba(198, 154, 126, ${opacity})`, // #C69A7E
  getClamShell: (opacity = 1) => `rgba(219, 197, 183, ${opacity})`, // #DBC5B7
};
