import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  // Request API
  requestLoginLocal: ['email', 'password'],
  requestLoginLti: ['token'],
  requestLoginCas: ['ticket'],
  requestLoginGoogle: [],
  requestLoginAzureAd: [],
  requestLogout: ['preLogout', 'postLogout'], // postLogout is a callback, preLogout is the inverse (first called)

  requestCreateRecovery: ['email'],
  requestTerminateRecovery: ['key', 'password'],
  requestGetRecoveryState: ['key'],

  requestUser: [],

  requestOrganizations: [],
  requestUserActivities: ['skip', 'limit', 'sortMethod', 'sortOrder', 'reset'],
  requestDeleteUserActivity: ['_id'],
  requestLiveProfiles: [],

  retryRequest: [],

  // Set reducers
  setUser: ['user'],
  setIsAuthenticated: ['bool'],
  setOrganizations: ['organizations'],
  setActivities: ['activities'],
  addActivities: ['activities'],
  deleteActivity: ['_id'],
  setIsRefreshingToken: ['isRefreshingToken'],
  refreshTokenFailed: [],

  setPasswordResetState: ['resetState'],

  setLiveProfiles: ['liveProfiles'],
  addLiveProfile: ['liveProfile'],
  updateLiveProfile: ['_id', 'liveProfile'],

  authUser: ['token', 'refreshToken'],
  resetUser: [],
});

export { Types };
export default Creators;
