import React, { useEffect, useState } from 'react';
import { StyleSheet, View, useWindowDimensions, Text, FlatList } from 'react-native';

import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Picker } from '@react-native-picker/picker';
import { ScrollView } from 'react-native-web';
import { useTranslation } from 'react-i18next';
import { useIsFocused } from '@react-navigation/native';
import userSelectors from '../../App/selectors/user';
import appSelectors from '../../App/selectors/app';

import userActions, { Types as userTypes } from '../../App/actions/user';
import activityActions, { Types as activityTypes } from '../../App/actions/activity';

import Activity from '../../components/Activity';
import { navigate } from '../../helpers/navigation';
import { screenName } from '../../constants/navigation';
import colors from '../../constants/colors';
import fonts from '../../constants/fonts';
import ContentModal from '../../components/Generic/contentModal';
import { LIST_LOAD_LIMIT } from '../../constants/activity';
import VodCard from '../../components/Vod/card';
import { Strings } from '../../helpers/strings';

import { RESPONSIVE_THRESHOLD, USER_ROLE } from '../../constants/global';
import { userRoleIsGTE } from '../../helpers/role';

const { SMALL, MEDIUM } = RESPONSIVE_THRESHOLD;

const { REQUEST_DELETE_USER_ACTIVITY } = activityTypes;
const { REQUEST_USER_ACTIVITIES } = userTypes;

const ModalType = {
  NONE: 'none',
  ACTIVITY: 'activity',
  ACTIVITY_DELETE: 'activity_delete',
  VOD_LIST: 'vod_list',
};

const SortMethodsTypes = {
  DATE: 'date',
  NAME: 'name',
};

const SortOrdersTypes = {
  OLD: 'ascending',
  NEW: 'descending',
};

const SortMethods = {
  [SortMethodsTypes.DATE]: {
    label: Strings.DATE,
  },
  [SortMethodsTypes.NAME]: {
    label: Strings.NAME,
  },
};

const SortOrders = {
  [SortOrdersTypes.OLD]: { label: Strings.OLD },
  [SortOrdersTypes.NEW]: { label: Strings.NEW },
};

const styles = StyleSheet.create({
  mainContainer: {
    height: '100%',
  },
  contentContainer: {
    height: 0,
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
  },
  modalContentContainer: {
    backgroundColor: colors.getGrayAthens(),
    padding: 20,
  },
  deleteModalContentContainer: {
    textAlign: 'center',
    backgroundColor: colors.getGrayAthens(),
    padding: 40,
  },
  vodModalContentContainer: {
    backgroundColor: colors.getGrayAthens(),
    gap: 10,
    padding: 10,
  },
  sortContainer: {
    flexDirection: 'row',
    marginBottom: 28,
    gap: 10,
    flexWrap: 'wrap',
  },
  redButtonText: {
    fontFamily: fonts.getSignika(),
    fontWeight: 600,
    fontSize: 20,
    color: colors.getDeleteRed(),
  },
  purpleButtonText: {
    fontFamily: fonts.getSignika(),
    fontWeight: 600,
    fontSize: 20,
    color: colors.getMainPurple(),
  },
  deleteModalText: {
    fontFamily: fonts.getSignika(),
    fontWeight: 600,
    fontSize: 20,
    color: colors.getBluePrussian(),
  },
  picker: {
    borderRadius: 10,
    backgroundColor: colors.getGrayAthens(),
    border: 'none',
    fontFamily: fonts.getSignika(),
    color: colors.getBluePrussian(),
    fontSize: 18,
  },
  pickerItem: {
    // TODO : item style doesn't work in web
    fontFamily: fonts.getSignika(),
    color: colors.getBluePrussian(),
    fontSize: 18,
  },
});

const MobileWidth = 800;

const ActivityList = ({
  userRole,
  activities,
  currentlyRequesting,
  requestUserActivities,
  setActivityInfo,
  requestDeleteUserActivity,
  route,
}) => {
  const { t } = useTranslation();
  const isFocused = useIsFocused();

  const [sortMethodType, setSortMethodType] = useState(SortMethodsTypes.DATE);
  const [sortOrder, setSortOrder] = useState(SortOrdersTypes.NEW);

  const [selectedActivity, setSelectedActivity] = useState(null);

  const [modalType, setModalType] = useState(ModalType.NONE);
  // const [modalVisible, setModalVisible] = useState(false);
  // const [modalDeleteMode, setModalDeleteMode] = useState(false);

  const { width } = useWindowDimensions();

  const loadMoreContent = () => {
    if (currentlyRequesting) return;
    const skip = activities.length;
    const limit = LIST_LOAD_LIMIT;
    requestUserActivities(skip, limit, sortMethodType, sortOrder);
  };

  /**
   * No we need to fix the sort on the back, especially the date, make it a Date
   */

  useEffect(() => {
    if (currentlyRequesting) return;
    if (!isFocused) return;
    requestUserActivities(0, LIST_LOAD_LIMIT, sortMethodType, sortOrder, true);
  }, [route, sortMethodType, sortOrder, isFocused]);

  const onModifyActivity = (activity) => {
    setActivityInfo(activity);
    navigate(screenName.CALENDAR, { section_name: 'edit', id: activity._id });
  };

  const onDeleteActivity = (activity) => {
    requestDeleteUserActivity(activity._id);
    setModalType(ModalType.NONE);
  };

  const onClickActivity = (activity) => {
    setSelectedActivity(activity);
    setModalType(ModalType.ACTIVITY);
  };

  const onClickVodButton = (activity) => {
    setSelectedActivity(activity);
    setModalType(ModalType.VOD_LIST);
  };

  const onClickVodItem = (vodItem) => {
    navigate(screenName.ACTIVITY_SCREENS, {
      screen: 'activity',
      params: { id: selectedActivity?.id, vodId: vodItem._id },
    });
    setModalType(ModalType.NONE);
  };

  const onJoinEvent = (activity) => {
    setModalType(ModalType.NONE);
    navigate(screenName.ACTIVITY_SCREENS, {
      screen: 'activity',
      params: { id: activity._id, showPlayer: true, vodId: undefined },
    });
  };

  const getSortMethodItems = () => {
    return Object.entries(SortMethods).map(([key, method]) => (
      <Picker.Item key={key} label={t(method.label)} value={key} />
    ));
  };

  const getSortOrderItems = () => {
    return Object.entries(SortOrders).map(([key, order]) => (
      <Picker.Item key={key} label={t(order.label)} value={key} />
    ));
  };

  const onSortMethodChange = (methodType) => {
    setSortMethodType(methodType);
  };

  const onSortOrderChange = (order) => {
    setSortOrder(order);
  };

  const renderModal = () => {
    if (modalType === ModalType.NONE) return null;
    if (!selectedActivity) return null;

    let modalContent;
    let modalButtons;
    let modalTitle = Strings.YOUR_EVENT;

    if (modalType === ModalType.VOD_LIST) {
      modalContent = function renderVodListModalContent() {
        return (
          <ScrollView
            style={{ maxHeight: '50vh' }}
            contentContainerStyle={styles.vodModalContentContainer}
          >
            {selectedActivity.vodSessions.map((session) => (
              <VodCard
                key={session._id}
                vodSession={session}
                onClick={() => onClickVodItem(session)}
                style={{ backgroundColor: colors.getMainWhite() }}
              />
            ))}
          </ScrollView>
        );
      };
      modalTitle = Strings.YOUR_VOD_SESSIONS;
    } else if (modalType === ModalType.ACTIVITY_DELETE) {
      modalContent = function renderActivityDeleteModalContent() {
        return (
          <View style={styles.deleteModalContentContainer}>
            <Text style={styles.deleteModalText}>
              {t(Strings.ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_ACTIVITY)}
            </Text>
          </View>
        );
      };
      modalButtons = [
        {
          text: t(Strings.CONFIRM),
          textStyle: styles.purpleButtonText,
          onClick: () => onDeleteActivity(selectedActivity),
        },
        {
          text: t(Strings.CANCEL),
          textStyle: styles.redButtonText,
          onClick: () => setModalType(ModalType.ACTIVITY),
        },
      ];
    } else {
      modalContent = function renderActivityModalContent() {
        return (
          <View style={styles.modalContentContainer}>
            <Activity
              activity={selectedActivity}
              viewSize={SMALL}
              onClickVod={() => onClickVodButton(selectedActivity)}
              onJoinEvent={() => onJoinEvent(selectedActivity)}
              isClickable={false}
              containerStyle={{ backgroundColor: colors.getMainWhite() }}
            />
          </View>
        );
      };
      modalButtons = userRoleIsGTE(userRole, USER_ROLE.ORGANIZER)
        ? [
            {
              text: t(Strings.DELETE),
              textStyle: styles.redButtonText,
              onClick: () => setModalType(ModalType.ACTIVITY_DELETE),
            },
            {
              text: t(Strings.MODIFY),
              textStyle: styles.purpleButtonText,
              onClick: () => onModifyActivity(selectedActivity),
            },
          ]
        : [];
    }

    return (
      <ContentModal
        buttons={modalButtons}
        name={t(modalTitle)}
        isVisible={modalType !== ModalType.NONE}
        setIsVisible={(visible) => {
          if (!visible) setModalType(ModalType.NONE);
        }}
        renderContent={modalContent}
      />
    );
  };

  return (
    <View style={styles.mainContainer}>
      <View style={styles.sortContainer}>
        <Picker
          selectedValue={sortMethodType}
          onValueChange={onSortMethodChange}
          style={[styles.picker]}
          itemStyle={styles.pickerItem}
        >
          {getSortMethodItems()}
        </Picker>
        <Picker
          selectedValue={sortOrder}
          onValueChange={onSortOrderChange}
          style={[styles.picker]}
          itemStyle={styles.pickerItem}
        >
          {getSortOrderItems()}
        </Picker>
      </View>
      <FlatList
        contentContainerStyle={styles.contentContainer}
        data={activities}
        renderItem={({ item, index }) => (
          <Activity
            activity={item}
            viewSize={width > MobileWidth ? MEDIUM : SMALL}
            onClick={() => onClickActivity(item)}
            onClickVod={() => onClickVodButton(item)}
            onJoinEvent={() => onJoinEvent(item)}
            showLink={false}
          />
        )}
        keyExtractor={(item) => item._id}
        onEndReached={loadMoreContent}
        onEndReachedThreshold={0.2}
      />
      {renderModal()}
    </View>
  );
};

ActivityList.propTypes = {
  route: PropTypes.object.isRequired,

  // Actions :
  userRole: PropTypes.string,
  activities: PropTypes.array.isRequired,
  currentlyRequesting: PropTypes.bool.isRequired,
  requestUserActivities: PropTypes.func.isRequired,
  setActivityInfo: PropTypes.func.isRequired,
  requestDeleteUserActivity: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  userRole: userSelectors.makeSelectRole(),
  activities: userSelectors.makeSelectActivities(),
  currentlyRequesting: appSelectors.makeSelectCurrentlySending([REQUEST_USER_ACTIVITIES]),
});

const mapDispatchToProps = (dispatch) => {
  return {
    requestUserActivities: (skip, limit, sortMethod, sortOrder, reset) =>
      dispatch(userActions.requestUserActivities(skip, limit, sortMethod, sortOrder, reset)),
    setActivityInfo: (activityInfo) => dispatch(activityActions.setActivityInfo(activityInfo)),
    requestDeleteUserActivity: (_id) => dispatch(userActions.requestDeleteUserActivity(_id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivityList);
