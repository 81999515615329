import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';

import { useEffect } from 'react';
import { useStore } from 'react-redux';
import { socketMiddleware } from './socket';
import rootReducer from '../App/reducers/_index';
import sagas from '../App/sagas/_index';
import { ENV_LOCAL } from '../constants/global';

export const configureStore = () => {
  let composeEnhancers = compose;

  /* eslint-disable no-underscore-dangle */
  if (
    process.env.ENVIRONMENT === ENV_LOCAL &&
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  )
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({});
  /* eslint-enable */

  const sagaMiddleware = createSagaMiddleware();

  let store = null;
  const customMiddleware = (storeAPI) => (next) => (action) => {
    const result = next(action);
    const { listeners } = store;
    if (listeners) {
      listeners.forEach((l) => {
        l(action);
      });
    }
    return result; // IMPORTANT TO RETURN THE RESULT OF SOCKETMIDDLEWARE
  };

  // /!\ SocketMiddlware return value /!\
  const middlewares = [customMiddleware, sagaMiddleware, socketMiddleware];

  const enhancers = [applyMiddleware(...middlewares)];

  store = createStore(rootReducer, composeEnhancers(...enhancers));
  store.listeners = [];
  store.addActionListener = (listener) => {
    if (!store.listeners.find((l) => l === listener)) {
      store.listeners.push(listener);
    }
  };
  store.removeActionListener = (listener) => {
    store.listeners = store.listeners.filter((l) => l !== listener);
  };

  sagaMiddleware.run(sagas);

  return store;
};

/**
 * Subscribes to redux store events
 *
 * @param effect
 * @param type
 * @param deps
 */
export function useReduxAction(effect, type, deps = []) {
  const store = useStore();
  useEffect(() => {
    const listener = (action) => {
      if (action?.type === type) effect(action);
    };
    store.addActionListener(listener);
    return () => {
      store.removeActionListener(listener);
    };
  }, deps);
}

export default {
  configureStore,
};
