import React, { createRef, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { StyleSheet, View, Text, TouchableOpacity } from 'react-native';
import { Video, VideoOvenMedia } from '@kalyzee/kast-react-player-module';
import { useTranslation } from 'react-i18next';
import colors from '../../constants/colors';
import fonts from '../../constants/fonts';
import {
  ActivityInteractionEventType,
  ActivityInteractionStatus,
} from '../../helpers/interactions';
import LoadingSvg from '../Svg/loading';
import UserAvatar from '../User/avatar';
import ElementInOverlay from '../Generic/elementInOverlay';
import ElementOnHover from '../Generic/elementOnHover';
import RefeshContainer from '../Generic/refreshContainer';
import { getDateDifferenceText, getSinceText } from '../../helpers/utils';
import IconChat from '../../assets/blue/icon-chat-white.svg';
import { Strings } from '../../helpers/strings';

// |                                                    |
// |  HEADER View  |     MAIN View   |    BUTTONS View  |
// |                                                    |

export const ActivityInteractionResponsiveMode = {
  NORMAL: 'normal',
  SMALL: 'small',
  XSMALL: 'xsmall',
};

// normal mode
const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: 20,
    width: '100%',
    height: 80,
    borderRadius: 5,
    overflow: 'hidden',
    backgroundColor: colors.getMirage(0.8),
    shadowRadius: 2,
    shadowColor: colors.getMainBlack(0.5),
    padding: 10,
  },
  smallContainer: {
    height: 60,
  },
  headerContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  mainContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    gap: 5,
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: 10,
    marginRight: 10,
  },
  loading: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  descriptionText: {
    fontFamily: fonts.getSignika(),
    fontSize: 16,
    color: colors.getMainWhite(),
  },
  descriptionDetailsText: {
    fontFamily: fonts.getSignika(),
    fontSize: 14,
    color: colors.getGrayLevel(0.6),
  },
  sinceText: {
    fontFamily: fonts.getHalisR(),
    fontSize: 20,
    fontWeight: 'bold',
    color: colors.getBlueBayoux(),
    minWidth: 60,
    textAlign: 'center',
  },
  button: {
    fontFamily: fonts.getSignika(),
    fontSize: 16,
    fontWeight: 'bold',
    minWidth: 70,
    textAlign: 'right',
  },
  validateButton: {
    color: colors.getMainGreen(),
  },
  unvalidateButton: {
    color: colors.getTorchRed(),
  },
  messageHoveredContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 10,
    backgroundColor: colors.getBlueCornflower(),
    padding: 7,
    borderRadius: 5,
    shadowRadius: 2,
    shadowColor: colors.getMainBlack(0.5),
    overflow: 'visible', // marker
    maxWidth: '40vw',
    marginBottom: 10,
  },
  messageHoveredMarker: {
    position: 'absolute',
    backgroundColor: colors.getBlueCornflower(),
    width: 8,
    height: 8,
    left: '50%',
    bottom: -4,
    transform: 'translate(-5px, 0px) rotate(45deg)',
  },
  messageHoveredText: {
    fontFamily: fonts.getSignika(),
    fontSize: 16,
    color: colors.getMainWhite(),
  },
});

const stylesSmallMode = StyleSheet.create({
  container: {
    height: 60,
  },
  smallContainer: {
    height: 50,
  },
  buttonsContainer: {
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'center',
    gap: 3,
  },
  mainContainer: {
    gap: 2,
  },
  descriptionText: {
    fontSize: 14,
  },
  descriptionDetailsText: {
    fontSize: 12,
  },
  sinceText: {
    fontSize: 18,
  },
  button: {
    fontSize: 14,
    textAlign: 'right',
  },
});

const stylesXSmallMode = StyleSheet.create({
  container: {
    height: 50,
  },
  smallContainer: {
    height: 40,
  },
  buttonsContainer: {
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'center',
    gap: 3,
  },
  mainContainer: {
    gap: 0,
  },
  descriptionText: {
    fontSize: 13,
  },
  descriptionDetailsText: {
    fontSize: 11,
  },
  sinceText: {
    fontSize: 16,
  },
  button: {
    fontSize: 13,
    textAlign: 'right',
    maxWidth: 100,
  },
});

const ActivityInteractionItem = ({
  interaction,
  me,
  onRequest,
  onJoin,
  onStop,
  onCancel,
  responsiveMode,
  displayHeaderView,
  displayMainView,
  displayButtonsView,
  style,
}) => {
  const { t } = useTranslation();
  const [videoIsReady, setVideoIsReady] = useState(false);
  const videoMediaRef = useRef(null);

  const loadingRef = useRef();
  const buttonContainerRef = useRef();

  const { status } = interaction;
  const { events } = interaction;
  const { user } = interaction;
  const username = `${user?.username ?? '-'}`;

  useEffect(() => {
    if (interaction?.status !== ActivityInteractionStatus.WAITING_TO_JOIN) {
      if (videoIsReady) {
        videoMediaRef.current = false;
        setVideoIsReady(false);
      }
    }
  }, [interaction, videoIsReady]);

  // ---------------------------------------------------- //
  // -------------------- UTILS ---------------------- //
  // ---------------------------------------------------- //

  const getStyles = (key) => {
    const result = [styles[key]];
    const mode = responsiveMode ?? ActivityInteractionResponsiveMode.NORMAL;
    if (mode === ActivityInteractionResponsiveMode.SMALL) {
      result.push(stylesSmallMode[key]);
    }
    if (mode === ActivityInteractionResponsiveMode.XSMALL) {
      result.push(stylesXSmallMode[key]);
    }
    return result;
  };

  const getPrefixKey = () => `${interaction.id}_${interaction.status}`;

  const renderButton = (type, title, callback, textStyle, refButton = null, refText = null) => {
    const textStyles = [...getStyles('button')];
    if (type === 'validate') textStyles.push(...getStyles('validateButton'));
    else if (type === 'unvalidate') textStyles.push(...getStyles('unvalidateButton'));
    if (textStyle) textStyles.push(...(Array.isArray(textStyle) ? textStyle : [textStyle]));
    return (
      <TouchableOpacity
        key={`${getPrefixKey()}_button_${type}`}
        onPress={async () => {
          if (!callback) return;
          if (loadingRef.current) loadingRef.current.setNativeProps({ style: { display: 'flex' } });
          if (buttonContainerRef.current)
            buttonContainerRef.current.setNativeProps({ style: { opacity: 0 } }); // keep dimensions
          await callback();
          if (loadingRef.current) loadingRef.current.setNativeProps({ style: { display: 'none' } });
          if (buttonContainerRef.current)
            buttonContainerRef.current.setNativeProps({ style: { opacity: 1 } });
        }}
        ref={refButton}
      >
        <Text numberOfLines={1} ellipsizeMode="tail" style={textStyles} ref={refText}>
          {title}
        </Text>
      </TouchableOpacity>
    );
  };

  const renderDescription = (text, currStyle = null, ref = null, key = null) => {
    const currStyles = [getStyles('descriptionText')];
    if (currStyle) currStyles.push(...(Array.isArray(currStyle) ? currStyle : [currStyle]));
    if (typeof text === 'string') {
      return (
        <Text
          ref={ref}
          key={key ?? `${getPrefixKey()}_description`}
          numberOfLines={1}
          ellipsizeMode="tail"
          style={currStyles}
        >
          {text}
        </Text>
      );
    }
    return (
      <View ref={ref} key={key ?? `${getPrefixKey()}_description`} style={currStyles}>
        {text}
      </View>
    );
  };

  const renderDescriptionDetails = (text, currStyle = null, ref = null, key = null) => {
    const currStyles = [getStyles('descriptionDetailsText')];
    if (currStyle) currStyles.push(...(Array.isArray(currStyle) ? currStyle : [currStyle]));
    if (typeof text === 'string') {
      return (
        <Text
          ref={ref}
          key={key ?? `${getPrefixKey()}_description_details`}
          numberOfLines={2}
          ellipsizeMode="tail"
          style={currStyles}
        >
          {text}
        </Text>
      );
    }
    return (
      <View ref={ref} key={key ?? `${getPrefixKey()}_description_details`} style={currStyles}>
        {text}
      </View>
    );
  };

  const renderLoading = (display) => (
    <View ref={loadingRef} style={[...getStyles('loading'), display ? {} : { display: 'none' }]}>
      <LoadingSvg width="20px" fill={colors.getMainWhite()} />
    </View>
  );

  const renderMessageHovered = (targetRef, icon, message, delay = undefined) => (
    <ElementInOverlay>
      <ElementOnHover targetRef={targetRef} delay={delay}>
        <View style={getStyles('messageHoveredContainer')}>
          {icon}
          <Text style={getStyles('messageHoveredText')}>{message}</Text>
          <View style={getStyles('messageHoveredMarker')} />
        </View>
      </ElementOnHover>
    </ElementInOverlay>
  );

  // ---------------------------------------------------- //
  // -------------------- CONTENT VIEWs ----------------- //
  // ---------------------------------------------------- //

  // Default
  let renderContentHeaderView = () => (
    <UserAvatar user={user} displayBadge={false} style={{ borderRadius: 5 }} />
  );
  let renderContentMainView = () => [];
  let renderContentButtonsView = () => [];

  // ---------------------------------------------------- //
  // -------------------- UPDATE RENDER FUNCTIONS -------------- //
  // ---------------------------------------------------- //

  const initWaitingResponse = () => {
    const event = events?.find((ev) => ev.type === ActivityInteractionEventType.REQUEST);
    // event?.message
    const description = `${username}`;
    const eventMessage = event?.message;
    const chatMessageRef = createRef();
    const message = (
      <View
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        <Text style={getStyles('descriptionDetailsText')} numberOfLines={1} ellipsizeMode="tail">
          {t(Strings.ACTIVITY_INTERACTION_HOST_CONTROLLER_WAITING_RESPONSE_MESSAGE)}
        </Text>
        {eventMessage ? (
          <View ref={chatMessageRef} style={{ paddingLeft: 15, paddingRight: 15 }}>
            <IconChat strokeWidth="2" stroke={colors.getBlueCornflower()} width={20} />
            {renderMessageHovered(
              chatMessageRef,
              <IconChat strokeWidth="2" stroke={colors.getMainWhite()} width={20} />,
              eventMessage,
              100,
            )}
          </View>
        ) : null}
      </View>
    );
    const validateButton = t(
      Strings.ACTIVITY_INTERACTION_HOST_CONTROLLER_WAITING_RESPONSE_ACCEPT_BUTTON,
    );
    const unvalidateButton = t(
      Strings.ACTIVITY_INTERACTION_HOST_CONTROLLER_WAITING_RESPONSE_REFUSE_BUTTON,
    );
    const validateCB = onRequest ? () => onRequest(interaction.id, true) : undefined;
    const unvalidateCB = onRequest ? () => onRequest(interaction.id, false) : undefined;
    renderContentMainView = () => [
      renderDescription(description),
      message ? renderDescriptionDetails(message) : null,
    ];
    renderContentButtonsView = () => [
      renderButton('validate', validateButton, validateCB),
      renderButton('unvalidate', unvalidateButton, unvalidateCB),
    ];
  };

  const initWaitingMedia = () => {
    const description = `${username}`;
    const message = t(Strings.ACTIVITY_INTERACTION_HOST_CONTROLLER_WAITING_MEDIA_MESSAGE);
    const unvalidateButton = t(
      Strings.ACTIVITY_INTERACTION_HOST_CONTROLLER_WAITING_MEDIA_CANCEL_BUTTON,
    );
    const unvalidateCB = onCancel ? () => onCancel(interaction.id) : undefined;
    renderContentMainView = () => [
      renderDescription(description),
      renderDescriptionDetails(message),
    ];
    renderContentButtonsView = () => [renderButton('unvalidate', unvalidateButton, unvalidateCB)];
  };

  const initWaitingToJoin = () => {
    const event = events?.find((ev) => ev.type === ActivityInteractionEventType.MEDIA_IS_READY);
    const description = `${username}`;
    const message = videoIsReady
      ? t(Strings.ACTIVITY_INTERACTION_HOST_CONTROLLER_WAITING_TO_JOIN_VIDEO_IS_READY_MESSAGE)
      : t(Strings.ACTIVITY_INTERACTION_HOST_CONTROLLER_WAITING_TO_JOIN_WAITING_VIDEO_MESSAGE);
    const validateButton = t(
      Strings.ACTIVITY_INTERACTION_HOST_CONTROLLER_WAITING_TO_JOIN_START_BUTTON,
    );
    const unvalidateButton = t(
      Strings.ACTIVITY_INTERACTION_HOST_CONTROLLER_WAITING_TO_JOIN_CANCEL_BUTTON,
    );
    const validateCB = onJoin ? () => onJoin(interaction.id, true) : undefined;
    const unvalidateCB = onJoin ? () => onJoin(interaction.id, false) : undefined;

    const playerContainerRef = createRef();

    renderContentHeaderView = () => [
      <View
        ref={(ref) => {
          playerContainerRef.current = ref;
          // force aspect-ratio here // Style doesn't work
          const refStyle = ref?.style;
          if (refStyle) refStyle.aspectRatio = 1;
        }}
        key={`${getPrefixKey()}_video`}
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
        }}
      >
        <VideoOvenMedia
          style={{
            maxWidth: '100%',
            maxHeight: '100%',
            backgroundColor: 'transparent',
            borderRadius: 5,
            overflow: 'hidden',
          }}
          src={event?.mediaSrc}
          autoRetry
          retryAfter={1000}
          mute
          play
          onReady={(_, ready, media) => {
            if (ready) {
              videoMediaRef.current = media;
              setVideoIsReady(true);
            }
          }}
        />
      </View>,
      !videoIsReady ? (
        <View
          key={`${getPrefixKey()}_loading_video`}
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <LoadingSvg width="20px" fill={colors.getMainWhite()} />
        </View>
      ) : null,
      videoIsReady ? (
        <ElementInOverlay key={`${getPrefixKey()}_overlay_video`}>
          <ElementOnHover targetRef={playerContainerRef} delay={100}>
            <View
              style={{
                backgroundColor: 'transparent',
                shadowColor: colors.getMainBlack(0.6),
                shadowRadius: 10,
                borderRadius: 10,
                overflow: 'hidden',
                width: 300,
              }}
            >
              <Video media={videoMediaRef.current} play mute />
            </View>
          </ElementOnHover>
        </ElementInOverlay>
      ) : null,
    ];

    renderContentMainView = () => [
      renderDescription(description),
      renderDescriptionDetails(message),
    ];
    renderContentButtonsView = () => [
      videoIsReady ? renderButton('validate', validateButton, validateCB) : null,
      renderButton('unvalidate', unvalidateButton, unvalidateCB),
    ];
  };

  const initRunning = () => {
    const event = events?.find((ev) => ev.type === ActivityInteractionEventType.JOINED);
    const description = `${username}`;
    let message = t(Strings.ACTIVITY_INTERACTION_HOST_CONTROLLER_RUNNING_MESSAGE);
    let liveDateComponent = null;
    if (event) {
      const date = new Date(event.createdAt);
      const strDate = date.toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit' });
      message = t(Strings.ACTIVITY_INTERACTION_HOST_CONTROLLER_RUNNING_MESSAGE_WITH_DATE, {
        date: strDate,
      });
      liveDateComponent = (
        <RefeshContainer refreshEveryMs={1000}>
          {() => (
            <Text style={getStyles('sinceText')} numberOfLines={1} ellipsizeMode="tail">
              {getSinceText(date)}
            </Text>
          )}
        </RefeshContainer>
      );
    }
    const unvalidateButton = t(Strings.ACTIVITY_INTERACTION_HOST_CONTROLLER_RUNNING_STOP_BUTTON);
    const unvalidateCB = onStop ? () => onStop(interaction.id) : undefined;
    renderContentMainView = () => (
      <View
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
          gap: 5,
        }}
      >
        <View style={getStyles('mainContainer')}>
          {[renderDescription(description), renderDescriptionDetails(message)]}
        </View>
        {liveDateComponent}
      </View>
    );
    renderContentButtonsView = () => [renderButton('unvalidate', unvalidateButton, unvalidateCB)];
  };

  const initRefused = () => {
    const event = events?.find((ev) => ev.type === ActivityInteractionEventType.REQUEST_DECLINED);
    const description = `${username}`;
    let message = t(Strings.ACTIVITY_INTERACTION_HOST_CONTROLLER_REFUSED_MESSAGE);
    if (event) {
      const date = new Date(event.createdAt);
      const strDate = date.toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit' });
      message = t(Strings.ACTIVITY_INTERACTION_HOST_CONTROLLER_REFUSED_MESSAGE_WITH_DATE, {
        date: strDate,
      });
      if (me?.id === event.initiatedBy?.id) {
        message = t(Strings.ACTIVITY_INTERACTION_HOST_CONTROLLER_REFUSED_MESSAGE_WITH_DATE_BY_ME, {
          date: strDate,
        });
      } else if (event.initiatedBy?.username) {
        message = t(
          Strings.ACTIVITY_INTERACTION_HOST_CONTROLLER_REFUSED_MESSAGE_WITH_DATE_BY_USER,
          { user: event.initiatedBy.username, date: strDate },
        );
      }
    }
    renderContentMainView = () => [
      renderDescription(description),
      message ? renderDescriptionDetails(message) : null,
    ];
    renderContentButtonsView = () => null;
  };

  const initCanceled = () => {
    const event = events?.find((ev) => ev.type === ActivityInteractionEventType.CANCELED);
    const eventJoined = events?.find((ev) => ev.type === ActivityInteractionEventType.JOINED);
    const description = `${username}`;
    let message = t(Strings.ACTIVITY_INTERACTION_HOST_CONTROLLER_CANCELED_MESSAGE);
    if (event) {
      const date = new Date(event.createdAt);
      const strDate = date.toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit' });
      message = t(Strings.ACTIVITY_INTERACTION_HOST_CONTROLLER_CANCELED_MESSAGE_WITH_DATE, {
        date: strDate,
      });
      if (me?.id === event.initiatedBy?.id) {
        message = t(Strings.ACTIVITY_INTERACTION_HOST_CONTROLLER_CANCELED_MESSAGE_WITH_DATE_BY_ME, {
          date: strDate,
        });
      } else if (event.initiatedBy?.username) {
        message = t(
          Strings.ACTIVITY_INTERACTION_HOST_CONTROLLER_CANCELED_MESSAGE_WITH_DATE_BY_USER,
          { user: event.initiatedBy.username, date: strDate },
        );
      }

      if (eventJoined) {
        const strDuration = getDateDifferenceText(eventJoined.createdAt, date.getTime());
        message = `${message}  -  ${t(
          Strings.ACTIVITY_INTERACTION_HOST_CONTROLLER_DURATION_MESSAGE,
          { duration: strDuration },
        )}`;
      }
    }
    renderContentMainView = () => [
      renderDescription(description),
      message ? renderDescriptionDetails(message) : null,
    ];
    renderContentButtonsView = () => null;
  };

  const initTerminated = () => {
    const event = events?.find((ev) => ev.type === ActivityInteractionEventType.STOPPED);
    const eventJoined = events?.find((ev) => ev.type === ActivityInteractionEventType.JOINED);
    const description = `${username}`;
    let message = t(Strings.ACTIVITY_INTERACTION_HOST_CONTROLLER_TERMINATED_MESSAGE);
    if (event) {
      const date = new Date(event.createdAt);
      const strDate = date.toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit' });
      message = t(Strings.ACTIVITY_INTERACTION_HOST_CONTROLLER_TERMINATED_MESSAGE_WITH_DATE, {
        date: strDate,
      });
      if (me?.id === event.initiatedBy?.id) {
        message = t(
          Strings.ACTIVITY_INTERACTION_HOST_CONTROLLER_TERMINATED_MESSAGE_WITH_DATE_BY_ME,
          { date: strDate },
        );
      } else if (event.initiatedBy?.username) {
        message = t(
          Strings.ACTIVITY_INTERACTION_HOST_CONTROLLER_TERMINATED_MESSAGE_WITH_DATE_BY_USER,
          { user: event.initiatedBy.username, date: strDate },
        );
      }

      if (eventJoined) {
        const strDuration = getDateDifferenceText(eventJoined.createdAt, date.getTime());
        message = `${message}  -  ${t(
          Strings.ACTIVITY_INTERACTION_HOST_CONTROLLER_DURATION_MESSAGE,
          { duration: strDuration },
        )}`;
      }
    }
    renderContentMainView = () => [
      renderDescription(description),
      message ? renderDescriptionDetails(message) : null,
    ];
    renderContentButtonsView = () => null;
  };

  if (status === ActivityInteractionStatus.WAITING_RESPONSE) {
    initWaitingResponse();
  } else if (status === ActivityInteractionStatus.WAITING_MEDIA) {
    initWaitingMedia();
  } else if (status === ActivityInteractionStatus.WAITING_TO_JOIN) {
    initWaitingToJoin();
  } else if (status === ActivityInteractionStatus.RUNNING) {
    initRunning();
  } else if (status === ActivityInteractionStatus.REFUSED) {
    initRefused();
  } else if (status === ActivityInteractionStatus.CANCELED) {
    initCanceled();
  } else if (status === ActivityInteractionStatus.TERMINATED) {
    initTerminated();
  } else {
    return null;
  }

  // ------------------------------------------ //
  // -------------------- VIEWS -------------- //
  // ------------------------------------------ //

  const renderHeaderView = () => {
    if (!displayHeaderView) return null;
    return <View style={getStyles('headerContainer')}>{renderContentHeaderView()}</View>;
  };

  const renderMainView = () => {
    if (!displayMainView) return null;
    return <View style={getStyles('mainContainer')}>{renderContentMainView()}</View>;
  };

  const renderButtonsView = () => {
    if (!displayButtonsView) return null;
    return (
      <View>
        <View ref={buttonContainerRef} style={getStyles('buttonsContainer')}>
          {renderContentButtonsView()}
        </View>
        {renderLoading()}
      </View>
    );
  };

  // ------------------------------------------ //
  // -------------------- RENDER -------------- //
  // ------------------------------------------ //

  const containerStyle = [getStyles('container')];
  if (style) containerStyle.push(...(Array.isArray(style) ? style : [style]));
  return (
    <View style={containerStyle}>
      {renderHeaderView()}
      {renderMainView()}
      {renderButtonsView()}
    </View>
  );
};

ActivityInteractionItem.propTypes = {
  interaction: PropTypes.object.isRequired,
  me: PropTypes.object,
  onRequest: PropTypes.func,
  onJoin: PropTypes.func,
  onStop: PropTypes.func,
  onCancel: PropTypes.func,
  displayHeaderView: PropTypes.bool,
  displayMainView: PropTypes.bool,
  displayButtonsView: PropTypes.bool,
  responsiveMode: PropTypes.string,
  style: PropTypes.any,
};

ActivityInteractionItem.defaultProps = {
  displayHeaderView: true,
  displayMainView: true,
  displayButtonsView: true,
  responsiveMode: ActivityInteractionResponsiveMode.NORMAL,
  style: undefined,
};

export default ActivityInteractionItem;
