import { put, call, fork, take, cancel } from 'redux-saga/effects';
import { ErrorType } from '../../constants/errors';
import { toastError } from '../../helpers/toast';

import { Types as errorTypes } from '../actions/error';
import userActions from '../actions/user';
import { navigate } from '../../helpers/navigation';
import { screenName } from '../../constants/navigation';

const DEV = process.env.ENVIRONMENT !== 'prod';

function* unauthorizedError(body) {
  const message = body?.message;
  let error = message;
  if (error === undefined) {
    error = 'Unauthorized';
  }
  yield put(userActions.requestLogout());

  let reason;
  // TODO replace cname with code from @kalyzee/kast-app-module
  if (body?.cname === 'USER_IS_BANNED') reason = 'ubanned';
  else if (body?.cname === 'USER_IS_DISABLED') reason = 'udisabled';
  else if (body?.cname === 'USER_TRIAL_PERIOD_IS_OVER') reason = 'utrialperiodisover';
  else if (body?.cname === 'ORGANIZATION_IS_DISABLED') reason = 'odisabled';
  else if (body?.cname === 'ORGANIZATION_TRIAL_PERIOD_IS_OVER') reason = 'otrialperiodisover';

  if (reason) {
    // addition of a timeout to allow time for "Navigation" to restart
    setTimeout(() => navigate(screenName.FORBIDDEN, { r: reason }), 200);
    return;
  }
  if (DEV) yield toastError(error); // TO REMOVE ?
}

function* notFoundError(body) {
  const message = body?.message;
  let error = message;
  if (error === undefined) {
    error = 'Not found';
  }
  if (DEV) yield toastError(error); // TO REMOVE ?
}

function* internalError(body) {
  const message = body?.message;
  let error = message;
  if (error === undefined) {
    error = 'Internal server error';
  }
  if (DEV) yield toastError(error); // TO REMOVE ?
}

function* defaultError(body) {
  const message = body?.message;
  let error = message;
  if (error === undefined) {
    error = 'Client error';
  }
  if (DEV) yield toastError(error); // TO REMOVE ?
}

function* handleError({ error }) {
  const { response, code, errorType } = error;

  if (errorType === ErrorType.TOKEN_ERROR) {
    yield call(unauthorizedError);
    return;
  }

  const status = code || response?.status;

  let body;
  try {
    body = yield response?.json();
  } catch {
    // Pass
  }

  switch (status) {
    case 401:
    case 403:
    case 460:
      yield call(unauthorizedError, body);
      break;
    case 404:
      yield call(notFoundError, body);
      break;
    case 500:
      yield call(internalError, body);
      break;
    default:
      yield call(defaultError, body);
  }

  /**
   * Need to do a "<WithAlert />" and use it along side <WithSocket />
   * Create a React Ref with function "alert(title, message, duration)".
   * Update the WithAlert Containers from the ref..
   * Could also create a hook useAlert(time, message, duration) => update the ref...
   * position absolute, visible for the duration time (hidden otherwise)
   *
   * yield alert(message);
   * */
}

/**
 * Idea : If a 401 error is catched, the other errors that are pending are all cancelled.
 * -> An error is pending if it was catched but didn't reach the point where it is displayed / logged yet.
 *
 * The following function is useful when a single action on the interface can simultaneously fire many requests to the backend,
 * which can potentially all fail if the user token is broken, causing the interface to display many pop-ups.
 *
 * Idea of improvement :
 * 1) Do not cancel totally the pending requests, only the pop-ups.
 * 2) Save every error in a stack.
 * 3) Make so that the most recent error, the one that managed to reach the end of the error handling,
 *    displays the error stack, so no information is 'lost' even for the user. (Could be a pop-up or a dedicated page)
 * 4) This could be generalized to every kinds of errors.
 */
function* watcherHandleError() {
  let pendingErrors = [];

  while (true) {
    const errorAction = yield take(errorTypes.HANDLE_ERROR);

    if (errorAction.error.response && errorAction.error.response.status === 401) {
      yield cancel(pendingErrors);
      pendingErrors = [];
    }

    const errorTask = yield fork(handleError, errorAction);
    pendingErrors.push(errorTask);
  }
}

export default [watcherHandleError()];
