import { requestAPI, getHeadersAPI } from '../../helpers/request';

const basePath = '/api';

const paths = {
  getVersion: () => {
    return `${basePath}/version`;
  }, // GET
};

// ------------------------------------------------------- //
// --------------------- REQUEST ------------------------- //
// ------------------------------------------------------- //

// ---------- GET
const requestApiVersion = async () => {
  return requestAPI(paths.getVersion(), getHeadersAPI('GET', false), undefined, false);
};

export default {
  requestApiVersion,
};
