import { call, put, takeLatest } from 'redux-saga/effects';

import api from '../api/_index';

import errorActions from '../actions/error';
import appActions from '../actions/app';

import vodActions, { Types as vodTypes } from '../actions/vod';
import { submitRequest } from './user';

const { REQUEST_VOD } = vodTypes;

function* requestVod({ _id }) {
  try {
    yield put(appActions.addCurrentlySending(`${REQUEST_VOD}`));

    const vod = yield call(submitRequest, api.vod.requestVodById, _id);

    yield put(vodActions.setVodInfo(vod));
  } catch (error) {
    yield put(errorActions.handleError(error));
  } finally {
    yield put(appActions.removeCurrentlySending(`${REQUEST_VOD}`));
  }
}

function* watcherRequestVod() {
  yield takeLatest(REQUEST_VOD, requestVod);
}

export default [watcherRequestVod()];
