import { StyleSheet, View, TouchableOpacity, Text, useWindowDimensions } from 'react-native';
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { createStructuredSelector } from 'reselect';
import moment from 'moment';

import colors, { DARK_BLUE } from '../../constants/colors';
import { useInterval } from '../../hooks/utils';
import { sGlobal, sNavBarTop } from '../../styles/fixed';
import userSelectors from '../../App/selectors/user';

import IconChat from '../../assets/blue/icon-chat.svg';
import IconRecording from '../../assets/blue/icon-recording.svg';
import IconView from '../../assets/blue/icon-view.svg';

import fonts from '../../constants/fonts';
import Badge, { BADGE_MODE } from '../../components/Generic/badge';
import PlayerMessage from '../../components/Activity/message';
import BackButton from '../../components/Generic/backButton';
import { Strings } from '../../helpers/strings';
import { filterActivityInteractionsInProcessing } from '../../helpers/interactions';
import { getSinceText, getUntilText } from '../../helpers/utils';
import RaiseHandSvg from '../../components/Svg/raiseHand';
import { VIDEO_STATE } from '../../constants/activity';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    backgroundColor: DARK_BLUE,
    alignItems: 'center',
    justifyContent: 'center',
    padding: sGlobal.padding,
    gap: '0px 10px',
    paddingBottom: 5,
    paddingTop: 5,
    paddingLeft: 40,
    paddingRight: 80,
    flexWrap: 'wrap',
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '10px',
  },
  leftOverlayContainer: {
    position: 'absolute',
    left: 0,
    top: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '10px',
    zIndex: 10,
    maxWidth: '100%',
    height: '100%',
  },
  backButton: {
    position: 'relative',
    top: 0,
    left: 0,
  },
  shortcuts: {
    position: 'absolute',
    left: 20,
    flexDirection: 'row',
    width: 120,
    justifyContent: 'space-between',
  },
  chatButton: {
    position: 'absolute',
    right: 15,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  vodMessage: {
    flex: 1,
  },
  statusContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
    backgroundColor: 'white',
    borderRadius: 5,
    paddingLeft: 10,
    paddingRight: 10,
  },
  liveStatus: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  liveStatusText: {
    marginLeft: 5,
    fontFamily: fonts.getSignika(),
  },
  previewButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.getMainGreen(),
    borderRadius: 5,
    paddingLeft: 10,
    paddingRight: 10,
  },
  previewButtonText: {
    marginLeft: 5,
    fontFamily: fonts.getSignika(),
    color: colors.getMainWhite(),
  },
  interactionButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.getGrayDusty(),
    borderRadius: 5,
    paddingLeft: 10,
    paddingRight: 10,
  },
  interactionButtonText: {
    marginLeft: 10,
    fontFamily: fonts.getSignika(),
    color: colors.getMainWhite(),
  },
});

const stylesSmallMode = StyleSheet.create({
  container: {
    height: sNavBarTop.SMALL.height,
    minHeight: sNavBarTop.SMALL.height,
    maxHeight: sNavBarTop.SMALL.height,
    flexDirection: 'column',
  },
  chatButton: {
    transform: 'scale(0.4) translateX(60%)',
    right: 5,
  },
  liveStatus: {
    height: 15,
  },
  liveStatusText: {
    fontSize: 12,
  },
  previewButton: {
    height: 15,
  },
  previewButtonText: {
    fontSize: 12,
  },
  interactionButton: {
    height: 15,
  },
  interactionButtonText: {
    fontSize: 12,
  },
});

const stylesMediumMode = StyleSheet.create({
  container: {
    height: sNavBarTop.MEDIUM.height,
    minHeight: sNavBarTop.MEDIUM.height,
    maxHeight: sNavBarTop.MEDIUM.height,
  },
  chatButton: {
    transform: 'scale(0.7) translateX(30%)',
    right: 10,
  },
  liveStatus: {
    height: 25,
  },
  liveStatusText: {
    fontSize: 14,
  },
  previewButton: {
    height: 25,
  },
  previewButtonText: {
    fontSize: 14,
  },
  interactionButton: {
    height: 25,
  },
  interactionButtonText: {
    fontSize: 14,
  },
});

const stylesLargeMode = StyleSheet.create({
  container: {
    height: sNavBarTop.LARGE.height,
    minHeight: sNavBarTop.LARGE.height,
    maxHeight: sNavBarTop.LARGE.height,
  },
  chatButton: {
    right: 15,
  },
  liveStatus: {
    height: 30,
  },
  liveStatusText: {
    fontSize: 16,
  },
  previewButton: {
    height: 30,
  },
  previewButtonText: {
    fontSize: 16,
  },
  interactionButton: {
    height: 30,
  },
  interactionButtonText: {
    fontSize: 16,
  },
});

const getResponsiveStyles = (width, height) => {
  let result = stylesLargeMode;
  if (height < 300) result = stylesSmallMode;
  else if (height < 600) result = stylesMediumMode;
  return result;
};

const areEqualWithDelta = (time1, time2, delta) => {
  return moment(time1).diff(moment(time2), 'second') < delta;
};

const NavBarTop = ({
  displayChatMenu,
  chatBadge,
  onDisplayChatMenuChange,
  user,
  activityStartsAt,
  activityEndsAt,
  liveStartedAt,
  recordStartedAt,
  activityState,
  activityCanLive,
  displayPreviewButton,
  onDisplayPreviewButton,
  displayInteractionsButton,
  enableInteractions,
  interactions,
  onInteractionsButton,
  displayBackButton,
  backButtonOptions,
}) => {
  const { t } = useTranslation();

  const [liveSince, setLiveSince] = useState(undefined);
  const [recordSince, setRecordSince] = useState(undefined);
  const [activityStartsIn, setActivityStartsIn] = useState(undefined);

  const { width, height } = useWindowDimensions();
  const responsiveStyles = getResponsiveStyles(width, height);

  const getLiveSinceText = () => {
    return getSinceText(liveStartedAt);
  };

  const getRecordSinceText = () => {
    return getSinceText(recordStartedAt);
  };

  const getActivityStartsInText = () => {
    return getUntilText(activityStartsAt);
  };

  useEffect(() => {
    if (liveStartedAt) {
      setLiveSince(getLiveSinceText());
    } else {
      setLiveSince(undefined);
    }
    if (recordStartedAt) {
      setRecordSince(getRecordSinceText());
    } else {
      setRecordSince(undefined);
    }
  }, [liveStartedAt, recordStartedAt]);

  useInterval(() => {
    if (liveSince !== undefined) {
      setLiveSince(getLiveSinceText());
    }
    if (recordSince !== undefined) {
      setRecordSince(getRecordSinceText());
    }
    setActivityStartsIn(getActivityStartsInText());
  }, 1000);

  const iconChat = () => {
    if (!displayChatMenu) {
      return (
        <View style={[styles.chatButton, responsiveStyles.chatButton]}>
          <TouchableOpacity
            onPress={() => {
              if (onDisplayChatMenuChange) onDisplayChatMenuChange(true);
            }}
          >
            <IconChat />
            <Badge mode={BADGE_MODE.NUMBER} maxNumber={9} number={chatBadge} />
          </TouchableOpacity>
        </View>
      );
    }
    return null;
  };

  const statusLive = useCallback(() => {
    const now = new Date();
    if (now < new Date(activityStartsAt) && activityStartsIn) {
      return (
        <View style={styles.statusContainer}>
          <View style={[styles.liveStatus, responsiveStyles.liveStatus]}>
            <Text style={[styles.liveStatusText, responsiveStyles.liveStatusText]}>
              {`${t(Strings.EVENT_STARTS_IN)} ${activityStartsIn}`}
            </Text>
          </View>
        </View>
      );
    }
    if (now > new Date(activityEndsAt)) {
      return (
        <View style={styles.statusContainer}>
          <View style={[styles.liveStatus, responsiveStyles.liveStatus]}>
            <Text style={[styles.liveStatusText, responsiveStyles.liveStatusText]}>
              {t(Strings.EVENT_TERMINATED)}
            </Text>
          </View>
        </View>
      );
    }
    if (liveSince && recordSince && areEqualWithDelta(liveStartedAt, recordStartedAt, 10)) {
      return (
        <View style={styles.statusContainer}>
          {liveSince ? (
            <View style={[styles.liveStatus, responsiveStyles.liveStatus]}>
              <IconRecording />
              <Text
                style={[styles.liveStatusText, responsiveStyles.liveStatusText]}
              >{`LIVE + RECORD ${liveSince}`}</Text>
            </View>
          ) : null}
        </View>
      );
    }
    if (liveSince !== undefined || recordSince !== undefined) {
      return (
        <View style={styles.statusContainer}>
          {liveSince ? (
            <View style={[styles.liveStatus, responsiveStyles.liveStatus]}>
              <IconRecording />
              <Text
                style={[styles.liveStatusText, responsiveStyles.liveStatusText]}
              >{`LIVE ${liveSince}`}</Text>
            </View>
          ) : null}
          {recordSince ? (
            <View style={[styles.liveStatus, responsiveStyles.liveStatus]}>
              <IconRecording />
              <Text
                style={[styles.liveStatusText, responsiveStyles.liveStatusText]}
              >{`RECORD ${recordSince}`}</Text>
            </View>
          ) : null}
        </View>
      );
    }
    if (
      activityState.liveStatus === VIDEO_STATE.STOPPED ||
      activityState.recordStatus === VIDEO_STATE.STOPPED
    ) {
      return (
        <View style={styles.statusContainer}>
          <View style={[styles.liveStatus, responsiveStyles.liveStatus]}>
            <IconRecording />
            <Text style={[styles.liveStatusText, responsiveStyles.liveStatusText]}>
              {t(Strings.PUBLICATION_INTERRUPTED)}
            </Text>
          </View>
        </View>
      );
    }
    return null;
  }, [liveSince, recordSince, activityStartsIn, activityEndsAt, activityState]);

  const renderPreviewButton = () => (
    <TouchableOpacity
      style={[styles.previewButton, responsiveStyles.previewButton]}
      onPress={() => onDisplayPreviewButton?.()}
    >
      <IconView stroke={colors.getMainWhite()} />
      {width > 700 ? (
        <Text style={[styles.previewButtonText, responsiveStyles.previewButtonText]}>
          {t(Strings.LIVE_STATUS)}
        </Text>
      ) : null}
    </TouchableOpacity>
  );

  const renderInteractionsButton = () => {
    const interactionsInProcess = filterActivityInteractionsInProcessing(interactions);
    const activeButton = enableInteractions && interactionsInProcess.length > 0;
    let text =
      interactionsInProcess.length > 1
        ? t(Strings.ACTIVITY_INTERACTION_HOST_CONTROLLER_TITLE_MANY_RAISE_HANDS, {
            count: interactionsInProcess.length,
          })
        : t(Strings.ACTIVITY_INTERACTION_HOST_CONTROLLER_TITLE_RAISE_HAND, {
            count: interactionsInProcess.length,
          });
    if (!enableInteractions)
      text = `${text} ${t(Strings.ACTIVITY_INTERACTION_HOST_CONTROLLER_TITLE_DISABLED)}`;
    return (
      <TouchableOpacity
        style={[
          styles.interactionButton,
          responsiveStyles.interactionButton,
          activeButton ? { backgroundColor: colors.getTorchRed() } : {},
        ]}
        onPress={() => onInteractionsButton?.()}
      >
        <RaiseHandSvg enabled={enableInteractions} height={23} />
        <Text style={[styles.interactionButtonText, responsiveStyles.interactionButtonText]}>
          {width > 700 ? text : `${interactionsInProcess.length}`}
        </Text>
      </TouchableOpacity>
    );
  };

  const renderLeftOverlayContainer = () => (
    <View style={styles.leftOverlayContainer}>
      {displayBackButton ? <BackButton style={styles.backButton} {...backButtonOptions} /> : null}
      <PlayerMessage style={styles.vodMessage} />
    </View>
  );

  return (
    <View style={[styles.container, responsiveStyles.container]}>
      <View style={styles.shortcuts}>
        {/* {iconSettings()} */}
        {/* <IconMedia onClick={() => navigate(screenName.MEDIAS)} /> */}
        {/* <IconFaq onClick={() => navigate(screenName.HELP)} /> */}
      </View>
      {renderLeftOverlayContainer()}
      {statusLive()}
      <View style={styles.buttonsContainer}>
        {displayPreviewButton && activityCanLive ? renderPreviewButton() : null}
        {displayInteractionsButton ? renderInteractionsButton() : null}
      </View>
      {iconChat()}
    </View>
  );
};

NavBarTop.propTypes = {
  user: PropTypes.object.isRequired,
  displayChatMenu: PropTypes.bool,
  chatBadge: PropTypes.number,
  liveStartedAt: PropTypes.string, // 'datetime' || undefined;
  recordStartedAt: PropTypes.string,
  activityStartsAt: PropTypes.string,
  activityEndsAt: PropTypes.string,
  activityState: PropTypes.object,
  activityCanLive: PropTypes.bool,
  onDisplayChatMenuChange: PropTypes.func,
  displayPreviewButton: PropTypes.bool,
  onDisplayPreviewButton: PropTypes.func,
  displayInteractionsButton: PropTypes.bool,
  enableInteractions: PropTypes.bool,
  interactions: PropTypes.array,
  onInteractionsButton: PropTypes.func,
  displayBackButton: PropTypes.bool,
  backButtonOptions: PropTypes.shape({
    enableRedirectionIfCantGoBack: PropTypes.bool,
    redirectionIfCantGoBack: PropTypes.string,
    paramsIfCantGoBack: PropTypes.object,
  }),
};

NavBarTop.defaultProps = {
  displayChatMenu: false,
  displayPreviewButton: false,
  chatBadge: 0,
};

const mapStateToProps = createStructuredSelector({
  user: userSelectors.makeSelectUserInfo(),
});

export default connect(mapStateToProps, null)(NavBarTop);
