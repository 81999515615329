import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../constants/colors';

const RaiseHandSvg = ({
  enabled,
  encircling,
  encirclingStroke,
  stroke,
  strokeWidth,
  fill,
  ...svgProps
}) => {
  const renderContent = () => (
    <>
      <path
        fill="none"
        stroke={stroke}
        strokeWidth={strokeWidth * 2.5}
        d="M 25.0117 50.2539 C 16.7149 50.2539 11.6524 44.9336 11.6524 35.4883 L 11.6524 16.0586 C 11.6524 15.0742 12.2851 14.3711 13.2695 14.3711 C 14.2305 14.3711 14.9336 15.0742 14.9336 16.0586 L 14.9336 28.0352 C 14.9336 28.9024 15.6367 29.4883 16.3867 29.4883 C 17.1836 29.4883 17.9102 28.9024 17.9102 28.0352 L 17.9102 10.1289 C 17.9102 9.1211 18.5430 8.4414 19.5039 8.4414 C 20.4883 8.4414 21.1680 9.1211 21.1680 10.1289 L 21.1680 26.8398 C 21.1680 27.7071 21.8711 28.2930 22.6445 28.2930 C 23.4414 28.2930 24.1680 27.7071 24.1680 26.8398 L 24.1680 7.2227 C 24.1680 6.2383 24.8242 5.5117 25.8086 5.5117 C 26.7461 5.5117 27.4258 6.2383 27.4258 7.2227 L 27.4258 26.8398 C 27.4258 27.6602 28.0820 28.2930 28.9024 28.2930 C 29.6992 28.2930 30.4024 27.6602 30.4024 26.8398 L 30.4024 10.1289 C 30.4024 9.1211 31.0820 8.4414 32.0430 8.4414 C 33.0273 8.4414 33.6836 9.1211 33.6836 10.1289 L 33.6836 33.1914 C 33.6836 34.2695 34.3633 35.0430 35.3476 35.0430 C 36.1914 35.0430 36.8945 34.6680 37.4336 33.4961 L 40.6211 26.3711 C 41.0430 25.3633 41.8867 24.8476 42.7539 25.1758 C 43.6914 25.5508 44.0195 26.4414 43.5742 27.6602 L 39.4258 39.2383 C 36.5664 47.2305 31.5508 50.2539 25.0117 50.2539 Z"
      />
      <path
        fill={fill}
        strokeWidth={strokeWidth * 0}
        d="M 25.0117 50.2539 C 16.7149 50.2539 11.6524 44.9336 11.6524 35.4883 L 11.6524 16.0586 C 11.6524 15.0742 12.2851 14.3711 13.2695 14.3711 C 14.2305 14.3711 14.9336 15.0742 14.9336 16.0586 L 14.9336 28.0352 C 14.9336 28.9024 15.6367 29.4883 16.3867 29.4883 C 17.1836 29.4883 17.9102 28.9024 17.9102 28.0352 L 17.9102 10.1289 C 17.9102 9.1211 18.5430 8.4414 19.5039 8.4414 C 20.4883 8.4414 21.1680 9.1211 21.1680 10.1289 L 21.1680 26.8398 C 21.1680 27.7071 21.8711 28.2930 22.6445 28.2930 C 23.4414 28.2930 24.1680 27.7071 24.1680 26.8398 L 24.1680 7.2227 C 24.1680 6.2383 24.8242 5.5117 25.8086 5.5117 C 26.7461 5.5117 27.4258 6.2383 27.4258 7.2227 L 27.4258 26.8398 C 27.4258 27.6602 28.0820 28.2930 28.9024 28.2930 C 29.6992 28.2930 30.4024 27.6602 30.4024 26.8398 L 30.4024 10.1289 C 30.4024 9.1211 31.0820 8.4414 32.0430 8.4414 C 33.0273 8.4414 33.6836 9.1211 33.6836 10.1289 L 33.6836 33.1914 C 33.6836 34.2695 34.3633 35.0430 35.3476 35.0430 C 36.1914 35.0430 36.8945 34.6680 37.4336 33.4961 L 40.6211 26.3711 C 41.0430 25.3633 41.8867 24.8476 42.7539 25.1758 C 43.6914 25.5508 44.0195 26.4414 43.5742 27.6602 L 39.4258 39.2383 C 36.5664 47.2305 31.5508 50.2539 25.0117 50.2539 Z"
      />
    </>
  );
  if (encircling) {
    return (
      <svg {...svgProps} viewBox="0 0 80 80" xmlns="http://www.w3.org/2000/svg">
        <g transform="translate(14, 12)">{renderContent()}</g>
        <circle
          cx="40"
          cy="40"
          r="36"
          fill="none"
          stroke={encirclingStroke}
          strokeWidth={strokeWidth * 1.5}
        />
        {!enabled ? (
          <path
            d="M 35 7 L 3 53"
            stroke={stroke}
            strokeWidth={strokeWidth * 3.5}
            strokeLinecap="round"
          />
        ) : null}
      </svg>
    );
  }
  return (
    <svg {...svgProps} viewBox="0 0 40 56" xmlns="http://www.w3.org/2000/svg">
      <g transform="translate(-8, 0)">{renderContent()}</g>
      {!enabled ? (
        <path
          d="M 35 7 L 3 53"
          stroke={stroke}
          strokeWidth={strokeWidth * 3.5}
          strokeLinecap="round"
        />
      ) : null}
    </svg>
  );
};

RaiseHandSvg.propTypes = {
  enabled: PropTypes.bool,
  encircling: PropTypes.bool,
  encirclingStroke: PropTypes.string,
  strokeWidth: PropTypes.number,
  stroke: PropTypes.string,
  fill: PropTypes.string,
};

RaiseHandSvg.defaultProps = {
  enabled: true,
  encircling: false,
  encirclingStroke: colors.getMainPurple(),
  strokeWidth: 1,
  stroke: colors.getMainWhite(),
  fill: 'transparent',
};

export default RaiseHandSvg;
