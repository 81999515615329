import { toast as reactToastify } from 'react-toastify';

export const ToastMode = {
  NORMAL: 'NORMAL',
  ACTIVITY_PLAYER: 'ACTIVITY_PLAYER',
};

const TOAST_DEFAULT_OPTIONS = {
  className: 'toast',
};

const TOAST_ACTIVITY_PLAYER_OPTIONS = {
  className: 'toast toast-activity-player',
};

const TOAST_OPTIONS = {
  [ToastMode.NORMAL]: {
    default: TOAST_DEFAULT_OPTIONS,
    info: TOAST_DEFAULT_OPTIONS,
    success: TOAST_DEFAULT_OPTIONS,
    warning: TOAST_DEFAULT_OPTIONS,
    error: TOAST_DEFAULT_OPTIONS,
  },
  [ToastMode.ACTIVITY_PLAYER]: {
    default: TOAST_ACTIVITY_PLAYER_OPTIONS,
    info: TOAST_ACTIVITY_PLAYER_OPTIONS,
    success: TOAST_ACTIVITY_PLAYER_OPTIONS,
    warning: TOAST_ACTIVITY_PLAYER_OPTIONS,
    error: TOAST_ACTIVITY_PLAYER_OPTIONS,
  },
};

export const toast = (content, mode = ToastMode.NORMAL) =>
  reactToastify(content, TOAST_OPTIONS[mode]?.default ?? TOAST_DEFAULT_OPTIONS);
export const toastInfo = (content, mode = ToastMode.NORMAL) =>
  reactToastify.info(content, TOAST_OPTIONS[mode]?.info ?? TOAST_DEFAULT_OPTIONS);
export const toastSuccess = (content, mode = ToastMode.NORMAL) =>
  reactToastify.success(content, TOAST_OPTIONS[mode]?.success ?? TOAST_DEFAULT_OPTIONS);
export const toastWarning = (content, mode = ToastMode.NORMAL) =>
  reactToastify.warning(content, TOAST_OPTIONS[mode]?.warning ?? TOAST_DEFAULT_OPTIONS);
export const toastError = (content, mode = ToastMode.NORMAL) =>
  reactToastify.error(content, TOAST_OPTIONS[mode]?.error ?? TOAST_DEFAULT_OPTIONS);
