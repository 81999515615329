import { useState } from 'react';

export const useRender = () => {
  const [value, setValue] = useState(false);
  return () => {
    setValue(!value);
  };
};

export default {
  useRender,
};
