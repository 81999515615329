import React, { useImperativeHandle, useState } from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';
import LoadingSvg from '../Svg/loading';
import colors from '../../constants/colors';

const LoadingContainer = React.forwardRef(({ children, loadingWidth, style }, forwardRef) => {
  const [loading, setLoading] = useState();

  const forwardedRef = {
    loading: (v) => setLoading(v),
  };
  useImperativeHandle(forwardRef, () => forwardedRef);

  const childrenComponent =
    typeof children === 'function' ? children(loading, setLoading) : children;
  return (
    <View style={style}>
      <View style={{ position: 'relative' }}>
        <div style={{ visibility: loading ? 'hidden' : 'visible' }}>{childrenComponent}</div>
        {loading ? (
          <View
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <LoadingSvg width={loadingWidth} fill={colors.getMainWhite()} />
          </View>
        ) : null}
      </View>
    </View>
  );
});

LoadingContainer.displayName = 'LoadingContainer';

LoadingContainer.propTypes = {
  children: PropTypes.any.isRequired,
  loadingWidth: PropTypes.number,
  style: PropTypes.any,
};

LoadingContainer.defaultProps = {
  loadingWidth: 20,
  style: undefined,
};

export default LoadingContainer;
