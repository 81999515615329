import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { START_TIME_STEP, STATE } from '../constants/activity';
import { getActivityStateFromLeftTimes, getLeftTime } from '../helpers/activity';
import { useMinuteStepClock } from './utils';

// TODO : improve the logic, 'time' value in useActivityState is not even used
// the only purpose is to awake the useEffect change. This can be done in a better way.
export const useActivityState = (activity) => {
  const [startTimeLeft, setStartTimeLeft] = useState(undefined);
  const [endTimeLeft, setEndTimeLeft] = useState(undefined);
  const [state, setState] = useState(STATE.UNDEFINED);

  // awaken every minute
  const time = useMinuteStepClock(1);

  useEffect(() => {
    if (!isEmpty(activity)) {
      setStartTimeLeft(getLeftTime(activity.startDatetime));
      setEndTimeLeft(getLeftTime(activity.endDatetime));
    }
  }, [activity]);

  useEffect(() => {
    if (!isEmpty(activity)) {
      if (startTimeLeft > 0) setStartTimeLeft(getLeftTime(activity.startDatetime));
      if (endTimeLeft > 0) setEndTimeLeft(getLeftTime(activity.endDatetime));
    }
  }, [time]);

  useEffect(() => {
    setState(getActivityStateFromLeftTimes(startTimeLeft, endTimeLeft));
  }, [startTimeLeft, endTimeLeft]);

  return { state, startTimeLeft, endTimeLeft };
};

export const useActivityStartTime = () => {
  return useMinuteStepClock(START_TIME_STEP, 5000);
};

export default {
  useActivityState,
  useActivityStartTime,
};
