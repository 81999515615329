import { NavigationContainer } from '@react-navigation/native';
import * as Linking from 'expo-linking';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, Text, View } from 'react-native';
import { TouchableOpacity } from 'react-native-web';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import appActions from '../../App/actions/app';
import organizationActions from '../../App/actions/organization';
import userActions from '../../App/actions/user';
import organizationSelectors from '../../App/selectors/organization';
import userSelectors from '../../App/selectors/user';
import ErrorNotifier from '../../components/Generic/errorNotifier';
import colors from '../../constants/colors';
import fonts from '../../constants/fonts';
import { screen, screenTitle } from '../../constants/navigation';
import { isReadyRef, navigationRef } from '../../helpers/navigation';
import { Strings } from '../../helpers/strings';
import AuthAwareStack from '../AuthAwareStack';
import WithSocket from '../WithSocket';

const documentTitle = 'Kapture';
const prefixes = [Linking.createURL('/')];

const styles = StyleSheet.create({
  statusBarContainer: {
    width: '100%',
    height: 20,
    backgroundColor: colors.getMainPurple(),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
    boxShadow: '0px 0px 5px black',
  },
  statusBarText: {
    fontFamily: fonts.getSignika(),
    fontSize: '0.8rem',
    fontWeight: 'bold',
    color: colors.getMainWhite(),
  },
  statusBarLine: {
    backgroundColor: colors.getMainBlack(),
    height: 1,
    opacity: 0.2,
    flex: 1,
    marginHorizontal: 20,
  },
});

const AuthAwareNavigation = ({
  isAuthenticated,
  user,
  organization,
  requestUser,
  requestOrganization,
  setSocketStatus,
}) => {
  const { t } = useTranslation();
  const [displayStatusBar, setDisplayStatusBar] = useState(true);
  useEffect(() => {
    return () => {
      isReadyRef.current = false;
    };
  }, []);

  useEffect(() => {
    if (isAuthenticated && user._id === undefined) requestUser();
  }, [isAuthenticated]);

  useEffect(() => {
    // if there is a hostname (eg: univ-amu (from univ-amu.kast.app)) we request the info of the organization
    // otherwise it means it's an admin process (extra step to select the organization)
    if (user?._organization !== undefined) requestOrganization();
  }, [user]);

  const onReady = () => {
    isReadyRef.current = true;
  };

  const config = { screens: screen };
  const linking = { prefixes, config };

  const renderStatusBar = () => {
    if (!displayStatusBar) return null;
    const userStatus = user?.status;
    const organizationStatus = organization?.status;
    if (
      userStatus === 'trial_period_in_progress' ||
      organizationStatus === 'trial_period_in_progress'
    ) {
      return (
        <TouchableOpacity
          style={styles.statusBarContainer}
          onPress={() => setDisplayStatusBar(false)}
        >
          <View style={styles.statusBarLine} />
          <Text style={styles.statusBarText} numberOfLines={1} ellipsizeMode="tail">
            {t(Strings.APP_STATUS_BAR_TRIAL_PERIOD_IN_PROGRESS)}
          </Text>
          <View style={styles.statusBarLine} />
        </TouchableOpacity>
      );
    }

    return null;
  };

  return (
    <NavigationContainer
      ref={navigationRef}
      onReady={onReady}
      linking={linking}
      documentTitle={{
        formatter: (options, route) => {
          const name = route?.name;
          const currTitle = name ? screenTitle[name] : undefined;
          return currTitle ? `${documentTitle} - ${currTitle}` : documentTitle;
        },
      }}
    >
      {renderStatusBar()}
      <WithSocket
        onSocketStatus={(status) => {
          setSocketStatus(status);
        }}
      />
      <AuthAwareStack />
      <ErrorNotifier />
    </NavigationContainer>
  );
};

AuthAwareNavigation.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  organization: PropTypes.object.isRequired,

  // Actions
  requestUser: PropTypes.func.isRequired,
  requestOrganization: PropTypes.func.isRequired,
  setSocketStatus: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  isAuthenticated: userSelectors.makeSelectIsAuthenticated(),
  user: userSelectors.makeSelectUserInfo(),
  organization: organizationSelectors.makeSelectOrganization(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    requestUser: () => dispatch(userActions.requestUser()),
    requestOrganization: () => dispatch(organizationActions.requestOrganization()),
    setSocketStatus: (status) => dispatch(appActions.setSocketStatus(status)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthAwareNavigation);
