import { fromJS } from 'immutable';
import { createReducer } from 'reduxsauce';

import { Types } from '../actions/organization';

/**
 * {
 *  authMethod: {},
 *  rooms: [],
 *  _id: '',
 *  name: '',
 * }
 */
const initialState = fromJS({
  name: undefined,
  authMethods: undefined,
  rooms: undefined,
  devices: undefined,
  settings: undefined,
});

export const setOrganization = (state = initialState, { organization }) =>
  state.merge(fromJS(organization));

export const setAuthInfo = (state = initialState, { authInfo }) => state.merge(fromJS(authInfo));

export const setRooms = (state = initialState, { rooms }) => state.set('rooms', fromJS(rooms));

export const setDevices = (state = initialState, { devices }) =>
  state.set('devices', fromJS(devices));

export const setOnlineDevices = (state = initialState, { ids }) => {
  return state.updateIn(['devices'], (devices) =>
    devices.map((device) => {
      if (ids.includes(device.get('_id'))) return device.set('online', true);
      return device;
    }),
  );
};

export default createReducer(initialState, {
  [Types.SET_ORGANIZATION]: setOrganization,
  [Types.SET_AUTH_INFO]: setAuthInfo,
  [Types.SET_ROOMS]: setRooms,
  [Types.SET_DEVICES]: setDevices,
  [Types.SET_ONLINE_DEVICES]: setOnlineDevices,
});
