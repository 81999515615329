import { call, put, takeLatest } from 'redux-saga/effects';

import api from '../api/_index';
import errorActions from '../actions/error';
import appActions from '../actions/app';
import roomActions, { Types as roomTypes } from '../actions/room';
import deviceActions from '../actions/device';
import { submitRequest } from './user';

const { REQUEST_ROOM, REQUEST_ROOM_DEVICES } = roomTypes;

function* requestRoom({ _id }) {
  try {
    yield put(appActions.addCurrentlySending(`${REQUEST_ROOM}`));

    const room = yield call(submitRequest, api.organization.requestRoom, _id);

    yield put(roomActions.setRoom(room));
  } catch (error) {
    yield put(errorActions.handleError(error));
  } finally {
    yield put(appActions.removeCurrentlySending(`${REQUEST_ROOM}`));
  }
}

function* requestRoomDevices({ _room }) {
  try {
    yield put(appActions.addCurrentlySending(`${REQUEST_ROOM_DEVICES}`));

    const devices = yield call(submitRequest, api.device.requestDevices, { _room });

    yield put(roomActions.setRoomDevices(devices));
    if (devices.length > 0) {
      yield put(deviceActions.setDevice(devices[0]));
    }
  } catch (error) {
    yield put(errorActions.handleError(error));
  } finally {
    yield put(appActions.removeCurrentlySending(`${REQUEST_ROOM_DEVICES}`));
  }
}

function* watcherRequestRoom() {
  yield takeLatest(REQUEST_ROOM, requestRoom);
}

function* watcherRequestRoomDevices() {
  yield takeLatest(REQUEST_ROOM_DEVICES, requestRoomDevices);
}

export default [watcherRequestRoom(), watcherRequestRoomDevices()];
