import { ApiErrorCode } from '@kalyzee/kast-websocket-module';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import activityActions, { Types as activityTypes } from '../../App/actions/activity';
import appSelectors from '../../App/selectors/app';
import userSelectors from '../../App/selectors/user';
import ActivityInteractionItem, {
  ActivityInteractionResponsiveMode,
} from '../../components/Activity/interactionItem';
import ActivityInteractions from '../../components/Activity/interactions';
import ActivityRequestInteraction from '../../components/Activity/requestInteraction';
import colors from '../../constants/colors';
import fonts from '../../constants/fonts';
import { USER_ROLE } from '../../constants/global';
import { ActivityInteractionType } from '../../helpers/interactions';
import { getUniqueLocalId } from '../../helpers/localId';
import { userRoleIsGTE } from '../../helpers/role';
import { useReduxAction } from '../../helpers/store';
import { Strings } from '../../helpers/strings';
import { toastError, toastInfo, ToastMode } from '../../helpers/toast';
import { useOuterClick } from '../../hooks/click';

const styles = StyleSheet.create({
  interactionContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    maxHeight: '100%',
    maxWidth: '100%',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
  interactionToastContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    gap: 5,
  },
  interactionToastDescription: {
    fontFamily: fonts.getSignika(),
    fontSize: 16,
    color: colors.getMainWhite(),
  },
  interactionToastMessage: {
    fontFamily: fonts.getSignika(),
    fontSize: 14,
    color: colors.getGrayLevel(0.6),
  },
});

const ActivityInteractionsContainer = ({
  enableInteractions,
  displayInteractions,
  displayRequestInteraction,
  onEnableInteractions,
  onDisplayInteractions,
  onDisplayRequestInteraction,
  onCurrentInteractionStatus,
  interactions,
  device,
  user,
  activity,
  socketEnableInteractions,
  socketRequestInteraction,
  socketAcceptRequestInteraction,
  socketDeclineRequestInteraction,
  socketJoinInteraction,
  socketStopInteraction,
  socketCancelInteraction,
}) => {
  const { t } = useTranslation();
  const activityRequestInteractionRef = useRef(null);
  const interactionsEnableOuterClickRef = useRef(false); // allow to await the view is displayed
  const interactionContainerRef = useOuterClick(() => {
    if (interactionsEnableOuterClickRef.current) {
      if (displayInteractions) onDisplayInteractions(false);
      if (displayRequestInteraction) onDisplayRequestInteraction(false);
    }
  });
  const [interactionsOfUser, setInteractionsOfUser] = useState();
  const hasControlRights = () => userRoleIsGTE(user.role, USER_ROLE.ORGANIZER);

  // ---------------------------------------------------------------------- //
  // ----------------------------- USE EFFECT ------------------------------ //
  // ---------------------------------------------------------------------- //
  useEffect(() => {
    if (!interactions) return;
    setInteractionsOfUser(interactions.filter((curr) => curr.user?.id === user?.id));
  }, [interactions]);

  useEffect(() => {
    let timeout;
    if (displayInteractions || displayRequestInteraction) {
      interactionsEnableOuterClickRef.current = false;
      timeout = setTimeout(() => {
        interactionsEnableOuterClickRef.current = true;
      }, 500);
    } else {
      interactionsEnableOuterClickRef.current = false;
    }
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [displayInteractions, displayRequestInteraction]);

  useEffect(() => {
    onEnableInteractions(activity.interactionsAreEnabled);
  }, [activity]);

  useEffect(() => {
    if (displayInteractions) {
      onDisplayRequestInteraction(false);
    }
  }, [displayInteractions]);

  useReduxAction((action) => {
    if (action.activityId !== activity.id) return;
    const { interaction } = action;
    toastInfo(
      <TouchableOpacity
        onPress={() => {
          if (hasControlRights()) {
            onDisplayInteractions(true);
            return;
          }
          if (interaction.user?.id === user?.id) {
            onDisplayRequestInteraction(true);
          }
        }}
      >
        <ActivityInteractionItem
          interaction={interaction}
          responsiveMode={ActivityInteractionResponsiveMode.SMALL}
          displayHeaderView={false}
          displayMainView={true}
          displayButtonsView={false}
          style={{
            backgroundColor: undefined,
            shadowRadius: undefined,
            shadowColor: undefined,
          }}
          me={user}
        />
      </TouchableOpacity>,
      ToastMode.ACTIVITY_PLAYER,
    );
  }, activityTypes.ON_INTERACTION_EVENT);

  useReduxAction(async (action) => {
    if (!activityRequestInteractionRef.current) return;
    const interaction = action?.interaction;
    if (!interaction) return;
    if (interaction.platformId !== getUniqueLocalId()) return; // interaction is not initiated by this device
    const mediaConstraints = action.data?.mediaConstraints;
    const webrtcToken = action.data?.webrtcToken;
    const success = await activityRequestInteractionRef.current.publishMedia(
      interaction.id,
      webrtcToken,
      mediaConstraints,
    );
    if (!success) {
      toastError(
        t(Strings.ACTIVITY_INTERACTION_TOAST_PUBLISH_MEDIA_ERROR),
        ToastMode.ACTIVITY_PLAYER,
      );
    }
  }, activityTypes.ON_MEDIA_REQUEST_INTERACTION);

  // ---------------------------------------------------------------------- //
  // ----------------------------- RENDERING ------------------------------ //
  // ---------------------------------------------------------------------- //

  const renderInteractionsView = () => (
    <ActivityInteractions
      enabled={enableInteractions}
      interactions={interactions}
      me={user}
      onRaiseHand={() => {
        onDisplayInteractions(false);
        onDisplayRequestInteraction(true);
      }}
      onClose={() => onDisplayInteractions(false)}
      onEnable={async (enable) => {
        const result = await socketEnableInteractions(activity.id, enable);
        if (result.error)
          toastError(
            t(Strings.ACTIVITY_INTERACTION_TOAST_DEFAULT_MESSAGE_ERROR),
            ToastMode.ACTIVITY_PLAYER,
          );
        else onEnableInteractions(enable);
      }}
      onRequest={async (interactionId, accepted) => {
        if (accepted) {
          const result = await socketAcceptRequestInteraction(activity.id, interactionId);
          if (result.error)
            toastError(
              t(Strings.ACTIVITY_INTERACTION_TOAST_DEFAULT_MESSAGE_ERROR),
              ToastMode.ACTIVITY_PLAYER,
            );
        } else {
          const result = await socketDeclineRequestInteraction(activity.id, interactionId);
          if (result.error)
            toastError(
              t(Strings.ACTIVITY_INTERACTION_TOAST_DEFAULT_MESSAGE_ERROR),
              ToastMode.ACTIVITY_PLAYER,
            );
        }
      }}
      onJoin={async (interactionId, joined) => {
        if (joined) {
          if (!device) {
            toastError(
              t(Strings.ACTIVITY_INTERACTION_TOAST_DEVICE_UNKNOWN_ERROR),
              ToastMode.ACTIVITY_PLAYER,
            );
            return;
          }
          if (!device.online) {
            toastError(
              t(Strings.ACTIVITY_INTERACTION_TOAST_DEVICE_DISCONNECTED_ERROR),
              ToastMode.ACTIVITY_PLAYER,
            );
            return;
          }
          const deviceId = device.id;
          const result = await socketJoinInteraction(activity.id, interactionId, deviceId);
          if (result.error) {
            if (
              result.error.code ===
              -ApiErrorCode.ACTIVITY_SIMULTANEOUS_ACTIVE_INTERACTION_LIMIT_IS_EXCEEDED
            ) {
              toastError(
                t(Strings.ACTIVITY_INTERACTION_TOAST_LIMIT_SIMULTANEOUS_INTERACTION_EXCEEDED_ERROR),
                ToastMode.ACTIVITY_PLAYER,
              );
            } else {
              toastError(
                t(Strings.ACTIVITY_INTERACTION_TOAST_DEFAULT_MESSAGE_ERROR),
                ToastMode.ACTIVITY_PLAYER,
              );
            }
          }
        } else {
          const result = await socketCancelInteraction(activity.id, interactionId);
          if (result.error)
            toastError(
              t(Strings.ACTIVITY_INTERACTION_TOAST_DEFAULT_MESSAGE_ERROR),
              ToastMode.ACTIVITY_PLAYER,
            );
        }
      }}
      onStop={async (interactionId) => {
        const result = await socketStopInteraction(activity.id, interactionId);
        if (result.error)
          toastError(
            t(Strings.ACTIVITY_INTERACTION_TOAST_DEFAULT_MESSAGE_ERROR),
            ToastMode.ACTIVITY_PLAYER,
          );
      }}
      onCancel={async (interactionId) => {
        const result = await socketCancelInteraction(activity.id, interactionId);
        if (result.error)
          toastError(
            t(Strings.ACTIVITY_INTERACTION_TOAST_DEFAULT_MESSAGE_ERROR),
            ToastMode.ACTIVITY_PLAYER,
          );
      }}
    />
  );

  const renderRequestInteractionView = () => (
    <ActivityRequestInteraction
      ref={activityRequestInteractionRef}
      interactions={interactionsOfUser}
      activity={activity}
      me={user}
      hide={!displayRequestInteraction}
      onClose={() => onDisplayRequestInteraction(false)}
      onRequest={async (message) => {
        const result = await socketRequestInteraction(
          activity.id,
          ActivityInteractionType.MEDIA_DEVICE,
          message,
        );
        if (result.error)
          toastError(
            t(Strings.ACTIVITY_INTERACTION_TOAST_DEFAULT_MESSAGE_ERROR),
            ToastMode.ACTIVITY_PLAYER,
          );
        else {
          const creatorIsMe = activity?._user === user?.id;
          if (creatorIsMe) {
            onDisplayRequestInteraction(false);
            onDisplayInteractions(true);
          }
        }
      }}
      onStop={async (interactionId) => {
        const result = await socketStopInteraction(activity.id, interactionId);
        if (result.error)
          toastError(
            t(Strings.ACTIVITY_INTERACTION_TOAST_DEFAULT_MESSAGE_ERROR),
            ToastMode.ACTIVITY_PLAYER,
          );
        else onDisplayRequestInteraction(false);
      }}
      onCancel={async (interactionId) => {
        const result = await socketCancelInteraction(activity.id, interactionId);
        if (result.error)
          toastError(
            t(Strings.ACTIVITY_INTERACTION_TOAST_DEFAULT_MESSAGE_ERROR),
            ToastMode.ACTIVITY_PLAYER,
          );
        else onDisplayRequestInteraction(false);
      }}
      onStatus={(interactionId, status) => onCurrentInteractionStatus(interactionId, status)}
      onMediaError={(error) => {
        toastError(
          t(Strings.ACTIVITY_INTERACTION_TOAST_MEDIA_ERROR, { error: error?.message }),
          ToastMode.ACTIVITY_PLAYER,
        );
      }}
      onMediaCaptureError={(type, error) => {
        toastError(
          t(Strings.ACTIVITY_INTERACTION_TOAST_CAPTURE_MEDIA_ERROR, { error: error?.message }),
          ToastMode.ACTIVITY_PLAYER,
        );
      }}
      onVideoError={() => {
        toastError(t(Strings.ACTIVITY_INTERACTION_TOAST_VIDEO_ERROR), ToastMode.ACTIVITY_PLAYER);
      }}
    />
  );

  return (
    <View style={[styles.interactionContainer]} ref={interactionContainerRef}>
      {displayInteractions ? renderInteractionsView() : null}
      {renderRequestInteractionView()}
    </View>
  );
};

ActivityInteractionsContainer.propTypes = {
  enableInteractions: PropTypes.bool.isRequired,
  displayInteractions: PropTypes.bool.isRequired,
  displayRequestInteraction: PropTypes.bool.isRequired,
  onEnableInteractions: PropTypes.func.isRequired,
  onDisplayInteractions: PropTypes.func.isRequired,
  onDisplayRequestInteraction: PropTypes.func.isRequired,
  onCurrentInteractionStatus: PropTypes.func.isRequired,
  interactions: PropTypes.array.isRequired,
  device: PropTypes.object.isRequired,
  activity: PropTypes.object.isRequired,

  // CONNECT
  user: PropTypes.object.isRequired,
  socketStatus: PropTypes.string.isRequired,
  socketEnableInteractions: PropTypes.func.isRequired,
  socketRequestInteraction: PropTypes.func.isRequired,
  socketAcceptRequestInteraction: PropTypes.func.isRequired,
  socketDeclineRequestInteraction: PropTypes.func.isRequired,
  socketJoinInteraction: PropTypes.func.isRequired,
  socketStopInteraction: PropTypes.func.isRequired,
  socketCancelInteraction: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  user: userSelectors.makeSelectUserInfo(),
  socketStatus: appSelectors.makeSelectSocketStatus(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    socketEnableInteractions: (activityId, enable) =>
      dispatch(activityActions.socketEnableInteractions(activityId, enable)),
    socketRequestInteraction: (activityId, type, message) =>
      dispatch(activityActions.socketRequestInteraction(activityId, type, message)),
    socketAcceptRequestInteraction: (activityId, interactionId) =>
      dispatch(activityActions.socketAcceptRequestInteraction(activityId, interactionId)),
    socketDeclineRequestInteraction: (activityId, interactionId) =>
      dispatch(activityActions.socketDeclineRequestInteraction(activityId, interactionId)),
    socketJoinInteraction: (activityId, interactionId, deviceId) =>
      dispatch(activityActions.socketJoinInteraction(activityId, interactionId, deviceId)),
    socketStopInteraction: (activityId, interactionId) =>
      dispatch(activityActions.socketStopInteraction(activityId, interactionId)),
    socketCancelInteraction: (activityId, interactionId) =>
      dispatch(activityActions.socketCancelInteraction(activityId, interactionId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivityInteractionsContainer);
