import { KalyzeeEmitterWebRTCSession } from '@kalyzee/kast-webrtc-client-module';
import PropTypes from 'prop-types';
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, Text, TouchableOpacity, useWindowDimensions, View } from 'react-native';
import IconClose from '../../assets/navigation/close.svg';
import colors from '../../constants/colors';
import fonts from '../../constants/fonts';
import {
  ActivityInteractionEventType,
  ActivityInteractionStatus,
} from '../../helpers/interactions';
import { getUniqueLocalId } from '../../helpers/localId';
import { getAudioTrack, getVideoTrack } from '../../helpers/media';
import { getWebRTCSocketUrl } from '../../helpers/request';
import { Strings } from '../../helpers/strings';
import TextInputLimited from '../Generic/textInputLimited';
import VideoCapture from '../Video/videoCapture';
import { ActivityInteractionResponsiveMode } from './interactionItem';

const LIMIT_MESSAGE_LENGTH = 80;
const LAST_INTERACTION_REFUSED_DISPLAY_MESSAGE_UNTIL = 10 * 60 * 1000; // 10min
const LAST_INTERACTION_TERMINATED_DISPLAY_MESSAGE_UNTIL = 10 * 60 * 1000; // 10min
const LAST_INTERACTION_CANCELED_DISPLAY_MESSAGE_UNTIL = 10 * 60 * 1000; // 10min
const TIMEOUT_CREATE_WEBRTC_SESSION = 10 * 1000; // 10 sec

const styles = StyleSheet.create({
  support: {
    display: 'flex',
    height: 500,
    maxHeight: '90%',
    minHeight: 300,
    minWidth: 300,
    width: 800,
    maxWidth: '90%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
    overflow: 'hidden',
    shadowRadius: 20,
    shadowColor: colors.getMainWhite(0.03),
    backdropFilter: 'blur(5px)',
  },
  supportHeader: {
    height: 50,
    minHeight: 50,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.getMirage(0.98),
  },
  textHeader: {
    fontFamily: fonts.getSignika(),
    fontSize: 16,
    color: colors.getMainWhite(),
    fontWeight: 'bold',
  },
  closeButton: {
    position: 'absolute',
    right: 15,
    top: '50%',
    transform: 'translate(0, -50%)',
  },
  supportFooter: {
    height: 50,
    minHeight: 50,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.getMirage(0.98),
  },
  buttonFooterContainer: {
    flex: 1,
    height: '100%',
  },
  buttonFooter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    textAlign: 'center',
    fontFamily: fonts.getSignika(),
    fontSize: 16,
    color: colors.getBlueCornflower(),
    fontWeight: 'bold',
  },
  validateButton: {
    color: colors.getMainGreen(),
  },
  unvalidateButton: {
    color: colors.getTorchRed(),
  },
  supportContent: {
    flex: 1,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.getLightMirage(0.95),
    padding: 10,
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '100%',
    width: '100%',
    gap: 5,
    flex: 1,
  },
  videoCapture: {
    width: '75%',
    flex: 1,
    shadowRadius: 5,
    shadowColor: colors.getMainBlack(0.3),
  },
  detailsContainer: {
    marginTop: 20,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 10,
    textAlign: 'center',
  },
  descriptionText: {
    fontFamily: fonts.getSignika(),
    fontSize: 18,
    color: colors.getMainWhite(),
  },
  lastStateText: {
    fontFamily: fonts.getSignika(),
    fontSize: 14,
    color: colors.getMainWhite(0.9),
    borderRadius: 10,
    overflow: 'hidden',
    shadowRadius: 5,
    shadowColor: colors.getMainBlack(0.3),
    backgroundColor: colors.getBlueBayoux(0.4),
    textAlign: 'center',
    minWidth: '50%',
    maxWidth: '90%',
    paddingHorizontal: 10,
    paddingVertical: 5,
    marginBottom: 10,
  },
  inputMessageContainer: {
    width: '80%',
  },
  inputMessageIndicator: {
    color: colors.getMainWhite(0.8),
  },
  inputMessageText: {
    color: colors.getMainWhite(),
    borderRadius: 10,
    overflow: 'hidden',
    shadowRadius: 5,
    shadowColor: colors.getMainBlack(0.3),
    backgroundColor: colors.getMirage(0.8),
    height: 40,
  },
});

const stylesSmallMode = StyleSheet.create({});
const stylesXSmallMode = StyleSheet.create({});

const getResponsiveStyles = (width, height) => {
  let result;
  let mode = ActivityInteractionResponsiveMode.NORMAL;
  if (width < 400) {
    mode = ActivityInteractionResponsiveMode.XSMALL;
    result = stylesXSmallMode;
  } else if (width < 600) {
    mode = ActivityInteractionResponsiveMode.SMALL;
    result = stylesSmallMode;
  }
  return [
    mode,
    result,
    (name) => {
      return [styles[name], result?.[name]];
    },
  ];
};

const ActivityRequestInteraction = React.forwardRef(
  (
    {
      activity,
      me,
      interactions,
      onClose,
      onRequest,
      onStop,
      onCancel,
      onVideoError,
      onMediaError,
      onMediaCaptureError,
      onStatus,
      onIsRunning,
      hide,
      style,
    },
    forwardRef,
  ) => {
    const { t } = useTranslation();
    const { width, height } = useWindowDimensions();
    const [responsiveMode, , getStyles] = getResponsiveStyles(width, height);
    const [mediaIsReady, setMediaIsReady] = useState(false);
    const [mediaCaptureError, setMediaCaptureError] = useState(false);
    /** @type { React.MutableRefObject<KalyzeeEmitterWebRTCSession> } */
    const webrtcSessionRef = useRef(null);
    const mediaRef = useRef(null);
    const videoCaptureRef = useRef(null);
    const interactionRef = useRef(null);
    const messageRef = useRef(undefined);
    const interaction = interactions?.length ? interactions[0] : undefined;
    const status = interaction?.status;
    const events = interaction?.events;

    interactionRef.current = interaction;

    const cancel = () => {
      webrtcSessionRef.current = undefined;
      onCancel(interaction?.id);
    };

    const stop = () => {
      webrtcSessionRef.current = undefined;
      onStop(interaction?.id);
    };

    // ---------------------------------------------------------------------- //
    // ---------------------------   UTILS ----------------------------- //
    // ---------------------------------------------------------------------- //

    const createWebrtcSession = (media, url, interactionId) => {
      const videoTrack = getVideoTrack(media);
      const audioTrack = getAudioTrack(media);
      if (!audioTrack || !videoTrack) return false;
      const session = new KalyzeeEmitterWebRTCSession();
      let timeout = setTimeout(() => {
        if (!webrtcSessionRef.current) return;
        webrtcSessionRef.current = undefined;
        const reason = 'timeout on create';
        session.destroy(reason);
        onMediaError(reason);
      }, TIMEOUT_CREATE_WEBRTC_SESSION);
      session.start(url, media);
      // console.log('Start session : ', session);

      // session.setLogger(console.log);
      session.addEventListener('create', (sessionId) => {
        // console.log(`Session created : ${sessionId}`);
        if (timeout) {
          clearTimeout(timeout);
          timeout = undefined;
        }
      });
      session.addEventListener('initialized', () => {
        // console.log(`Session initialized `);
      });
      session.addEventListener('metadata', (metadata) => {
        // console.log(`Session metadata : `, metadata);
      });
      session.addEventListener('close', (reason) => {
        /* console.log(
          `Session closed : ${reason} `,
          webrtcSessionRef.current,
          interactionRef.current,
        ); */
        if (interactionId === interactionRef.current?.id) {
          if (interactionRef.current?.status === ActivityInteractionStatus.RUNNING) {
            if (webrtcSessionRef.current === session) {
              onMediaError(reason);
            }
          }
        }
        if (webrtcSessionRef.current === session) webrtcSessionRef.current = undefined;
      });
      session.addEventListener('error', (error) => {
        // console.log(`Session error : ${error} `, webrtcSessionRef.current, interactionRef.current);
        if (interactionId === interactionRef.current?.id) {
          if (interactionRef.current?.status === ActivityInteractionStatus.RUNNING) {
            if (webrtcSessionRef.current === session) {
              onMediaError(error);
              webrtcSessionRef.current = undefined;
            }
          }
        }
        if (webrtcSessionRef.current === session) webrtcSessionRef.current = undefined;
      });
      webrtcSessionRef.current = session;
      return true;
    };

    const updateMediaWebrtcSession = (media) => {
      if (!media) return;
      if (!webrtcSessionRef.current) return;
      const peerConnection = webrtcSessionRef.current.rtcSession?.getRTCPeerConnection();
      if (!peerConnection) return;
      const videoTrack = getVideoTrack(media);
      const audioTrack = getAudioTrack(media);
      const senders = peerConnection.getSenders();
      senders.forEach(async (sender) => {
        if (sender.track.kind === 'video' && sender.track.id !== videoTrack.id) {
          await sender.replaceTrack(videoTrack);
        } else if (sender.track.kind === 'audio' && sender.track.id !== audioTrack.id) {
          await sender.replaceTrack(audioTrack);
        }
      });
    };

    const forwardedRef = {
      publishMedia: async (interactionId, token, mediaConstraints) => {
        if (interactionId !== interaction?.id) return false;
        if (!videoCaptureRef.current) return false;
        await videoCaptureRef.current.updateMediaConstraints(mediaConstraints);
        const media = videoCaptureRef.current.getMedia();
        if (!media) return false;
        const webrtcURL = new URL(getWebRTCSocketUrl());
        webrtcURL.searchParams.set('token', token);
        const success = createWebrtcSession(media, webrtcURL.href, interactionId);
        return success;
      },
    };
    useImperativeHandle(forwardRef, () => forwardedRef);

    // ---------------------------------------------------------------------- //
    // ----------------------------- USE EFFECT ------------------------------ //
    // ---------------------------------------------------------------------- //

    useEffect(() => {
      const isRunningMap = {
        [ActivityInteractionStatus.WAITING_RESPONSE]: false,
        [ActivityInteractionStatus.WAITING_MEDIA]: false,
        [ActivityInteractionStatus.WAITING_TO_JOIN]: false,
        [ActivityInteractionStatus.RUNNING]: true,
        [ActivityInteractionStatus.REFUSED]: false,
        [ActivityInteractionStatus.TERMINATED]: false,
        [ActivityInteractionStatus.CANCELED]: false,
      };
      onStatus(interaction?.id, status);
      if (status) {
        if (onIsRunning) onIsRunning(!!isRunningMap[status]);
      } else if (onIsRunning) onIsRunning(false);
    }, [interactions]);

    // ---------------------------------------------------------------------- //
    // ----------------------------- RENDERING ------------------------------ //
    // ---------------------------------------------------------------------- //

    const renderVideoCapture = (additionnalStyle) => {
      const currStyle = [styles.videoCapture];
      if (additionnalStyle)
        currStyle.push(
          ...(Array.isArray(additionnalStyle) ? additionnalStyle : [additionnalStyle]),
        );
      return (
        <VideoCapture
          ref={videoCaptureRef}
          style={currStyle}
          enableVideoElement
          enableAudioElement
          screenSharingElement
          listVideoDevicesElement
          listAudioDevicesElement
          videoElement
          onMedia={(media) => {
            if (!media) return;
            mediaRef.current = media;
            const videoTrack = getVideoTrack(media);
            const audioTrack = getAudioTrack(media);
            setMediaIsReady(videoTrack && audioTrack);
            if (webrtcSessionRef.current) {
              updateMediaWebrtcSession(media);
            }
          }}
          onMediaCaptureError={(type, error) => {
            setMediaCaptureError(true);
            if (onMediaCaptureError) onMediaCaptureError(type, error);
          }}
          onVideoError={onVideoError}
          onDestroy={() => setMediaIsReady(false)}
        />
      );
    };

    const initMediaIsNotReady = () => {
      const info = () => null;
      const media = () => (!hide ? renderVideoCapture() : null);
      const content = () => (
        <View style={getStyles('detailsContainer')}>
          <Text style={getStyles('descriptionText')}>
            {t(Strings.ACTIVITY_INTERACTION_ATTENDEE_CONTROLLER_WAITING_CAPTURE_MEDIA_MESSAGE)}
          </Text>
        </View>
      );
      const footer = () => (
        <>
          <TouchableOpacity onPress={onClose} style={getStyles('buttonFooterContainer')}>
            <Text style={[...getStyles('buttonFooter'), ...getStyles('unvalidateButton')]}>
              {t(
                Strings.ACTIVITY_INTERACTION_ATTENDEE_CONTROLLER_WAITING_CAPTURE_MEDIA_CANCEL_BUTTON,
              )}
            </Text>
          </TouchableOpacity>
        </>
      );
      return [info, media, content, footer];
    };

    const initAlreadyStartedFromAnotherDevice = () => {
      const info = () => null;
      const media = () => null;
      const content = () => (
        <View style={getStyles('detailsContainer')}>
          <Text style={getStyles('descriptionText')}>
            {t(Strings.ACTIVITY_INTERACTION_ATTENDEE_CONTROLLER_ALREADY_STARTED_MESSAGE)}
          </Text>
        </View>
      );
      const footer = () => (
        <>
          <TouchableOpacity onPress={() => cancel()} style={getStyles('buttonFooterContainer')}>
            <Text style={[...getStyles('buttonFooter'), ...getStyles('unvalidateButton')]}>
              {t(Strings.ACTIVITY_INTERACTION_ATTENDEE_CONTROLLER_ALREADY_STARTED_STOP_BUTTON)}
            </Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={onClose} style={getStyles('buttonFooterContainer')}>
            <Text style={[...getStyles('buttonFooter'), ...getStyles('validateButton')]}>
              {t(Strings.ACTIVITY_INTERACTION_ATTENDEE_CONTROLLER_ALREADY_STARTED_OK_BUTTON)}
            </Text>
          </TouchableOpacity>
        </>
      );
      return [info, media, content, footer];
    };

    const initMediaCaptureError = () => {
      const info = () => null;
      const media = () => null;
      const content = () => (
        <View style={getStyles('detailsContainer')}>
          <Text style={getStyles('descriptionText')}>
            {t(Strings.ACTIVITY_INTERACTION_ATTENDEE_CONTROLLER_MEDIA_ERROR_MESSAGE)}
          </Text>
        </View>
      );
      const footer = () => (
        <>
          <TouchableOpacity
            onPress={() => setMediaCaptureError(false)}
            style={getStyles('buttonFooterContainer')}
          >
            <Text style={[...getStyles('buttonFooter'), ...getStyles('validateButton')]}>
              {t(Strings.ACTIVITY_INTERACTION_ATTENDEE_CONTROLLER_MEDIA_RETRY_BUTTON)}
            </Text>
          </TouchableOpacity>
        </>
      );
      return [info, media, content, footer];
    };

    const initStartNew = () => {
      const info = () => null;
      const media = () => (!hide ? renderVideoCapture() : null);
      const content = () => {
        const creatorIsMe = activity?._user === me?.id;
        const message = creatorIsMe
          ? t(Strings.ACTIVITY_INTERACTION_ATTENDEE_CONTROLLER_START_NEW_MESSAGE_IAM_CREATOR)
          : t(Strings.ACTIVITY_INTERACTION_ATTENDEE_CONTROLLER_START_NEW_MESSAGE);
        return (
          <View style={getStyles('detailsContainer')}>
            <Text style={getStyles('descriptionText')}>{message}</Text>
            {!creatorIsMe ? (
              <TextInputLimited
                style={styles.inputMessageContainer}
                textIndicatorStyle={styles.inputMessageIndicator}
                textInputStyle={styles.inputMessageText}
                limit={LIMIT_MESSAGE_LENGTH}
                placeholder={t(
                  Strings.ACTIVITY_INTERACTION_ATTENDEE_CONTROLLER_START_NEW_PLACEHOLDER_MESSAGE,
                )}
                defaultValue={messageRef.current}
                onChangeText={(text) => {
                  messageRef.current = text;
                }}
              />
            ) : null}
          </View>
        );
      };
      const footer = (renew = false) => {
        const creatorIsMe = activity?._user === me?.id;
        let message = t(Strings.ACTIVITY_INTERACTION_ATTENDEE_CONTROLLER_START_NEW_VALIDATE_BUTTON);
        if (creatorIsMe)
          message = t(
            Strings.ACTIVITY_INTERACTION_ATTENDEE_CONTROLLER_START_NEW_VALIDATE_BUTTON_IAM_CREATOR,
          );
        else if (renew)
          message = t(
            Strings.ACTIVITY_INTERACTION_ATTENDEE_CONTROLLER_START_NEW_VALIDATE_BUTTON_AGAIN,
          );
        return (
          <>
            <TouchableOpacity
              onPress={() => {
                onRequest(messageRef.current?.trim());
              }}
              style={getStyles('buttonFooterContainer')}
            >
              <Text style={[...getStyles('buttonFooter'), ...getStyles('validateButton')]}>
                {message}
              </Text>
            </TouchableOpacity>
          </>
        );
      };
      return [info, media, content, footer];
    };

    const initWaitingResponse = () => {
      const info = () => null;
      const media = () => renderVideoCapture(); // keep videoCapture alive even if the view is hide
      const content = () => (
        <View style={getStyles('detailsContainer')}>
          <Text style={getStyles('descriptionText')}>
            {t(Strings.ACTIVITY_INTERACTION_ATTENDEE_CONTROLLER_WAITING_RESPONSE_MESSAGE)}
          </Text>
        </View>
      );
      const footer = () => (
        <>
          <TouchableOpacity onPress={() => cancel()} style={getStyles('buttonFooterContainer')}>
            <Text style={[...getStyles('buttonFooter'), ...getStyles('unvalidateButton')]}>
              {t(Strings.ACTIVITY_INTERACTION_ATTENDEE_CONTROLLER_WAITING_RESPONSE_CANCEL_BUTTON)}
            </Text>
          </TouchableOpacity>
        </>
      );
      return [info, media, content, footer];
    };

    const initWaitingMedia = () => {
      const info = () => null;
      const media = () => renderVideoCapture(); // keep videoCapture alive even if the view is hide
      const content = () => (
        <View style={getStyles('detailsContainer')}>
          <Text style={getStyles('descriptionText')}>
            {t(Strings.ACTIVITY_INTERACTION_ATTENDEE_CONTROLLER_WAITING_MEDIA_MESSAGE)}
          </Text>
        </View>
      );
      const footer = () => (
        <>
          <TouchableOpacity onPress={() => cancel()} style={getStyles('buttonFooterContainer')}>
            <Text style={[...getStyles('buttonFooter'), ...getStyles('unvalidateButton')]}>
              {t(Strings.ACTIVITY_INTERACTION_ATTENDEE_CONTROLLER_WAITING_MEDIA_CANCEL_BUTTON)}
            </Text>
          </TouchableOpacity>
        </>
      );
      return [info, media, content, footer];
    };

    const initWaitingToJoin = () => {
      const info = () => null;
      const media = () => renderVideoCapture(); // keep videoCapture alive even if the view is hide
      const content = () => (
        <View style={getStyles('detailsContainer')}>
          <Text style={getStyles('descriptionText')}>
            {t(Strings.ACTIVITY_INTERACTION_ATTENDEE_CONTROLLER_WAITING_TO_JOIN_MESSAGE)}
          </Text>
        </View>
      );
      const footer = () => (
        <>
          <TouchableOpacity onPress={() => cancel()} style={getStyles('buttonFooterContainer')}>
            <Text style={[...getStyles('buttonFooter'), ...getStyles('unvalidateButton')]}>
              {t(Strings.ACTIVITY_INTERACTION_ATTENDEE_CONTROLLER_WAITING_TO_JOIN_CANCEL_BUTTON)}
            </Text>
          </TouchableOpacity>
        </>
      );
      return [info, media, content, footer];
    };

    const initRunning = () => {
      const info = () => null;
      const media = () => renderVideoCapture(); // keep videoCapture alive even if the view is hide
      const content = () => (
        <View style={getStyles('detailsContainer')}>
          <Text style={getStyles('descriptionText')}>
            {t(Strings.ACTIVITY_INTERACTION_ATTENDEE_CONTROLLER_RUNNING_MESSAGE)}
          </Text>
        </View>
      );
      const footer = () => (
        <>
          <TouchableOpacity onPress={() => stop()} style={getStyles('buttonFooterContainer')}>
            <Text style={[...getStyles('buttonFooter'), ...getStyles('unvalidateButton')]}>
              {t(Strings.ACTIVITY_INTERACTION_ATTENDEE_CONTROLLER_RUNNING_STOP_BUTTON)}
            </Text>
          </TouchableOpacity>
        </>
      );
      return [info, media, content, footer];
    };

    const initRefused = () => {
      let lastInteractionIsRecent = false;
      const event = events?.find((ev) => ev.type === ActivityInteractionEventType.REQUEST_DECLINED);
      if (event?.createdAt) {
        const createdAt = new Date(event.createdAt);
        const now = new Date();
        if (now.getTime() - createdAt.getTime() < LAST_INTERACTION_REFUSED_DISPLAY_MESSAGE_UNTIL) {
          lastInteractionIsRecent = true;
        }
      }
      const info = () => (
        <>
          {lastInteractionIsRecent ? (
            <Text style={styles.lastStateText} numberOfLines={1} ellipsizeMode="tail">
              {t(Strings.ACTIVITY_INTERACTION_ATTENDEE_CONTROLLER_REFUSED_MESSAGE)}
            </Text>
          ) : null}
        </>
      );
      const startNew = initStartNew();
      const media = () => (!hide ? renderVideoCapture() : null);
      const content = () => startNew[2]();
      const footer = () => startNew[3](!!lastInteractionIsRecent);
      return [info, media, content, footer];
    };

    const initTerminated = () => {
      let lastInteractionIsRecent = false;
      const event = events?.find((ev) => ev.type === ActivityInteractionEventType.STOPPED);
      if (event?.createdAt) {
        const createdAt = new Date(event.createdAt);
        const now = new Date();
        if (
          now.getTime() - createdAt.getTime() <
          LAST_INTERACTION_TERMINATED_DISPLAY_MESSAGE_UNTIL
        ) {
          lastInteractionIsRecent = true;
        }
      }
      const info = () => (
        <>
          {lastInteractionIsRecent ? (
            <Text style={styles.lastStateText} numberOfLines={1} ellipsizeMode="tail">
              {t(Strings.ACTIVITY_INTERACTION_ATTENDEE_CONTROLLER_TERMINATED_MESSAGE)}
            </Text>
          ) : null}
        </>
      );
      const startNew = initStartNew();
      const media = () => (!hide ? renderVideoCapture() : null);
      const content = () => startNew[2]();
      const footer = () => startNew[3](!!lastInteractionIsRecent);
      return [info, media, content, footer];
    };

    const initCanceled = () => {
      let lastInteractionIsRecent = false;
      const event = events?.find((ev) => ev.type === ActivityInteractionEventType.CANCELED);
      if (event?.createdAt) {
        const createdAt = new Date(event.createdAt);
        const now = new Date();
        if (now.getTime() - createdAt.getTime() < LAST_INTERACTION_CANCELED_DISPLAY_MESSAGE_UNTIL) {
          lastInteractionIsRecent = true;
        }
      }
      const info = () => (
        <>
          {lastInteractionIsRecent ? (
            <Text style={styles.lastStateText} numberOfLines={1} ellipsizeMode="tail">
              {t(Strings.ACTIVITY_INTERACTION_ATTENDEE_CONTROLLER_CANCELED_MESSAGE)}
            </Text>
          ) : null}
        </>
      );
      const startNew = initStartNew();
      const media = () => (!hide ? renderVideoCapture() : null);
      const content = () => startNew[2]();
      const footer = () => startNew[3](!!lastInteractionIsRecent);
      return [info, media, content, footer];
    };

    const renderHeader = () => (
      <View style={getStyles('supportHeader')}>
        <Text style={getStyles('textHeader')}>
          {t(Strings.ACTIVITY_INTERACTION_ATTENDEE_CONTROLLER_TITLE)}
        </Text>
        <TouchableOpacity style={styles.closeButton} onPress={onClose}>
          <IconClose width={20} />
        </TouchableOpacity>
      </View>
    );

    const initRenderFunctions = () => {
      const localId = getUniqueLocalId();
      const clearMessageInput = () => {
        messageRef.current = undefined;
      };
      if (interaction && interaction.platformId !== localId) {
        // interaction is initiated by another device & interaction is processing
        if (
          interaction.status === ActivityInteractionStatus.WAITING_RESPONSE ||
          interaction.status === ActivityInteractionStatus.WAITING_MEDIA ||
          interaction.status === ActivityInteractionStatus.WAITING_TO_JOIN ||
          interaction.status === ActivityInteractionStatus.RUNNING
        ) {
          clearMessageInput();
          return initAlreadyStartedFromAnotherDevice();
        }
      }
      if (mediaCaptureError) {
        clearMessageInput();
        return initMediaCaptureError();
      }
      if (!mediaIsReady) {
        clearMessageInput();
        return initMediaIsNotReady();
      }
      if (!interaction) return initStartNew();
      if (!status) return initStartNew();
      const initFuncMap = {
        [ActivityInteractionStatus.WAITING_RESPONSE]: initWaitingResponse,
        [ActivityInteractionStatus.WAITING_MEDIA]: initWaitingMedia,
        [ActivityInteractionStatus.WAITING_TO_JOIN]: initWaitingToJoin,
        [ActivityInteractionStatus.RUNNING]: initRunning,
        [ActivityInteractionStatus.REFUSED]: initRefused,
        [ActivityInteractionStatus.TERMINATED]: initTerminated,
        [ActivityInteractionStatus.CANCELED]: initCanceled,
      };
      let initFunc = initFuncMap[status];
      if (initFunc) clearMessageInput();
      else initFunc = initStartNew;
      return initFunc();
    };

    const [renderInformation, renderMedia, renderContent, renderFooter] = initRenderFunctions();

    const viewStyle = [...getStyles('support')];
    if (style) viewStyle.push(...(Array.isArray(style) ? style : [style]));
    if (hide) viewStyle.push({ display: 'none' });
    return (
      <View style={viewStyle}>
        {renderHeader()}
        <View style={getStyles('supportContent')}>
          {renderInformation()}
          {renderMedia()}
          {renderContent()}
        </View>
        <View style={getStyles('supportFooter')}>{renderFooter()}</View>
      </View>
    );
  },
);

ActivityRequestInteraction.displayName = 'ActivityRequestInteraction';

ActivityRequestInteraction.propTypes = {
  activity: PropTypes.object.isRequired,
  me: PropTypes.object.isRequired,
  interactions: PropTypes.array,
  onClose: PropTypes.func.isRequired,
  onRequest: PropTypes.func.isRequired,
  onStop: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onVideoError: PropTypes.func.isRequired,
  onMediaError: PropTypes.func.isRequired,
  onMediaCaptureError: PropTypes.func.isRequired,
  onStatus: PropTypes.func.isRequired,
  onIsRunning: PropTypes.func,
  hide: PropTypes.bool,
  style: PropTypes.any,
};

ActivityRequestInteraction.defaultProps = {
  hide: false,
};

export default ActivityRequestInteraction;
