import { fromJS } from 'immutable';
import { createReducer } from 'reduxsauce';

import { Types } from '../actions/activity';

const initialState = fromJS({
  availableRooms: [],
  roomLiveProfiles: undefined,
  info: {},
  state: {},
  devices: [],
  interactions: [],
});

const sortDevices = (devices) => {
  return devices.sort((a, b) => {
    return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
  });
};

const sortInteractions = (interactions) => {
  return interactions.sort((a, b) => {
    return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
  });
};

export const setAvailableRooms = (state = initialState, { rooms }) =>
  state.set('availableRooms', fromJS(rooms));

export const setRoomLiveProfiles = (state = initialState, { roomLiveProfiles }) =>
  state.set('roomLiveProfiles', fromJS(roomLiveProfiles));

export const setActivityInfo = (state = initialState, { activityInfo }) =>
  state.set('info', fromJS(activityInfo));

export const setActivityState = (state = initialState, { activityState }) =>
  state.set('state', fromJS(activityState));

export const setActivityDevices = (state = initialState, { devices }) =>
  state.set('devices', fromJS(sortDevices(devices || [])));

export const updateActivityDevice = (state = initialState, { device }) => {
  if (!device) return state;
  const devices = state.get('devices').toJS();
  if (!devices) return state;
  devices.forEach((d) => {
    if (d.id === device.id) {
      Object.assign(d, device);
    }
  });
  return state.set('devices', fromJS(sortDevices(devices)));
};

export const setActivityInteractions = (state = initialState, { interactions }) => {
  return state.set('interactions', fromJS(sortInteractions(interactions ?? [])));
};

export const updateActivityInteraction = (state = initialState, { activityId, interaction }) => {
  const activity = state.get('info').toJS();
  if (!activity) return state;
  if (activity.id !== activityId) return state;
  if (!interaction) return state;
  const interactions = state.get('interactions').toJS();
  let interactionFound = false;
  interactions.forEach((curr) => {
    if (curr.id === interaction.id) {
      interactionFound = true;
      Object.assign(curr, interaction);
    }
  });
  if (!interactionFound) interactions.push(interaction);
  return state.set('interactions', fromJS(sortInteractions(interactions ?? [])));
};

export const enableActivityInteractions = (state = initialState, { activityId, enabled }) => {
  const activity = state.get('info').toJS();
  if (!activity) return state;
  if (activity.id === activityId) {
    activity.interactionsAreEnabled = enabled;
  }
  return state.set('info', fromJS(activity));
};

export const updateVodState = (initState = initialState, { vodId, state }) => {
  const info = initState.get('info').toJS();
  if (!info || !info.vodSessions) return initState;
  // eslint-disable-next-line no-restricted-syntax
  for (const vod of info.vodSessions) {
    if (vod._id === vodId) {
      vod.state = state;
    }
  }
  return initState.set('info', fromJS(info));
};

export default createReducer(initialState, {
  [Types.SET_AVAILABLE_ROOMS]: setAvailableRooms,
  [Types.SET_ACTIVITY_INFO]: setActivityInfo,
  [Types.SET_ACTIVITY_STATE]: setActivityState,
  [Types.SET_ROOM_LIVE_PROFILES]: setRoomLiveProfiles,
  [Types.SET_ACTIVITY_DEVICES]: setActivityDevices,
  [Types.UPDATE_ACTIVITY_DEVICE]: updateActivityDevice,
  [Types.SET_ACTIVITY_INTERACTIONS]: setActivityInteractions,
  [Types.UPDATE_ACTIVITY_INTERACTION]: updateActivityInteraction,
  [Types.ENABLE_ACTIVITY_INTERACTIONS]: enableActivityInteractions,
  [Types.UPDATE_VOD_STATE]: updateVodState,
});
