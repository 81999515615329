export const mediaDevicesMatch = (
  /** @type { 'video' | 'audio' | 'both' } */ type,
  /** @type { 'input' | 'output' | 'both' } */ io,
  /** @type {MediaDeviceInfo} */ device,
) => {
  if (type === 'video' && !device.kind.startsWith('video')) return false;
  if (type === 'audio' && !device.kind.startsWith('audio')) return false;

  if (io === 'input' && !device.kind.endsWith('input')) return false;
  if (io === 'output' && !device.kind.endsWith('output')) return false;
  return true;
};

export const filterMediaDevices = (
  /** @type { 'video' | 'audio' | 'both' } */ type,
  /** @type { 'input' | 'output' | 'both' } */ io,
) => {
  return (device) => mediaDevicesMatch(type, io, device);
};

export const addMediaDevicesChangeListener = (listener) => {
  navigator.mediaDevices.addEventListener('devicechange', listener);
};

export const removeMediaDevicesChangeListener = (listener) => {
  navigator.mediaDevices.removeEventListener('devicechange', listener);
};

export const getMediaDevices = async (
  /** @type { 'video' | 'audio' | 'both' } */ type,
  /** @type { 'input' | 'output' | 'both' } */ io,
) => {
  const devices = await navigator.mediaDevices.enumerateDevices();
  return devices.filter(filterMediaDevices(type, io));
};

export const getAudioInputMediaDevices = async () => {
  return getMediaDevices('audio', 'input');
};
export const getAudioOutputMediaDevices = async () => {
  return getMediaDevices('audio', 'output');
};
export const getVideoInputMediaDevices = async () => {
  return getMediaDevices('video', 'input');
};
export const getVideoOutputMediaDevices = async () => {
  return getMediaDevices('video', 'output');
};

export const getUserMedia = async (constraint = { video: true, audio: true }) => {
  const media = await navigator.mediaDevices.getUserMedia(constraint);
  if (media) {
    // trigger event devicechange to update devices after the permission (FIREFOX)
    navigator.mediaDevices.dispatchEvent(new Event('devicechange'));
  }
  return media;
};

export const getDisplayMedia = async (constraint = { video: true, audio: true }) => {
  const media = navigator.mediaDevices.getDisplayMedia(constraint);
  return media;
};

export const displayMediaIsAvailable = () => {
  return !!navigator.mediaDevices.getDisplayMedia;
};

export const addVideoDeviceIdToMediaConstraints = (
  deviceId,
  constraints = { video: true, audio: true },
) => {
  let result = {};
  if (constraints) result = JSON.parse(JSON.stringify(constraints)); // Deep clone
  if (!deviceId?.length) return result;
  if (result.video === true) {
    result.video = {
      deviceId: { exact: deviceId },
    };
  } else if (typeof result.video === 'object') {
    result.video.deviceId = { exact: deviceId };
  }
  return result;
};

export const addAudioDeviceIdToMediaConstraints = (
  deviceId,
  constraints = { video: true, audio: true },
) => {
  let result = {};
  if (constraints) result = JSON.parse(JSON.stringify(constraints)); // Deep clone
  if (!deviceId?.length) return result;
  if (result.audio === true) {
    result.audio = {
      deviceId,
    };
  } else if (typeof result.audio === 'object') {
    result.audio.deviceId = deviceId;
  }
  return result;
};

export const removeAllVideoTracks = (/** @type { MediaStream } */ mediaStream) => {
  if (!mediaStream) return;
  const videoTracks = mediaStream.getVideoTracks();
  videoTracks.forEach((track) => {
    if (!track) return;
    track.stop();
    mediaStream.removeTrack(track);
  });
};

export const removeAllAudioTracks = (/** @type { MediaStream } */ mediaStream) => {
  if (!mediaStream) return;
  const audioTracks = mediaStream.getAudioTracks();
  audioTracks.forEach((track) => {
    if (!track) return;
    track.stop();
    mediaStream.removeTrack(track);
  });
};

export const removeAllTracks = (/** @type { MediaStream } */ mediaStream) => {
  if (!mediaStream) return;
  removeAllVideoTracks(mediaStream);
  removeAllAudioTracks(mediaStream);
};

export const getVideoTrack = (/** @type { MediaStream } */ mediaStream) => {
  if (!mediaStream) return undefined;
  const videoTracks = mediaStream.getVideoTracks();
  if (!videoTracks.length) return undefined;
  return videoTracks[0];
};

export const setVideoTrack = (
  /** @type { MediaStream } */ mediaStream,
  /** @type { MediaStreamTrack } */ track,
) => {
  if (!mediaStream) return;
  if (!track || track.kind !== 'video') return;
  removeAllVideoTracks(mediaStream);
  mediaStream.addTrack(track);
};

export const getAudioTrack = (/** @type { MediaStream } */ mediaStream) => {
  if (!mediaStream) return undefined;
  const audioTracks = mediaStream.getAudioTracks();
  if (!audioTracks.length) return undefined;
  return audioTracks[0];
};

export const setAudioTrack = (
  /** @type { MediaStream } */ mediaStream,
  /** @type { MediaStreamTrack } */ track,
) => {
  if (!mediaStream) return;
  if (!track || track.kind !== 'audio') return;
  removeAllAudioTracks(mediaStream);
  mediaStream.addTrack(track);
};

export const addTrackEndedMediaStreamListener = (
  /** @type { MediaStream } */ mediaStream,
  /** @type { (track: MediaStreamTrack) => void } */ callback,
) => {
  const result = {
    clear: () => null,
  };
  if (!mediaStream) return result;
  function onChange() {
    if (callback) callback(this);
  }
  const onTrackChange = () => {
    if (!mediaStream) return;
    mediaStream.getTracks().forEach((track) => {
      track.removeEventListener('ended', onChange); // if already added
      track.addEventListener('ended', onChange);
    });
  };
  mediaStream.getTracks().forEach((track) => {
    track.addEventListener('ended', onChange);
  });
  mediaStream.addEventListener('addtrack', onTrackChange);
  mediaStream.addEventListener('removetrack', onTrackChange);
  result.clear = () => {
    if (!mediaStream) return;
    mediaStream.getTracks().forEach((track) => {
      track.removeEventListener('ended', onChange);
    });
    mediaStream.addEventListener('addtrack', onTrackChange);
    mediaStream.addEventListener('removetrack', onTrackChange);
  };
  return result;
};

export const updateConstraintsForMedia = async (
  /** @type { MediaStream } */ mediaStream,
  constraints,
) => {
  if (!mediaStream) return;
  const promises = [];
  const tacks = mediaStream.getTracks();
  tacks.forEach((track) => {
    const constraintsForTrack = constraints?.[track.kind];
    if (constraintsForTrack && typeof constraintsForTrack === 'object') {
      promises.push(track.applyConstraints(constraintsForTrack));
    }
  });
  await Promise.allSettled(promises);
};
