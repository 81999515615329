export class ResponseError extends Error {
  response;

  errorType;

  code;

  constructor(errorType, response) {
    super();
    this.response = response;
    this.code = response?.status;
    this.errorType = errorType;
  }
}

export default {
  ResponseError,
};
