import { resettableReducer } from 'reduxsauce';
import { combineReducers } from 'redux-immutable';

import { Types as RoomTypes } from '../actions/room';
import { Types as OrganizationTypes } from '../actions/organization';
import { Types as ActivityTypes } from '../actions/activity';

import app from './app';
import room from './room';
import user from './user';
import organization from './organization';
import system from './system';
import activity from './activity';
import device from './device';
import vod from './vod';
import error from './error';

const roomResettable = resettableReducer(RoomTypes.RESET_ROOM);
const organizationResettable = resettableReducer(OrganizationTypes.RESET_ORGANIZATION);
const activityResettable = resettableReducer(ActivityTypes.RESET_ACTIVITY);

// If there is a specific need to reset reducers, check out how it's done for user;

const rootReducer = combineReducers({
  app,
  room: roomResettable(room),
  user,
  organization: organizationResettable(organization),
  system,
  activity: activityResettable(activity),
  device,
  vod,
  error,
});

export default rootReducer;
