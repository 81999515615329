import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { StyleSheet, View } from 'react-native';
import { connect } from 'react-redux';
import IconInfo from '../../assets/blue/icon-info.svg';
import colors from '../../constants/colors';
import fonts from '../../constants/fonts';
import MessageOnHover from './messageOnHover';

const styles = StyleSheet.create({
  container: {
    position: 'relative',
  },
  messageHoveredContainer: {
    position: 'fixed',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 10,
    backgroundColor: colors.getBlueCornflower(),
    padding: 7,
    borderRadius: 5,
    shadowRadius: 2,
    shadowColor: colors.getMainBlack(0.5),
    overflow: 'visible', // marker
    marginBottom: 10,
    left: '50%',
    bottom: '100%',
    transform: 'translate(-50%, 5px)',
  },
  messageHoveredMarker: {
    position: 'absolute',
    backgroundColor: colors.getBlueCornflower(),
    width: 8,
    height: 8,
    left: '50%',
    bottom: -4,
    transform: 'translate(-5px, 0px) rotate(45deg)',
  },
  messageHoveredText: {
    fontFamily: fonts.getSignika(),
    fontSize: 16,
    color: colors.getMainWhite(),
  },
});

const Info = ({ message, iconProps, style }) => {
  const containerRef = useRef();
  const currStyles = [styles.container];
  if (style) currStyles.push(style);
  return (
    <View style={currStyles} ref={containerRef}>
      <MessageOnHover targetRef={containerRef} message={message} />
      <IconInfo width={'100%'} height={'100%'} {...iconProps} />
    </View>
  );
};

Info.propTypes = {
  message: PropTypes.string,
  iconProps: PropTypes.any,
  style: PropTypes.any,
};

Info.defaultProps = {};

export default connect(null, null)(Info);
