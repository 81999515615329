import React, { useEffect, useState } from 'react';
import { ActivityIndicator, StyleSheet, Text, TouchableOpacity } from 'react-native';
import PropTypes from 'prop-types';

import { View } from 'react-native-web';
import { useTranslation } from 'react-i18next';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import fonts from '../../constants/fonts';
import { Strings } from '../../helpers/strings';

import errorSelectors from '../../App/selectors/error';
import userActions from '../../App/actions/user';

import IconWifi from '../../assets/error/icon-wifi-outline.svg';

const styles = StyleSheet.create({
  mainContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: '50%',
    transform: 'translate(-50%)',
    backgroundColor: '#fc4747',
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    padding: 10,
    flexWrap: 'wrap',
    justifyContent: 'center',
    minWidth: 200,
  },
  issueContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
    alignItems: 'center',
  },
  mainText: {
    fontFamily: fonts.getSignika(),
    fontWeight: 800,
    fontSize: 20,
    color: 'white',
  },
  hintText: {
    fontFamily: fonts.getSignika(),
    fontWeight: 600,
    fontSize: 16,
    color: '#c2c2c2',
  },
  icon: {
    height: 25,
  },
});

const ErrorNotifier = ({ error, retryRequest }) => {
  if (!error || !error.isNetwork) return null;

  const { t } = useTranslation();
  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    if (clicked) {
      const id = setTimeout(() => {
        // Cosmetic delay so the user can see the activity
        setClicked(false);
      }, 1000);
      return () => clearTimeout(id);
    }
    return () => {};
  }, [error]);

  const onClick = () => {
    if (!clicked) {
      setClicked(true);
      retryRequest();
    }
  };

  return (
    <TouchableOpacity style={styles.mainContainer} onPressOut={onClick}>
      <View style={styles.issueContainer}>
        <IconWifi style={styles.icon} />
        <Text style={styles.mainText}>{t(Strings.NETWORK_ISSUE)}</Text>
      </View>
      {clicked ? (
        <ActivityIndicator />
      ) : (
        <Text style={styles.hintText}>({t(Strings.CLICK_TO_RETRY)})</Text>
      )}
    </TouchableOpacity>
  );
};

ErrorNotifier.propTypes = {
  error: PropTypes.object,
  retryRequest: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  error: errorSelectors.makeSelectError(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    retryRequest: () => dispatch(userActions.retryRequest()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorNotifier);
