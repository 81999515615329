import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  switchResolution: ['string'],
  switchScene: ['object'],
  switchFocus: ['boolean'],
  sendAlpha: ['object'],
  sendSoundVideo: ['object'],
  setFlip: ['boolean'],
  sendSound: ['object'],
  sendHdmi: ['stringOne', 'stringTwo'],
  sendFtp: ['object'],
  sendSmtp: ['object'],
});

export { Types };
export default Creators;
