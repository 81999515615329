import moment from 'moment';
import crypto from 'crypto';
import { UAParser } from 'ua-parser-js';
import { START_TIME_STEP } from '../constants/activity';

// Allow to await some ms
export const delay = async (ms) => {
  return new Promise((resolve) => {
    if (!ms || ms < 0) resolve(null);
    setTimeout(() => {
      resolve(null);
    }, ms);
  });
};

export const roundDownTime = (time, step) => {
  const roundedDown = Math.floor(time.minute() / step) * step;
  return time.minute(roundedDown).second(0).millisecond(0);
};

export const getNowRoundedDown = (step = START_TIME_STEP) => {
  return roundDownTime(moment(), step);
};

export const isValidHttpUrl = (string) => {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
};

export const isValidWebSocketUrl = (string) => {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === 'ws:' || url.protocol === 'wss:';
};

export const isMobilePlatform = () => {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ];
  return toMatch.some((toMatchItem) => navigator.userAgent.match(toMatchItem));
};

export const generateId = (length = 16) => {
  return crypto.randomBytes(length).toString('hex');
};

export const getDateDifferenceText = (start, end) => {
  const momentDiffMilliseconds = moment(end).diff(start);
  const momentDuration = moment.duration(momentDiffMilliseconds);
  let ms = momentDuration.asMilliseconds();
  if (ms < 0) return '';
  const hours = Math.floor(ms / 3600000); // get hours
  ms -= hours * 3600000;
  const minutes = Math.floor(ms / 60000); // get minutes
  ms -= minutes * 60000;
  const secondes = Math.floor(ms / 1000); // get secondes
  if (hours) return `${hours}:${`0${minutes}`.slice(-2)}:${`0${secondes}`.slice(-2)}`;
  return `${minutes}:${`0${secondes}`.slice(-2)}`;
};

export const getSinceText = (start) => {
  return getDateDifferenceText(start, new Date());
};

export const getUntilText = (start) => {
  return getDateDifferenceText(new Date(), start);
};

export const getUserAgent = () => {
  return new UAParser(navigator.userAgent).getResult();
};

export default {
  delay,
  roundDownTime,
  getNowRoundedDown,
  isValidHttpUrl,
  isMobilePlatform,
  getDateDifferenceText,
  getSinceText,
  getUserAgent,
};
