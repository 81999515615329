export const ActivityInteractionStatus = {
  WAITING_RESPONSE: 'waiting_response',
  WAITING_MEDIA: 'waiting_media',
  WAITING_TO_JOIN: 'waiting_to_join',
  RUNNING: 'running',
  REFUSED: 'refused',
  TERMINATED: 'terminated',
  CANCELED: 'canceled',
};

export const ActivityInteractionEventType = {
  CREATED: 'created',
  REQUEST: 'request',
  REQUEST_ACCEPTED: 'request_accepted',
  REQUEST_DECLINED: 'request_declined',
  MEDIA_IS_READY: 'media_is_ready',
  JOINED: 'joined',
  STOPPED: 'stopped',
  CANCELED: 'canceled',
};

export const ActivityInteractionType = {
  MEDIA_DEVICE: 'media_device',
};

export const ActivityInteractionMediaProvider = {
  OVENMEDIA: 'ovenmedia',
};

export const filterActivityInteractionsByStatus = (interactions, status) => {
  if (!interactions || !status) return interactions;
  return interactions.filter((curr) => status.includes(curr.status));
};

export const filterActivityInteractionsInProcessing = (interactions) => {
  return filterActivityInteractionsByStatus(interactions, [
    ActivityInteractionStatus.WAITING_RESPONSE,
    ActivityInteractionStatus.WAITING_MEDIA,
    ActivityInteractionStatus.WAITING_TO_JOIN,
    ActivityInteractionStatus.RUNNING,
  ]);
};

export const filterActivityInteractionsInWaiting = (interactions) => {
  return filterActivityInteractionsByStatus(interactions, [
    ActivityInteractionStatus.WAITING_RESPONSE,
    ActivityInteractionStatus.WAITING_MEDIA,
    ActivityInteractionStatus.WAITING_TO_JOIN,
  ]);
};

export const filterActivityInteractionsInRunning = (interactions) => {
  return filterActivityInteractionsByStatus(interactions, [ActivityInteractionStatus.RUNNING]);
};

export const filterActivityInteractionsEnded = (interactions) => {
  return filterActivityInteractionsByStatus(interactions, [
    ActivityInteractionStatus.REFUSED,
    ActivityInteractionStatus.TERMINATED,
    ActivityInteractionStatus.CANCELED,
  ]);
};
