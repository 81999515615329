import moment from 'moment';
import { STATE } from '../constants/activity';
import { USER_ROLE } from '../constants/global';
import { userRoleIsLT } from './role';

export const getLeftTime = (toTime) => {
  return moment(toTime).diff(moment());
};

export const getActivityStateFromLeftTimes = (startTimeLeft, endTimeLeft) => {
  if (!startTimeLeft || !endTimeLeft) return STATE.UNDEFINED;
  if (startTimeLeft <= 0 && endTimeLeft >= 0) return STATE.IN_PROGRESS;
  if (startTimeLeft <= 0 && endTimeLeft <= 0) return STATE.OVER;
  return STATE.COMING;
};

export const getActivityState = (activity) => {
  const startTimeLeft = getLeftTime(activity.startDatetime);
  const endTimeLeft = getLeftTime(activity.endDatetime);
  return getActivityStateFromLeftTimes(startTimeLeft, endTimeLeft);
};

export const activityCanBeEdited = (activity) => {
  const state = getActivityState(activity);
  return state === STATE.COMING;
};

export const userCanEditActivity = (activity, user) => {
  if (userRoleIsLT(user.role, USER_ROLE.KADMIN)) {
    if (user._organization !== activity._organization) return false;
    if (userRoleIsLT(user.role, USER_ROLE.ADMIN)) {
      if (user._id !== activity._user) {
        return false;
      }
    }
  }
  return true;
  // return activityCanBeEdited(activity);
};

export default {
  getLeftTime,
  getActivityStateFromLeftTimes,
  getActivityState,
  userCanEditActivity,
};
