import { createSelector } from 'reselect';

const activitySelector = (globalState) => globalState.get('activity');
const availableRoomsSelector = (state) => activitySelector(state).get('availableRooms');
const roomLiveProfilesSelector = (state) => activitySelector(state).get('roomLiveProfiles');
const activityInfoSelector = (state) => activitySelector(state).get('info');
const activityStateSelector = (state) => activitySelector(state).get('state');
const activityDevicesSelector = (state) => activitySelector(state).get('devices');
const activityInteractionsSelector = (state) => activitySelector(state).get('interactions');

const makeSelectAvailableRooms = () =>
  createSelector(availableRoomsSelector, (state) => state.toJS());

const makeSelectRoomLiveProfiles = () =>
  createSelector(roomLiveProfilesSelector, (state) => state?.toJS());

const makeSelectActivityInfo = () => createSelector(activityInfoSelector, (state) => state.toJS());

const makeSelectActivityState = () =>
  createSelector(activityStateSelector, (state) => state.toJS());

const makeSelectActivityDevices = () =>
  createSelector(activityDevicesSelector, (state) => state.toJS());

const makeSelectActivityInteractions = () =>
  createSelector(activityInteractionsSelector, (state) => state.toJS());

export default {
  makeSelectAvailableRooms,
  makeSelectActivityInfo,
  makeSelectActivityState,
  makeSelectRoomLiveProfiles,
  makeSelectActivityDevices,
  makeSelectActivityInteractions,
};
