import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { AUTH_TYPE } from '../../constants/global';

const organizationSelector = (globalState) => globalState.get('organization');
const authMethodsSelector = (state) => organizationSelector(state).get('authMethods');
const roomsSelector = (state) => organizationSelector(state).get('rooms');
const devicesSelector = (state) => organizationSelector(state).get('devices');
const settingsSelector = (state) => organizationSelector(state).get('settings');

// Organization's info
const organizationIdSelector = (state) => organizationSelector(state).get('_id');
const organizationNameSelector = (state) => organizationSelector(state).get('name');

// Selectors
const makeSelectOrganization = () => createSelector(organizationSelector, (state) => state.toJS());

const makeSelectOrganizationId = () => createSelector(organizationIdSelector, (state) => state);

const makeSelectAuthMethods = () => createSelector(authMethodsSelector, (state) => state.toJS());

const makeSelectRooms = () => createSelector(roomsSelector, (state) => state.toJS());

const makeSelectDevices = () => createSelector(devicesSelector, (state) => state.toJS());

const makeSelectOrganizationName = () => createSelector(organizationNameSelector, (state) => state);

const makeSelectOrganizationSettings = () =>
  createSelector(settingsSelector, (state) => state.toJS());

const makeSelectAuthMethodCas = () =>
  createSelector(authMethodsSelector, (state) => {
    if (!state) return undefined;
    const authCas = state[AUTH_TYPE.CAS]; // state.find((method) => method.get('type') === AUTH_TYPE.CAS);
    if (authCas !== undefined) return authCas.toJS();
    return undefined;
  });

// Hooks
const useSelectorOrganization = () => useSelector(organizationSelector)?.toJS();
const useSelectorOrganizationSettings = () => useSelector(settingsSelector)?.toJS();

export default {
  // Make select
  makeSelectOrganization,
  makeSelectOrganizationId,
  makeSelectAuthMethods,
  makeSelectRooms,
  makeSelectDevices,
  makeSelectAuthMethodCas,
  makeSelectOrganizationName,
  makeSelectOrganizationSettings,

  // HOOKs useSelector
  useSelectorOrganization,
  useSelectorOrganizationSettings,
};
