export const VIRTUAL_SCREEN_DEVICE_GROUP_ID = 'screen_sharing';
export const VIRTUAL_SCREEN_DEVICE_VIDEO_ID = `${VIRTUAL_SCREEN_DEVICE_GROUP_ID}_video`;
export const VIRTUAL_SCREEN_DEVICE_AUDIO_ID = `${VIRTUAL_SCREEN_DEVICE_GROUP_ID}_audio`;

export const VideoCaptureEvent = {
  MEDIA: 'media',
  VIDEO_ENABLE: 'videoEnable',
  AUDIO_ENABLE: 'audioEnable',
  VIDEO_DEVICE: 'videoDevice',
  AUDIO_DEVICE: 'audioDevice',
  VIDEO_DEVICES: 'videoDevices',
  AUDIO_DEVICES: 'audioDevices',
  AUDIO_VOLUME: 'audioVolume',
  SCREEN_SHARING: 'screenSharing',
  SCREEN_SHARING_STATUS: 'screenSharingStatus',
  DESTROY: 'destroy',
};

export const VideoCaptureScreenStatus = {
  WAITING: 'waiting',
  PROCESSING: 'processing',
  RUNNING: 'running',
  ERROR: 'error',
};

export const VideoCaptureElementType = {
  ENABLE_VIDEO_BUTTON: 'enableVideoButton',
  ENABLE_AUDIO_BUTTON: 'enableAaudioButton',
  SCREEN_SHARING_BUTTON: 'screenSharingButton',
  CONTROL_BUTTONS: 'controlButtons',
  LIST_VIDEO_DEVICES: 'listVideoDevices',
  LIST_AUDIO_DEVICES: 'listAudioDevices',
  LIST_DEVICES: 'listDevices',
  VIDEO: 'video',
};
