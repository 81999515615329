import { fromJS } from 'immutable';
import { createReducer } from 'reduxsauce';

import { Types } from '../actions/user';

import { getToken } from '../../helpers/auth';

export const PASSWORD_RESET_STATE = {
  IDLE: 'idle',
  RESET_REFUSED: 'reset_refused',
  USER_INPUT: 'user_input',
  EMAIL_SENT: 'email_sent',
  COMPLETED: 'completed',
  KEY_REFUSED: 'key_refused',
};

/**
 * {
 *  info: {
 *    _id: '',
 *    username: ''
 *  },
 *  organizations: [],
 *  isAuthenticated: bool,
 * }
 */
const initialState = fromJS({
  isAuthenticated: !!getToken(), // isAuthenticated status is only set in Login/Logout pages
  authType: undefined,
  organizations: [],
  activities: [],
  info: {},
  liveProfiles: undefined,
  isRefreshingToken: false,
  passwordResetState: PASSWORD_RESET_STATE.IDLE,
});

export const setUser = (state = initialState, { user }) => state.set('info', fromJS(user));

export const setIsAuthenticated = (state = initialState, { bool }) =>
  state.set('isAuthenticated', bool);

export const setOrganizations = (state = initialState, { organizations }) =>
  state.set('organizations', fromJS(organizations));

export const setActivities = (state = initialState, { activities }) => {
  return state.set('activities', fromJS(activities));
};

export const addActivities = (state = initialState, { activities }) => {
  const filteredActivities = activities.filter(
    (newAct) =>
      undefined === state.get('activities').find((currAct) => newAct._id === currAct.get('_id')),
  );
  return state.set('activities', state.get('activities').concat(fromJS(filteredActivities)));
};

export const deleteActivity = (state = initialState, { _id }) =>
  state.update('activities', (activities) => activities.filter((act) => act.get('_id') !== _id));

export const setLiveProfiles = (state = initialState, { liveProfiles }) =>
  state.set('liveProfiles', fromJS(liveProfiles));

export const addLiveProfile = (state = initialState, { liveProfile }) =>
  state.update('liveProfiles', (liveProfiles) =>
    (liveProfiles ?? []).insert(0, fromJS(liveProfile)),
  );

export const updateLiveProfile = (state = initialState, { _id, body }) =>
  state.update('liveProfiles', (liveProfiles) => {
    const idx = liveProfiles ? liveProfiles.findIndex((value) => value.get('_id') === _id) : -1;
    if (idx !== -1) return liveProfiles.update(idx, () => fromJS(body));
    return liveProfiles;
  });

// Won't work without the "action" param
export const resetUser = (state = initialState, action) =>
  initialState.set('isAuthenticated', !!getToken());

export const setIsRefreshingToken = (state = initialState, { isRefreshingToken }) =>
  state.set('isRefreshingToken', isRefreshingToken);

export const setPasswordResetState = (state = initialState, { resetState }) =>
  state.set('passwordResetState', resetState);

export default createReducer(initialState, {
  [Types.SET_USER]: setUser,
  [Types.SET_IS_AUTHENTICATED]: setIsAuthenticated,
  [Types.SET_ORGANIZATIONS]: setOrganizations,
  [Types.SET_ACTIVITIES]: setActivities,
  [Types.ADD_ACTIVITIES]: addActivities,
  [Types.DELETE_ACTIVITY]: deleteActivity,
  [Types.RESET_USER]: resetUser,
  [Types.SET_LIVE_PROFILES]: setLiveProfiles,
  [Types.ADD_LIVE_PROFILE]: addLiveProfile,
  [Types.UPDATE_LIVE_PROFILE]: updateLiveProfile,
  [Types.SET_IS_REFRESHING_TOKEN]: setIsRefreshingToken,
  [Types.SET_PASSWORD_RESET_STATE]: setPasswordResetState,
});
