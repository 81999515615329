import { createRef } from 'react';
import { CommonActions, StackActions } from '@react-navigation/native';
import platformHelpers from './platform';

export const isReadyRef = createRef();

export const navigationRef = createRef();

export const navigate = (name, params) => {
  // eslint-disable-next-line no-unused-expressions
  if (isReadyRef.current && navigationRef.current) navigationRef.current?.navigate(name, params);
};

// Use push instead of navigate when you want to navigate within the same screen and be able to go back.
export const push = (name, params) => {
  if (isReadyRef.current && navigationRef.current) {
    const pushAction = StackActions.push(name, params);
    navigationRef.current.dispatch(pushAction);
  }
};

export const reset = (name, params) => {
  if (isReadyRef.current && navigationRef.current) {
    const resetAction = CommonActions.reset({
      index: 0,
      routes: [
        {
          name,
          params,
        },
      ],
    });
    navigationRef.current.dispatch(resetAction);
  }
};

export const getDomain = () => platformHelpers.getDomain();

export const getOrigin = () => platformHelpers.getOrigin();

export default {
  isReadyRef,
  navigationRef,
  navigate,
  getDomain,
};
