import platformHelpers from './platform';

const TokenType = {
  NORMAL: 'token',
  REFRESH: 'refreshToken',
};

// Normal Token Access :

export const setToken = (token) => platformHelpers.storageSetter(TokenType.NORMAL, token);

export const getToken = () => platformHelpers.storageGetter(TokenType.NORMAL);

export const removeToken = () => platformHelpers.storageRemover(TokenType.NORMAL);

// Refresh Token Access :

export const setRefreshToken = (token) => platformHelpers.storageSetter(TokenType.REFRESH, token);

export const getRefreshToken = () => platformHelpers.storageGetter(TokenType.REFRESH);

export const removeRefreshToken = () => platformHelpers.storageRemover(TokenType.REFRESH);

// Auth Type Access :

export const setAuthType = (authType) => platformHelpers.storageSetter('authType', authType);

export const getAuthType = () => platformHelpers.storageGetter('authType');

export const removeAuthType = () => platformHelpers.storageRemover('authType');

/**
 * Get the JWT Token and return it has an HTTP Header
 *
 * @returns {object} Returns { Authorization: `Bearer ${token}` } or empty Object
 */
export const getAuthHeader = () => {
  const token = getToken();

  if (token) return { Authorization: `Bearer ${token}` };
  return {};
};

export default {
  setToken,
  getToken,
  removeToken,
  setRefreshToken,
  getRefreshToken,
  removeRefreshToken,
  getAuthHeader,
};
