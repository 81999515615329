import React, { useEffect, useState } from 'react';
import { View, StyleSheet, Text } from 'react-native';
import PropTypes from 'prop-types';

import { Player } from '@kalyzee/kast-react-player-module';

import { useTranslation } from 'react-i18next';
import VodCard from '../../components/Vod/card';

import fonts from '../../constants/fonts';
import colors from '../../constants/colors';
import { Strings } from '../../helpers/strings';
import BackButton from '../../components/Generic/backButton';
import { useHorizontalScroll, useResponsiveThreshold } from '../../hooks/element';
import { RESPONSIVE_THRESHOLD } from '../../constants/global';
import { VodState } from '../../constants/vod';

const styles = StyleSheet.create({
  main: {
    backgroundColor: colors.getMirage(),
    maxHeight: '100vh',
    height: '100vh',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 20,
    paddingHorizontal: 20,
  },
  mainMobile: {
    paddingHorizontal: 0,
  },
  playerContainer: {
    flex: 1,
    justifyContent: 'center',
    paddingBottom: 20,
    width: '100%',
  },
  player: {
    maxHeight: '100%',
  },
  noDataContainer: {
    flexGrow: 1,
    justifyContent: 'center',
  },
  vodSelector: {
    flexGrow: 0,
    marginBottom: 20,
    paddingBottom: 10,
    width: '100%',
    flexDirection: 'row',
    overflowX: 'scroll',
    gap: 10,
  },
  icon: {
    height: 50,
    width: 50,
    padding: 30,
  },
  title: {
    fontFamily: fonts.getSignika(),
    color: colors.getBluePrussian(),
    fontSize: 20,
    fontWeight: 600,
  },
  subTitle: {
    fontFamily: fonts.getSignika(),
    color: colors.getBluePrussian(),
    fontSize: 18,
    fontWeight: 300,
  },
  noDataText: {
    color: colors.getMountainMeadow(),
    fontWeight: '500',
    fontSize: 20,
  },
});

const ActivityVod = ({ vodSessions, selectedVodId, onSelect, requestActivityRefresh }) => {
  const { t } = useTranslation();
  const viewSize = useResponsiveThreshold();
  const [selectedVod, setSelectedVod] = useState(undefined);
  const scrollRef = useHorizontalScroll(3);
  const [wasRefreshed, setWasRefreshed] = useState(false);
  // prevent the page from being refrehed in circle if there's an issue and a terminated VOD
  // does not have a video url.

  useEffect(() => {
    if (wasRefreshed) setWasRefreshed(false);
    if (vodSessions && vodSessions.length > 0) {
      if (selectedVodId) {
        const vod = vodSessions.find((item) => item.id === selectedVodId);
        if (vod) {
          setSelectedVod(vod);
          return;
        }
      }
      setSelectedVod(vodSessions[0]);
      return;
    }
    if (selectedVod) setSelectedVod(undefined);
  }, [vodSessions, selectedVodId]);

  const renderPlayer = () => {
    const locations = selectedVod?.contentLocations;
    if (locations && locations.length > 0) {
      const { videoUrl } = locations[0];
      if (videoUrl) {
        return (
          <View style={styles.playerContainer}>
            <Player
              style={{
                maxHeight: '100%',
              }}
              src={videoUrl}
              srcIsHls
              enableVideoControls
              enableVideoProgress
              displayTime="normal"
              loop={false}
              playAndPauseAtClick={true}
              displayPlay={true}
            />
          </View>
        );
      }
    }
    if (selectedVod?.state === VodState.Terminated && !wasRefreshed) {
      requestActivityRefresh();
      setWasRefreshed(true);
    }
    return (
      <View style={styles.noDataContainer}>
        <Text style={styles.noDataText}>{t(Strings.NO_DATA_FOUND)}</Text>
      </View>
    );
  };

  const handleVodCardClick = (vodId) => {
    if (onSelect) onSelect(vodId);
  };

  const renderVodCard = (vod) => {
    const selectedStyle = { border: `3px dashed ${colors.getMountainMeadow()}` };
    const extraStyle = vod._id === selectedVodId ? selectedStyle : undefined;
    return (
      <VodCard
        key={vod._id}
        style={extraStyle}
        vodSession={vod}
        onClick={() => handleVodCardClick(vod._id)}
      />
    );
  };

  const renderVodSelector = () => {
    return (
      <View ref={scrollRef} style={styles.vodSelector}>
        {vodSessions?.map((vod) => renderVodCard(vod))}
      </View>
    );
  };

  return (
    <View style={[styles.main, viewSize === RESPONSIVE_THRESHOLD.SMALL ? styles.mainMobile : null]}>
      <BackButton style={{ top: '25px', left: '25px' }} />
      {renderPlayer()}
      {renderVodSelector()}
    </View>
  );
};

ActivityVod.propTypes = {
  vodSessions: PropTypes.array.isRequired,
  selectedVodId: PropTypes.string,
  onSelect: PropTypes.func,
  requestActivityRefresh: PropTypes.func,
};

export default ActivityVod;
