export const sGlobal = {
  padding: 15,
};

export const sNavBarTop = {
  LARGE: {
    height: 80,
  },
  MEDIUM: {
    height: 60,
  },
  SMALL: {
    height: 40,
  },
};

export const sCameraControlChevron = {
  height: 30,
};

export const sVideoControlRow = {
  height: 100,
};

export default {
  sGlobal,
  sNavBarTop,
  sCameraControlChevron,
  sVideoControlRow,
};
